import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

// Icons (same imports)
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

// Images
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import BeforeSpace from "./Images/curtain-effect-hero/BeforeSpace.png";
import AfterSpace from "./Images/curtain-effect-hero/AfterSpace.png";
import BeforeSpaceExample1 from "./Images/curtain-effect/BeforeSpaceStaging1.png";
import AfterSpaceExample1 from "./Images/curtain-effect/AfterSpaceStaging1.png";
import BeforeSpaceExample2 from "./Images/curtain-effect/BeforeSpaceStaging2.png";
import AfterSpaceExample2 from "./Images/curtain-effect/AfterSpaceStaging2.png";
import BeforeSpaceExample3 from "./Images/curtain-effect/BeforeSpaceStaging3.png";
import AfterSpaceExample3 from "./Images/curtain-effect/AfterSpaceStaging3.png";
import VirtualStagingExample1 from "./Images/VirtualStagingExample1.png";
import VirtualStagingExample2 from "./Images/VirtualStagingExample2.png";
import VirtualStagingExample3 from "./Images/VirtualStagingExample3.png";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function HomeStagingLocalEs() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);

  const [example, setExample] = useState({
    name: "Local",
    beforeImage: BeforeSpace,
    afterImage: AfterSpace,
  });

  const handleExample = (example) => {
    if (example === "Local") {
      setExample({
        name: "Local",
        beforeImage: BeforeSpace,
        afterImage: AfterSpace,
      });
    } else if (example === "Oficina") {
      setExample({
        name: "Oficina",
        beforeImage: BeforeSpaceExample1,
        afterImage: AfterSpaceExample1,
      });
    } else if (example === "Restaurante") {
      setExample({
        name: "Restaurante",
        beforeImage: BeforeSpaceExample2,
        afterImage: AfterSpaceExample2,
      });
    } else {
      setExample({
        name: "Tienda",
        beforeImage: BeforeSpaceExample3,
        afterImage: AfterSpaceExample3,
      });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "¿Qué es el home staging para locales comerciales?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "El home staging para locales comerciales es una técnica de valorización inmobiliaria que utiliza la IA para transformar fotos de espacios comerciales en visualizaciones profesionales. Esta tecnología te permite crear ambientaciones fotorrealistas de espacios comerciales con diferentes estilos, acabados y diseños sin necesidad de modelado 3D manual o software complejo.",
        },
      },
      {
        "@type": "Question",
        name: "¿Cuáles son los beneficios del home staging para locales comerciales?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "El home staging para locales comerciales ofrece varios beneficios, incluyendo: transformación instantánea de espacios comerciales, capacidad para visualizar múltiples opciones de diseño rápidamente, resultados fotorrealistas de alta calidad, ahorro significativo de tiempo y costos en comparación con el home staging tradicional, y flexibilidad para experimentar con diferentes configuraciones y layouts.",
        },
      },
      {
        "@type": "Question",
        name: "¿Cómo funciona el proceso de home staging para locales comerciales?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "El proceso implica subir fotos del local comercial actual a nuestra plataforma, seleccionar las transformaciones deseadas (como cambiar acabados, agregar elementos o modificar diseños) y recibir visualizaciones fotorrealistas que muestran el espacio transformado en minutos. Nuestra tecnología de IA maneja todo el trabajo complejo de ambientación automáticamente.",
        },
      },
      {
        "@type": "Question",
        name: "¿Cuánto cuesta el home staging para locales comerciales?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Nuestro servicio de home staging para locales comerciales está disponible por €29 más IVA al mes. Este plan te permite generar hasta 100 transformaciones por mes, sin compromiso a largo plazo.",
        },
      },
    ],
  };

  const [stagingExample, setStagingExample] = useState({
    name: "ejemploLocal1",
    beforeImage: BeforeSpaceExample1,
    afterImage: AfterSpaceExample1,
  });

  const stagingExamples = [
    {
      name: "ejemploLocal1",
      beforeImage: BeforeSpaceExample1,
      afterImage: AfterSpaceExample1,
    },
    {
      name: "ejemploLocal2",
      beforeImage: BeforeSpaceExample2,
      afterImage: AfterSpaceExample2,
    },
    {
      name: "ejemploLocal3",
      beforeImage: BeforeSpaceExample3,
      afterImage: AfterSpaceExample3,
    },
  ];

  const handleStagingExample = (exampleName) => {
    const selectedExample = stagingExamples.find(
      (example) => example.name === exampleName
    );
    if (selectedExample) {
      setStagingExample(selectedExample);
    }
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Home Staging para Locales Comerciales</h1>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2 style={{ fontSize: "20px", marginTop: "12px" }}>
                  Crea ambientaciones fotorrealistas de locales comerciales
                  automáticamente
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Instantáneo:</strong> Genera renders en segundos
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Sencillo:</strong> No requiere conocimientos
                    técnicos
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Realista:</strong> Resultados fotorrealistas
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Soporte:</strong> Asistencia experta disponible
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Prueba Pedra <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        €29 por 100 renders
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa, Director de Fotografía en aProperties"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera, Agente Inmobiliario en Huspy"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata, CEO de Actívox"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart, CEO de Gilart Consultors"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      ></img>
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        Más de 100 testimonios
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore="Antes del render del local comercial"
                  altAfter="Después del render del local comercial"
                />

                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Local" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Local")}
                    >
                      Local Comercial
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Oficina" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Oficina")}
                    >
                      Oficina
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Restaurante" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Restaurante")}
                    >
                      Restaurante
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Tienda" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Tienda")}
                    >
                      Tienda
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          MÁS DE 20.000 PROFESIONALES YA CONFÍAN EN PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Crea ambientaciones profesionales de locales comerciales{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            automáticamente
          </span>{" "}
          en segundos.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h3 className="feature-title">
                  Transforma espacios al instante
                </h3>
                <div className="feature-text">
                  Genera renders fotorrealistas con un solo clic
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">Múltiples opciones de diseño</h3>
                <div className="feature-text">
                  Visualiza diferentes layouts y configuraciones fácilmente
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">Perspectivas perfectas</h3>
                <div className="feature-text">
                  Corrección y optimización automática de ángulos de cámara
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h3 className="feature-title">Iluminación automática</h3>
                <div className="feature-text">
                  Ajustes profesionales de iluminación en cada render
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h3 className="feature-title">Cambio de materiales</h3>
                <div className="feature-text">
                  Actualiza superficies y acabados automáticamente
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h3 className="feature-title">Limpieza de escena</h3>
                <div className="feature-text">
                  Elimina objetos no deseados automáticamente
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Ejemplos de nuestras transformaciones de home staging para locales
          comerciales.
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="hero-example-container">
          <CurtainEffect
            beforeImage={stagingExample.beforeImage}
            afterImage={stagingExample.afterImage}
            altBefore="Antes del render del local comercial"
            altAfter="Después del render del local comercial"
          />

          <div style={{ display: "flex", flex: "1 0 0" }}></div>
          <div className="hero-example-buttons-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "6px",
                width: "100%",
              }}
            >
              {stagingExamples.map((item) => (
                <div
                  key={item.name}
                  className={`hero-button ${
                    stagingExample.name === item.name ? "selected" : ""
                  }`}
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    borderRadius: "8px",
                    overflow: "hidden",
                    height: "50px",
                    padding: "0px",
                  }}
                  onClick={() => handleStagingExample(item.name)}
                >
                  <img
                    src={item.beforeImage}
                    alt={item.name}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "6px",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container" style={{ display: "inline" }}>
          ¿Cómo funciona el home staging para locales comerciales?
        </h3>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASO 1
            </h5>
            <h2>Sube tu foto del local comercial</h2>
            <div className="value-prop-text">
              Sube cualquier foto de tu local comercial a la plataforma de
              Pedra.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample1}
              alt="Cómo subir fotos de locales comerciales para renderizado automático en Pedra – Infografía"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASO 2
            </h5>
            <h2>Elige tus opciones de renderizado</h2>
            <div className="value-prop-text">
              Selecciona cómo quieres transformar tu espacio.
              <p>
                Cambia materiales, actualiza la iluminación, modifica el layout,
                añade elementos y más.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample2}
              alt="Cómo elegir opciones para el renderizado automático de locales comerciales – Infografía"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASO 3
            </h5>
            <h2>Obtén tus renders fotorrealistas</h2>
            <div className="value-prop-text">
              Recibe tus imágenes del local comercial renderizadas
              profesionalmente en segundos.
              <p>Descarga y usa tus renders inmediatamente.</p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample3}
              alt="Cómo descargar renders automáticos de locales comerciales – Infografía"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Home staging profesional para locales comerciales por €29 al mes.{" "}
          <br />{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            Sin compromiso de permanencia.
          </span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Plan Pro
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">€29</div>
              <div className="modal-product-plan-description-text-3">
                al mes
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">Incluye</div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#B15DE1" }}>
                100 transformaciones
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>al mes</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Transforma espacios al instante
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Cambia materiales y acabados
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Añade nuevos elementos
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Limpieza automática de escena
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Salida en alta resolución
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Múltiples opciones de layout
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Iluminación profesional
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Proyectos ilimitados
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Compartir ilimitado
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Marcas de agua personalizadas
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Soporte prioritario
            </div>
            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Prueba Pedra
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Cancela cuando quieras.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Renders adicionales disponibles por €0,20 cada uno si los
              necesitas.
            </div>
          </div>
        </div>
      </div>

      <div className="long-break"> </div>

      <div className="section-one-column-left">
        <div className="title-container">
          Preguntas sobre el home staging para locales comerciales
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">
                ¿Qué es el home staging para locales comerciales?
              </h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                El home staging para locales comerciales utiliza tecnología de
                IA avanzada para transformar fotos normales de espacios
                comerciales en ambientaciones profesionales. Esta tecnología te
                permite crear visualizaciones de alta calidad sin necesidad de
                modelado 3D manual o software complejo.
                <br />
                <br />
                El sistema maneja automáticamente la iluminación, materiales,
                perspectivas y otros aspectos técnicos para producir resultados
                profesionales al instante.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                ¿Cuáles son los beneficios del home staging para locales
                comerciales?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                El home staging para locales comerciales ofrece numerosas
                ventajas:
                <br />
                <br />
                1. Velocidad: Transforma espacios en segundos, no en horas o
                días
                <br />
                2. Rentable: No necesitas muebles ni accesorios físicos costosos
                <br />
                3. Fácil de usar: No requiere experiencia en decoración
                <br />
                4. Múltiples opciones: Prueba diferentes estilos rápidamente
                <br />
                5. Resultados fotorrealistas: Ambientación de calidad
                profesional
                <br />
                6. Flexibilidad: Realiza cambios al instante
                <br />
                7. Consistencia: Resultados fiables en cada uso
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                ¿Qué puedo hacer con el home staging de locales comerciales de
                Pedra?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                El sistema de home staging para locales comerciales de Pedra
                ofrece una amplia gama de capacidades:
                <br />
                <br />
                1. Ambientación virtual: Crea visualizaciones fotorrealistas
                desde cualquier foto de espacio comercial al instante.
                <br />
                2. Optimización de iluminación: Mejora automáticamente la
                iluminación y sombras para resultados profesionales.
                <br />
                3. Cambios de materiales: Modifica superficies, suelos, paredes
                y otros materiales con un clic.
                <br />
                4. Añadir mobiliario: Inserta nuevo mobiliario y elementos
                decorativos automáticamente.
                <br />
                5. Limpieza de escena: Elimina objetos no deseados y desorden
                automáticamente.
                <br />
                6. Visualización de layouts: Prueba diferentes configuraciones
                del espacio rápidamente.
                <br />
                <br />
                Todas las transformaciones son gestionadas automáticamente por
                nuestra IA, entregando resultados profesionales en segundos.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeStagingLocalEs;
