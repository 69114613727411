// RenderEs.js
import React, { useState } from "react";
import CustomerLogos from "./CustomerLogos.js";

//Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";

//Images
import RealEstatePhotographyEsPage1 from "./Images/RealEstatePhotographyEsPage1En.png";
import RealEstatePhotographyEsPage2 from "./Images/RealEstatePhotographyEsPage2En.png";
import RealEstatePhotographyEsPage3 from "./Images/RealEstatePhotographyEsPage3En.png";
import RealEstatePhotographyEsPage4 from "./Images/RealEstatePhotographyEsPage4En.png";
import RealEstatePhotographyEsPage5 from "./Images/RealEstatePhotographyEsPage5En.jpeg";
import RealEstatePhotographyEsPage6 from "./Images/RealEstatePhotographyEsPage6En.jpeg";
import iadLogo from "./Images/iadLogo.png";
import OrangeFlowerIcon from "./Icons/OrangeFlowerIcon.jsx";
import OrangeSunIcon from "./Icons/OrangeSunIcon.jsx";
import OrangeHDIcon from "./Icons/OrangeHDIcon.jsx";
import OrangeCleanerIcon from "./Icons/OrangeCleanerIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import OrangeBlueSky from "./Icons/OrangeBlueSky.jsx";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import posterImageVideo from "./Images/poster-images/poster-image-video.png";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function RealEstatePhotographyPt() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);
  return (
    <div>
      <section className="section-first-wrapper">
        <section className="section-first">
          <div className="section-first-text-container">
            <h1 className="section-first-title">
              Melhore suas fotografias imobiliárias com IA em 1 clique
            </h1>
            <div className="section-first-text">
              <div
                className="section-first-text-bold"
                style={{ width: "100%" }}
              >
                Use a IA da Pedra para melhorar facilmente suas fotografias
                imobiliárias
              </div>
              <div className="section-first-text-gray">
                Corrija perspectivas, melhore a iluminação e remova o
                desnecessário
              </div>
            </div>
            <div className="section-first-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Experimente Pedra <ArrowIcon />
              </button>
            </div>
            <div className="testimonials-on-header-container">
              <div className="testimonials-on-header-image-container">
                <img
                  className="testimonials-on-header-image"
                  src={VictorLaviosa}
                  alt="Victor Laviosa, Diretor de Fotografia na aProperties"
                />
                <img
                  className="testimonials-on-header-image"
                  src={Soma}
                  alt="Francisco Somacarrera, Corretor de Imóveis na Huspy"
                />
                <img
                  className="testimonials-on-header-image"
                  src={DanieldelaMata}
                  alt="Daniel de la Mata, CEO da Actívox"
                />
                <img
                  className="testimonials-on-header-image"
                  src={MariaJoseGilart}
                  alt="Maria José Gilart, CEO da Gilart Consultors"
                />
                <img
                  className="testimonials-on-header-image"
                  src={EmanuelaCosma}
                  alt="Emanuela Cosma"
                />
              </div>
              <div>
                <div className="testimonials-on-header-stars">★★★★★</div>
                <p className="testimonials-on-header-text">
                  Mais de 20.000 profissionais imobiliários já usam a Pedra
                </p>
              </div>
            </div>
          </div>
          <div className="header-picture">
            <div className="header-video-frame">
              <div className="header-video-frame-circles-container">
                <div className="header-video-frame-circle"></div>
                <div className="header-video-frame-circle"></div>
                <div className="header-video-frame-circle"></div>
              </div>
              <div className="video-container">
                <video
                  className="header-video"
                  width="100%"
                  height="auto"
                  playsInline
                  autoPlay
                  muted
                  loop
                  preload="auto"
                  poster={posterImageVideo}
                >
                  <source
                    src="https://pedraimages.s3.eu-west-3.amazonaws.com/Pedra+demo+video+09+Jul+24+English.mp4"
                    type="video/mp4"
                  />
                  Seu navegador não suporta a tag de vídeo.
                </video>
              </div>
            </div>
          </div>
        </section>
      </section>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          MAIS DE 20.000 PROFISSIONAIS IMOBILIÁRIOS JÁ CONFIAM NA PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <h2 className="title-container">
          Melhore sua fotografia imobiliária em 1 clique
        </h2>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeFlowerIcon />
                <h2 className="feature-title">
                  Aprimore facilmente suas fotos de imóveis
                </h2>
                <div className="feature-text">
                  Faça upload de suas imagens e clique em "Embelezar e
                  corrigir". A Pedra cuidará de melhorar a luz e cor, e corrigir
                  as perspectivas.
                </div>
              </div>
              <img
                src={RealEstatePhotographyEsPage1}
                alt="Corrija perspectiva, cor e luz com IA"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h2 className="feature-title">
                  Remova objetos desnecessários de suas fotos de imóveis
                </h2>
                <div className="feature-text">
                  A IA da Pedra removerá objetos dos ambientes, como fotos de
                  família, bandeiras, quadros e muito mais.
                </div>
              </div>
              <img
                src={RealEstatePhotographyEsPage2}
                alt="Remova objetos de fotografias imobiliárias"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeSunIcon />
                <h2 className="feature-title">
                  Ilumine automaticamente ambientes muito escuros
                </h2>
                <div className="feature-text">
                  Use a IA da Pedra para corrigir a iluminação em ambientes onde
                  está muito escuro.
                </div>
              </div>
              <img
                src={RealEstatePhotographyEsPage3}
                alt="Ilumine ambientes muito escuros"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeHDIcon />
                <h2 className="feature-title">
                  Aumente a resolução de suas fotos de imóveis para HD
                </h2>
                <div className="feature-text">
                  Não se preocupe com fotos de baixa resolução. Com a Pedra,
                  você pode aumentar a resolução de suas imagens para HD com
                  apenas um clique.
                </div>
              </div>
              <img
                src={RealEstatePhotographyEsPage4}
                alt="Ferramenta de aprimoramento de resolução HD para paredes e pisos através de renders com IA"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeCleanerIcon />
                <h2 className="feature-title">
                  Mude materiais de paredes e pisos de imóveis com IA
                </h2>
                <div className="feature-text">
                  Use IA para mudar o material de pisos e paredes. Se você tem
                  um imóvel com grama mal conservada, com a Pedra você pode
                  mostrá-lo em sua melhor versão.
                </div>
              </div>
              <img
                src={RealEstatePhotographyEsPage5}
                alt="Ferramenta para mudança de paredes e pisos via renders com IA"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeBlueSky />
                <h2 className="feature-title">Coloque o céu azul com IA</h2>
                <div className="feature-text">
                  Use a IA da Pedra para corrigir um dia ruim. Não deixe que o
                  clima estrague a sessão de fotos do seu imóvel.
                </div>
              </div>
              <img
                src={RealEstatePhotographyEsPage6}
                alt="Coloque o céu azul com IA"
              />
            </div>
          </div>
        </div>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h3 className="quote">"É a descoberta do século"</h3>
            <div className="quote-author-block">
              <img
                style={{
                  maxWidth: "45px",
                  height: "auto",
                }}
                className="quote-author-image"
                src={iadLogo}
                alt="Logo da iad para Pedra"
              />

              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Bouchra</div>
                <div className="quote-author-company">iad Espanha</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br></br>
      <br></br>
      <div className="section-first-button-container">
        <button className="button-top-of-page" onClick={redirectToPage}>
          Experimente Pedra <ArrowIcon />
        </button>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">O que é a Pedra?</h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                A Pedra é uma aplicação de IA projetada para corretores e
                empresas imobiliárias.
                <br />
                <br />
                O objetivo da Pedra é ajudar você a vender mais reduzindo
                custos.
                <br />
                <br />
                Com um único clique, você pode melhorar as fotografias dos seus
                imóveis, otimizando luz, cor e corrigindo perspectivas ou
                definição. Isso economiza horas de edição no Photoshop,
                Lightroom ou com fotógrafos profissionais.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Quais recursos o kit de aprimoramento de fotos da Pedra inclui?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                <div>
                  O kit de aprimoramento de fotografia imobiliária da Pedra
                  inclui os seguintes recursos:
                </div>
                <h4>Correção automática de perspectiva:</h4>Com a Pedra, você
                pode endireitar e corrigir as perspectivas de suas fotografias
                automaticamente com um único clique. Isso economiza tempo e
                esforço. A funcionalidade geralmente leva 5 segundos.
                <br />
                <br />
                <h4>Embelezamento automático de fotos:</h4>Melhore a luz e cor
                de suas fotografias imobiliárias com um único botão. Clique e a
                Pedra aprimorará as cores e luzes, tornando a fotografia mais
                atraente. A funcionalidade também leva 5 segundos.
                <br />
                <br />
                <h4>Correção e embelezamento de fotografias:</h4>Você também
                pode melhorar a luz, cor e perspectiva de suas fotos
                simultaneamente. Esta funcionalidade também leva 5 segundos.
                <br />
                <br />
                <h4>Iluminação automática de fotografias:</h4>Se você tem fotos
                muito escuras, com a Pedra você pode iluminá-las para que fiquem
                claras. Assim, seus imóveis serão apresentados de maneira mais
                atraente, melhorando o relacionamento com o proprietário e
                aumentando as chances de venda.
                <br />
                <br />
                <h4>Aumento de resolução de fotografias para HD:</h4>Você também
                pode aumentar a resolução de suas fotografias para HD com um
                único clique. Basta selecionar a opção "Converter para HD".
                Fotografias de maior qualidade melhoram a presença do seu
                anúncio nos principais portais imobiliários.
                <br />
                <br />
                <h4>Remoção de objetos:</h4>Com a Pedra, você também pode
                remover qualquer tipo de objeto das fotografias imobiliárias,
                como fotos de família, lixo, quadros, cruzes, bandeiras, etc.
                Basta usar o pincel da Pedra nos objetos que deseja remover e
                selecionar "Gerar imagem".
                <br />
                <br />
                <h4>Remoção de marca d'água:</h4>Você pode remover marcas d'água
                de outras imobiliárias usando a mesma funcionalidade "Remover
                objeto".
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                Por que devo usar a Pedra para melhorar minhas fotografias
                imobiliárias?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Melhorar suas fotografias imobiliárias antes de publicá-las pode
                ter um impacto significativo na forma como compradores ou
                locatários em potencial percebem seus imóveis. Aqui estão
                algumas razões principais:
                <br />
                <h4>Primeira impressão:</h4> A fotografia é frequentemente o
                primeiro contato que um interessado tem com seu imóvel. Imagens
                de alta qualidade criam uma primeira impressão positiva, que
                pode influenciar significativamente a decisão de explorar mais o
                imóvel.
                <br />
                <h4>Destaque características:</h4> Um bom retoque fotográfico
                pode destacar as melhores características do seu imóvel, como a
                amplitude dos espaços, iluminação natural e acabamentos de
                qualidade. Isso pode ajudar potenciais compradores a visualizar
                o valor do imóvel.
                <br />
                <h4>Diferenciação no mercado:</h4> O mercado imobiliário pode
                ser muito competitivo. Fotografias bem produzidas podem fazer
                seu imóvel se destacar entre outros no mercado, atraindo mais
                interesse e possivelmente acelerando a venda ou locação. Imagens
                atraentes e de qualidade também tendem a gerar mais interação em
                plataformas de listagem online e redes sociais. Isso pode
                aumentar a visibilidade do seu anúncio e atrair mais
                interessados.
                <br />
                <h4>Redução do tempo no mercado:</h4> Imóveis com imagens
                atraentes tendem a vender ou alugar mais rápido. Compradores
                estão mais inclinados a tomar decisões rápidas quando as imagens
                fornecem uma boa representação do imóvel.
                <br />
                <h4>Promoção efetiva:</h4> Imagens de alta qualidade são
                essenciais para a promoção efetiva de qualquer imóvel. Não só
                são úteis para anúncios online, mas também para brochuras,
                apresentações e outros materiais de marketing. Em resumo,
                investir tempo e recursos no aprimoramento de suas fotografias
                imobiliárias pode resultar em uma melhor percepção dos seus
                imóveis, diferenciação no mercado e uma venda ou locação mais
                rápida.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ4(!FAQ4)}>
              <h3 className="question-title">Quanto custa a Pedra?</h3>
              {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ4 && (
              <div className="answer-container">
                A Pedra custa €29 por mês e é uma assinatura mensal sem
                compromisso. Se você decidir não usar mais, pode cancelar a
                qualquer momento.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RealEstatePhotographyPt;
