import React, { useState } from "react";
import CheckIcon from "../Icons/CheckIcon";
import CustomerLogos from "../CustomerLogos";
import DownloadIcon from "../Icons/DownloadIcon";

const FreeImageFormatConverter = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [targetFormat, setTargetFormat] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [convertedFiles, setConvertedFiles] = useState([]);

  const supportedFormats = ["JPG", "PNG", "JPEG"];

  const isValidFileType = (file) => {
    const validTypes = ["image/jpeg", "image/jpg", "image/png"];
    return validTypes.includes(file.type);
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const invalidFiles = files.filter((file) => !isValidFileType(file));

    if (invalidFiles.length > 0) {
      setError(
        `Unsupported files: ${invalidFiles
          .map((f) => f.name)
          .join(", ")}. Only JPG, JPEG and PNG are accepted.`
      );
      return;
    }

    setSelectedFiles(files);
    setError("");
    setConvertedFiles([]);
  };

  const handleFormatChange = (e) => {
    setTargetFormat(e.target.value);
    setError("");
  };

  const convertImage = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const img = new Image();

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const maxDimension = 4096; // Prevent memory issues with very large images

          let width = img.width;
          let height = img.height;

          // Scale down if image is too large
          if (width > maxDimension || height > maxDimension) {
            const ratio = Math.min(maxDimension / width, maxDimension / height);
            width *= ratio;
            height *= ratio;
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext("2d", { willReadFrequently: true });

          // Set white background for PNG transparency
          if (targetFormat.toLowerCase() !== "png") {
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, canvas.width, canvas.height);
          }

          ctx.drawImage(img, 0, 0, width, height);

          let quality = 0.92;
          if (
            targetFormat.toLowerCase() === "jpg" ||
            targetFormat.toLowerCase() === "jpeg"
          ) {
            quality = 0.92; // High quality for JPG/JPEG
          }

          canvas.toBlob(
            (blob) => {
              if (!blob) {
                reject(new Error("Conversion error"));
                return;
              }
              resolve(blob);
            },
            `image/${targetFormat.toLowerCase()}`,
            quality
          );
        };

        img.onerror = () => reject(new Error("Error loading image"));
        img.src = reader.result;
      };

      reader.onerror = () => reject(new Error("Error reading file"));
      reader.readAsDataURL(file);
    });
  };

  const handleDownload = async (file) => {
    try {
      const originalFile = selectedFiles.find(
        (f) => f.name === file.originalName
      );
      if (!originalFile) {
        throw new Error("Original file not found");
      }

      setIsLoading(true);
      const convertedBlob = await convertImage(originalFile);

      // Verify converted file size
      if (convertedBlob.size === 0) {
        throw new Error("The converted file is empty");
      }

      const url = URL.createObjectURL(convertedBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = file.name;

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (err) {
      setError(`Error downloading ${file.name}: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadAll = async () => {
    setIsLoading(true);
    try {
      for (const file of convertedFiles) {
        await handleDownload(file);
      }
    } catch (err) {
      setError("Error downloading all files: " + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedFiles.length === 0) {
      setError("Please select at least one file");
      return;
    }
    if (!targetFormat) {
      setError("Please select a target format");
      return;
    }

    setIsLoading(true);
    setError("");

    try {
      const newConvertedFiles = selectedFiles.map((file) => ({
        name: `${file.name.split(".")[0]}.${targetFormat.toLowerCase()}`,
        size: file.size,
        originalName: file.name,
      }));

      setConvertedFiles(newConvertedFiles);
    } catch (err) {
      setError("Error during conversion: " + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="section-first-wrapper">
      <section className="section-first">
        <div className="section-first-text-container">
          <h1 className="section-first-title">
            Free Image Format Converter{" "}
            <span className="text-highlight">Free</span>
          </h1>
          <div className="section-first-text free-tool-value-propcontainer">
            <p className="free-tool-value-prop">
              <CheckIcon /> <span>100% free</span>
            </p>
            <p className="free-tool-value-prop">
              <CheckIcon /> <span>Instant conversion</span>
            </p>
            <p className="free-tool-value-prop">
              <CheckIcon /> <span>Multiple formats supported</span>
            </p>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="converter-form">
          <div>
            <label className="free-tool-form">
              <div className="free-tool-form-number-container">
                <div className="free-tool-form-number">1</div>
              </div>{" "}
              Select Images
            </label>
            <input
              type="file"
              multiple
              accept="image/*"
              onChange={handleFileChange}
              className="form-input"
            />
          </div>

          <div className="form-group">
            <label className="free-tool-form">
              <div className="free-tool-form-number-container">
                <div className="free-tool-form-number">2</div>
              </div>{" "}
              Target Format
            </label>
            <select
              value={targetFormat}
              onChange={handleFormatChange}
              className="form-select"
            >
              <option value="">Select format</option>
              {supportedFormats.map((format) => (
                <option key={format} value={format}>
                  {format}
                </option>
              ))}
            </select>
          </div>

          <br />

          <button
            type="submit"
            disabled={isLoading}
            className="free-tool-button"
          >
            {isLoading ? "Converting..." : "Convert images"}
          </button>
          <br />

          {convertedFiles.length > 0 && (
            <div className="form-group">
              <div className="converted-files-header">
                <h2 className="converted-files-title">Converted Files:</h2>
                <button
                  onClick={handleDownloadAll}
                  className="free-tool-button"
                >
                  <DownloadIcon />
                  Download images
                </button>
              </div>
            </div>
          )}

          {error && (
            <div className="error-message">
              <strong>Error:</strong> {error}
            </div>
          )}
        </form>

        <div className="step-container article" style={{ marginTop: "100px" }}>
          <article className="article-text">
            <h2 className="article-subtitleh2">
              What is an image format converter?
            </h2>
            <p>
              An image format converter is a tool that allows you to transform
              images from one format to another while maintaining the original
              quality. This tool is especially useful when you need images in
              specific formats for different platforms or uses.
            </p>
            <h2 className="article-subtitleh2">Supported formats</h2>
            <p>
              Our tool supports the most common and widely used image formats:
            </p>
            <ul>
              <li>
                JPG/JPEG - Ideal for photographs and images with many colors
              </li>
              <li>PNG - Perfect for images that require transparency</li>
            </ul>
            <h2 className="article-subtitleh2">Advantages of our converter</h2>
            <h3>Ease of use</h3>
            <p>
              Our tool is designed to be intuitive and easy to use. You just
              need to upload your images, select the desired format, and click
              convert. No technical knowledge required.
            </p>
            <h3>Batch conversion</h3>
            <p>
              Convert multiple images simultaneously, saving time and effort
              when you need to process multiple files.
            </p>
            <h3>Maintains quality</h3>
            <p>
              Our converter uses advanced algorithms to maintain the best
              possible quality during conversion, ensuring your images look
              excellent in their new format.
            </p>
            <h2 className="article-subtitleh2">
              Tips for choosing the right format
            </h2>
            <ul>
              <li>
                JPG/JPEG: Ideal for photographs and images with many colors.
                It's the most common format for digital photographs and offers
                good compression while maintaining acceptable visual quality.
              </li>
              <li>
                PNG: Best choice when you need transparency or for images with
                text, graphics, or defined edges. It's also excellent for
                screenshots and graphics with solid color areas.
              </li>
            </ul>
          </article>
        </div>

        <div className="customer-logo-container">
          OVER 20,000 USERS ALREADY TRUST OUR CONVERTER
          <CustomerLogos />
        </div>
      </section>
    </section>
  );
};

export default FreeImageFormatConverter;
