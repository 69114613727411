import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

// Icons (keeping same imports as they can be reused)
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

// Images (keeping same imports)
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";

import BeforeSalon from "./Images/BeforeRenovation1.png";
import AfterSalon from "./Images/AfterRenovation1.png";
import BeforeSalonStaging1 from "./Images/BeforeRenovation2.png";
import AfterSalonStaging1 from "./Images/AfterRenovation2.png";
import BeforeSalonStaging2 from "./Images/BeforeRenovation3.png";
import AfterSalonStaging2 from "./Images/AfterRenovation3.png";
import BeforeSalonStaging3 from "./Images/BeforeRenovation4.png";
import AfterSalonStaging3 from "./Images/AfterRenovation4.png";
import VirtualStagingExample1 from "./Images/VirtualStagingExample1.png";
import VirtualStagingExample2 from "./Images/VirtualStagingExample2.png";
import VirtualStagingExample3 from "./Images/VirtualStagingExample3.png";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function RenovationDe() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);

  const [example, setExample] = useState({
    name: "Modern",
    beforeImage: BeforeSalon,
    afterImage: AfterSalon,
  });

  const handleExample = (example) => {
    if (example === "Modern") {
      setExample({
        name: "Modern",
        beforeImage: BeforeSalon,
        afterImage: AfterSalon,
      });
    } else if (example === "Traditional") {
      setExample({
        name: "Traditional",
        beforeImage: BeforeSalonStaging1,
        afterImage: AfterSalonStaging1,
      });
    } else if (example === "Rustic") {
      setExample({
        name: "Rustic",
        beforeImage: BeforeSalonStaging2,
        afterImage: AfterSalonStaging2,
      });
    } else {
      setExample({
        name: "Contemporary",
        beforeImage: BeforeSalonStaging3,
        afterImage: AfterSalonStaging3,
      });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "Was sind Renovierungs-Renderings?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Renovierungs-Renderings sind fotorealistische Visualisierungen, die zeigen, wie ein Raum nach der Renovierung aussehen wird. Sie ermöglichen es Ihnen, das Endergebnis vor Beginn der Arbeiten zu sehen, was die Entscheidungsfindung und Kommunikation mit Kunden erleichtert.",
        },
      },
      {
        "@type": "Question",
        name: "Was sind die Vorteile von Renovierungs-Renderings?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Renovierungs-Renderings bieten mehrere Vorteile: Sie ermöglichen es, das Endergebnis vor Beginn der Arbeiten zu visualisieren, erleichtern die Projektgenehmigung, helfen bei der Erkennung potenzieller Probleme vor der Ausführung, verbessern die Kommunikation mit Kunden und Auftragnehmern und sind ein effektives Marketinginstrument für den Verkauf von Renovierungsprojekten.",
        },
      },
      {
        "@type": "Question",
        name: "Wie funktioniert der Prozess der Erstellung von Renovierungs-Renderings?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Der Prozess umfasst das Hochladen von Fotos des aktuellen Raums auf unsere Plattform, die Auswahl der gewünschten Transformationsoptionen (wie Änderungen bei Materialien, Oberflächen oder Layout) und den Erhalt fotorealistischer Renderings, die das Endergebnis der Renovierung zeigen.",
        },
      },
    ],
  };

  const [stagingExample, setStagingExample] = useState({
    name: "stagingExample1",
    beforeImage: BeforeSalonStaging1,
    afterImage: AfterSalonStaging1,
  });

  const stagingExamples = [
    {
      name: "stagingExample1",
      beforeImage: BeforeSalonStaging1,
      afterImage: AfterSalonStaging1,
    },
    {
      name: "stagingExample2",
      beforeImage: BeforeSalonStaging2,
      afterImage: AfterSalonStaging2,
    },
    {
      name: "stagingExample3",
      beforeImage: BeforeSalonStaging3,
      afterImage: AfterSalonStaging3,
    },
  ];

  const handleStagingExample = (exampleName) => {
    const selectedExample = stagingExamples.find(
      (example) => example.name === exampleName
    );
    if (selectedExample) {
      setStagingExample(selectedExample);
    }
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Renovierungs-Renderings</h1>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2 style={{ fontSize: "20px", marginTop: "12px" }}>
                  Die besten professionellen Renderings für Renovierungsprojekte
                  auf Knopfdruck
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Schnell:</strong> Visualisieren Sie Ihre Renovierung
                    in Sekunden
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Einfach:</strong> Erstellen Sie attraktive
                    Renderings mühelos
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Qualität:</strong> Professionelle fotorealistische
                    Ergebnisse
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Support:</strong>{" "}
                    Experten-Visualisierungsunterstützung
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Pedra testen <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        29€ für 100 Bilder
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa, Direktor für Fotografie bei aProperties"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera, Immobilienmakler bei Huspy"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata, CEO bei Actívox"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart, CEO bei Gilart Consultors"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      ></img>
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        Über 100 Bewertungen
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore={"Vor der Renovierung"}
                  altAfter={"Renovierungs-Rendering"}
                />

                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Modern" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Modern")}
                    >
                      Renovierung 1
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Traditional" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Traditional")}
                    >
                      Renovierung 2
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Rustic" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Rustic")}
                    >
                      Renovierung 3
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Contemporary" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Contemporary")}
                    >
                      Renovierung 4
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          ÜBER 20.000 PROFIS VERTRAUEN PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Renovierungs-Renderings zum{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            besseren Verkauf
          </span>{" "}
          jeder Art von Projekt.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h3 className="feature-title">
                  Visualisierung vor der Renovierung
                </h3>
                <div className="feature-text">
                  Zeigen Sie das Endergebnis vor Beginn der Arbeiten
                </div>
              </div>
            </div>
            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">
                  Wichtige Änderungen hervorheben
                </h3>
                <div className="feature-text">
                  Betonen Sie die wichtigsten Verbesserungen und
                  Transformationen
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">Mehrere Perspektiven</h3>
                <div className="feature-text">
                  Betrachten Sie die Renovierung aus verschiedenen Blickwinkeln
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h3 className="feature-title">
                  Realistische Materialien und Oberflächen
                </h3>
                <div className="feature-text">
                  Visualisieren Sie neue Oberflächen mit maximaler Realitätsnähe
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h3 className="feature-title">Layout-Änderungen</h3>
                <div className="feature-text">
                  Visualisieren Sie verschiedene Raumanordnungen
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h3 className="feature-title">Vorher und Nachher</h3>
                <div className="feature-text">
                  Vergleichen Sie vor und nach der Renovierung
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Beispiele unserer Renovierungs-Renderings
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="hero-example-container">
          <CurtainEffect
            beforeImage={stagingExample.beforeImage}
            afterImage={stagingExample.afterImage}
            altBefore={"Aktueller Zustand des Raums"}
            altAfter={"Renovierungs-Rendering"}
          />

          <div style={{ display: "flex", flex: "1 0 0" }}></div>
          <div className="hero-example-buttons-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "6px",
                width: "100%",
              }}
            >
              {stagingExamples.map((item) => (
                <div
                  key={item.name}
                  className={`hero-button ${
                    stagingExample.name === item.name ? "selected" : ""
                  }`}
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    borderRadius: "8px",
                    overflow: "hidden",
                    height: "50px",
                    padding: "0px",
                  }}
                  onClick={() => handleStagingExample(item.name)}
                >
                  <img
                    src={item.beforeImage}
                    alt={item.name}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "6px",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container" style={{ display: "inline" }}>
          Wie funktioniert der Prozess der Erstellung von
          Renovierungs-Renderings?
        </h3>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              SCHRITT 1
            </h5>
            <h2>Laden Sie Fotos des aktuellen Raums hoch</h2>
            <div className="value-prop-text">
              Laden Sie ein oder mehrere Bilder des zu renovierenden Raums bei
              Pedra hoch.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample1}
              alt="Wie man Fotos hochlädt, um Renovierungs-Renderings in Pedra zu erstellen – Infografik"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              SCHRITT 2
            </h5>
            <h2>Wählen Sie Renovierungsoptionen</h2>
            <div className="value-prop-text">
              Wählen Sie die Änderungen aus, die Sie in Ihrer Renovierung
              visualisieren möchten.
              <p>
                Änderungen bei Materialien, Layout, Oberflächen und weitere
                Optionen verfügbar.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample2}
              alt="Wie man Optionen auswählt, um Renovierungs-Renderings zu erstellen – Infografik"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              SCHRITT 3
            </h5>
            <h2>Erhalten Sie Ihre professionellen Renderings</h2>
            <div className="value-prop-text">
              In wenigen Minuten erhalten Sie fotorealistische Renderings Ihrer
              Renovierung.
              <p>
                Laden Sie die Bilder herunter und verwenden Sie sie zur
                Präsentation und zum Verkauf Ihres Projekts.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample3}
              alt="Wie man Renovierungs-Renderings herunterlädt – Infografik"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Renovierungs-Renderings für 29€ pro Monat. <br />{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            Keine Bindung.
          </span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Pro Plan
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">29€</div>
              <div className="modal-product-plan-description-text-3">
                pro Monat
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">Enthält</div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#B15DE1" }}>
                100 Bildgenerierungen
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>pro Monat</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Wohnzimmer renovieren und neu gestalten
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Wohnzimmer-Oberflächen ändern
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Möbel und Dekoration hinzufügen
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Unerwünschte Objekte entfernen
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Fotoauflösung erhöhen
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Wohnzimmer-Layout ändern
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Wohnzimmerfotografie verbessern
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unbegrenzte Grundrisse
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unbegrenztes Projekt-Sharing
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Wasserzeichen hinzufügen
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unbegrenzter Support
            </div>
            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Pedra testen
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Kündigen Sie, wann immer Sie möchten.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Kaufen Sie weitere Renderings für 0,20€ pro Bild, wenn Sie sie
              benötigen.
            </div>
          </div>
        </div>
      </div>

      <div className="long-break"> </div>

      <div className="section-one-column-left">
        <div className="title-container">
          Häufig gestellte Fragen zu Renovierungs-Renderings
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">
                Was sind Renovierungs-Renderings?
              </h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Renovierungs-Renderings sind fotorealistische Visualisierungen,
                die zeigen, wie ein Raum nach der Renovierung aussehen wird.
                Dieses Tool ermöglicht es Ihnen, das Endergebnis vor Beginn der
                Arbeiten zu sehen.
                <br />
                <br />
                Mit unserer fortschrittlichen Technologie erstellen wir
                realistische Bilder, die helfen:
                <br />
                - Verschiedene Renovierungsoptionen zu visualisieren
                <br />
                - Fundiertere Entscheidungen zu treffen
                <br />
                - Ideen besser mit Kunden zu kommunizieren
                <br />- Renovierungsprojekte effektiver zu verkaufen
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Was sind die Vorteile von Renovierungs-Renderings?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                Renovierungs-Renderings bieten zahlreiche Vorteile:
                <br />
                <br />
                1. Vorschau-Visualisierung: Sehen Sie das Endergebnis vor Beginn
                der Arbeiten
                <br />
                2. Zeit- und Kostenersparnis: Vermeiden Sie teure Änderungen
                während der Arbeiten
                <br />
                3. Bessere Kommunikation: Erleichtert das Verständnis mit Kunden
                <br />
                4. Verkaufsinstrument: Hilft beim besseren Verkauf von Projekten
                <br />
                5. Entscheidungsfindung: Ermöglicht den Vergleich verschiedener
                Optionen
                <br />
                6. Problemerkennung: Identifiziert potenzielle Probleme vor
                Arbeitsbeginn
                <br />
                7. Effektives Marketing: Verbessert die Projektpräsentation
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                Welche Arten von Renovierungen kann ich mit Pedra visualisieren?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Mit Pedra können Sie alle Arten von Renovierungen visualisieren:
                <br />
                <br />
                1. Materialänderungen: Böden, Wände, Decken, Oberflächen
                <br />
                2. Komplette Renovierungen: Visualisieren Sie vollständige
                Raumveränderungen
                <br />
                3. Layout-Änderungen: Neue Raumanordnungen
                <br />
                4. Stil-Updates: Modernisierung des Raums
                <br />
                5. Teilrenovierungen: Spezifische Änderungen in bestimmten
                Bereichen
                <br />
                6. Möblierung: Visualisieren Sie verschiedene
                Einrichtungsoptionen
                <br />
                7. Beleuchtung: Simulieren Sie verschiedene Beleuchtungskonzepte
                <br />
                <br />
                Alle Visualisierungen werden realistisch erstellt und
                ermöglichen es Ihnen, das Potenzial jeder Renovierung
                professionell zu präsentieren.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RenovationDe;
