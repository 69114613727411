import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

// Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import VideoPricingIcon from "./Icons/VideoPricingIcon.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import BathroomIcon from "./Icons/hero-icons/BathroomIcon.jsx";
import BedIcon from "./Icons/hero-icons/BedIcon.jsx";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";

// Images
import Bouchra from "./Images/Bouchra image.png";
import PaulaAharonian from "./Images/Paula Aharonian image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import RomainBury from "./Images/Romain Bury.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import NachoGarcia from "./Images/Nacho Garcia image.png";
import PurpleFurnishIcon from "./Icons/hero-icons/PurpleFurnishIcon.jsx";
import PurpleEnhanceIcon from "./Icons/hero-icons/PurpleEnhanceIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";
import BeforeFurnish from "./Images/curtain-effect-hero/BeforeFurnish2.png";
import AfterFurnish from "./Images/curtain-effect-hero/AfterFurnish2.png";
import BeforeBedroomStaging from "./Images/curtain-effect-hero/BeforeBedroomStaging.png";
import AfterBedroomStaging from "./Images/curtain-effect-hero/AfterBedroomStaging.png";
import BeforeTerraceStaging from "./Images/curtain-effect-hero/BeforeTerraceStaging.png";
import AfterTerraceStaging from "./Images/curtain-effect-hero/AfterTerraceStaging.png";
import BeforeBathroomStaging from "./Images/curtain-effect-hero/BeforeBathroomStaging.png";
import AfterBathroomStaging from "./Images/curtain-effect-hero/AfterBathroomStaging.png";
import BeforeStagingExample1 from "./Images/curtain-effect/BeforeStagingExample1.png";
import AfterStagingExample1 from "./Images/curtain-effect/AfterStagingExample1.png";
import BeforeStagingExample2 from "./Images/curtain-effect/BeforeStagingExample2.png";
import AfterStagingExample2 from "./Images/curtain-effect/AfterStagingExample2.png";
import BeforeStagingExample3 from "./Images/curtain-effect/BeforeStagingExample3.png";
import AfterStagingExample3 from "./Images/curtain-effect/AfterStagingExample3.png";
import BeforeStagingExample4 from "./Images/curtain-effect/BeforeStagingExample4.png";
import AfterStagingExample4 from "./Images/curtain-effect/AfterStagingExample4.png";
import BeforeStagingExample5 from "./Images/curtain-effect/BeforeStagingExample5.png";
import AfterStagingExample5 from "./Images/curtain-effect/AfterStagingExample5.png";
import BeforeStagingExample6 from "./Images/curtain-effect/BeforeStagingExample6.png";
import AfterStagingExample6 from "./Images/curtain-effect/AfterStagingExample6.png";
import BeforeStagingExample7 from "./Images/curtain-effect/BeforeStagingExample7.png";
import AfterStagingExample7 from "./Images/curtain-effect/AfterStagingExample7.png";
import VirtualStagingExample1 from "./Images/VirtualStagingExample1.png";
import VirtualStagingExample2 from "./Images/VirtualStagingExample2.png";
import VirtualStagingExample3 from "./Images/VirtualStagingExample3.png";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function HomeStagingFr() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);
  const [FAQ5, setFAQ5] = useState(false);
  const [FAQ6, setFAQ6] = useState(false);

  // Images dans les exemples de mise en scène héros

  const [example, setExample] = useState({
    name: "Furnish",
    beforeImage: BeforeFurnish,
    afterImage: AfterFurnish,
  });

  const handleExample = (example) => {
    if (example === "Furnish") {
      setExample({
        name: "Furnish",
        beforeImage: BeforeFurnish,
        afterImage: AfterFurnish,
      });
    } else if (example === "Bedroom") {
      setExample({
        name: "Bedroom",
        beforeImage: BeforeBedroomStaging,
        afterImage: AfterBedroomStaging,
      });
    } else if (example === "Terrace") {
      setExample({
        name: "Terrace",
        beforeImage: BeforeTerraceStaging,
        afterImage: AfterTerraceStaging,
      });
    } else {
      setExample({
        name: "Bathroom",
        beforeImage: BeforeBathroomStaging,
        afterImage: AfterBathroomStaging,
      });
    }
  };

  // Images dans les exemples de mise en scène du corps

  const stagingExamples = [
    {
      name: "stagingExample1",
      image: BeforeStagingExample1,
    },
    {
      name: "stagingExample2",
      image: BeforeStagingExample2,
    },
    {
      name: "stagingExample3",
      image: BeforeStagingExample3,
    },
    {
      name: "stagingExample4",
      image: BeforeStagingExample4,
    },
    {
      name: "stagingExample5",
      image: BeforeStagingExample5,
    },
    {
      name: "stagingExample6",
      image: BeforeStagingExample6,
    },
    {
      name: "stagingExample7",
      image: BeforeStagingExample7,
    },
  ];

  const [stagingExample, setStagingExample] = useState({
    name: "stagingExample1",
    beforeImage: BeforeStagingExample1,
    afterImage: AfterStagingExample1,
  });

  const handleStagingExample = (example) => {
    if (example === "stagingExample1") {
      setStagingExample({
        name: "stagingExample1",
        beforeImage: BeforeStagingExample1,
        afterImage: AfterStagingExample1,
      });
    } else if (example === "stagingExample2") {
      setStagingExample({
        name: "stagingExample2",
        beforeImage: BeforeStagingExample2,
        afterImage: AfterStagingExample2,
      });
    } else if (example === "stagingExample3") {
      setStagingExample({
        name: "stagingExample3",
        beforeImage: BeforeStagingExample3,
        afterImage: AfterStagingExample3,
      });
    } else if (example === "stagingExample4") {
      setStagingExample({
        name: "stagingExample4",
        beforeImage: BeforeStagingExample4,
        afterImage: AfterStagingExample4,
      });
    } else if (example === "stagingExample5") {
      setStagingExample({
        name: "stagingExample5",
        beforeImage: BeforeStagingExample5,
        afterImage: AfterStagingExample5,
      });
    } else if (example === "stagingExample6") {
      setStagingExample({
        name: "stagingExample6",
        beforeImage: BeforeStagingExample6,
        afterImage: AfterStagingExample6,
      });
    } else if (example === "stagingExample7") {
      setStagingExample({
        name: "stagingExample7",
        beforeImage: BeforeStagingExample7,
        afterImage: AfterStagingExample7,
      });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "Qu'est-ce que le home staging virtuel ?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Le home staging virtuel est une technique de marketing immobilier qui utilise des outils numériques pour préparer et présenter une propriété de manière attrayante aux acheteurs ou locataires potentiels. Contrairement au home staging traditionnel, qui implique des changements physiques à la propriété, le home staging virtuel se fait par le biais de l'édition d'images et du rendu numérique pour améliorer l'apparence des espaces.",
        },
      },
      {
        "@type": "Question",
        name: "En quoi consiste le home staging virtuel ?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Le home staging virtuel consiste à éditer des images de propriété à l'aide d'outils numériques. Cela comprend : \n\n- Édition d'images : Retouche et amélioration des photos à l'aide de l'IA, suppression d'objets indésirables, ajustement de l'éclairage et ajout de meubles ou de décorations virtuels. \n\n- Rendu de conception : Création de représentations numériques montrant à quoi pourrait ressembler la propriété une fois décorée ou rénovée. \n\n- Gain de temps et d'argent : C'est plus rapide et plus économique que le home staging traditionnel, car il ne nécessite pas de changements physiques à la propriété.",
        },
      },
      {
        "@type": "Question",
        name: "Quelles sont les étapes du processus de Home Staging Virtuel ?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Le processus de home staging virtuel comprend les étapes suivantes : \n\n1. Télécharger les photos du projet. \n\n2. Sélectionner les options d'édition de home staging virtuel, comme vider les pièces meublées ou ajouter des meubles virtuels. \n\n3. Télécharger le projet une fois les modifications terminées.",
        },
      },
      {
        "@type": "Question",
        name: "Combien de temps faut-il pour réaliser un projet de Home Staging Virtuel ?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Le temps nécessaire pour réaliser un projet de home staging virtuel dépend du nombre d'images et du temps nécessaire pour les éditer. Cependant, le téléchargement des fichiers et le téléchargement des projets sont rapides, et les résultats sont obtenus instantanément.",
        },
      },
      {
        "@type": "Question",
        name: "Peut-on faire du Home Staging Virtuel sur des propriétés vides ?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Oui ! Le home staging virtuel est idéal pour les propriétés vides. Vous pouvez les meubler avec l'IA ou manuellement, en ajoutant les meubles que vous voulez, ce qui aide à visualiser le potentiel des espaces et améliore la présentation de la propriété.",
        },
      },
    ],
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Home staging virtuel</h1>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2
                  style={{
                    fontSize: "20px",
                    marginTop: "12px",
                  }}
                >
                  Le meilleur home staging virtuel à portée de main
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Rapide :</strong> Résultats en quelques secondes
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Facile :</strong> Intuitif dès le départ
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Qualité :</strong> Résultats hyperréalistes
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Aide :</strong> Assistance humaine si nécessaire
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Essayez Pedra <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        29€ pour 100 images
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa, Directeur de la Photographie chez aProperties"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera, Agent Immobilier chez Huspy"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata, PDG d'Actívox"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart, PDG de Gilart Consultors"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      ></img>
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        Plus de 100 témoignages
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore={"Avant le home staging virtuel"}
                  altAfter={"Après le home staging virtuel"}
                />

                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Furnish" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Furnish")}
                    >
                      <PurpleFurnishIcon />
                      Salon
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Bedroom" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Bedroom")}
                    >
                      <BedIcon />
                      Chambre
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Bathroom" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Bathroom")}
                    >
                      <BathroomIcon />
                      Salle de bain
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Terrace" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Terrace")}
                    >
                      <PurpleEnhanceIcon />
                      Terrasse
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          PLUS DE 20 000 PROFESSIONNELS DE L'IMMOBILIER FONT DÉJÀ CONFIANCE À
          PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Home staging virtuel pour{" "}
          <span
            style={{
              color: "rgb(248, 121, 42)",
              fontWeight: "bold",
            }}
          >
            vendre plus rapidement
          </span>{" "}
          tout type de propriété.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h3 className="feature-title">
                  Meublez virtuellement en 1 clic
                </h3>
                <div className="feature-text">
                  Créez un home staging automatique en 1 clic
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">Légendes</h3>
                <div className="feature-text">
                  Indiquez que votre image est un home staging virtuel avec
                  votre propre texte
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">
                  Améliorez et corrigez les photos
                </h3>
                <div className="feature-text">
                  Améliorez la qualité et la perspective des photos pour un
                  meilleur home staging virtuel
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h3 className="feature-title">Ajoutez vos meubles</h3>
                <div className="feature-text">
                  Ajoutez de la personnalité en incluant vos propres meubles
                  dans le home staging virtuel
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h3 className="feature-title">Rénovez virtuellement</h3>
                <div className="feature-text">
                  Créez des propositions de rénovation pour vos propriétés
                  d'occasion
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h3 className="feature-title">
                  Supprimez des objets ou videz des espaces
                </h3>
                <div className="feature-text">
                  Retirez des objets ou des filigranes pour un home staging
                  virtuel plus épuré
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Exemples de notre home staging virtuel.
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="hero-example-container">
          <CurtainEffect
            beforeImage={stagingExample.beforeImage}
            afterImage={stagingExample.afterImage}
            altBefore={"Avant le home staging virtuel"}
            altAfter={"Après le home staging virtuel"}
          />

          <div style={{ display: "flex", flex: "1 0 0" }}></div>
          <div className="hero-example-buttons-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "6px",
                width: "100%",
              }}
            >
              {stagingExamples.map((item) => (
                <div
                  key={item.name}
                  className={`hero-button ${
                    stagingExample.name === item.name ? "selected" : ""
                  }`}
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    borderRadius: "8px",
                    overflow: "hidden",
                    height: "50px",
                    padding: "0px",
                  }}
                  onClick={() => handleStagingExample(item.name)}
                >
                  <img
                    src={item.image}
                    alt={item.name}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "6px",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container" style={{ display: "inline" }}>
          <span
            style={{
              color: "rgb(248, 121, 42)",
              fontWeight: "bold",
            }}
          >
            Plus de 100
          </span>{" "}
          témoignages sur notre home staging virtuel.
        </h3>
      </div>

      <div className="section-testimonials-container">
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={VictorLaviosa}
              alt="Victor Laviosa, Directeur de la Photographie chez aProperties"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Victor Laviosa
              </div>
              <div className="testimonial-author-container-second-title">
                Directeur de la Photographie chez aProperties
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            Pedra fonctionne très bien. Je pense que le meilleur aspect de
            l'outil est{" "}
            <span className="text-highlight">
              la rapidité avec laquelle il génère les images.
            </span>
          </q>
          <div className="testimonial-author-container-date">
            3 juillet 2024
          </div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={Bouchra}
              alt="Bouchra, Conseillère Immobilière chez iad"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Bouchra Benhammi
              </div>
              <div className="testimonial-author-container-second-title">
                Conseillère Immobilière chez iad
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            <span className="text-highlight">
              Pedra est la découverte du siècle
            </span>
          </q>
          <div className="testimonial-author-container-date">
            3 juillet 2024
          </div>
        </div>

        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={RomainBury}
              alt="Romain Bury, Agent Immobilier chez iad"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Romain Bury
              </div>
              <div className="testimonial-author-container-second-title">
                Agent Immobilier chez iad
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            Impressionnant.
            <div style={{ marginTop: "10px", display: "inline" }}>
              . De plus,{" "}
              <span className="text-highlight">
                l'espace spécifique que je voulais vider semblait plus compliqué
                car il était profond, mais il l'a fait brillamment.
              </span>
            </div>
          </q>
          <div className="testimonial-author-container-date">26 juin 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={NachoGarcia}
              alt="Nacho García, Directeur chez Lucas Fox"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Nacho García
              </div>
              <div className="testimonial-author-container-second-title">
                Directeur chez Lucas Fox
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            <span className="text-highlight">
              Quelques maisons ont été vendues grâce à Pedra.
            </span>
            <div style={{ marginTop: "10px", display: "inline" }}>
              J'ai placé des propositions de l'aspect que pourrait avoir la
              terrasse meublée sur la photo de couverture et beaucoup de gens
              ont commencé à cliquer sur l'annonce
            </div>
          </q>
          <div className="testimonial-author-container-date">7 juin 2024</div>
        </div>

        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={EmanuelaCosma}
              alt="Emanuela Cosma"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Emanuela Cosma
              </div>
              <div className="testimonial-author-container-second-title">
                Agent Immobilier
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            <span className="text-highlight">
              Avec Pedra, j'ai réussi à{" "}
              <a
                className="article-link"
                href="https://www.instagram.com/p/C4A6kCmIeMy/"
                rel="nofollow"
              >
                vendre un bien de 500 000 €
              </a>{" "}
              en moins d'une journée.{" "}
            </span>{" "}
            L'appartement que j'avais à vendre était un BIEN À RÉNOVER et avec
            Pedra, j'ai obtenu des rendus qui ont permis de visualiser le
            potentiel des espaces après rénovation mais sans rénovation !
          </q>
          <div className="testimonial-author-container-date">2 juin 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={PaulaAharonian}
              alt="Paula Aharonian, Conseillère Immobilière chez Monapart"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Paula Aharonian
              </div>
              <div className="testimonial-author-container-second-title">
                Conseillère Immobilière chez Monapart
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            <span className="text-highlight">
              Je suis très satisfaite des résultats
            </span>
            <div style={{ marginTop: "10px", display: "inline" }}>
              . De plus, ils ont amélioré le produit selon les retours que j'ai
              partagés !
            </div>
          </q>
          <div className="testimonial-author-container-date">4 juin 2024</div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container" style={{ display: "inline" }}>
          Comment ça marche ?
        </h3>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              ÉTAPE 1
            </h5>
            <h2>Créez un projet et téléchargez une image</h2>
            <div className="value-prop-text">
              Téléchargez une ou plusieurs images sur Pedra.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample1}
              alt="Comment télécharger des fichiers pour créer un home staging virtuel dans Pedra – Infographie"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              ÉTAPE 2
            </h5>
            <h2>Choisissez une option pour créer votre home staging virtuel</h2>
            <div className="value-prop-text">
              Téléchargez une image et cliquez sur l'option appropriée pour
              créer votre home staging virtuel.
              <p>
                Vous pourrez meubler des espaces, ajouter des objets
                spécifiques, les rénover, retirer des parties et bien plus
                encore !
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample2}
              alt="Comment choisir les options pour créer un home staging virtuel – Infographie"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              ÉTAPE 3
            </h5>
            <h2>Attendez et téléchargez le home staging virtuel</h2>
            <div className="value-prop-text">
              Les home stagings virtuels peuvent prendre de 15 secondes à
              quelques minutes pour être générés.
              <p>
                Une fois générés, vous pouvez continuer à les éditer ou les
                télécharger.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample3}
              alt="Comment télécharger un home staging virtuel – Infographie"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Les home stagings virtuels dont vous avez besoin pour 29€ par mois.{" "}
          <br />{" "}
          <span
            style={{
              color: "rgb(248, 121, 42)",
              fontWeight: "bold",
            }}
          >
            Et sans engagement.
          </span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Plan Pro
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">29€</div>
              <div className="modal-product-plan-description-text-3">
                par mois
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">Inclut</div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#B15DE1" }}>
                100 générations d'images
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>par mois</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Rénover et redécorer
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Vider les pièces
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Meubler
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Retirer des objets
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Augmenter la résolution
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Changer les sols
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Améliorer la photographie
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <VideoPricingIcon /> Générer des vidéos virtuelles
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Plans illimités
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Partage de projets illimité
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Ajouter des filigranes
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Support illimité
            </div>
            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Essayez Pedra
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Annulez quand vous voulez.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Achetez plus d'images à 0,20€ par image si vous en avez besoin.
            </div>
          </div>
        </div>
      </div>

      <div className="long-break"> </div>

      <div className="section-one-column-left">
        <div className="title-container">Questions et Réponses</div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">
                Qu'est-ce que le home staging ?
              </h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Le home staging est une technique de marketing immobilier qui
                consiste à préparer une propriété (maison, appartement, etc.)
                pour la vente ou la location dans le but de la rendre plus
                attrayante pour les acheteurs ou locataires potentiels. Cela se
                fait par la présentation et la décoration stratégique de
                l'espace pour mettre en valeur ses caractéristiques les plus
                positives et minimiser ses défauts.
                <br />
                <br />
                Le processus de home staging implique le nettoyage, la
                dépersonnalisation et le désencombrement de la propriété pour
                permettre aux acheteurs ou locataires potentiels de visualiser
                facilement comment ce serait de vivre dans cet espace. Il peut
                également inclure le réarrangement des meubles, l'ajout
                d'éléments décoratifs et, dans certains cas, des rénovations ou
                réparations mineures pour améliorer l'apparence générale de la
                propriété.
                <br />
                <br />
                L'objectif du home staging est de générer une première
                impression positive chez les visiteurs et d'augmenter les
                chances de vendre ou de louer la propriété rapidement et à un
                prix favorable. C'est une stratégie de plus en plus populaire
                sur le marché immobilier en raison de sa capacité à améliorer
                l'attrait visuel et émotionnel d'une propriété.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                En quoi consiste le home staging virtuel ?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                <div>
                  Le home staging virtuel est une variante du home staging
                  traditionnel qui utilise des outils numériques et la
                  technologie pour présenter une propriété de manière attrayante
                  aux acheteurs ou locataires potentiels. Au lieu de faire des
                  changements physiques à la propriété, comme réorganiser les
                  meubles ou ajouter des éléments décoratifs, le home staging
                  virtuel se fait principalement de la manière suivante :
                </div>
                <h4>Édition d'images :</h4>Avec Pedra, vous pouvez éditer les
                images avec l'IA pour retoucher et améliorer les photographies
                de la propriété. Cela peut impliquer la suppression d'objets
                indésirables, des ajustements de l'éclairage et de la couleur,
                et même l'ajout de meubles virtuels ou de décorations.
                <br />
                <br />
                <h4>Rendu de conception :</h4> Créez des représentations
                numériques de l'aspect que pourrait avoir la propriété après
                avoir étédécorée ou rénovée. Cela peut aider les acheteurs à
                visualiser le potentiel de l'espace et à s'inspirer pour leur
                propre décoration.
                <br />
                <br />
                <h4>Gain de temps et d'argent :</h4>Le home staging virtuel est
                plus rapide et plus économique que le home staging traditionnel,
                car il ne nécessite pas la location de meubles ou d'accessoires
                physiques ni la réalisation de changements physiques dans la
                propriété. Cela peut être particulièrement bénéfique pour les
                propriétés vides ou dans des situations où le temps est un
                facteur critique.
                <br />
                <br />
                <h4>Flexibilité et personnalisation :</h4>Avec le home staging
                virtuel, il est possible d'expérimenter différents styles de
                décoration et configurations d'espace rapidement et sans
                engagement. Cela permet d'adapter la présentation de la
                propriété en fonction du marché cible et des préférences des
                acheteurs ou locataires potentiels.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                Conseils pour le home staging virtuel
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                <h4>Connaissez votre public cible :</h4>Comprendre les besoins
                et les préférences de votre public cible vous aidera à créer un
                contenu de home staging virtuel qui les interpelle. Considérez
                qui sont les acheteurs ou locataires potentiels et adaptez votre
                approche en conséquence.
                <br />
                <br />
                <h4>Soyez honnête et transparent :</h4>Assurez-vous que le
                contenu de home staging virtuel reflète fidèlement l'apparence
                et les caractéristiques de la propriété. Évitez la sur-édition
                ou la manipulation excessive des images, car cela peut créer des
                attentes irréalistes.
                <br />
                <br />
                <h4>Multipliez les angles :</h4>Capturez la propriété sous
                différents angles pour fournir une vision complète. Cela permet
                aux spectateurs d'avoir une meilleure idée de l'agencement de
                l'espace et des caractéristiques uniques de la propriété.
                <br />
                <br />
                <h4>Maintenez une cohérence stylistique :</h4>Utilisez un style
                de décoration cohérent dans toutes les images et visites
                virtuelles pour créer une expérience visuelle uniforme. Cela
                aide à maintenir l'intérêt des spectateurs et facilite la
                visualisation de la propriété dans son ensemble.
                <br />
                <br />
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ4(!FAQ4)}>
              <h3 className="question-title">Prix du home staging virtuel</h3>
              {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ4 && (
              <div className="answer-container">
                Le prix de Pedra est de 29€ plus TVA par mois.
                <br />
                <br />
                Pour ce prix, vous pouvez générer jusqu'à 100 images par mois.
                <br />
                <br />
                Il n'y a pas d'engagement, vous pouvez donc résilier lorsque
                vous n'en avez plus besoin.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ5(!FAQ5)}>
              <h3 className="question-title">
                Quelles sont les étapes du processus de Home Staging Virtuel ?
              </h3>
              {FAQ5 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ5 && (
              <div className="answer-container">
                Le processus de home staging est assez rapide et simple. Voici
                les étapes à suivre pour créer un home staging virtuel avec
                Pedra :
                <br />
                <br />
                1. <strong>Téléchargez les photos du projet :</strong> Lors de
                la création d'un projet, vous pouvez télécharger une ou
                plusieurs images à éditer.
                <br />
                <br />
                2. <strong>Options de Home Staging Virtuel :</strong> Une fois
                que vous avez sélectionné les photos, dans le menu déroulant
                "Éditer avec l'IA", vous aurez toutes les options dont vous avez
                besoin, depuis vider les pièces jusqu'à ajouter des meubles à
                votre pièce, en passant par la rénovation des espaces et plus
                encore.
                <br />
                <br />
                3. <strong>Téléchargez votre projet :</strong> Une fois que vous
                avez effectué tous les changements et édité les images, il vous
                suffira de télécharger les images de votre Home Staging
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ6(!FAQ6)}>
              <h3 className="question-title">
                Peut-on réaliser un Home Staging Virtuel sur des propriétés
                vides ?
              </h3>
              {FAQ6 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ6 && (
              <div className="answer-container">
                <p>
                  Oui ! L'une des fonctions de notre outil de home staging
                  virtuel est de meubler la pièce. Vous pouvez utiliser l'option
                  "Meubler" pour meubler automatiquement l'espace avec l'IA, ou
                  si vous préférez, vous pouvez ajouter manuellement les meubles
                  que vous voulez avec l'option "Ajouter un objet".
                </p>
                <p>
                  Avec l'une ou l'autre de ces options, notre outil de Home
                  Staging Virtuel vous aide à visualiser le potentiel de vos
                  espaces, facilitant la prise de décision et améliorant la
                  présentation de la propriété.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeStagingFr;
