import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

// Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

// Images
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";

import BeforeKitchen from "./Images/curtain-effect/BeforeInteriorDesignAI2.png";
import AfterKitchen from "./Images/curtain-effect/AfterInteriorDesignAI2.png";

import BeforeKitchenStaging1 from "./Images/curtain-effect/BeforeInteriorDesignAI1.png";
import AfterKitchenStaging1 from "./Images/curtain-effect/AfterInteriorDesignAI1.png";

import BeforeKitchenStaging2 from "./Images/curtain-effect-hero/BeforeFurnish.png";
import AfterKitchenStaging2 from "./Images/curtain-effect-hero/AfterFurnish.png";

import BeforeKitchenStaging3 from "./Images/curtain-effect-hero/BeforeTerraceStaging.png";
import AfterKitchenStaging3 from "./Images/curtain-effect-hero/AfterTerraceStaging.png";

import VirtualStagingExample1 from "./Images/VirtualStagingExample1.png";
import VirtualStagingExample2 from "./Images/VirtualStagingExample2.png";
import VirtualStagingExample3 from "./Images/VirtualStagingExample3.png";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function InteriorDesignEs() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);

  const [example, setExample] = useState({
    name: "Moderno",
    beforeImage: BeforeKitchenStaging1,
    afterImage: AfterKitchenStaging1,
  });

  const handleExample = (example) => {
    switch (example) {
      case "Moderno":
        setExample({
          name: "Moderno",
          beforeImage: BeforeKitchenStaging1,
          afterImage: AfterKitchenStaging1,
        });
        break;
      case "Minimalista":
        setExample({
          name: "Minimalista",
          beforeImage: BeforeKitchenStaging2,
          afterImage: AfterKitchenStaging2,
        });
        break;
      case "Industrial":
        setExample({
          name: "Industrial",
          beforeImage: BeforeKitchenStaging3,
          afterImage: AfterKitchenStaging3,
        });
        break;
      default:
        setExample({
          name: "Clásico",
          beforeImage: BeforeKitchen,
          afterImage: AfterKitchen,
        });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "¿Qué es el diseño de interiores con IA?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "El diseño de interiores con IA combina la creatividad del diseño tradicional con la inteligencia artificial para optimizar y visualizar espacios de forma eficiente. La IA analiza tu espacio y preferencias para generar diseños personalizados y profesionales de forma instantánea.",
        },
      },
      {
        "@type": "Question",
        name: "¿Qué tipos de espacios puedo diseñar?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Puedes diseñar espacios residenciales (salas, dormitorios, cocinas), comerciales (oficinas, tiendas, restaurantes), públicos (museos, bibliotecas) y áreas especializadas (clínicas, gimnasios, hoteles).",
        },
      },
      {
        "@type": "Question",
        name: "¿Qué ventajas ofrece el diseño con IA?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Las ventajas incluyen visualización instantánea de cambios, múltiples opciones de diseño en segundos, optimización inteligente del espacio, personalización detallada, ahorro de tiempo y costos, y resultados profesionales sin experiencia previa.",
        },
      },
    ],
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Diseño de interiores con IA</h1>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2 style={{ fontSize: "20px", marginTop: "12px" }}>
                  Transforma tus espacios con diseño de interiores inteligente
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Instantáneo:</strong> Visualiza cambios en tiempo
                    real
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Sencillo:</strong> Sin conocimientos técnicos
                    necesarios
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Profesional:</strong> Resultados de alta calidad
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Personalizado:</strong> Adaptado a tus preferencias
                  </div>
                </div>

                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Prueba el diseño con IA <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        €29 por mes sin permanencia
                      </div>
                    </div>
                  </div>

                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      />
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        100+ testimonios
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore="Antes del diseño de interiores"
                  altAfter="Después del diseño de interiores"
                />

                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Moderno" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Moderno")}
                    >
                      Dormitorio
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Minimalista" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Minimalista")}
                    >
                      Salón
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Industrial" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Industrial")}
                    >
                      Terraza
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Clásico" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Clásico")}
                    >
                      Cocina
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          MÁS DE 20.000 PROFESIONALES YA CONFÍAN EN PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Crea diseños profesionales{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            automáticamente
          </span>{" "}
          en segundos.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h3 className="feature-title">Diseña múltiples espacios</h3>
                <div className="feature-text">
                  Genera diseños para varias habitaciones simultáneamente
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h3 className="feature-title">Sube tu propio estilo</h3>
                <div className="feature-text">
                  Sube imágenes de tus propios estilos y la IA rediseñara tus
                  fotografías en base a esos estilos
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h3 className="feature-title">Optimización espacial</h3>
                <div className="feature-text">
                  Distribuye muebles de forma inteligente
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h3 className="feature-title">Limpieza automática</h3>
                <div className="feature-text">
                  Elimina objetos no deseados de la escena
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">Personalización total</h3>
                <div className="feature-text">
                  Ajusta cada detalle a tu gusto
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">Diseña en segundos</h3>
                <div className="feature-text">
                  Genera propuestas de diseño con IA en pocos segundos
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          ¿Cómo funciona el diseño de interiores con IA?
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASO 1
            </h5>
            <h2>Sube una imagen de tu espacio</h2>
            <div className="value-prop-text">
              Comienza cargando una foto de la habitación que deseas rediseñar.
              Nuestro software analiza la estructura, iluminación y elementos
              clave.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample1}
              alt="Cómo subir fotos para diseño de interiores con IA"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASO 2
            </h5>
            <h2>Elige tu estilo preferido</h2>
            <div className="value-prop-text">
              Selecciona entre diferentes estilos de decoración y personaliza
              colores, materiales y elementos decorativos según tus
              preferencias.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample2}
              alt="Selección de estilos de diseño de interiores"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASO 3
            </h5>
            <h2>Obtén tu diseño al instante</h2>
            <div className="value-prop-text">
              Recibe visualizaciones profesionales de tu espacio transformado.
              Descarga y usa tus diseños inmediatamente.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample3}
              alt="Resultado final del diseño de interiores"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Diseño de interiores profesional por €29 al mes. <br />{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            Sin compromiso de permanencia.
          </span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Plan Pro
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">€29</div>
              <div className="modal-product-plan-description-text-3">
                al mes
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">Incluye</div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#B15DE1" }}>
                100 diseños
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>al mes</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Diseño instantáneo de espacios
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Escoje tus propios estilos de diseño
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Distribución inteligente de muebles
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Limpieza automática de escena
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Visualizaciones en alta resolución
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Cambio de materiales y texturas
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Iluminación profesional
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Proyectos ilimitados
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Compartir ilimitado
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Soporte prioritario
            </div>

            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Prueba el diseño con IA
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Cancela cuando quieras.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Diseños adicionales disponibles por €0,20 cada uno.
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="title-container">
          Preguntas frecuentes sobre diseño de interiores con IA
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">
                ¿Qué es el diseño de interiores con inteligencia artificial?
              </h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                El diseño de interiores con IA es una tendencia emergente que
                combina la creatividad del diseño tradicional con la capacidad
                analítica y predictiva de la IA. La tecnología analiza espacios
                y preferencias para generar diseños personalizados de forma
                instantánea.
                <br />
                <br />
                Nuestro sistema puede:
                <br />
                • Generar diseños automáticamente
                <br />
                • Optimizar la distribución del espacio
                <br />
                • Sugerir combinaciones de colores y materiales
                <br />• Crear visualizaciones fotorrealistas
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                ¿Qué tipos de espacios puedo diseñar?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                Nuestra herramienta puede diseñar una amplia variedad de
                espacios:
                <br />
                <br />
                <strong>Espacios Residenciales:</strong>
                <br />
                • Salas de estar y comedores
                <br />
                • Dormitorios
                <br />
                • Cocinas y baños
                <br />
                • Áreas exteriores y jardines
                <br />
                <br />
                <strong>Espacios Comerciales:</strong>
                <br />
                • Oficinas
                <br />
                • Tiendas y locales comerciales
                <br />
                • Restaurantes y cafeterías
                <br />
                <br />
                <strong>Espacios Públicos:</strong>
                <br />
                • Museos y galerías
                <br />
                • Bibliotecas y centros educativos
                <br />• Instalaciones deportivas
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                ¿Qué ventajas tiene la IA en el diseño de interiores?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Las principales ventajas incluyen:
                <br />
                <br />
                <strong>Eficiencia y rapidez</strong>
                <br />
                • Generación instantánea de diseños
                <br />
                • Automatización de tareas repetitivas
                <br />
                • Visualización inmediata de cambios
                <br />
                <br />
                <strong>Precisión y optimización</strong>
                <br />
                • Análisis detallado del espacio
                <br />
                • Distribución óptima de elementos
                <br />
                • Reducción de errores de diseño
                <br />
                <br />
                <strong>Personalización</strong>
                <br />
                • Adaptación a preferencias individuales
                <br />
                • Múltiples opciones de estilo
                <br />• Recomendaciones basadas en el uso del espacio
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InteriorDesignEs;
