import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

// Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";

// Images
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import BeforeLivingRoom from "./Images/curtain-effect/BeforeLivingRoomRender.png";
import AfterLivingRoom from "./Images/curtain-effect/AfterLivingRoomRender.png";
import BeforeLivingRoomStaging1 from "./Images/curtain-effect/BeforeSalonStaging1.png";
import AfterLivingRoomStaging1 from "./Images/curtain-effect/AfterSalonStaging1.png";
import BeforeLivingRoomStaging2 from "./Images/curtain-effect/BeforeSalonStaging2.png";
import AfterLivingRoomStaging2 from "./Images/curtain-effect/AfterSalonStaging2.png";
import BeforeLivingRoomStaging3 from "./Images/curtain-effect/BeforeSalonStaging3.png";
import AfterLivingRoomStaging3 from "./Images/curtain-effect/AfterSalonStaging3.png";
import VirtualStagingExample1 from "./Images/VirtualStagingExample1.png";
import VirtualStagingExample2 from "./Images/VirtualStagingExample2.png";
import VirtualStagingExample3 from "./Images/VirtualStagingExample3.png";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function RenderLivingRoomPt() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);

  const [example, setExample] = useState({
    name: "Modern",
    beforeImage: BeforeLivingRoom,
    afterImage: AfterLivingRoom,
  });

  const handleExample = (example) => {
    if (example === "Modern") {
      setExample({
        name: "Modern",
        beforeImage: BeforeLivingRoom,
        afterImage: AfterLivingRoom,
      });
    } else if (example === "Traditional") {
      setExample({
        name: "Traditional",
        beforeImage: BeforeLivingRoomStaging1,
        afterImage: AfterLivingRoomStaging1,
      });
    } else if (example === "Rustic") {
      setExample({
        name: "Rustic",
        beforeImage: BeforeLivingRoomStaging2,
        afterImage: AfterLivingRoomStaging2,
      });
    } else {
      setExample({
        name: "Contemporary",
        beforeImage: BeforeLivingRoomStaging3,
        afterImage: AfterLivingRoomStaging3,
      });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "O que são renders automáticos de salas de estar?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Os renders automáticos de salas de estar são visualizações geradas por IA que transformam fotos de salas em imagens renderizadas profissionalmente. Esta tecnologia permite criar visualizações fotorrealistas de salas com diferentes estilos, mobiliário e layouts sem a necessidade de modelagem 3D manual ou software complexo.",
        },
      },
      {
        "@type": "Question",
        name: "Quais são os benefícios dos renders automáticos de salas de estar?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Os renders automáticos de salas de estar oferecem vários benefícios, incluindo: transformação instantânea dos espaços, capacidade de visualizar múltiplas opções de design rapidamente, resultados fotorrealistas de alta qualidade, economia significativa de tempo e custos em comparação com a renderização tradicional, e flexibilidade para experimentar diferentes estilos e configurações.",
        },
      },
      {
        "@type": "Question",
        name: "Como funciona o processo de renderização automática de salas de estar?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "O processo envolve fazer upload de fotos da sala atual em nossa plataforma, selecionar as transformações desejadas (como mudar acabamentos, adicionar móveis ou modificar layouts) e receber renders fotorrealistas mostrando a sala transformada em minutos. Nossa tecnologia de IA gerencia todo o trabalho complexo de renderização automaticamente.",
        },
      },
      {
        "@type": "Question",
        name: "Quanto custam os renders automáticos de salas de estar?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Nosso serviço de renderização automática de salas de estar está disponível por €29 mais impostos por mês. Este plano permite gerar até 100 renders por mês, sem necessidade de compromisso a longo prazo.",
        },
      },
    ],
  };

  const [stagingExample, setStagingExample] = useState({
    name: "stagingExample1",
    beforeImage: BeforeLivingRoomStaging1,
    afterImage: AfterLivingRoomStaging1,
  });

  const stagingExamples = [
    {
      name: "stagingExample1",
      beforeImage: BeforeLivingRoomStaging1,
      afterImage: AfterLivingRoomStaging1,
    },
    {
      name: "stagingExample2",
      beforeImage: BeforeLivingRoomStaging2,
      afterImage: AfterLivingRoomStaging2,
    },
    {
      name: "stagingExample3",
      beforeImage: BeforeLivingRoomStaging3,
      afterImage: AfterLivingRoomStaging3,
    },
  ];

  const handleStagingExample = (exampleName) => {
    const selectedExample = stagingExamples.find(
      (example) => example.name === exampleName
    );
    if (selectedExample) {
      setStagingExample(selectedExample);
    }
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Renders de Sala de Estar</h1>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2 style={{ fontSize: "20px", marginTop: "12px" }}>
                  Crie renders fotorrealistas de salas de estar automaticamente
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Instantâneo:</strong> Gere renders em segundos
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Simples:</strong> Não precisa de conhecimentos
                    técnicos
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Realista:</strong> Resultados fotorrealistas
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Suporte:</strong> Assistência especializada
                    disponível
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Experimente Pedra <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        €29 por 100 renders
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa, Diretor de Fotografia na aProperties"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera, Corretor de Imóveis na Huspy"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata, CEO da Actívox"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart, CEO da Gilart Consultors"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      />
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        Mais de 100 depoimentos
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore="Antes do render da sala de estar"
                  altAfter="Depois do render da sala de estar"
                />

                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Modern" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Modern")}
                    >
                      Sala Moderna
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Traditional" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Traditional")}
                    >
                      Estilo Tradicional
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Rustic" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Rustic")}
                    >
                      Design Rústico
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Contemporary" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Contemporary")}
                    >
                      Visual Contemporâneo
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          MAIS DE 20.000 PROFISSIONAIS JÁ CONFIAM NA PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Crie renders profissionais de salas de estar{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            automaticamente
          </span>{" "}
          em segundos.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h3 className="feature-title">
                  Transforme salas instantaneamente
                </h3>
                <div className="feature-text">
                  Gere renders fotorrealistas com um único clique
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">
                  Múltiplos estilos de decoração
                </h3>
                <div className="feature-text">
                  Visualize diferentes estilos e configurações facilmente
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">Perspectivas perfeitas</h3>
                <div className="feature-text">
                  Corrija e otimize ângulos de câmera automaticamente
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h3 className="feature-title">Iluminação automática</h3>
                <div className="feature-text">
                  Ajustes profissionais de iluminação em cada render
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h3 className="feature-title">Mude materiais</h3>
                <div className="feature-text">
                  Atualize paredes, pisos e acabamentos automaticamente
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h3 className="feature-title">Limpeza automática da cena</h3>
                <div className="feature-text">
                  Remova objetos indesejados automaticamente
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Exemplos dos nossos renders automáticos de salas de estar.
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="hero-example-container">
          <CurtainEffect
            beforeImage={stagingExample.beforeImage}
            afterImage={stagingExample.afterImage}
            altBefore="Antes do render da sala de estar"
            altAfter="Depois do render da sala de estar"
          />

          <div style={{ display: "flex", flex: "1 0 0" }}></div>
          <div className="hero-example-buttons-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "6px",
                width: "100%",
              }}
            >
              {stagingExamples.map((item) => (
                <div
                  key={item.name}
                  className={`hero-button ${
                    stagingExample.name === item.name ? "selected" : ""
                  }`}
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    borderRadius: "8px",
                    overflow: "hidden",
                    height: "50px",
                    padding: "0px",
                  }}
                  onClick={() => handleStagingExample(item.name)}
                >
                  <img
                    src={item.beforeImage}
                    alt={item.name}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "6px",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container" style={{ display: "inline" }}>
          Como funcionam os renders automáticos de salas de estar?
        </h3>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASSO 1
            </h5>
            <h2>Faça upload da foto da sua sala de estar</h2>
            <div className="value-prop-text">
              Faça upload de qualquer foto da sua sala de estar na plataforma
              Pedra.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample1}
              alt="Como fazer upload de fotos de salas de estar para renderização automática na Pedra – Infográfico"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASSO 2
            </h5>
            <h2>Escolha suas opções de renderização</h2>
            <div className="value-prop-text">
              Selecione como você quer transformar sua sala de estar.
              <p>
                Mude materiais, atualize iluminação, modifique layout, adicione
                móveis e muito mais.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample2}
              alt="Como escolher opções para renderização automática de salas de estar – Infográfico"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASSO 3
            </h5>
            <h2>Receba seus renders fotorrealistas</h2>
            <div className="value-prop-text">
              Receba suas imagens de sala de estar renderizadas
              profissionalmente em segundos.
              <p>Baixe e use seus renders imediatamente.</p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample3}
              alt="Como baixar renders automáticos de salas de estar – Infográfico"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Renders profissionais de salas de estar por €29 por mês. <br />{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            Sem compromisso.
          </span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Plano Pro
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">€29</div>
              <div className="modal-product-plan-description-text-3">
                por mês
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">Inclui</div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#B15DE1" }}>
                100 renders
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>por mês</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Transforme salas instantaneamente
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Mude materiais e acabamentos
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Adicione móveis e decoração
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Limpeza automática da cena
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Saída em alta resolução
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Múltiplos estilos de decoração
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Iluminação profissional
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Projetos ilimitados
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Compartilhamento ilimitado
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Marcas d'água personalizadas
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Suporte prioritário
            </div>
            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Experimente Pedra
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Cancele quando quiser.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Renders adicionais disponíveis por €0,20 cada, se necessário.
            </div>
          </div>
        </div>
      </div>

      <div className="long-break"> </div>

      <div className="section-one-column-left">
        <div className="title-container">
          Perguntas sobre renders automáticos de salas de estar
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">
                O que são renders automáticos de salas de estar?
              </h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Os renders automáticos de salas de estar usam tecnologia de IA
                avançada para transformar fotos comuns de salas em visualizações
                fotorrealistas profissionais. Esta tecnologia permite criar
                renders de alta qualidade sem a necessidade de modelagem 3D
                manual ou software complexo de renderização.
                <br />
                <br />O sistema gerencia automaticamente iluminação, materiais,
                perspectivas e outros aspectos técnicos para produzir resultados
                profissionais instantaneamente.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Quais são os benefícios dos renders automáticos de salas de
                estar?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                Os renders automáticos de salas de estar oferecem várias
                vantagens:
                <br />
                <br />
                1. Velocidade: Gere renders em segundos, não em horas ou dias
                <br />
                2. Custo-benefício: Sem necessidade de software 3D caro
                <br />
                3. Facilidade de uso: Não requer experiência técnica
                <br />
                4. Múltiplas opções: Teste diferentes estilos rapidamente
                <br />
                5. Resultados fotorrealistas: Saída de qualidade profissional
                <br />
                6. Flexibilidade: Faça alterações instantaneamente
                <br />
                7. Consistência: Resultados confiáveis sempre
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                O que posso fazer com os renders de salas de estar da Pedra?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                O sistema de renderização automática de salas de estar da Pedra
                oferece uma ampla gama de recursos:
                <br />
                <br />
                1. Gerar renders: Crie visualizações fotorrealistas de qualquer
                foto de sala instantaneamente.
                <br />
                2. Otimização de iluminação: Aprimore automaticamente a
                iluminação e sombras para resultados profissionais.
                <br />
                3. Mudanças de material: Modifique paredes, pisos e acabamentos
                com um clique.
                <br />
                4. Adicionar elementos: Insira novos móveis, acessórios e
                elementos decorativos automaticamente.
                <br />
                5. Limpeza de cena: Remova objetos indesejados e desordem
                automaticamente.
                <br />
                6. Visualização de layout: Teste diferentes configurações de
                sala rapidamente.
                <br />
                <br />
                Todas as transformações são gerenciadas automaticamente por
                nossa IA, entregando resultados profissionais em segundos.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RenderLivingRoomPt;
