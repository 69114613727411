import React, { useState } from "react";
import CustomerLogos from "./CustomerLogos.js";

//Icons
import BlueChairIcon from "./Icons/BlueChairIcon";
import BlueEyeIcon from "./Icons/BlueEyeIcon";
import BlueRulerIcon from "./Icons/BlueRulerIcon";
import BlueSmileIcon from "./Icons/BlueSmileIcon";
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";

//Images
import FloorPlanHeader from "./Images/FloorPlanHeader.png";
import FloorPlanFeature1 from "./Images/FloorPlanFeature1.png";
import FloorPlanFeature2 from "./Images/FloorPlanFeature2.png";
import FloorPlanFeature3 from "./Images/FloorPlanFeature3.png";
import FloorPlanFeature4 from "./Images/FloorPlanFeature4.png";
import FloorPlanStep1 from "./Images/FloorPlanStep1.png";
import FloorPlanStep2 from "./Images/FloorPlanStep2.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function FloorPlanFr() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);
  return (
    <div>
      <section className="section-first-wrapper">
        <section className="section-first">
          <div className="section-first-text-container">
            <h1 className="section-first-title">
              L'outil de plan d'étage le plus simple
            </h1>
            <div className="section-first-text">
              Simple. Puissant. Dessinez des plans d'étage en quelques secondes
              pour mieux positionner vos propriétés.
            </div>
            <div className="section-first-button-container">
              <button
                className="button-small"
                onClick={redirectToPage}
                style={{
                  backgroundColor: "#57A4EB",
                  border: "1px solid #57A4EB",
                }}
              >
                Essayez Pedra <ArrowIcon />
              </button>
            </div>
            <div className="testimonials-on-header-container">
              <div className="testimonials-on-header-image-container">
                <img
                  className="testimonials-on-header-image"
                  src={VictorLaviosa}
                  alt="Victor Laviosa, Director of Photography at aProperties"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={Soma}
                  alt="Francisco Somacarrera, Real Estate Agent at Huspy"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={DanieldelaMata}
                  alt="Daniel de la Mata, CEO at Actívox"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={MariaJoseGilart}
                  alt="Maria José Gilart, CEO at Gilart Consultors"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={EmanuelaCosma}
                  alt="Emanuela Cosma"
                ></img>
              </div>
              <div>
                <div className="testimonials-on-header-stars">★★★★★</div>
                <p className="testimonials-on-header-text">
                  20 000+ professionnels de l'immobilier utilisent déjà Pedra
                </p>
              </div>
            </div>
          </div>
          <div className="header-picture">
            <img
              src={FloorPlanHeader}
              alt="Outil pour créer un plan d'étage pour les propriétés immobilières – Capture d'écran du logiciel"
            ></img>
          </div>
        </section>
      </section>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          PLUS DE 20 000 PROFESSIONNELS DE L'IMMOBILIER FONT DÉJÀ CONFIANCE À
          PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <h2 className="title-container">Dessinez un plan d'étage avec Pedra</h2>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <BlueChairIcon />
                <h2 className="feature-title">
                  Ajoutez des meubles au plan d'étage
                </h2>
                <div className="feature-text">
                  Utilisez des calques de meubles pour montrer ce qui peut
                  s'adapter dans un espace et facilitez la compréhension des
                  plans d'étage pour votre public
                </div>
              </div>
              <img
                src={FloorPlanFeature1}
                alt="Ajouter des meubles au plan d'étage – Capture d'écran du logiciel"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <BlueEyeIcon />
                <div className="feature-title">
                  Affichez les mesures du plan d'étage en 1 clic
                </div>
                <div className="feature-text">
                  Décidez d'afficher ou de masquer les mesures sur vos plans
                  d'étage
                </div>
              </div>
              <img
                src={FloorPlanFeature2}
                alt="Afficher les mesures du plan d'étage – Capture d'écran du logiciel"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <BlueSmileIcon />
                <h2 className="feature-title">Outil de plan d'étage facile</h2>
                <div className="feature-text">
                  L'outil de plan d'étage de Pedra est le plus simple — il
                  suffit de glisser-déposer
                </div>
              </div>
              <img
                src={FloorPlanFeature3}
                alt="Outil de plan d'étage facile – Capture d'écran du logiciel"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <BlueRulerIcon />
                <h2 className="feature-title">
                  Plan d'étage flexible mais simple
                </h2>
                <div className="feature-text">
                  Le plan d'étage de Pedra est simple mais flexible. Profitez de
                  nombreux types de calques différents : portes, fenêtres,
                  meubles et modifiez leurs paramètres
                </div>
              </div>
              <img
                src={FloorPlanFeature4}
                alt="Outil de plan d'étage flexible mais simple – Capture d'écran du logiciel"
              />
            </div>
          </div>
        </div>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <div className="title-container">
          Comment dessiner un plan d'étage ?
        </div>
      </div>

      <section className="project project-alt">
        <div className="picture">
          <img
            src={FloorPlanStep1}
            alt="Comment dessiner un plan d'étage – Diagramme"
          />
        </div>

        <div className="description">
          <h5>ÉTAPE 1</h5>
          <h2>
            Créez un nouveau projet et sélectionnez "Dessiner un plan d'étage"
          </h2>
        </div>
      </section>

      <section className="project project-alt">
        <div className="picture">
          <img
            src={FloorPlanStep2}
            alt="Comment modifier un plan d'étage – Diagramme"
          />
        </div>

        <div className="description">
          <h5>ÉTAPE 2</h5>
          <h2>Cliquez sur les calques pour les ajouter au plan d'étage</h2>
          <div className="value-prop-text">
            Pour modifier la taille, la couleur ou les propriétés du calque,
            vous pouvez les sélectionner
          </div>
        </div>
      </section>

      <div className="long-break"> </div>
      <div className="centered-heading">
        <button
          className="button"
          onClick={redirectToPage}
          style={{ backgroundColor: "#57A4EB", border: "1px solid #57A4EB" }}
          onMouseOver={(e) => {
            e.target.style.backgroundColor = "#0077D4";
            e.target.style.borderColor = "#0077D4";
          }}
          onMouseOut={(e) => {
            e.target.style.backgroundColor = "#57A4EB";
            e.target.style.borderColor = "#57A4EB";
          }}
        >
          Essayez Pedra <ArrowIcon />
        </button>
      </div>
      <br></br>
      <br></br>
      <div className="section-one-column-left">
        <div className="title-container">Questions et réponses</div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h2 className="question-title">Que sont les plans d'étage ?</h2>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Les plans d'étage sont des représentations graphiques qui
                montrent une vue de dessus d'un bâtiment, d'une maison ou d'une
                structure, comme si vous regardiez du haut vers le bas. Ces
                plans sont principalement utilisés en architecture, en design
                d'intérieur, en génie civil et dans d'autres domaines connexes
                pour visualiser la disposition et l'agencement des espaces dans
                une structure.
                <br />
                <br />
                Les plans d'étage incluent généralement des détails tels que les
                dimensions des pièces, l'emplacement des murs, des portes, des
                fenêtres, des meubles et d'autres éléments importants pour la
                planification et la construction d'un bâtiment. Ils sont
                essentiels pour la communication entre les designers, les
                architectes, les ingénieurs et les entrepreneurs impliqués dans
                un projet de construction.
                <br />
                <br />
                Imaginez que vous regardez un bâtiment depuis le ciel. Les plans
                d'étage capturent cette perspective, montrant la distribution
                des espaces intérieurs, des pièces, des murs, des portes, des
                fenêtres et d'autres éléments structurels. Essentiellement, ces
                plans offrent une vue de dessus d'un bâtiment, permettant une
                compréhension claire de la façon dont les espaces seront
                organisés et utilisés.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Quels sont les types de plans d'étage ?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                <div>
                  Il existe plusieurs types de plans d'étage, chacun ayant son
                  propre objectif et focus :
                </div>
                <h4>Plans du rez-de-chaussée :</h4>Ils montrent la disposition
                des espaces au niveau du sol. Cela comprend des zones telles que
                les entrées, les salons, les cuisines, les chambres et les
                garages.
                <br />
                <br />
                <h4>Plans des étages supérieurs :</h4> Ils représentent les
                niveaux supérieurs d'un bâtiment, comme le deuxième étage, le
                grenier ou tout niveau supplémentaire. Ces plans sont cruciaux
                pour comprendre la disposition verticale d'un bâtiment.
                <br />
                <br />
                <h4>Plans d'étage avec meubles :</h4>Ils intègrent le placement
                des meubles dans les espaces. Ces plans aident les designers
                d'intérieur et les propriétaires à visualiser à quoi ressemblera
                l'espace une fois meublé.
                <br />
                <br />
                <h4>Plans de disposition électrique et de plomberie :</h4>Ils
                détaillent l'emplacement des points d'accès électriques et de
                plomberie dans le bâtiment. Ils sont essentiels pour assurer une
                installation correcte des systèmes électriques et de plomberie.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                Quel est le processus de création de plans d'étage ?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                <h4>Créez un nouveau projet :</h4>Commencez à créer un plan
                d'étage dans la section 'Dessiner un plan d'étage'.
                <br />
                <br />
                <h4>Ajoutez des calques :</h4>Ajoutez tous les calques dont vous
                avez besoin et modifiez les dimensions, la couleur ou les
                propriétés du calque.
                <br />
                <br />
                <h4>Meublez-le :</h4>Ajoutez des meubles au plan d'étage pour
                montrer visuellement l'espace disponible et comment il pourrait
                être meublé.
                <br />
                <br />
                <h4>Téléchargez votre plan d'étage en ligne :</h4>Finalisez les
                derniers détails tels que les portes, les fenêtres, etc., et
                téléchargez votre plan d'étage.
                <br />
                <br />
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ4(!FAQ4)}>
              <h3 className="question-title">Combien coûte Pedra ?</h3>
              {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ4 && (
              <div className="answer-container">
                Pedra coûte 29€ plus taxes par mois.
                <br />
                <br />
                C'est un abonnement mensuel, donc vous pouvez annuler votre
                abonnement si vous n'en avez plus besoin.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FloorPlanFr;
