import React from "react";
import CheckIcon from "./Icons/CheckIcon";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple";
import EmptyIcon from "./Icons/EmptyIcon";
import HammerIconPricing from "./Icons/HammerIconPricing";
import BrushIconPricing from "./Icons/BrushIconPricing";
import HDIconPricing from "./Icons/HDIconPricing";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon";
import VideoPricingIcon from "./Icons/VideoPricingIcon";
import CustomerLogos from "./CustomerLogos";
import ArrowIcon from "./Icons/ArrowIcon";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function PricingFr() {
  return (
    <div
      className="section-one-column-left"
      style={{ display: "flex", flexDirection: "column", gap: "0px" }}
    >
      <section className="section-first-wrapper">
        <section className="section-first">
          <div className="section-first-text-container" style={{ gap: "0px" }}>
            <div
              className="section-first-title"
              style={{ paddingBottom: "0px" }}
            >
              Essayez notre forfait Pro
            </div>
            <h1 className="section-first-text" style={{ fontSize: "20px" }}>
              Payez par mois,
              <span style={{ color: "#F8792A" }}> annulez à tout moment.</span>
            </h1>
            <div className="section-first-button-container"></div>
          </div>
        </section>
      </section>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Forfait Pro
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">29€</div>
              <div className="modal-product-plan-description-text-3">
                par mois
              </div>
            </div>
          </div>
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">Inclut</div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#B15DE1" }}>
                100 générations d'images
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>par mois</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Rénover et redécorer
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Vider les pièces
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Meubler
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Supprimer des objets
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Augmenter la résolution
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Changer les murs et les sols
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Améliorer la photographie
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <VideoPricingIcon /> Générer des vidéos virtuelles
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Plans d'étage illimités
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Partages de projets illimités
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Ajouter des filigranes
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Support illimité
            </div>
            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Essayez Pedra
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Annulez quand vous voulez.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Achetez plus d'images à 0,20 € par image si vous en avez besoin.
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />

      <div className="section-one-column-left" style={{ marginBottom: "0px" }}>
        <div className="customer-logo-container">
          PLUS DE 20 000 PROFESSIONNELS DE L'IMMOBILIER FONT DÉJÀ CONFIANCE À
          PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              "Vous avez pensé à tout ! Pedra a tout ce dont nous avons besoin
              !"
            </h2>
            <div className="quote-author-block">
              <img
                style={{
                  maxWidth: "150px",
                  height: "auto",
                }}
                className="quote-author-image"
                src="/vives-homes.png"
                alt="Logo de Vives Homes pour Pedra"
              />

              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Jordi Vives</div>
                <div className="quote-author-company">Vives Homes</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PricingFr;
