import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

// Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";

// Images
import BeforeKitchen from "./Images/curtain-effect-hero/BeforeKitchen.png";
import AfterKitchen from "./Images/curtain-effect-hero/AfterKitchen.png";
import BeforeKitchenStaging1 from "./Images/curtain-effect/BeforeKitchenStaging1.png";
import AfterKitchenStaging1 from "./Images/curtain-effect/AfterKitchenStaging1.png";
import BeforeKitchenStaging2 from "./Images/curtain-effect/BeforeKitchenStaging2.png";
import AfterKitchenStaging2 from "./Images/curtain-effect/AfterKitchenStaging2.png";
import BeforeKitchenStaging3 from "./Images/curtain-effect/BeforeKitchenStaging3.png";
import AfterKitchenStaging3 from "./Images/curtain-effect/AfterKitchenStaging3.png";
import VirtualStagingExample1 from "./Images/VirtualStagingExample1.png";
import VirtualStagingExample2 from "./Images/VirtualStagingExample2.png";
import VirtualStagingExample3 from "./Images/VirtualStagingExample3.png";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function HomeStagingKitchenDe() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);

  const [example, setExample] = useState({
    name: "Modern",
    beforeImage: BeforeKitchen,
    afterImage: AfterKitchen,
  });

  const handleExample = (example) => {
    if (example === "Modern") {
      setExample({
        name: "Modern",
        beforeImage: BeforeKitchen,
        afterImage: AfterKitchen,
      });
    } else if (example === "Traditional") {
      setExample({
        name: "Traditional",
        beforeImage: BeforeKitchenStaging1,
        afterImage: AfterKitchenStaging1,
      });
    } else if (example === "Rustic") {
      setExample({
        name: "Rustic",
        beforeImage: BeforeKitchenStaging2,
        afterImage: AfterKitchenStaging2,
      });
    } else {
      setExample({
        name: "Contemporary",
        beforeImage: BeforeKitchenStaging3,
        afterImage: AfterKitchenStaging3,
      });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "Was ist Küchen-Home-Staging?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Küchen-Home-Staging ist eine Immobilienmarketing-Technik, die sich darauf konzentriert, die Küche einer Immobilie für potenzielle Käufer oder Mieter attraktiv zu präsentieren. Dies kann eine Grundreinigung, Organisation, strategische Dekoration und kleinere Verbesserungen umfassen, um die Stärken der Küche hervorzuheben und ihre Schwächen zu minimieren.",
        },
      },
      {
        "@type": "Question",
        name: "Was sind die Vorteile von virtuellem Home-Staging für Küchen?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Virtuelles Home-Staging für Küchen bietet mehrere Vorteile, darunter: die Möglichkeit, das Potenzial der Küche ohne kostspielige physische Änderungen zu präsentieren, die Fähigkeit, schnell mit verschiedenen Stilen und Konfigurationen zu experimentieren, verbesserte Online-Immobilienpräsentation und die Chance, sich in einem wettbewerbsintensiven Immobilienmarkt abzuheben.",
        },
      },
      {
        "@type": "Question",
        name: "Wie funktioniert der virtuelle Home-Staging-Prozess für Küchen?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Der virtuelle Home-Staging-Prozess für Küchen beinhaltet das Hochladen von Fotos der aktuellen Küche auf unsere Plattform, die Auswahl gewünschter Bearbeitungsoptionen (wie das Ändern von Oberflächen, Hinzufügen oder Entfernen von Elementen oder Ändern des Layouts) und dann den Erhalt bearbeiteter Bilder, die die transformierte Küche zeigen.",
        },
      },
      {
        "@type": "Question",
        name: "Wie viel kostet virtuelles Küchen-Home-Staging?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Der Preis für unseren virtuellen Home-Staging-Service, der Küchen und andere Bereiche des Hauses umfasst, beträgt 29 € plus MwSt. pro Monat. Dieser Plan ermöglicht es Ihnen, bis zu 100 Bilder pro Monat zu generieren, ohne langfristige Verpflichtung.",
        },
      },
    ],
  };

  const [stagingExample, setStagingExample] = useState({
    name: "stagingExample1",
    beforeImage: BeforeKitchenStaging1,
    afterImage: AfterKitchenStaging1,
  });

  const stagingExamples = [
    {
      name: "stagingExample1",
      beforeImage: BeforeKitchenStaging1,
      afterImage: AfterKitchenStaging1,
    },
    {
      name: "stagingExample2",
      beforeImage: BeforeKitchenStaging2,
      afterImage: AfterKitchenStaging2,
    },
    {
      name: "stagingExample3",
      beforeImage: BeforeKitchenStaging3,
      afterImage: AfterKitchenStaging3,
    },
  ];

  const handleStagingExample = (exampleName) => {
    const selectedExample = stagingExamples.find(
      (example) => example.name === exampleName
    );
    if (selectedExample) {
      setStagingExample(selectedExample);
    }
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Küchen Home Staging</h1>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2 style={{ fontSize: "20px", marginTop: "12px" }}>
                  Das beste virtuelle Küchen-Home-Staging auf Knopfdruck
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Schnell:</strong> Verwandeln Sie Ihre Küche in
                    Sekunden
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Einfach:</strong> Gestalten Sie attraktive Küchen
                    mühelos
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Qualität:</strong> Hyperrealistische Ergebnisse für
                    Küchen
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Hilfe:</strong> Expertenunterstützung bei der
                    Küchengestaltung
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Pedra testen <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        29 € für 100 Bilder
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    {/* Add testimonial images and text here */}
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore={"Vor virtuellem Küchen-Home-Staging"}
                  altAfter={"Nach virtuellem Küchen-Home-Staging"}
                />

                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Modern" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Modern")}
                    >
                      Küchen Beispiel 1
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Traditional" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Traditional")}
                    >
                      Küchen Beispiel 2
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Rustic" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Rustic")}
                    >
                      Küchen Beispiel 3
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Contemporary" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Contemporary")}
                    >
                      Küchen Beispiel 4
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          ÜBER 20.000 IMMOBILIENPROFIS VERTRAUEN BEREITS AUF PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Virtuelles Küchen-Home-Staging zum{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            schnelleren Verkauf
          </span>{" "}
          jeder Art von Immobilie.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h3 className="feature-title">
                  Erneuern Sie Ihre Küche virtuell
                </h3>
                <div className="feature-text">
                  Transformieren Sie Ihre Küche mit einem Klick, ohne
                  Renovierungen oder Kosten
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">
                  Besondere Merkmale hervorheben
                </h3>
                <div className="feature-text">
                  Betonen Sie die Stärken Ihrer Küche mit beschreibendem Text
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">
                  Küchenfotos verbessern und korrigieren
                </h3>
                <div className="feature-text">
                  Optimieren Sie die Qualität und Perspektive Ihrer Küchenfotos
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h3 className="feature-title">Moderne Geräte hinzufügen</h3>
                <div className="feature-text">
                  Aktualisieren Sie Ihre Küche virtuell mit den neuesten Geräten
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h3 className="feature-title">Oberflächen erneuern</h3>
                <div className="feature-text">
                  Ändern Sie Arbeitsplatten, Fliesen und Oberflächen mit einem
                  Klick
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h3 className="feature-title">
                  Virtuell aufräumen und organisieren
                </h3>
                <div className="feature-text">
                  Entfernen Sie Unordnung und unerwünschte Objekte für eine
                  makellose Küche
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Beispiele unseres virtuellen Küchen-Home-Stagings.
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="hero-example-container">
          <CurtainEffect
            beforeImage={stagingExample.beforeImage}
            afterImage={stagingExample.afterImage}
            altBefore={"Vor virtuellem Küchen-Home-Staging"}
            altAfter={"Nach virtuellem Küchen-Home-Staging"}
          />

          <div style={{ display: "flex", flex: "1 0 0" }}></div>
          <div className="hero-example-buttons-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "6px",
                width: "100%",
              }}
            >
              {stagingExamples.map((item) => (
                <div
                  key={item.name}
                  className={`hero-button ${
                    stagingExample.name === item.name ? "selected" : ""
                  }`}
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    borderRadius: "8px",
                    overflow: "hidden",
                    height: "50px",
                    padding: "0px",
                  }}
                  onClick={() => handleStagingExample(item.name)}
                >
                  <img
                    src={item.beforeImage}
                    alt={item.name}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "6px",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container" style={{ display: "inline" }}>
          Wie funktioniert virtuelles Küchen-Home-Staging?
        </h3>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              SCHRITT 1
            </h5>
            <h2>Laden Sie Fotos Ihrer Küche hoch</h2>
            <div className="value-prop-text">
              Laden Sie ein oder mehrere Bilder Ihrer Küche bei Pedra hoch.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample1}
              alt="Wie man Küchenfotos hochlädt, um virtuelles Home-Staging in Pedra zu erstellen – Infografik"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              SCHRITT 2
            </h5>
            <h2>Wählen Sie Transformationsoptionen für Ihre Küche</h2>
            <div className="value-prop-text">
              Wählen Sie aus verschiedenen Optionen, um Ihre Küche virtuell zu
              renovieren.
              <p>
                Sie können Oberflächen ändern, Geräte hinzufügen, den Raum neu
                organisieren und vieles mehr.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample2}
              alt="Wie man Optionen wählt, um virtuelles Küchen-Home-Staging zu erstellen – Infografik"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              SCHRITT 3
            </h5>
            <h2>Erhalten und laden Sie Ihre transformierte Küche herunter</h2>
            <div className="value-prop-text">
              In wenigen Minuten erhalten Sie realistische Bilder Ihrer
              renovierten Küche.
              <p>
                Laden Sie die Bilder herunter und nutzen Sie sie zur Vermarktung
                Ihrer Immobilie.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample3}
              alt="Wie man ein virtuelles Küchen-Home-Staging herunterlädt – Infografik"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Virtuelles Küchen-Home-Staging für 29 € pro Monat. <br />{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            Und keine langfristige Bindung.
          </span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Pro Plan
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">29 €</div>
              <div className="modal-product-plan-description-text-3">
                pro Monat
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">Enthält</div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#B15DE1" }}>
                100 Bildgenerierungen
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>pro Monat</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Küchen renovieren und neu gestalten
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Küchenoberflächen ändern
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Geräte hinzufügen
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Unerwünschte Objekte entfernen
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Fotoauflösung erhöhen
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Küchenlayout ändern
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Küchenfotografie verbessern
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unbegrenzte Küchengrundrisse
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unbegrenztes Projekt-Sharing
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Wasserzeichen hinzufügen
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unbegrenzter Support
            </div>
            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Pedra testen
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Jederzeit kündbar.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Kaufen Sie bei Bedarf weitere Bilder für 0,20 € pro Bild.
            </div>
          </div>
        </div>
      </div>

      <div className="long-break"> </div>

      <div className="section-one-column-left">
        <div className="title-container">
          Fragen und Antworten zum Küchen-Home-Staging
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">Was ist Küchen-Home-Staging?</h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Küchen-Home-Staging ist eine Immobilienmarketing-Technik, die
                sich darauf konzentriert, die Küche einer Immobilie für
                potenzielle Käufer oder Mieter attraktiv zu präsentieren. Dies
                kann eine Grundreinigung, Organisation, strategische Dekoration
                und kleinere Verbesserungen umfassen, um die Stärken der Küche
                hervorzuheben und ihre Schwächen zu minimieren.
                <br />
                <br />
                Im Fall des virtuellen Küchen-Home-Stagings verwenden wir
                fortschrittliche Technologie, um den Raum digital zu
                transformieren und sein Potenzial ohne kostspielige physische
                Änderungen zu zeigen.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Was sind die Vorteile von virtuellem Home-Staging für Küchen?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                Virtuelles Home-Staging für Küchen bietet zahlreiche Vorteile:
                <br />
                <br />
                1. Kosteneinsparungen: Keine teuren physischen Renovierungen
                nötig.
                <br />
                2. Zeitersparnis: Transformationen erfolgen in Minuten statt
                Wochen.
                <br />
                3. Vielseitigkeit: Sie können mehrere Stile und Konfigurationen
                derselben Küche zeigen.
                <br />
                4. Visuelle Attraktivität: Hilft Käufern, das Potenzial der
                Küche zu visualisieren.
                <br />
                5. Marktdifferenzierung: Heben Sie Ihre Immobilie mit
                attraktiven Küchenbildern hervor.
                <br />
                6. Keine Unannehmlichkeiten: Keine Störung des Alltags der
                aktuellen Bewohner.
                <br />
                7. Verbesserte Online-Präsentation: Ideal für
                Online-Immobilienanzeigen.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                Was kann ich mit Pedras Küchen-Home-Staging machen?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Mit Pedras virtuellem Home Staging für Küchen können Sie eine
                Vielzahl von Transformationen durchführen:
                <br />
                <br />
                1. Küchen renovieren: Sie können Pedras vordefinierte Stile
                nutzen oder eigene Stile erstellen, um das Aussehen der Küche
                komplett zu verändern.
                <br />
                2. Licht und Farbe verbessern: Optimieren Sie die Beleuchtung
                und Farben von Küchenfotos für ein attraktiveres und
                professionelleres Erscheinungsbild.
                <br />
                3. Objekte entfernen: Löschen Sie unerwünschte Elemente aus
                Fotos, wie alte Geräte, Unordnung oder persönliche Gegenstände.
                <br />
                4. Elemente hinzufügen: Fügen Sie neue Objekte in die Küche ein,
                wie moderne Geräte, Dekorationsaccessoires oder zusätzliche
                Möbel.
                <br />
                5. Oberflächen ändern: Modifizieren Sie das Aussehen von
                Arbeitsplatten, Fliesen, Schränken und anderen Oberflächen, um
                verschiedene Designoptionen zu zeigen.
                <br />
                6. Raum neu gestalten: Visualisieren Sie verschiedene
                Küchenkonfigurationen ohne physische Änderungen.
                <br />
                <br />
                Alle diese Transformationen werden virtuell durchgeführt und
                ermöglichen es Ihnen, das Potenzial der Küche schnell und
                wirtschaftlich zu präsentieren.
              </div>
            )}
          </div>

          {/* Add more FAQs here */}
        </div>
      </div>
    </div>
  );
}

export default HomeStagingKitchenDe;
