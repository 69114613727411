// App.js
import React, { useState, useEffect } from "react";
import "./App.css";
import { Link } from "react-router-dom";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ReactComponent as Logo } from "./Images/Logo.svg";
import WorldIcon from "./Icons/WorldIcon";
import ScrollToTop from "./ScrollToTop";
import FooterLogo from "./Images/FooterLogo.png";
import LangManager from "./LangManager";
import LanguageSwitcher from "./LanguageSwitcher.js";

// Pages
import Pricing from "./Pricing";
import PricingEs from "./PricingEs";
import Home from "./Home";
import HomeEs from "./HomeEs";
import FloorPlan from "./FloorPlan";
import FloorPlanEs from "./FloorPlanEs";
import RealEstateAgents from "./RealEstateAgents";
import RealEstateAgentsEs from "./RealEstateAgentsEs";
import InteriorDesign from "./InteriorDesign";
import InteriorDesignEs from "./InteriorDesignEs";
import Render from "./Render";
import RenderEs from "./RenderEs";
import HomeStagingEs from "./HomeStagingEs.js";
import HomeStaging from "./HomeStaging.js";
import NotFound from "./NotFound";
import NotFoundEs from "./NotFoundEs";
import Blog from "./Blog";
import BlogEs from "./BlogEs";
import BlogEsArticle1 from "./BlogArticles/BlogEsArticle1";
import BlogEsArticle2 from "./BlogArticles/BlogEsArticle2";
import BlogEsArticle3 from "./BlogArticles/BlogEsArticle3";
import BlogEsArticle4 from "./BlogArticles/BlogEsArticle4.js";
import BlogEsArticle5 from "./BlogArticles/BlogEsArticle5.js";
import BlogArticle1 from "./BlogArticles/BlogArticle1";
import BlogArticle5 from "./BlogArticles/BlogArticle5.js";
import BlogArticle8 from "./BlogArticles/BlogArticle8.js";
import BlogFrArticle8 from "./BlogArticles/BlogFrArticle8.js";
import RenderIAHeaderIcon from "./Icons/RenderIAHeaederIcon.jsx";
import VirtualStagingAIHeaderIcon from "./Icons/VirtualStagingAIHeaderIcon.jsx";
import FloorPlanHeaderIcon from "./Icons/FloorPlanHeaderIcon.jsx";
import ToggleIconUp from "./Icons/ToggleIconUp.jsx";
import ToggleIconDown from "./Icons/ToggleIconDown.jsx";
import Legal from "./Legal.js";
import LegalEs from "./LegalEs.js";
import Privacy from "./Privacy.js";
import PrivacyEs from "./PrivacyEs.js";
import RealEstatePhotographyEs from "./RealEstatePhotographyEs.js";
import OrangeBlueSky from "./Icons/OrangeBlueSky.jsx";
import RealEstatePhotography from "./RealEstatePhotography.js";
import MlsEs from "./MlsEs.js";
import ReleasesEs from "./ReleasesEs.js";
import ReleaseEsJun24 from "./Releases/ReleaseEsJun24.js";
import Releases from "./Releases.js";
import ReleaseJun24 from "./Releases/ReleaseJun24.js";
import ReleaseMay24 from "./Releases/ReleaseMay24.js";
import ReleaseEsMay24 from "./Releases/ReleaseEsMay24.js";
import AboutUsEs from "./AboutUsEs.js";
import AboutUs from "./AboutUs.js";
import BlogEsArticle6 from "./BlogArticles/BlogEsArticle6.js";
import BlogArticle6 from "./BlogArticles/BlogArticle6.js";
import TestimonialsEs from "./TestimonialsEs.js";
import Testimonials from "./Testimonials.js";
import HowTo3DEs from "./Help/HowTo3DEs.js";
import HowTo3D from "./Help/HowTo3D.js";
import BlogArticle4 from "./BlogArticles/BlogArticle4.js";
import ReleaseEsJul24 from "./Releases/ReleaseEsJul24.js";
import ReleaseJul24 from "./Releases/ReleaseJul24.js";
import HowToRenovateEs from "./Help/HowToRenovateEs.js";
import HowToRenovate from "./Help/HowToRenovate.js";
import HowToFurnishEs from "./Help/HowToFurnishEs.js";
import HowToFurnish from "./Help/HowToFurnish.js";
import SocialMediaIcon1 from "./Icons/SocialMediaIcon1.jsx";
import SocialMediaIcon2 from "./Icons/SocialMediaIcon2.jsx";
import SocialMediaIcon3 from "./Icons/SocialMediaIcon3.jsx";
import SocialMediaIcon4 from "./Icons/SocialMediaIcon4.jsx";
import BlogEsArticle7 from "./BlogArticles/BlogEsArticle7.js";
import BlogArticle7 from "./BlogArticles/BlogArticle7.js";
import HowToRemoveObjectEs from "./Help/HowToRemoveObjectEs.js";
import HowToRemoveObject from "./Help/HowToRemoveObject.js";
import HomeFr from "./HomeFr.js";
import HowToRenovateFr from "./Help/HowToRenovateFr.js";
import HowToFurnishFr from "./Help/HowToFurnishFr.js";
import HowToRemoveObjectFr from "./Help/HowToRemoveObjectFr.js";
import HowTo3DFr from "./Help/HowTo3DFr.js";
import HowToCurtainEffect from "./Help/HowToCurtainEffect.js";
import HowToCurtainEffectEs from "./Help/HowToCurtainEffectEs.js";
import HowToCurtainEffectFr from "./Help/HowToCurtainEffectFr.js";
import HowToCurtainEffectPt from "./Help/HowToCurtainEffectPt.js";
import HowToCurtainEffectDe from "./Help/HowToCurtainEffectDe.js";
import PrivacyFr from "./PrivacyFr.js";
import LegalFr from "./LegalFr.js";
import NotFoundFr from "./NotFoundFr.js";
import BlogFrArticle1 from "./BlogArticles/BlogFrArticle1.js";
import BlogFrArticle4 from "./BlogArticles/BlogFrArticle4.js";
import BlogFrArticle5 from "./BlogArticles/BlogFrArticle5.js";
import BlogFrArticle6 from "./BlogArticles/BlogFrArticle6.js";
import BlogFrArticle7 from "./BlogArticles/BlogFrArticle7.js";
import ReleaseJul24Fr from "./Releases/ReleaseFrJul24.js";
import ReleaseJun24Fr from "./Releases/ReleaseFrJun24.js";
import ReleaseMay24Fr from "./Releases/ReleaseFrMay24.js";
import ReleasesFr from "./ReleasesFr.js";
import HomeStagingFr from "./HomeStagingFr.js";
import RenderFr from "./RenderFr.js";
import FloorPlanFr from "./FloorPlanFr.js";
import RealEstatePhotographyFr from "./RealEstatePhotographyFr.js";
import RealEstateAgentsFr from "./RealEstateAgentsFr.js";
import AboutUsFr from "./AboutUsFr.js";
import PricingFr from "./PricingFr.js";
import BlogFr from "./BlogFr.js";
import TestimonialsFr from "./TestimonialsFr.js";
import InteriorDesignFr from "./InteriorDesignFr.js";
import BlogEsArticle8 from "./BlogArticles/BlogEsArticle8.js";
import BlogEsArticle9 from "./BlogArticles/BlogEsArticle9.js";
import BlogFrArticle9 from "./BlogArticles/BlogFrArticle9.js";
import BlogArticle9 from "./BlogArticles/BlogArticle9.js";
import FreeDescriptionGeneratorEs from "./FreeTools/FreeDescriptionGeneratorEs.js";
import BlogEsArticle10 from "./BlogArticles/BlogEsArticle10.js";
import BlogFrArticle10 from "./BlogArticles/BlogFrArticle10.js";
import BlogArticle10 from "./BlogArticles/BlogArticle10.js";
import BlogEsArticle11 from "./BlogArticles/BlogEsArticle11.js";
import BlogFrArticle11 from "./BlogArticles/BlogFrArticle11.js";
import BlogArticle11 from "./BlogArticles/BlogArticle11.js";
import ReleaseEsAug24 from "./Releases/ReleaseEsAug24.js";
import ReleaseAug24 from "./Releases/ReleaseAug24.js";
import ReleaseFrAug24 from "./Releases/ReleaseFrAug24.js";
import BlogFrArticle12 from "./BlogArticles/BlogFrArticle12.js";
import BlogArticle12 from "./BlogArticles/BlogArticle12.js";
import BlogEsArticle12 from "./BlogArticles/BlogEsArticle12.js";
import HowToEmptyRoomEs from "./Help/HowToEmptyRoomEs.js";
import HowToEmptyRoomFr from "./Help/HowToEmptyRoomFr.js";
import HowToEmptyRoom from "./Help/HowToEmptyRoom.js";
import BlogEsArticle13 from "./BlogArticles/BlogEsArticle13.js";
import BlogFrArticle13 from "./BlogArticles/BlogFrArticle13.js";
import BlogArticle13 from "./BlogArticles/BlogArticle13.js";
import BlogFrArticle14 from "./BlogArticles/BlogFrArticle14.js";
import BlogEsArticle14 from "./BlogArticles/BlogEsArticle14.js";
import BlogArticle14 from "./BlogArticles/BlogArticle14.js";
import FreeDescriptionGenerator from "./FreeTools/FreeDescriptionGenerator.js";
import FreeDescriptionGeneratorFr from "./FreeTools/FreeDescriptionGeneratorFr.js";
import BlogEsArticle15 from "./BlogArticles/BlogEsArticle15.js";
import BlogArticle15 from "./BlogArticles/BlogArticle15.js";
import BlogFrArticle15 from "./BlogArticles/BlogFrArticle15.js";
import HowToVirtualStagingEs from "./Help/HowToVirtualStagingEs.js";
import HowToVirtualStaging from "./Help/HowToVirtualStaging.js";
import HowToVirtualStagingFr from "./Help/HowToVirtualStagingFr.js";
import BlogArticle16 from "./BlogArticles/BlogArticle16.js";
import BlogEsArticle16 from "./BlogArticles/BlogEsArticle16.js";
import BlogFrArticle16 from "./BlogArticles/BlogFrArticle16.js";
import BlogArticle17 from "./BlogArticles/BlogArticle17.js";
import BlogEsArticle17 from "./BlogArticles/BlogEsArticle17.js";
import BlogFrArticle17 from "./BlogArticles/BlogFrArticle17.js";
import BlogEsArticle18 from "./BlogArticles/BlogEsArticle18.js";
import BlogFrArticle18 from "./BlogArticles/BlogFrArticle18.js";
import BlogArticle18 from "./BlogArticles/BlogArticle18.js";
import ReleaseEsSep24 from "./Releases/ReleaseEsSep24.js";
import ReleaseFrSep24 from "./Releases/ReleaseFrSep24.js";
import ReleaseSep24 from "./Releases/ReleaseSep24.js";
import ApiDocs from "./ApiDocs.js";
import ApiDocsEs from "./ApiDocsEs.js";
import ApiDocsFr from "./ApiDocsFr.js";
import BlogArticle19 from "./BlogArticles/BlogArticle19.js";
import BlogFrArticle19 from "./BlogArticles/BlogFrArticle19.js";
import BlogEsArticle19 from "./BlogArticles/BlogEsArticle19.js";
import BlogArticle20 from "./BlogArticles/BlogArticle20.js";
import BlogEsArticle20 from "./BlogArticles/BlogEsArticle20.js";
import BlogFrArticle20 from "./BlogArticles/BlogFrArticle20.js";
import BlogArticle21 from "./BlogArticles/BlogArticle21.js";
import BlogEsArticle21 from "./BlogArticles/BlogEsArticle21.js";
import BlogFrArticle21 from "./BlogArticles/BlogFrArticle21.js";
import HomeStagingKitchenEs from "./HomeStagingKitchenEs.js";
import HomeStagingKitchen from "./HomeStagingKitchen.js";
import HomeStagingKitchenFr from "./HomeStagingKitchenFr.js";
import HomeStagingLivingRoomFr from "./HomeStagingLivingRoomFr.js";
import HomeStagingLivingRoomEs from "./HomeStagingLivingRoomEs.js";
import HomeStagingLivingRoom from "./HomeStagingLivingRoom.js";
import BlogArticle22 from "./BlogArticles/BlogArticle22.js";
import BlogEsArticle22 from "./BlogArticles/BlogEsArticle22.js";
import BlogFrArticle22 from "./BlogArticles/BlogFrArticle22.js";
import BlogFrArticle23 from "./BlogArticles/BlogFrArticle23.js";
import BlogEsArticle23 from "./BlogArticles/BlogEsArticle23.js";
import BlogArticle23 from "./BlogArticles/BlogArticle23.js";
import HomeStagingTerraceEs from "./HomeStagingTerraceEs.js";
import HomeStagingTerrace from "./HomeStagingTerrace.js";
import HomeStagingTerraceFr from "./HomeStagingTerraceFr.js";
import HomeStagingBedroom from "./HomeStagingBedroom.js";
import HomeStagingBedroomEs from "./HomeStagingBedroomEs.js";
import HomeStagingBedroomFr from "./HomeStagingBedroomFr.js";
import HomeStagingBathroomFr from "./HomeStagingBathroomFr.js";
import HomeStagingBathroomEs from "./HomeStagingBathroomEs.js";
import HomeStagingBathroom from "./HomeStagingBathroom.js";
import BlogArticle24 from "./BlogArticles/BlogArticle24.js";
import BlogEsArticle24 from "./BlogArticles/BlogEsArticle24.js";
import BlogFrArticle24 from "./BlogArticles/BlogFrArticle24.js";
import HowToVirtualStagingTerrace from "./Help/HowToVirtualStagingTerrace.js";
import HowToVirtualStagingTerraceEs from "./Help/HowToVirtualStagingTerraceEs.js";
import HowToVirtualStagingTerraceFr from "./Help/HowToVirtualStagingTerraceFr.js";
import BlogArticle25 from "./BlogArticles/BlogArticle25.js";
import BlogEsArticle25 from "./BlogArticles/BlogEsArticle25.js";
import BlogFrArticle25 from "./BlogArticles/BlogFrArticle25.js";
import HowToVirtualStagingBedroom from "./Help/HowToVirtualStagingBedroom.js";
import HowToVirtualStagingBedroomEs from "./Help/HowToVirtualStagingBedroomEs.js";
import HowToVirtualStagingBedroomFr from "./Help/HowToVirtualStagingBedroomFr.js";
import HowToVirtualStagingLivingRoom from "./Help/HowToVirtualStagingLivingRoom.js";
import HowToVirtualStagingLivingRoomEs from "./Help/HowToVirtualStagingLivingRoomEs.js";
import HowToVirtualStagingLivingRoomFr from "./Help/HowToVirtualStagingLivingRoomFr.js";
import BlogArticle26 from "./BlogArticles/BlogArticle26.js";
import BlogEsArticle26 from "./BlogArticles/BlogEsArticle26.js";
import BlogFrArticle26 from "./BlogArticles/BlogFrArticle26.js";
import FreeImageFormatConverterEs from "./FreeTools/FreeImageFormatConverterEs.js";
import FreeImageFormatConverter from "./FreeTools/FreeImageFormatConverter.js";
import FreeImageFormatConverterFr from "./FreeTools/FreeImageFormatConverterFr.js";
import BlogEsArticle27 from "./BlogArticles/BlogEsArticle27.js";
import BlogArticle27 from "./BlogArticles/BlogArticle27.js";
import BlogFrArticle27 from "./BlogArticles/BlogFrArticle27.js";
import HowToVirtualStagingKitchenEs from "./Help/HowToVirtualStagingKitchenEs.js";
import HowToVirtualStagingKitchenFr from "./Help/HowToVirtualStagingKitchenFr.js";
import HowToVirtualStagingKitchen from "./Help/HowToVirtualStagingKitchen.js";
import HowToVirtualStagingBathroom from "./Help/HowToVirtualStagingBathroom.js";
import HowToVirtualStagingBathroomEs from "./Help/HowToVirtualStagingBathroomEs.js";
import HowToVirtualStagingBathroomFr from "./Help/HowToVirtualStagingBathroomFr.js";
import ReleaseOct24 from "./Releases/ReleaseOct24.js";
import ReleaseEsOct24 from "./Releases/ReleaseEsOct24.js";
import ReleaseFrOct24 from "./Releases/ReleaseFrOct24.js";
import RenovationFr from "./RenovationFr.js";
import RenovationEs from "./RenovationEs.js";
import Renovation from "./Renovation.js";
import BlogEsArticle28 from "./BlogArticles/BlogEsArticle28.js";
import BlogFrArticle28 from "./BlogArticles/BlogFrArticle28.js";
import BlogArticle28 from "./BlogArticles/BlogArticle28.js";
import RenderKitchen from "./RenderKitchen.js";
import RenderKitchenEs from "./RenderKitchenEs.js";
import RenderKitchenFr from "./RenderKitchenFr.js";
import RenderBedroom from "./RenderBedroom.js";
import RenderBedroomEs from "./RenderBedroomEs.js";
import RenderBedroomFr from "./RenderBedroomFr.js";
import RenderBathroomEs from "./RenderBathroomEs.js";
import RenderBahtroomFr from "./RenderBahtroomFr.js";
import RenderBathroom from "./RenderBathroom.js";
import RenderLivingRoom from "./RenderLivingRoom.js";
import RenderLivingRoomEs from "./RenderLivingRoomEs.js";
import RenderLivingRoomFr from "./RenderLivingRoomFr.js";
import RenderTerrace from "./RenderTerrace.js";
import RenderTerraceEs from "./RenderTerraceEs.js";
import RenderTerraceFr from "./RenderTerraceFr.js";
import RenderHouse from "./RenderHouse.js";
import RenderHouseFr from "./RenderHouseFr.js";
import RenderHouseEs from "./RenderHouseEs.js";
import HowToRenderKitchen from "./Help/HowToRenderKitchen.js";
import HowToRenderKitchenEs from "./Help/HowToRenderKitchenEs.js";
import HowToRenderKitchenFr from "./Help/HowToRenderKitchenFr.js";
import HowToRenderBathroom from "./Help/HowToRenderBathroom.js";
import HowToRenderBathroomEs from "./Help/HowToRenderBathroomEs.js";
import HowToRenderBathroomFr from "./Help/HowToRenderBathroomFr.js";
import HowToRenderLivingRoom from "./Help/HowToRenderLivingRoom.js";
import HowToRenderLivingRoomEs from "./Help/HowToRenderLivingRoomEs.js";
import HowToRenderLivingRoomFr from "./Help/HowToRenderLivingRoomFr.js";
import HowToRenderBedroomFr from "./Help/HowToRenderBedroomFr.js";
import HowToRenderBedroom from "./Help/HowToRenderBedroom.js";
import HowToRenderBedroomEs from "./Help/HowToRenderBedroomEs.js";
import HowToRenderTerrace from "./Help/HowToRenderTerrace.js";
import HowToRenderTerraceEs from "./Help/HowToRenderTerraceEs.js";
import HowToRenderTerraceFr from "./Help/HowToRenderTerraceFr.js";
import HowToDrawFloorPlan from "./Help/HowToDrawFloorPlan.js";
import HowToDrawFloorPlanEs from "./Help/HowToDrawFloorPlanEs.js";
import HowToDrawFloorPlanFr from "./Help/HowToDrawFloorPlanFr.js";
import HowToRenderHouse from "./Help/HowToRenderHouse.js";
import HowToRenderHouseEs from "./Help/HowToRenderHouseEs.js";
import HowToRenderHouseFr from "./Help/HowToRenderHouseFr.js";
import Help from "./Help.js";
import HelpEs from "./HelpEs.js";
import HelpFr from "./HelpFr.js";
import ReleaseEsNov24 from "./Releases/ReleaseEsNov24.js";
import ReleaseNov24 from "./Releases/ReleaseNov24.js";
import ReleaseFrNov24 from "./Releases/ReleaseFrNov24.js";
import BlogFrArticle29 from "./BlogArticles/BlogFrArticle29.js";
import BlogEsArticle29 from "./BlogArticles/BlogEsArticle29.js";
import BlogArticle29 from "./BlogArticles/BlogArticle29.js";
import RenderFromFloorPlan from "./RenderFromFloorPlan.js";
import RenderFromFloorPlanEs from "./RenderFromFloorPlanEs.js";
import RenderFromFloorPlanFr from "./RenderFromFloorPlanFr.js";
import RenderFromFloorPlanIcon from "./Icons/RenderFromFloorPlanIcon.jsx";
import HowToRenderFloorPlanWithAI from "./Help/HowToRenderFloorPlanWithAI.js";
import HowToRenderFloorPlanWithAIEs from "./Help/HowToRenderFloorPlanWithAIEs.js";
import HowToRenderFloorPlanWithAIFr from "./Help/HowToRenderFloorPlanWithAIFr.js";
import BlogArticle30 from "./BlogArticles/BlogArticle30.js";
import BlogEsArticle30 from "./BlogArticles/BlogEsArticle30.js";
import BlogFrArticle30 from "./BlogArticles/BlogFrArticle30.js";
import RenderLocalEs from "./RenderLocalEs.js";
import RenderLocal from "./RenderLocal.js";
import RenderLocalFr from "./RenderLocalFr.js";
import HomeStagingLocal from "./HomeStagingLocal.js";
import HomeStagingLocalFr from "./HomeStagingLocalFr.js";
import HomeStagingLocalEs from "./HomeStagingLocalEs.js";
import HowToRenderLocal from "./Help/HowToRenderLocal.js";
import HowToRenderLocalFr from "./Help/HowToRenderLocalFr.js";
import HowToRenderLocalEs from "./Help/HowToRenderLocalEs.js";
import ReleaseDec24 from "./Releases/ReleaseDec24.js";
import ReleaseEsDec24 from "./Releases/ReleaseEsDec24.js";
import ReleaseFrDec24 from "./Releases/ReleaseFrDec24.js";
import BlogEsArticle32 from "./BlogArticles/BlogEsArticle32.js";
import BlogDeArticle32 from "./BlogArticles/BlogDeArticle32.js";
import BlogPtArticle32 from "./BlogArticles/BlogPtArticle32.js";
import BlogArticle32 from "./BlogArticles/BlogArticle32.js";
import BlogFrArticle32 from "./BlogArticles/BlogFrArticle32.js";

// Portuguese imports
import AboutUsPt from "./AboutUsPt";
import ApiDocsPt from "./ApiDocsPt";
import BlogPt from "./BlogPt";
import BlogPtArticle1 from "./BlogArticles/BlogPtArticle1";
import BlogPtArticle10 from "./BlogArticles/BlogPtArticle10";
import BlogPtArticle11 from "./BlogArticles/BlogPtArticle11";
import BlogPtArticle12 from "./BlogArticles/BlogPtArticle12";
import BlogPtArticle13 from "./BlogArticles/BlogPtArticle13";
import BlogPtArticle14 from "./BlogArticles/BlogPtArticle14";
import BlogPtArticle15 from "./BlogArticles/BlogPtArticle15";
import BlogPtArticle16 from "./BlogArticles/BlogPtArticle16";
import BlogPtArticle17 from "./BlogArticles/BlogPtArticle17";
import BlogPtArticle18 from "./BlogArticles/BlogPtArticle18";
import BlogPtArticle19 from "./BlogArticles/BlogPtArticle19";
import BlogPtArticle20 from "./BlogArticles/BlogPtArticle20";
import BlogPtArticle21 from "./BlogArticles/BlogPtArticle21";
import BlogPtArticle22 from "./BlogArticles/BlogPtArticle22";
import BlogPtArticle23 from "./BlogArticles/BlogPtArticle23";
import BlogPtArticle24 from "./BlogArticles/BlogPtArticle24";
import BlogPtArticle25 from "./BlogArticles/BlogPtArticle25";
import BlogPtArticle26 from "./BlogArticles/BlogPtArticle26";
import BlogPtArticle27 from "./BlogArticles/BlogPtArticle27";
import BlogPtArticle28 from "./BlogArticles/BlogPtArticle28";
import BlogPtArticle29 from "./BlogArticles/BlogPtArticle29";
import BlogPtArticle30 from "./BlogArticles/BlogPtArticle30";
import BlogPtArticle4 from "./BlogArticles/BlogPtArticle4";
import BlogPtArticle5 from "./BlogArticles/BlogPtArticle5";
import BlogPtArticle6 from "./BlogArticles/BlogPtArticle6";
import BlogPtArticle7 from "./BlogArticles/BlogPtArticle7";
import BlogPtArticle8 from "./BlogArticles/BlogPtArticle8";
import BlogPtArticle9 from "./BlogArticles/BlogPtArticle9";
import FloorPlanPt from "./FloorPlanPt";
import FreeDescriptionGeneratorPt from "./FreeTools/FreeDescriptionGeneratorPt";
import FreeImageFormatConverterPt from "./FreeTools/FreeImageFormatConverterPt";
import HelpPt from "./HelpPt";
import HomePt from "./HomePt";
import HomeStagingPt from "./HomeStagingPt";
import HomeStagingBathroomPt from "./HomeStagingBathroomPt";
import HomeStagingBedroomPt from "./HomeStagingBedroomPt";
import HomeStagingKitchenPt from "./HomeStagingKitchenPt";
import HomeStagingLivingRoomPt from "./HomeStagingLivingRoomPt";
import HomeStagingLocalPt from "./HomeStagingLocalPt";
import HomeStagingTerracePt from "./HomeStagingTerracePt";
import HowTo3DPt from "./Help/HowTo3DPt";
import HowToDrawFloorPlanPt from "./Help/HowToDrawFloorPlanPt";
import HowToEmptyRoomPt from "./Help/HowToEmptyRoomPt";
import HowToFurnishPt from "./Help/HowToFurnishPt";
import HowToRemoveObjectPt from "./Help/HowToRemoveObjectPt";
import HowToRenderBathroomPt from "./Help/HowToRenderBathroomPt";
import HowToRenderBedroomPt from "./Help/HowToRenderBedroomPt";
import HowToRenderFloorPlanWithAIPt from "./Help/HowToRenderFloorPlanWithAIPt";
import HowToRenderHousePt from "./Help/HowToRenderHousePt";
import HowToRenderKitchenPt from "./Help/HowToRenderKitchenPt";
import HowToRenderLivingRoomPt from "./Help/HowToRenderLivingRoomPt";
import HowToRenderLocalPt from "./Help/HowToRenderLocalPt";
import HowToRenderTerracePt from "./Help/HowToRenderTerracePt";
import HowToRenovatePt from "./Help/HowToRenovatePt";
import HowToVirtualStagingPt from "./Help/HowToVirtualStagingPt";
import HowToVirtualStagingBathroomPt from "./Help/HowToVirtualStagingBathroomPt";
import HowToVirtualStagingBedroomPt from "./Help/HowToVirtualStagingBedroomPt";
import HowToVirtualStagingKitchenPt from "./Help/HowToVirtualStagingKitchenPt";
import HowToVirtualStagingLivingRoomPt from "./Help/HowToVirtualStagingLivingRoomPt";
import HowToVirtualStagingTerracePt from "./Help/HowToVirtualStagingTerracePt";
import InteriorDesignPt from "./InteriorDesignPt";
import LegalPt from "./LegalPt";
import NotFoundPt from "./NotFoundPt";
import PricingPt from "./PricingPt";
import PrivacyPt from "./PrivacyPt";
import RealEstateAgentsPt from "./RealEstateAgentsPt";
import RealEstatePhotographyPt from "./RealEstatePhotographyPt";
import ReleaseAug24Pt from "./Releases/ReleaseAug24Pt";
import ReleaseDec24Pt from "./Releases/ReleaseDec24Pt";
import ReleaseJul24Pt from "./Releases/ReleaseJul24Pt";
import ReleaseJun24Pt from "./Releases/ReleaseJun24Pt";
import ReleaseMay24Pt from "./Releases/ReleaseMay24Pt";
import ReleaseNov24Pt from "./Releases/ReleaseNov24Pt";
import ReleaseOct24Pt from "./Releases/ReleaseOct24Pt";
import ReleasesPt from "./ReleasesPt";
import ReleaseSep24Pt from "./Releases/ReleaseSep24Pt";
import RenderPt from "./RenderPt";
import RenderBathroomPt from "./RenderBathroomPt";
import RenderBedroomPt from "./RenderBedroomPt";
import RenderFromFloorPlanPt from "./RenderFromFloorPlanPt";
import RenderHousePt from "./RenderHousePt";
import RenderKitchenPt from "./RenderKitchenPt";
import RenderLivingRoomPt from "./RenderLivingRoomPt";
import RenderLocalPt from "./RenderLocalPt";
import RenderTerracePt from "./RenderTerracePt";
import RenovationPt from "./RenovationPt";
import TestimonialsPt from "./TestimonialsPt";
import GoogleIcon from "./Icons/GoogleIcon.jsx";
import RenderTerraceDe from "./RenderTerraceDe.js";
import BlogDeArticle30 from "./BlogArticles/BlogDeArticle30.js";
import BlogDeArticle29 from "./BlogArticles/BlogDeArticle29.js";
import BlogDeArticle28 from "./BlogArticles/BlogDeArticle28.js";
import BlogDeArticle27 from "./BlogArticles/BlogDeArticle27.js";
import BlogDeArticle26 from "./BlogArticles/BlogDeArticle26.js";
import BlogDeArticle25 from "./BlogArticles/BlogDeArticle25.js";
import BlogDeArticle24 from "./BlogArticles/BlogDeArticle24.js";
import BlogDeArticle23 from "./BlogArticles/BlogDeArticle23.js";
import BlogDeArticle22 from "./BlogArticles/BlogDeArticle22.js";
import BlogDeArticle21 from "./BlogArticles/BlogDeArticle21.js";
import BlogDeArticle20 from "./BlogArticles/BlogDeArticle20.js";
import BlogDeArticle19 from "./BlogArticles/BlogDeArticle19.js";
import BlogDeArticle18 from "./BlogArticles/BlogDeArticle18.js";
import BlogDeArticle17 from "./BlogArticles/BlogDeArticle17.js";
import BlogDeArticle16 from "./BlogArticles/BlogDeArticle16.js";
import BlogDeArticle15 from "./BlogArticles/BlogDeArticle15.js";
import BlogDeArticle14 from "./BlogArticles/BlogDeArticle14.js";
import BlogDeArticle13 from "./BlogArticles/BlogDeArticle13.js";
import BlogDeArticle12 from "./BlogArticles/BlogDeArticle12.js";
import BlogDeArticle11 from "./BlogArticles/BlogDeArticle11.js";
import BlogDeArticle10 from "./BlogArticles/BlogDeArticle10.js";
import BlogDeArticle9 from "./BlogArticles/BlogDeArticle9.js";
import BlogDeArticle8 from "./BlogArticles/BlogDeArticle8.js";
import BlogDeArticle7 from "./BlogArticles/BlogDeArticle7.js";
import BlogDeArticle6 from "./BlogArticles/BlogDeArticle6.js";
import BlogDeArticle5 from "./BlogArticles/BlogDeArticle5.js";
import BlogDeArticle4 from "./BlogArticles/BlogDeArticle4.js";
import BlogDeArticle1 from "./BlogArticles/BlogDeArticle1.js";
import BlogDe from "./BlogDe.js";
import HomeStagingTerraceDe from "./HomeStagingTerraceDe.js";
import HomeStagingLocalDe from "./HomeStagingLocalDe.js";
import HomeStagingLivingRoomDe from "./HomeStagingLivingRoomDe.js";
import HomeStagingKitchenDe from "./HomeStagingKitchenDe.js";
import HomeStagingDe from "./HomeStagingDe.js";
import HomeStagingBedroomDe from "./HomeStagingBedroomDe.js";
import HomeStagingBathroomDe from "./HomeStagingBathroomDe.js";
import RenderLocalDe from "./RenderLocalDe.js";
import RenderLivingRoomDe from "./RenderLivingRoomDe.js";
import RenderKitchenDe from "./RenderKitchenDe.js";
import RenderHouseDe from "./RenderHouseDe.js";
import RenderFromFloorPlanDe from "./RenderFromFloorPlanDe.js";
import RenderDe from "./RenderDe.js";
import RenderBedroomDe from "./RenderBedroomDe.js";
import RenderBathroomDe from "./RenderBathroomDe.js";
import HowToVirtualStagingTerraceDe from "./Help/HowToVirtualStagingTerraceDe.js";
import HowToVirtualStagingLivingRoomDe from "./Help/HowToVirtualStagingLivingRoomDe.js";
import HowToVirtualStagingKitchenDe from "./Help/HowToVirtualStagingKitchenDe.js";
import HowToVirtualStagingDe from "./Help/HowToVirtualStagingDe.js";
import HowToVirtualStagingBedroomDe from "./Help/HowToVirtualStagingBedroomDe.js";
import HowToVirtualStagingBathroomDe from "./Help/HowToVirtualStagingBathroomDe.js";
import HowToRenovateDe from "./Help/HowToRenovateDe.js";
import HowToRenderTerraceDe from "./Help/HowToRenderTerraceDe.js";
import HowToRenderLocalDe from "./Help/HowToRenderLocalDe.js";
import HowToRenderLivingRoomDe from "./Help/HowToRenderLivingRoomDe.js";
import HowToRenderKitchenDe from "./Help/HowToRenderKitchenDe.js";
import HowToRenderHouseDe from "./Help/HowToRenderHouseDe.js";
import HowToRenderFloorPlanWithAIDe from "./Help/HowToRenderFloorPlanWithAIDe.js";
import HowToRenderBedroomDe from "./Help/HowToRenderBedroomDe.js";
import HowToRenderBathroomDe from "./Help/HowToRenderBathroomDe.js";
import HowToRemoveObjectDe from "./Help/HowToRemoveObjectDe.js";
import HowToFurnishDe from "./Help/HowToFurnishDe.js";
import HowToEmptyRoomDe from "./Help/HowToEmptyRoomDe.js";
import HowToDrawFloorPlanDe from "./Help/HowToDrawFloorPlanDe.js";
import HowTo3DDe from "./Help/HowTo3DDe.js";
import ReleasesDe from "./ReleasesDe.js";
import ReleaseSep24De from "./Releases/ReleaseSep24De.js";
import ReleaseOct24De from "./Releases/ReleaseOct24De.js";
import ReleaseNov24De from "./Releases/ReleaseNov24De.js";
import ReleaseMay24De from "./Releases/ReleaseMay24De.js";
import ReleaseJun24De from "./Releases/ReleaseJun24De.js";
import ReleaseJul24De from "./Releases/ReleaseJul24De.js";
import ReleaseDec24De from "./Releases/ReleaseDec24De.js";
import ReleaseAug24De from "./Releases/ReleaseAug24De.js";
import TestimonialsDe from "./TestimonialsDe.js";
import RenovationDe from "./RenovationDe.js";
import RealEstatePhotographyDe from "./RealEstatePhotographyDe.js";
import RealEstateAgentsDe from "./RealEstateAgentsDe.js";
import PrivacyDe from "./PrivacyDe.js";
import PricingDe from "./PricingDe.js";
import NotFoundDe from "./NotFoundDe.js";
import LegalDe from "./LegalDe.js";
import InteriorDesignDe from "./InteriorDesignDe.js";
import HelpDe from "./HelpDe.js";
import HomeDe from "./HomeDe.js";
import FreeImageFormatConverterDe from "./FreeTools/FreeImageFormatConverterDe.js";
import FreeDescriptionGeneratorDe from "./FreeTools/FreeDescriptionGeneratorDe.js";
import FloorPlanDe from "./FloorPlanDe.js";
import ApiDocsDe from "./ApiDocsDe.js";
import AboutUsDe from "./AboutUsDe.js";
import BlogArticle31 from "./BlogArticles/BlogArticle31.js";
import BlogArticle31Es from "./BlogArticles/BlogEsArticle31.js";
import BlogArticle31Fr from "./BlogArticles/BlogFrArticle31.js";
import BlogArticle31Pt from "./BlogArticles/BlogPtArticle31.js";
import BlogArticle31De from "./BlogArticles/BlogDeArticle31.js";
import HowToVirtualStageVideoEs from "./Help/HowToVirtualStageVideoEs.js";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function redirectToLoginPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de", "_blank");
  } else {
    window.open("https://app.pedra.so", "_blank");
  }
}

function App() {
  // States to handle page interactions
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [productHover, setProductHover] = useState(false);
  const [languageHover, setLanguageHover] = useState(false);

  // Determine initial locale and locale path based on URL
  const initialLocale = window.location.pathname.startsWith("/es")
    ? "es"
    : window.location.pathname.startsWith("/fr")
    ? "fr"
    : window.location.pathname.startsWith("/pt")
    ? "pt"
    : window.location.pathname.startsWith("/de")
    ? "de"
    : "en";
  const initialLocalePath =
    initialLocale === "en"
      ? "/"
      : initialLocale === "fr"
      ? "fr/"
      : initialLocale === "pt"
      ? "pt/"
      : initialLocale === "de"
      ? "de/"
      : "es/";
  const [locale, setLocale] = useState(initialLocale);
  const [localePath, setLocalePath] = useState(initialLocalePath);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Router>
      <LangManager />
      <div className="App">
        <header>
          <Link
            to={`/${locale === "en" ? "" : locale}`}
            style={{ height: "40px", marginLeft: "0px" }}
          >
            <Logo height="40" width="100" style={{ marginLeft: "10px" }} />
          </Link>

          <div>
            {windowWidth > 625 && (
              <div
                className="button-small-secondary"
                onMouseEnter={() => setProductHover(true)}
                onMouseLeave={() => setProductHover(false)}
              >
                {locale === "en" ? (
                  <>Product</>
                ) : locale === "es" ? (
                  <>Producto</>
                ) : locale === "pt" ? (
                  "Produto"
                ) : locale === "de" ? (
                  "Produkt"
                ) : (
                  "Outils"
                )}
                {productHover ? <ToggleIconUp /> : <ToggleIconDown />}
              </div>
            )}
          </div>

          <div>
            {windowWidth > 625 && (
              <Link
                className="button-small-secondary"
                to={`${localePath}${
                  locale === "pt"
                    ? "precos"
                    : locale === "de"
                    ? "preise"
                    : "pricing"
                }`}
                hrefLang={
                  locale === "en"
                    ? "en"
                    : locale === "es"
                    ? "es"
                    : locale === "pt"
                    ? "pt"
                    : locale === "de"
                    ? "de"
                    : "fr"
                }
              >
                {locale === "en"
                  ? "Pricing"
                  : locale === "es"
                  ? "Precio"
                  : locale === "pt"
                  ? "Preços"
                  : locale === "de"
                  ? "Preise"
                  : "Tarifs"}
              </Link>
            )}
          </div>

          <div className="header-spacer"></div>

          <button
            className="button-small-secondary"
            onMouseEnter={() => setLanguageHover(true)}
            onMouseLeave={() => setLanguageHover(false)}
          >
            <span
              style={{
                color: "#878787",
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <WorldIcon />
            </span>
            <span>
              {locale === "en"
                ? "English"
                : locale === "es"
                ? "Español"
                : locale === "pt"
                ? "Português"
                : locale === "de"
                ? "Deutsch"
                : "Français"}
            </span>
            {languageHover && (
              <div
                style={{
                  position: "absolute",
                  display: "flex",
                  flexDirection: "column",
                  gap: "2px",
                  top: "45px",
                  zIndex: 2,
                  width: "120px",
                  backgroundColor: "#fff",
                  padding: "4px",
                  borderRadius: "8px",
                  border: "1px solid #e0e0e0",
                  boxShadow:
                    "rgba(15, 15, 15, 0.1) 0px 3px 6px 0, rgba(15, 15, 15, 0.2) 0px 9px 24px 0px",
                }}
                onMouseEnter={() => setLanguageHover(true)}
                onMouseLeave={() => setLanguageHover(false)}
              >
                <LanguageSwitcher
                  setLocale={setLocale}
                  setLocalePath={setLocalePath}
                />
              </div>
            )}
          </button>

          <div className="button-navbar-divider"></div>

          <div className="button-container">
            {windowWidth > 450 && (
              <button
                className="button-small-secondary"
                style={{ lineHeight: "100%" }}
                onClick={redirectToLoginPage}
              >
                {locale === "en"
                  ? "Log in"
                  : locale === "es"
                  ? "Iniciar sesión"
                  : locale === "pt"
                  ? "Entrar"
                  : locale === "de"
                  ? "Anmelden"
                  : "Se connecter"}
              </button>
            )}
            <button className="button-small-header" onClick={redirectToPage}>
              {locale === "en"
                ? "Try Pedra"
                : locale === "es"
                ? "Prueba Pedra"
                : locale === "pt"
                ? "Experimente Pedra"
                : locale === "de"
                ? "Pedra testen"
                : "Essayez Pedra"}
            </button>
          </div>

          {productHover && (
            <div
              style={{
                position: "fixed",
                display: "flex",
                flexDirection: "column",
                gap: "2px",
                top: "45px",
                left: "130px",
                zIndex: 2,
                backgroundColor: "#fff",
                padding: "4px",
                borderRadius: "8px",
                border: "1px solid #e0e0e0",
                boxShadow:
                  "rgba(15, 15, 15, 0.1) 0px 3px 6px 0, rgba(15, 15, 15, 0.2) 0px 9px 24px 0px",
              }}
              onMouseEnter={() => setProductHover(true)}
              onMouseLeave={() => setProductHover(false)}
            >
              <Link
                to={`${localePath}${"render"}`}
                hrefLang={
                  locale === "en"
                    ? "en"
                    : locale === "es"
                    ? "es"
                    : locale === "pt"
                    ? "pt"
                    : locale === "de"
                    ? "de"
                    : "fr"
                }
                style={{ padding: "0" }}
              >
                <div className="product-header-submenu-page-container">
                  <div className="product-header-submenu-icon-container">
                    <RenderIAHeaderIcon />
                  </div>
                  <div className="product-header-title-subtitle-container">
                    <div className="product-header-title">
                      {locale === "en"
                        ? "AI Render"
                        : locale === "es"
                        ? "Render IA"
                        : locale === "pt"
                        ? "Renderização IA"
                        : locale === "de"
                        ? "KI-Rendering"
                        : "Rendu IA"}
                    </div>
                    <div className="product-header-subtitle">
                      {locale === "en"
                        ? "Create renders in 1 click"
                        : locale === "es"
                        ? "Crear renders en 1 click"
                        : locale === "pt"
                        ? "Crie renders em 1 clique"
                        : locale === "de"
                        ? "Renderings mit 1 Klick erstellen"
                        : "Créer rendus dans 1 click"}
                    </div>
                  </div>
                </div>
              </Link>

              <Link
                to={`${localePath}${
                  locale === "pt"
                    ? "home-staging-virtual"
                    : locale === "de"
                    ? "virtuelles-home-staging"
                    : "home-staging-virtual"
                }`}
                hrefLang={
                  locale === "en"
                    ? "en"
                    : locale === "es"
                    ? "es"
                    : locale === "pt"
                    ? "pt"
                    : locale === "de"
                    ? "de"
                    : "fr"
                }
                style={{ padding: "0" }}
              >
                <div className="product-header-submenu-page-container">
                  <div className="product-header-submenu-icon-container">
                    <VirtualStagingAIHeaderIcon />
                  </div>
                  <div className="product-header-title-subtitle-container">
                    <div className="product-header-title">
                      {locale === "en"
                        ? "Virtual home staging"
                        : locale === "es"
                        ? "Home staging virtual"
                        : locale === "pt"
                        ? "Home staging virtual"
                        : locale === "de"
                        ? "Virtuelles Home Staging"
                        : "Home staging virtuel"}
                    </div>
                    <div className="product-header-subtitle">
                      {locale === "en"
                        ? "Furnish empty spaces with virtual staging"
                        : locale === "es"
                        ? "Amuebla espacios vacios con home staging virtual"
                        : locale === "pt"
                        ? "Mobiliar espaços vazios com home staging virtual"
                        : locale === "de"
                        ? "Leere Räume virtuell einrichten"
                        : "Meublez d'éspaces vides avec mise en scène virtuelle"}
                    </div>
                  </div>
                </div>
              </Link>

              <Link
                to={`${localePath}${
                  locale === "pt"
                    ? "planta-baixa"
                    : locale === "de"
                    ? "grundriss"
                    : "floorplan"
                }`}
                hrefLang={
                  locale === "en"
                    ? "en"
                    : locale === "es"
                    ? "es"
                    : locale === "pt"
                    ? "pt"
                    : locale === "de"
                    ? "de"
                    : "fr"
                }
                style={{ padding: "0" }}
              >
                <div className="product-header-submenu-page-container">
                  <div className="product-header-submenu-icon-container">
                    <FloorPlanHeaderIcon />
                  </div>
                  <div className="product-header-title-subtitle-container">
                    <div className="product-header-title">
                      {locale === "en"
                        ? "Floor plan"
                        : locale === "es"
                        ? "Planos de planta"
                        : locale === "pt"
                        ? "Planta baixa"
                        : locale === "de"
                        ? "Grundriss"
                        : "Plan d'étage"}
                    </div>
                    <div className="product-header-subtitle">
                      {locale === "en"
                        ? "Draw floor plans fast and easy"
                        : locale === "es"
                        ? "Dibuja planos rápido y facilmente"
                        : locale === "pt"
                        ? "Desenhe plantas baixas rápido e fácil"
                        : locale === "de"
                        ? "Grundrisse schnell und einfach zeichnen"
                        : "Dessinez des plans d'étage"}
                    </div>
                  </div>
                </div>
              </Link>

              <Link
                to={`${localePath}${
                  locale === "pt"
                    ? "fotografia-imobiliaria"
                    : locale === "de"
                    ? "immobilienfotografie"
                    : "real-estate-photography"
                }`}
                hrefLang={
                  locale === "en"
                    ? "en"
                    : locale === "es"
                    ? "es"
                    : locale === "pt"
                    ? "pt"
                    : locale === "de"
                    ? "de"
                    : "fr"
                }
                style={{ padding: "0" }}
              >
                <div className="product-header-submenu-page-container">
                  <div className="product-header-submenu-icon-container">
                    <OrangeBlueSky />
                  </div>
                  <div className="product-header-title-subtitle-container">
                    <div className="product-header-title">
                      {locale === "en"
                        ? "Real estate photography"
                        : locale === "es"
                        ? "Fotografía inmobiliaria"
                        : locale === "pt"
                        ? "Fotografia imobiliária"
                        : locale === "de"
                        ? "Immobilienfotografie"
                        : "Photos immobilières"}
                    </div>
                    <div className="product-header-subtitle">
                      {locale === "en"
                        ? "Improve you real estate photos with AI"
                        : locale === "es"
                        ? "Mejora tus fotografías inmobiliarias con IA"
                        : locale === "pt"
                        ? "Melhore suas fotos imobiliárias com IA"
                        : locale === "de"
                        ? "Verbessern Sie Ihre Immobilienfotos mit KI"
                        : "Perfectionnez facilement vos photos immobilières"}
                    </div>
                  </div>
                </div>
              </Link>

              <Link
                to={
                  locale === "en"
                    ? `${localePath}render-from-floor-plan`
                    : locale === "es"
                    ? `${localePath}render-desde-plano`
                    : locale === "pt"
                    ? `${localePath}render-desde-planta`
                    : locale === "de"
                    ? `${localePath}rendering-vom-grundriss`
                    : `${localePath}render-depuis-plan`
                }
                hrefLang={
                  locale === "en"
                    ? "en"
                    : locale === "es"
                    ? "es"
                    : locale === "pt"
                    ? "pt"
                    : locale === "de"
                    ? "de"
                    : "fr"
                }
                style={{ padding: "0" }}
              >
                <div className="product-header-submenu-page-container">
                  <div className="product-header-submenu-icon-container">
                    <RenderFromFloorPlanIcon />
                  </div>
                  <div className="product-header-title-subtitle-container">
                    <div className="product-header-title">
                      {locale === "en" ? (
                        <>Floor Plan Rendering</>
                      ) : locale === "es" ? (
                        <>Renderizado de Planos</>
                      ) : locale === "pt" ? (
                        "Renderização de Plantas"
                      ) : locale === "de" ? (
                        "Grundriss-Rendering"
                      ) : (
                        "Rendu de Plans"
                      )}
                    </div>
                    <div className="product-header-subtitle">
                      {locale === "en" ? (
                        <>
                          Transform your floor plans into realistic room visuals
                        </>
                      ) : locale === "es" ? (
                        <>
                          Transforma tus planos en imágenes realistas de
                          habitaciones
                        </>
                      ) : locale === "pt" ? (
                        "Transforme suas plantas em imagens realistas dos ambientes"
                      ) : locale === "de" ? (
                        "Verwandeln Sie Ihre Grundrisse in realistische Raumvisualisierungen"
                      ) : (
                        "Transformez vos plans en images réalistes des pièces"
                      )}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          )}
        </header>

        <ScrollToTop />
        <Routes>
          {/* Re-direct routes */}
          <Route
            path="/es/homestagingai"
            element={<Navigate to="/es/home-staging-virtual" replace />}
          />
          <Route
            path="/fr/homestagingai"
            element={<Navigate to="/fr/home-staging-virtual" replace />}
          />
          <Route
            path="/homestagingai"
            element={<Navigate to="/home-staging-virtual" replace />}
          />
          {/* English routes */}
          <Route path="/" element={<Home locale={locale} />} />
          <Route path="/interiordesign" element={<InteriorDesign />} />
          <Route path="/realestate" element={<RealEstateAgents />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/floorplan" element={<FloorPlan />} />
          <Route path="/render" element={<Render />} />
          <Route path="/home-staging-virtual" element={<HomeStaging />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route
            path="/real-estate-photography"
            element={<RealEstatePhotography />}
          />
          <Route
            path="/blog/how-to-sell-properties-with-ai"
            element={<BlogArticle1 />}
          />
          <Route
            path="/blog/sell-more-properties-with-ai"
            element={<BlogArticle4 />}
          />
          <Route
            path="/blog/how-to-speed-up-sales-with-real-estate-listing-cover-photo"
            element={<BlogArticle5 />}
          />
          <Route
            path="/blog/how-to-make-sky-blue-real-estate-photo"
            element={<BlogArticle6 />}
          />
          <Route
            path="/blog/home-staging-examples"
            element={<BlogArticle7 />}
          />
          <Route path="/releases" element={<Releases />} />
          <Route
            path="/releases/renovate-interiors-with-ai-from-phone"
            element={<ReleaseJun24 />}
          />
          <Route
            path="/releases/empty-rooms-and-remove-objects-with-ai"
            element={<ReleaseMay24 />}
          />
          <Route
            path="/releases/add-3d-objects-and-make-sky-blue"
            element={<ReleaseJul24 />}
          />
          <Route
            path="/releases/realistic-furnishing"
            element={<ReleaseAug24 />}
          />
          <Route
            path="/help/how-to-renovate-houses-virtually"
            element={<HowToRenovate />}
          />
          <Route
            path="/help/how-to-furnish-virtually"
            element={<HowToFurnish />}
          />
          <Route
            path="/help/how-to-remove-object"
            element={<HowToRemoveObject />}
          />
          <Route path="/help/how-to-add-3d-objects" element={<HowTo3D />} />
          <Route path="/about" element={<AboutUs />} />
          <Route
            path="/blog/multifunctional-spaces"
            element={<BlogArticle8 />}
          />
          <Route path="/blog/increase-home-value" element={<BlogArticle9 />} />
          <Route
            path="/blog/real-estate-marketing"
            element={<BlogArticle10 />}
          />
          <Route
            path="/blog/real-estate-market-trends"
            element={<BlogArticle11 />}
          />
          <Route
            path="/blog/real-estate-advertising"
            element={<BlogArticle12 />}
          />
          <Route
            path="/help/how-to-empty-rooms-virtually"
            element={<HowToEmptyRoom />}
          />
          <Route path="/blog/real-estate-pricing" element={<BlogArticle13 />} />
          <Route path="/*" element={<NotFound />} />
          <Route path="/blog/real-estate-mentor" element={<BlogArticle14 />} />
          <Route
            path="/free-tools/generate-real-estate-listing-description-free"
            element={<FreeDescriptionGenerator />}
          />
          <Route
            path="/blog/how-to-personalize-room"
            element={<BlogArticle15 />}
          />
          <Route
            path="/help/how-to-create-virtual-home-staging"
            element={<HowToVirtualStaging />}
          />
          <Route path="/blog/sunken-living-rooms" element={<BlogArticle16 />} />
          <Route path="/blog/home-automation" element={<BlogArticle17 />} />
          <Route
            path="/blog/property-that-wont-sell"
            element={<BlogArticle18 />}
          />
          <Route
            path="/releases/renovate-and-furnish-in-one-click"
            element={<ReleaseSep24 />}
          />
          <Route path="/api-documentation" element={<ApiDocs />} />
          <Route path="/blog/flipping" element={<BlogArticle19 />} />
          <Route path="/blog/modernize-home" element={<BlogArticle20 />} />
          <Route
            path="/blog/environmental-designer"
            element={<BlogArticle21 />}
          />
          <Route
            path="/home-staging-kitchen"
            element={<HomeStagingKitchen />}
          />
          <Route
            path="/home-staging-living-room"
            element={<HomeStagingLivingRoom />}
          />
          <Route path="/blog/short-sale" element={<BlogArticle22 />} />
          <Route
            path="/blog/mistakes-when-selling-house"
            element={<BlogArticle23 />}
          />
          <Route
            path="/home-staging-terrace"
            element={<HomeStagingTerrace />}
          />
          <Route
            path="/home-staging-bedroom"
            element={<HomeStagingBedroom />}
          />
          <Route
            path="/home-staging-bathroom"
            element={<HomeStagingBathroom />}
          />
          <Route path="/blog/real-estate" element={<BlogArticle24 />} />
          <Route
            path="/help/how-to-create-terrace-virtual-home-staging"
            element={<HowToVirtualStagingTerrace />}
          />
          <Route path="/blog/real-estate-phrases" element={<BlogArticle25 />} />
          <Route
            path="/help/how-to-create-bedroom-virtual-home-staging"
            element={<HowToVirtualStagingBedroom />}
          />
          <Route
            path="/help/how-to-create-living-room-virtual-home-staging"
            element={<HowToVirtualStagingLivingRoom />}
          />
          <Route path="/blog/real-estate-trust" element={<BlogArticle26 />} />
          <Route
            path="/free-tools/free-image-format-converter"
            element={<FreeImageFormatConverter />}
          />
          <Route path="/blog/buildable-land" element={<BlogArticle27 />} />
          <Route
            path="/help/how-to-create-kitchen-virtual-home-staging"
            element={<HowToVirtualStagingKitchen />}
          />
          <Route
            path="/help/how-to-create-bathroom-virtual-home-staging"
            element={<HowToVirtualStagingBathroom />}
          />
          <Route
            path="/releases/pedra-improvements-pools-and-preservation"
            element={<ReleaseOct24 />}
          />
          <Route path="/renders-for-renovations" element={<Renovation />} />
          <Route
            path="/blog/how-to-sell-a-house-to-renovate"
            element={<BlogArticle28 />}
          />
          <Route path="/render-kitchen" element={<RenderKitchen />} />
          <Route path="/render-bedroom" element={<RenderBedroom />} />
          <Route path="/render-bathroom" element={<RenderBathroom />} />
          <Route path="/render-living-room" element={<RenderLivingRoom />} />
          <Route path="/render-terrace" element={<RenderTerrace />} />
          <Route path="/render-house" element={<RenderHouse />} />
          <Route
            path="/help/how-to-create-kitchen-render"
            element={<HowToRenderKitchen />}
          />
          <Route
            path="/help/how-to-create-bathroom-render"
            element={<HowToRenderBathroom />}
          />
          <Route
            path="/help/how-to-create-living-room-render"
            element={<HowToRenderLivingRoom />}
          />
          <Route
            path="/help/how-to-create-bedroom-render"
            element={<HowToRenderBedroom />}
          />
          <Route
            path="/help/how-to-create-terrace-render"
            element={<HowToRenderTerrace />}
          />
          <Route
            path="/help/how-to-draw-floor-plan"
            element={<HowToDrawFloorPlan />}
          />
          <Route
            path="/help/how-to-create-house-exterior-render"
            element={<HowToRenderHouse />}
          />
          <Route path="/help" element={<Help />} />
          <Route path="/releases/workspaces" element={<ReleaseNov24 />} />
          <Route
            path="/blog/traditional-home-staging-vs-virtual-home-staging"
            element={<BlogArticle29 />}
          />
          <Route
            path="/render-from-floor-plan"
            element={<RenderFromFloorPlan />}
          />
          <Route
            path="/help/render-floor-plan-with-ai"
            element={<HowToRenderFloorPlanWithAI />}
          />
          <Route path="/blog/roi-virtual-staging" element={<BlogArticle30 />} />
          <Route path="/render-commercial" element={<RenderLocal />} />
          <Route
            path="/home-staging-commercial"
            element={<HomeStagingLocal />}
          />
          <Route
            path="/help/how-to-create-commercial-space-render"
            element={<HowToRenderLocal />}
          />
          <Route
            path="/releases/floorplans-and-commercial-spaces"
            element={<ReleaseDec24 />}
          />
          <Route
            path="/help/how-to-create-virtual-home-staging-curtain-effect"
            element={<HowToCurtainEffect />}
          />
          <Route
            path="/blog/real-estate-photography-tips"
            element={<BlogArticle31 />}
          />
          <Route
            path="/blog/property-listing-optimization"
            element={<BlogArticle32 />}
          />

          {/* Spanish routes */}
          <Route path="/es" element={<HomeEs locale={locale} />} />
          <Route path="/es/interiordesign" element={<InteriorDesignEs />} />
          <Route path="/es/realestate" element={<RealEstateAgentsEs />} />
          <Route path="/es/pricing" element={<PricingEs />} />
          <Route path="/es/floorplan" element={<FloorPlanEs />} />
          <Route path="/es/render" element={<RenderEs />} />
          <Route path="/es/home-staging-virtual" element={<HomeStagingEs />} />
          <Route path="/es/blog" element={<BlogEs />} />
          <Route path="/es/legal" element={<LegalEs />} />
          <Route path="/es/privacy" element={<PrivacyEs />} />
          <Route
            path="/es/real-estate-photography"
            element={<RealEstatePhotographyEs />}
          />
          <Route
            path="/es/blog/como-utilizar-ia-para-vender-propiedades"
            element={<BlogEsArticle1 />}
          />
          <Route path="/reviews-and-testimonials" element={<Testimonials />} />
          <Route
            path="/es/blog/como-vender-casa"
            element={<BlogEsArticle2 />}
          />
          <Route path="/es/blog/renovar-casa" element={<BlogEsArticle3 />} />
          <Route
            path="/es/blog/vende-mas-propiedades-inmobiliarias-con-ia"
            element={<BlogEsArticle4 />}
          />
          <Route
            path="/es/blog/como-asegurar-que-la-foto-de-portada-de-tu-anuncio-inmobiliario-acelere-la-venta"
            element={<BlogEsArticle5 />}
          />
          <Route
            path="/es/blog/como-poner-cielo-azul-fotografias-de-inmuebles"
            element={<BlogEsArticle6 />}
          />
          <Route
            path="/es/blog/home-staging-ejemplos"
            element={<BlogEsArticle7 />}
          />
          <Route path="/es/mls" element={<MlsEs />} />
          <Route path="/es/releases" element={<ReleasesEs />} />
          <Route
            path="/es/releases/vacia-habitacion-y-quita-objetos-con-ia"
            element={<ReleaseEsMay24 />}
          />
          <Route
            path="/es/releases/renueva-espacios-desde-el-movil-con-ia"
            element={<ReleaseEsJun24 />}
          />
          <Route
            path="/es/releases/anade-objetos-3d-y-cielo-azul"
            element={<ReleaseEsJul24 />}
          />
          <Route
            path="/es/releases/amueblado-realista"
            element={<ReleaseEsAug24 />}
          />
          <Route path="/es/*" element={<NotFoundEs />} />
          <Route path="/es/about" element={<AboutUsEs />} />
          <Route
            path="/es/reviews-and-testimonials"
            element={<TestimonialsEs />}
          />
          <Route
            path="/es/help/como-anadir-objetos-3d"
            element={<HowTo3DEs />}
          />
          <Route
            path="/es/help/como-renovar-casas-virtualmente"
            element={<HowToRenovateEs />}
          />
          <Route
            path="/es/help/como-amueblar-virtualmente"
            element={<HowToFurnishEs />}
          />
          <Route
            path="/es/help/como-borrar-objeto"
            element={<HowToRemoveObjectEs />}
          />
          <Route
            path="/es/blog/espacios-multifuncionales"
            element={<BlogEsArticle8 />}
          />
          <Route
            path="/es/blog/aumenta-valor-vivienda"
            element={<BlogEsArticle9 />}
          />
          <Route
            path="/es/blog/marketing-inmobiliario"
            element={<BlogEsArticle10 />}
          />
          <Route
            path="/es/herramientas-gratis/generar-descripcion-anuncio-inmobiliario-gratis"
            element={<FreeDescriptionGeneratorEs />}
          />
          <Route
            path="/es/blog/tendencias-mercado-inmobiliario"
            element={<BlogEsArticle11 />}
          />
          <Route
            path="/es/blog/publicidad-inmobiliaria"
            element={<BlogEsArticle12 />}
          />
          <Route
            path="/es/help/como-vaciar-habitacion-virtualmente"
            element={<HowToEmptyRoomEs />}
          />
          <Route
            path="/es/blog/pricing-inmobiliario"
            element={<BlogEsArticle13 />}
          />
          <Route
            path="/es/blog/mentor-inmobiliario"
            element={<BlogEsArticle14 />}
          />
          <Route
            path="/es/blog/como-personalizar-habitacion"
            element={<BlogEsArticle15 />}
          />
          <Route
            path="/es/help/como-crear-home-staging-virtual"
            element={<HowToVirtualStagingEs />}
          />
          <Route path="/es/blog/salas-hundidas" element={<BlogEsArticle16 />} />
          <Route path="/es/blog/domotizar-casa" element={<BlogEsArticle17 />} />
          <Route
            path="/es/blog/propiedad-no-se-vende"
            element={<BlogEsArticle18 />}
          />
          <Route
            path="/es/lanzamientos/renueva-y-amuebla-en-un-clic"
            element={<ReleaseEsSep24 />}
          />
          <Route path="/es/api-documentation" element={<ApiDocsEs />} />
          <Route path="/es/blog/flipping" element={<BlogEsArticle19 />} />
          <Route
            path="/es/blog/modernizar-casa"
            element={<BlogEsArticle20 />}
          />
          <Route
            path="/es/blog/diseñador-ambientes"
            element={<BlogEsArticle21 />}
          />
          <Route
            path="/es/home-staging-cocina"
            element={<HomeStagingKitchenEs />}
          />
          <Route
            path="/es/home-staging-salon"
            element={<HomeStagingLivingRoomEs />}
          />
          <Route path="/es/blog/short-sale" element={<BlogEsArticle22 />} />
          <Route
            path="/es/blog/errores-al-vender-casa"
            element={<BlogEsArticle23 />}
          />
          <Route
            path="/es/home-staging-terraza"
            element={<HomeStagingTerraceEs />}
          />
          <Route
            path="/es/home-staging-dormitorio"
            element={<HomeStagingBedroomEs />}
          />
          <Route
            path="/es/home-staging-bano"
            element={<HomeStagingBathroomEs />}
          />
          <Route path="/es/blog/bienes-raices" element={<BlogEsArticle24 />} />
          <Route
            path="/es/help/como-crear-home-staging-virtual-terraza"
            element={<HowToVirtualStagingTerraceEs />}
          />
          <Route
            path="/es/blog/frases-inmobiliarias"
            element={<BlogEsArticle25 />}
          />
          <Route
            path="/es/help/como-crear-home-staging-virtual-dormitorio"
            element={<HowToVirtualStagingBedroomEs />}
          />
          <Route
            path="/es/help/como-crear-home-staging-virtual-salon"
            element={<HowToVirtualStagingLivingRoomEs />}
          />
          <Route
            path="/es/blog/fideicomiso-inmobiliario"
            element={<BlogEsArticle26 />}
          />
          <Route
            path="/es/herramientas-gratis/convertidor-formato-imagen-gratuito"
            element={<FreeImageFormatConverterEs />}
          />
          <Route
            path="/es/blog/terrenos-urbanizables"
            element={<BlogEsArticle27 />}
          />
          <Route
            path="/es/help/como-crear-home-staging-virtual-cocina"
            element={<HowToVirtualStagingKitchenEs />}
          />
          <Route
            path="/es/help/como-crear-home-staging-virtual-bano"
            element={<HowToVirtualStagingBathroomEs />}
          />
          <Route
            path="/es/releases/mejoras-pedra-piscinas-y-preservacion"
            element={<ReleaseEsOct24 />}
          />
          <Route path="/es/renders-para-reformas" element={<RenovationEs />} />
          <Route
            path="/es/blog/como-vender-casa-a-reformar"
            element={<BlogEsArticle28 />}
          />
          <Route path="/es/render-cocina" element={<RenderKitchenEs />} />
          <Route path="/es/render-dormitorio" element={<RenderBedroomEs />} />
          <Route path="/es/render-bano" element={<RenderBathroomEs />} />
          <Route path="/es/render-salon" element={<RenderLivingRoomEs />} />
          <Route path="/es/render-terraza" element={<RenderTerraceEs />} />
          <Route path="/es/render-casa" element={<RenderHouseEs />} />
          <Route
            path="/es/help/como-crear-render-cocina"
            element={<HowToRenderKitchenEs />}
          />
          <Route
            path="/es/help/como-crear-render-bano"
            element={<HowToRenderBathroomEs />}
          />
          <Route
            path="/es/help/como-crear-render-sala-estar"
            element={<HowToRenderLivingRoomEs />}
          />
          <Route
            path="/es/help/como-crear-render-dormitorio"
            element={<HowToRenderBedroomEs />}
          />
          <Route
            path="/es/help/como-crear-render-terraza"
            element={<HowToRenderTerraceEs />}
          />
          <Route
            path="/es/help/como-dibujar-plano"
            element={<HowToDrawFloorPlanEs />}
          />
          <Route
            path="/es/help/como-crear-render-exterior-casa"
            element={<HowToRenderHouseEs />}
          />
          <Route path="/es/help" element={<HelpEs />} />
          <Route
            path="/es/releases/espacios-de-trabajo"
            element={<ReleaseEsNov24 />}
          />
          <Route
            path="/es/blog/home-staging-tradicional-vs-home-staging-virtual"
            element={<BlogEsArticle29 />}
          />
          <Route
            path="/es/render-desde-plano"
            element={<RenderFromFloorPlanEs />}
          />
          <Route
            path="/es/help/renderizar-plano-de-planta-con-ia"
            element={<HowToRenderFloorPlanWithAIEs />}
          />
          <Route
            path="/es/blog/rentabilidad-home-staging-virtual"
            element={<BlogEsArticle30 />}
          />
          <Route path="/es/render-local" element={<RenderLocalEs />} />
          <Route
            path="/es/home-staging-local"
            element={<HomeStagingLocalEs />}
          />
          <Route
            path="/es/help/como-crear-render-local-comercial"
            element={<HowToRenderLocalEs />}
          />
          <Route
            path="/es/releases/planos-y-espacios-comerciales"
            element={<ReleaseEsDec24 />}
          />
          <Route
            path="/es/help/como-crear-efecto-cortinilla-home-staging-virtual"
            element={<HowToCurtainEffectEs />}
          />
          <Route
            path="/es/blog/consejos-para-fotografia-inmobiliaria"
            element={<BlogArticle31Es />}
          />
          <Route
            path="/es/blog/optimizacion-de-anuncios-inmobiliarios"
            element={<BlogEsArticle32 />}
          />
          <Route
            path="/es/help/video-home-staging-virtual"
            element={<HowToVirtualStageVideoEs />}
          />

          {/* French routes */}
          <Route path="/fr" element={<HomeFr />} />
          <Route
            path="/fr/help/comment-ajouter-des-objets-3d"
            element={<HowTo3DFr />}
          />
          <Route
            path="/fr/help/comment-renover-des-maisons-en-virtuel"
            element={<HowToRenovateFr />}
          />
          <Route
            path="/fr/help/comment-meubler-en-virtuel"
            element={<HowToFurnishFr />}
          />
          <Route
            path="/fr/home-staging-local"
            element={<HomeStagingLocalFr />}
          />
          <Route
            path="/fr/help/comment-supprimer-un-objet"
            element={<HowToRemoveObjectFr />}
          />
          <Route path="/fr/privacy" element={<PrivacyFr />} />
          <Route path="/fr/legal" element={<LegalFr />} />
          <Route path="/fr/*" element={<NotFoundFr />} />
          <Route
            path="/fr/releases/vide-chambres-et-enlever-objets-avec-ia"
            element={<ReleaseMay24Fr />}
          />
          <Route
            path="/fr/releases/renovation-des-interieurs-avec-lia-depuis-le-telephone"
            element={<ReleaseJun24Fr />}
          />
          <Route
            path="/fr/releases/ajouter-des-objets-3d-et-un-ciel-bleu"
            element={<ReleaseJul24Fr />}
          />
          <Route
            path="/fr/releases/ameliorations-ameublement-styles-personnalises-preservation-fenetres"
            element={<ReleaseFrAug24 />}
          />
          <Route
            path="/fr/blog/vendre-plus-de-proprietes-immobilieres-avec-ia"
            element={<BlogFrArticle1 />}
          />
          <Route
            path="/fr/blog/comment-utiliser-lia-pour-vendre-des-proprietes"
            element={<BlogFrArticle4 />}
          />
          <Route
            path="/fr/blog/comment-acceleration-des-ventes-avec-la-photo-de-couverture-de-lannonce-immobiliere"
            element={<BlogFrArticle5 />}
          />
          <Route
            path="/fr/blog/comment-ajouter-un-ciel-bleu-aux-photographies-immobilieres"
            element={<BlogFrArticle6 />}
          />
          <Route
            path="/fr/blog/exemples-de-home-staging"
            element={<BlogFrArticle7 />}
          />
          <Route path="/fr/releases" element={<ReleasesFr />} />
          <Route path="/fr/home-staging-virtual" element={<HomeStagingFr />} />
          <Route path="/fr/pricing" element={<PricingFr />} />
          <Route path="/fr/render" element={<RenderFr />} />
          <Route path="/fr/blog" element={<BlogFr />} />
          <Route path="/fr/floorplan" element={<FloorPlanFr />} />
          <Route path="/fr/interiordesign" element={<InteriorDesignFr />} />
          <Route
            path="/fr/reviews-and-testimonials"
            element={<TestimonialsFr />}
          />
          <Route
            path="/fr/real-estate-photography"
            element={<RealEstatePhotographyFr />}
          />
          <Route path="/fr/realestate" element={<RealEstateAgentsFr />} />
          <Route path="/fr/about" element={<AboutUsFr />} />
          <Route
            path="/fr/blog/espaces-multifonctionnels"
            element={<BlogFrArticle8 />}
          />
          <Route
            path="/fr/blog/augmentez-valeur-maison"
            element={<BlogFrArticle9 />}
          />
          <Route
            path="/fr/blog/marketing-immobilier"
            element={<BlogFrArticle10 />}
          />
          <Route
            path="/fr/blog/tendances-marche-immobilier"
            element={<BlogFrArticle11 />}
          />
          <Route
            path="/fr/blog/publicite-immobiliere"
            element={<BlogFrArticle12 />}
          />
          <Route
            path="/fr/help/comment-vider-pieces-virtuellement"
            element={<HowToEmptyRoomFr />}
          />
          <Route
            path="/fr/blog/pricing-immobilier"
            element={<BlogFrArticle13 />}
          />
          <Route
            path="/fr/blog/mentor-immobilier"
            element={<BlogFrArticle14 />}
          />
          <Route
            path="/fr/outils-gratuits/generer-description-annonce-immobiliere-gratuit"
            element={<FreeDescriptionGeneratorFr />}
          />
          <Route
            path="/fr/blog/comment-personnaliser-chambre"
            element={<BlogFrArticle15 />}
          />
          <Route
            path="/fr/help/comment-creer-home-staging-virtuel"
            element={<HowToVirtualStagingFr />}
          />
          <Route
            path="/fr/blog/salons-en-contrebas"
            element={<BlogFrArticle16 />}
          />
          <Route
            path="/fr/blog/domotiser-maison"
            element={<BlogFrArticle17 />}
          />
          <Route
            path="/fr/blog/propriete-qui-ne-se-vend-pas"
            element={<BlogFrArticle18 />}
          />
          <Route
            path="/fr/versions/renovez-et-meublez-en-un-clic"
            element={<ReleaseFrSep24 />}
          />
          <Route path="/fr/api-documentation" element={<ApiDocsFr />} />
          <Route path="/fr/blog/flipping" element={<BlogFrArticle19 />} />
          <Route
            path="/fr/blog/moderniser-maison"
            element={<BlogFrArticle20 />}
          />
          <Route
            path="/fr/blog/designer-environnement"
            element={<BlogFrArticle21 />}
          />
          <Route
            path="/fr/home-staging-cuisine"
            element={<HomeStagingKitchenFr />}
          />
          <Route
            path="/fr/home-staging-salon"
            element={<HomeStagingLivingRoomFr />}
          />
          <Route path="/fr/blog/short-sale" element={<BlogFrArticle22 />} />
          <Route
            path="/fr/blog/erreurs-vente-maison"
            element={<BlogFrArticle23 />}
          />
          <Route
            path="/fr/home-staging-terrasse"
            element={<HomeStagingTerraceFr />}
          />
          <Route
            path="/fr/home-staging-chambre"
            element={<HomeStagingBedroomFr />}
          />
          <Route
            path="/fr/home-staging-salle-de-bain"
            element={<HomeStagingBathroomFr />}
          />
          <Route path="/fr/blog/immobilier" element={<BlogFrArticle24 />} />
          <Route
            path="/fr/help/comment-creer-home-staging-virtuel-terrasse"
            element={<HowToVirtualStagingTerraceFr />}
          />
          <Route
            path="/fr/blog/phrases-immobilieres"
            element={<BlogFrArticle25 />}
          />
          <Route
            path="/fr/help/comment-creer-home-staging-virtuel-chambre"
            element={<HowToVirtualStagingBedroomFr />}
          />
          <Route
            path="/fr/help/comment-creer-home-staging-virtuel-salon"
            element={<HowToVirtualStagingLivingRoomFr />}
          />
          <Route
            path="/fr/blog/fiducie-immobiliere"
            element={<BlogFrArticle26 />}
          />
          <Route
            path="/fr/outils-gratuits/convertisseur-format-image"
            element={<FreeImageFormatConverterFr />}
          />
          <Route
            path="/fr/blog/terrains-constructibles"
            element={<BlogFrArticle27 />}
          />
          <Route
            path="/fr/help/comment-creer-home-staging-virtuel-cuisine"
            element={<HowToVirtualStagingKitchenFr />}
          />
          <Route
            path="/fr/help/comment-creer-home-staging-virtuel-salle-de-bain"
            element={<HowToVirtualStagingBathroomFr />}
          />
          <Route
            path="/fr/releases/ameliorations-pedra-piscines-et-preservation"
            element={<ReleaseFrOct24 />}
          />
          <Route path="/fr/rendus-de-renovation" element={<RenovationFr />} />
          <Route
            path="/fr/blog/comment-vendre-une-maison-a-renover"
            element={<BlogFrArticle28 />}
          />
          <Route path="/fr/rendu-cuisine" element={<RenderKitchenFr />} />
          <Route path="/fr/rendu-chambre" element={<RenderBedroomFr />} />
          <Route
            path="/fr/rendu-salle-de-bain"
            element={<RenderBahtroomFr />}
          />
          <Route path="/fr/rendu-salon" element={<RenderLivingRoomFr />} />
          <Route path="/fr/rendu-terrasse" element={<RenderTerraceFr />} />
          <Route path="/fr/rendu-maison" element={<RenderHouseFr />} />
          <Route
            path="/fr/help/comment-creer-rendu-cuisine"
            element={<HowToRenderKitchenFr />}
          />
          <Route
            path="/fr/help/comment-creer-rendu-salle-de-bain"
            element={<HowToRenderBathroomFr />}
          />
          <Route
            path="/fr/help/comment-creer-rendu-salon"
            element={<HowToRenderLivingRoomFr />}
          />
          <Route
            path="/fr/help/comment-creer-rendu-chambre"
            element={<HowToRenderBedroomFr />}
          />
          <Route
            path="/fr/help/comment-creer-rendu-terrasse"
            element={<HowToRenderTerraceFr />}
          />
          <Route
            path="/fr/help/comment-dessiner-plan"
            element={<HowToDrawFloorPlanFr />}
          />
          <Route
            path="/fr/help/comment-creer-rendu-exterieur-maison"
            element={<HowToRenderHouseFr />}
          />
          <Route path="/fr/help" element={<HelpFr />} />
          <Route
            path="/fr/releases/espaces-de-travail"
            element={<ReleaseFrNov24 />}
          />
          <Route
            path="/fr/blog/home-staging-traditionnel-vs-home-staging-virtuel"
            element={<BlogFrArticle29 />}
          />
          <Route
            path="/fr/render-depuis-plan"
            element={<RenderFromFloorPlanFr />}
          />
          <Route
            path="/fr/help/rendu-plan-etage-avec-ia"
            element={<HowToRenderFloorPlanWithAIFr />}
          />
          <Route
            path="/fr/blog/rentabilite-home-staging-virtuel"
            element={<BlogFrArticle30 />}
          />
          <Route path="/fr/rendu-local" element={<RenderLocalFr />} />
          <Route
            path="/fr/help/comment-creer-rendu-local-commercial"
            element={<HowToRenderLocalFr />}
          />
          <Route
            path="/fr/releases/plans-et-espaces-commerciaux"
            element={<ReleaseFrDec24 />}
          />
          <Route
            path="/fr/help/comment-creer-effet-rideaux-home-staging-virtuel"
            element={<HowToCurtainEffectFr />}
          />
          <Route
            path="/fr/blog/conseils-pour-la-photographie-immobiliere"
            element={<BlogArticle31Fr />}
          />
          <Route
            path="/fr/blog/optimisation-des-annonces-immobilieres"
            element={<BlogFrArticle32 />}
          />

          {/* Portuguese routes */}
          <Route path="/pt/about" element={<AboutUsPt />} />
          <Route path="/pt/api-documentation" element={<ApiDocsPt />} />
          <Route path="/pt/blog" element={<BlogPt />} />
          <Route
            path="/pt/blog/como-vender-imoveis-com-ia"
            element={<BlogPtArticle1 />}
          />
          <Route
            path="/pt/blog/marketing-imobiliario"
            element={<BlogPtArticle10 />}
          />
          <Route
            path="/pt/blog/tendencias-mercado-imobiliario"
            element={<BlogPtArticle11 />}
          />
          <Route
            path="/pt/blog/publicidade-imobiliaria"
            element={<BlogPtArticle12 />}
          />
          <Route
            path="/pt/blog/precificacao-imobiliaria"
            element={<BlogPtArticle13 />}
          />
          <Route
            path="/pt/blog/mentor-imobiliario"
            element={<BlogPtArticle14 />}
          />
          <Route
            path="/pt/blog/como-personalizar-ambientes"
            element={<BlogPtArticle15 />}
          />
          <Route
            path="/pt/blog/salas-rebaixadas"
            element={<BlogPtArticle16 />}
          />
          <Route
            path="/pt/blog/automacao-residencial"
            element={<BlogPtArticle17 />}
          />
          <Route
            path="/pt/blog/imovel-que-nao-vende"
            element={<BlogPtArticle18 />}
          />
          <Route path="/pt/blog/flipping" element={<BlogPtArticle19 />} />
          <Route
            path="/pt/blog/modernizar-casa"
            element={<BlogPtArticle20 />}
          />
          <Route
            path="/pt/blog/designer-ambiental"
            element={<BlogPtArticle21 />}
          />
          <Route path="/pt/blog/venda-rapida" element={<BlogPtArticle22 />} />
          <Route
            path="/pt/blog/erros-ao-vender-casa"
            element={<BlogPtArticle23 />}
          />
          <Route path="/pt/blog/imoveis" element={<BlogPtArticle24 />} />
          <Route
            path="/pt/blog/frases-imobiliarias"
            element={<BlogPtArticle25 />}
          />
          <Route
            path="/pt/blog/confianca-imobiliaria"
            element={<BlogPtArticle26 />}
          />
          <Route
            path="/pt/blog/terreno-construivel"
            element={<BlogPtArticle27 />}
          />
          <Route
            path="/pt/blog/como-vender-casa-para-reforma"
            element={<BlogPtArticle28 />}
          />
          <Route
            path="/pt/blog/home-staging-tradicional-vs-virtual"
            element={<BlogPtArticle29 />}
          />
          <Route
            path="/pt/blog/roi-home-staging-virtual"
            element={<BlogPtArticle30 />}
          />
          <Route
            path="/pt/blog/vender-mais-imoveis-com-ia"
            element={<BlogPtArticle4 />}
          />
          <Route
            path="/pt/blog/como-acelerar-vendas-com-foto-capa-anuncio"
            element={<BlogPtArticle5 />}
          />
          <Route
            path="/pt/blog/como-fazer-ceu-azul-foto-imobiliaria"
            element={<BlogPtArticle6 />}
          />
          <Route
            path="/pt/blog/exemplos-home-staging"
            element={<BlogPtArticle7 />}
          />
          <Route
            path="/pt/blog/espacos-multifuncionais"
            element={<BlogPtArticle8 />}
          />
          <Route
            path="/pt/blog/aumentar-valor-imovel"
            element={<BlogPtArticle9 />}
          />
          <Route path="/pt/planta-baixa" element={<FloorPlanPt />} />
          <Route
            path="/pt/ferramentas-gratuitas/gerar-descricao-anuncio-gratis"
            element={<FreeDescriptionGeneratorPt />}
          />
          <Route
            path="/pt/ferramentas-gratuitas/conversor-formato-imagem-gratis"
            element={<FreeImageFormatConverterPt />}
          />
          <Route path="/pt/ajuda" element={<HelpPt />} />
          <Route path="/pt" element={<HomePt />} />
          <Route path="/pt/home-staging-virtual" element={<HomeStagingPt />} />
          <Route
            path="/pt/home-staging-banheiro"
            element={<HomeStagingBathroomPt />}
          />
          <Route
            path="/pt/home-staging-quarto"
            element={<HomeStagingBedroomPt />}
          />
          <Route
            path="/pt/home-staging-cozinha"
            element={<HomeStagingKitchenPt />}
          />
          <Route
            path="/pt/home-staging-sala"
            element={<HomeStagingLivingRoomPt />}
          />
          <Route
            path="/pt/home-staging-local"
            element={<HomeStagingLocalPt />}
          />
          <Route
            path="/pt/home-staging-terraco"
            element={<HomeStagingTerracePt />}
          />
          <Route
            path="/pt/ajuda/como-adicionar-objetos-3d"
            element={<HowTo3DPt />}
          />
          <Route
            path="/pt/ajuda/como-desenhar-planta-baixa"
            element={<HowToDrawFloorPlanPt />}
          />
          <Route
            path="/pt/ajuda/como-esvaziar-ambientes-virtualmente"
            element={<HowToEmptyRoomPt />}
          />
          <Route
            path="/pt/ajuda/como-mobiliar-virtualmente"
            element={<HowToFurnishPt />}
          />
          <Route
            path="/pt/ajuda/como-remover-objetos"
            element={<HowToRemoveObjectPt />}
          />
          <Route
            path="/pt/ajuda/como-criar-render-banheiro"
            element={<HowToRenderBathroomPt />}
          />
          <Route
            path="/pt/ajuda/como-criar-render-quarto"
            element={<HowToRenderBedroomPt />}
          />
          <Route
            path="/pt/ajuda/renderizar-planta-baixa-com-ia"
            element={<HowToRenderFloorPlanWithAIPt />}
          />
          <Route
            path="/pt/ajuda/como-criar-render-externa-casa"
            element={<HowToRenderHousePt />}
          />
          <Route
            path="/pt/ajuda/como-criar-render-cozinha"
            element={<HowToRenderKitchenPt />}
          />
          <Route
            path="/pt/ajuda/como-criar-render-sala"
            element={<HowToRenderLivingRoomPt />}
          />
          <Route
            path="/pt/ajuda/como-criar-render-local-comercial"
            element={<HowToRenderLocalPt />}
          />
          <Route
            path="/pt/ajuda/como-criar-render-terraco"
            element={<HowToRenderTerracePt />}
          />
          <Route
            path="/pt/ajuda/como-renovar-casas-virtualmente"
            element={<HowToRenovatePt />}
          />
          <Route
            path="/pt/ajuda/como-criar-home-staging-virtual"
            element={<HowToVirtualStagingPt />}
          />
          <Route
            path="/pt/ajuda/como-criar-home-staging-virtual-banheiro"
            element={<HowToVirtualStagingBathroomPt />}
          />
          <Route
            path="/pt/ajuda/como-criar-home-staging-virtual-quarto"
            element={<HowToVirtualStagingBedroomPt />}
          />
          <Route
            path="/pt/ajuda/como-criar-home-staging-virtual-cozinha"
            element={<HowToVirtualStagingKitchenPt />}
          />
          <Route
            path="/pt/ajuda/como-criar-home-staging-virtual-sala"
            element={<HowToVirtualStagingLivingRoomPt />}
          />
          <Route
            path="/pt/ajuda/como-criar-home-staging-virtual-terraco"
            element={<HowToVirtualStagingTerracePt />}
          />
          <Route path="/pt/interiordesign" element={<InteriorDesignPt />} />
          <Route path="/pt/legal" element={<LegalPt />} />
          <Route path="/pt/404" element={<NotFoundPt />} />
          <Route path="/pt/*" element={<NotFoundPt />} />
          <Route path="/pt/precos" element={<PricingPt />} />
          <Route path="/pt/privacy" element={<PrivacyPt />} />
          <Route path="/pt/realestate" element={<RealEstateAgentsPt />} />
          <Route
            path="/pt/fotografia-imobiliaria"
            element={<RealEstatePhotographyPt />}
          />
          <Route
            path="/pt/lancamentos/mobiliario-realista"
            element={<ReleaseAug24Pt />}
          />
          <Route
            path="/pt/lancamentos/plantas-baixas-e-espacos-comerciais"
            element={<ReleaseDec24Pt />}
          />
          <Route
            path="/pt/lancamentos/adicionar-objetos-3d-e-fazer-ceu-azul"
            element={<ReleaseJul24Pt />}
          />
          <Route
            path="/pt/lancamentos/renovar-interiores-com-ia-pelo-celular"
            element={<ReleaseJun24Pt />}
          />
          <Route
            path="/pt/lancamentos/esvaziar-ambientes-e-remover-objetos-com-ia"
            element={<ReleaseMay24Pt />}
          />
          <Route
            path="/pt/lancamentos/espacos-trabalho"
            element={<ReleaseNov24Pt />}
          />
          <Route
            path="/pt/lancamentos/melhorias-pedra-piscinas-e-preservacao"
            element={<ReleaseOct24Pt />}
          />
          <Route path="/pt/releases" element={<ReleasesPt />} />
          <Route
            path="/pt/lancamentos/renovar-e-mobiliar-em-um-clique"
            element={<ReleaseSep24Pt />}
          />
          <Route path="/pt/render" element={<RenderPt />} />
          <Route
            path="/pt/renderizar-banheiro"
            element={<RenderBathroomPt />}
          />
          <Route path="/pt/renderizar-quarto" element={<RenderBedroomPt />} />
          <Route
            path="/pt/render-desde-planta"
            element={<RenderFromFloorPlanPt />}
          />
          <Route path="/pt/renderizar-casa" element={<RenderHousePt />} />
          <Route path="/pt/renderizar-cozinha" element={<RenderKitchenPt />} />
          <Route path="/pt/renderizar-sala" element={<RenderLivingRoomPt />} />
          <Route
            path="/pt/renderizacao-comercial"
            element={<RenderLocalPt />}
          />
          <Route path="/pt/renderizar-terraco" element={<RenderTerracePt />} />
          <Route
            path="/pt/renderizacoes-para-reformas"
            element={<RenovationPt />}
          />
          <Route
            path="/pt/reviews-and-testimonials"
            element={<TestimonialsPt />}
          />
          <Route
            path="/pt/ajuda/como-criar-efeito-cortinas-home-staging-virtual"
            element={<HowToCurtainEffectPt />}
          />
          <Route
            path="/pt/blog/dicas-de-fotografia-imobiliaria"
            element={<BlogArticle31Pt />}
          />
          <Route
            path="/pt/blog/otimizacao-de-anuncios-imobiliarios"
            element={<BlogPtArticle32 />}
          />

          {/* German routes */}
          <Route path="/de" element={<HomeDe locale={locale} />} />
          <Route path="/de/innenarchitektur" element={<InteriorDesignDe />} />
          <Route path="/de/realestate" element={<RealEstateAgentsDe />} />
          <Route path="/de/preise" element={<PricingDe />} />
          <Route path="/de/grundriss" element={<FloorPlanDe />} />
          <Route path="/de/render" element={<RenderDe />} />
          <Route
            path="/de/reviews-and-testimonials"
            element={<TestimonialsDe />}
          />
          <Route
            path="/de/virtuelles-home-staging"
            element={<HomeStagingDe />}
          />
          <Route path="/de/blog" element={<BlogDe />} />
          <Route path="/de/rechtliches" element={<LegalDe />} />
          <Route path="/de/datenschutz" element={<PrivacyDe />} />
          <Route
            path="/de/immobilienfotografie"
            element={<RealEstatePhotographyDe />}
          />
          <Route
            path="/de/blog/wie-man-immobilien-mit-ki-verkauft"
            element={<BlogDeArticle1 />}
          />
          <Route
            path="/de/blog/mehr-immobilien-mit-ki-verkaufen"
            element={<BlogDeArticle4 />}
          />
          <Route
            path="/de/blog/wie-man-verkaufe-mit-immobilienanzeigen-titelbild-beschleunigt"
            element={<BlogDeArticle5 />}
          />
          <Route
            path="/de/blog/wie-man-blauen-himmel-in-immobilienfotos-macht"
            element={<BlogDeArticle6 />}
          />
          <Route
            path="/de/blog/home-staging-beispiele"
            element={<BlogDeArticle7 />}
          />
          <Route path="/de/releases" element={<ReleasesDe />} />
          <Route
            path="/de/releases/innenraume-mit-ki-vom-handy-renovieren"
            element={<ReleaseJun24De />}
          />
          <Route
            path="/de/releases/raume-leeren-und-objekte-mit-ki-entfernen"
            element={<ReleaseMay24De />}
          />
          <Route
            path="/de/releases/3d-objekte-hinzufugen-und-himmel-blau-machen"
            element={<ReleaseJul24De />}
          />
          <Route
            path="/de/releases/realistische-einrichtung"
            element={<ReleaseAug24De />}
          />
          <Route
            path="/de/hilfe/wie-man-hauser-virtuell-renoviert"
            element={<HowToRenovateDe />}
          />
          <Route
            path="/de/hilfe/wie-man-virtuell-einrichtet"
            element={<HowToFurnishDe />}
          />
          <Route
            path="/de/hilfe/wie-man-objekte-entfernt"
            element={<HowToRemoveObjectDe />}
          />
          <Route
            path="/de/hilfe/wie-man-3d-objekte-hinzufugt"
            element={<HowTo3DDe />}
          />
          <Route path="/de/about" element={<AboutUsDe />} />
          <Route
            path="/de/blog/multifunktionale-raume"
            element={<BlogDeArticle8 />}
          />
          <Route
            path="/de/blog/hauswert-steigern"
            element={<BlogDeArticle9 />}
          />
          <Route
            path="/de/blog/immobilien-marketing"
            element={<BlogDeArticle10 />}
          />
          <Route
            path="/de/blog/immobilienmarkt-trends"
            element={<BlogDeArticle11 />}
          />
          <Route
            path="/de/blog/immobilien-werbung"
            element={<BlogDeArticle12 />}
          />
          <Route
            path="/de/hilfe/wie-man-raume-virtuell-leert"
            element={<HowToEmptyRoomDe />}
          />
          <Route
            path="/de/blog/immobilien-preisgestaltung"
            element={<BlogDeArticle13 />}
          />
          <Route path="/de/404" element={<NotFoundDe />} />
          <Route
            path="/de/blog/immobilien-mentor"
            element={<BlogDeArticle14 />}
          />
          <Route
            path="/de/kostenlose-tools/immobilienanzeige-beschreibung-kostenlos-erstellen"
            element={<FreeDescriptionGeneratorDe />}
          />
          <Route
            path="/de/blog/wie-man-raume-personalisiert"
            element={<BlogDeArticle15 />}
          />
          <Route
            path="/de/hilfe/wie-man-virtuelles-home-staging-erstellt"
            element={<HowToVirtualStagingDe />}
          />
          <Route
            path="/de/blog/versenktes-wohnzimmer"
            element={<BlogDeArticle16 />}
          />
          <Route
            path="/de/blog/hausautomatisierung"
            element={<BlogDeArticle17 />}
          />
          <Route
            path="/de/blog/immobilie-die-sich-nicht-verkauft"
            element={<BlogDeArticle18 />}
          />
          <Route
            path="/de/releases/renovieren-und-einrichten-mit-einem-klick"
            element={<ReleaseSep24De />}
          />
          <Route path="/de/api-documentation" element={<ApiDocsDe />} />
          <Route path="/de/blog/flipping" element={<BlogDeArticle19 />} />
          <Route
            path="/de/blog/haus-modernisieren"
            element={<BlogDeArticle20 />}
          />
          <Route path="/de/blog/umweltdesigner" element={<BlogDeArticle21 />} />
          <Route
            path="/de/home-staging-kuche"
            element={<HomeStagingKitchenDe />}
          />
          <Route
            path="/de/home-staging-wohnzimmer"
            element={<HomeStagingLivingRoomDe />}
          />
          <Route path="/de/blog/schnellverkauf" element={<BlogDeArticle22 />} />
          <Route
            path="/de/blog/fehler-beim-hausverkauf"
            element={<BlogDeArticle23 />}
          />
          <Route
            path="/de/home-staging-terrasse"
            element={<HomeStagingTerraceDe />}
          />
          <Route
            path="/de/home-staging-schlafzimmer"
            element={<HomeStagingBedroomDe />}
          />
          <Route
            path="/de/home-staging-badezimmer"
            element={<HomeStagingBathroomDe />}
          />
          <Route path="/de/blog/immobilien" element={<BlogDeArticle24 />} />
          <Route
            path="/de/hilfe/wie-man-virtuelles-terrassen-home-staging-erstellt"
            element={<HowToVirtualStagingTerraceDe />}
          />
          <Route
            path="/de/blog/immobilien-phrasen"
            element={<BlogDeArticle25 />}
          />
          <Route
            path="/de/hilfe/wie-man-virtuelles-schlafzimmer-home-staging-erstellt"
            element={<HowToVirtualStagingBedroomDe />}
          />
          <Route
            path="/de/hilfe/wie-man-virtuelles-wohnzimmer-home-staging-erstellt"
            element={<HowToVirtualStagingLivingRoomDe />}
          />
          <Route
            path="/de/blog/immobilien-vertrauen"
            element={<BlogDeArticle26 />}
          />
          <Route
            path="/de/kostenlose-tools/bildformat-konverter-kostenlos"
            element={<FreeImageFormatConverterDe />}
          />
          <Route path="/de/blog/bauland" element={<BlogDeArticle27 />} />
          <Route
            path="/de/hilfe/wie-man-virtuelles-kuchen-home-staging-erstellt"
            element={<HowToVirtualStagingKitchenDe />}
          />
          <Route
            path="/de/hilfe/wie-man-virtuelles-badezimmer-home-staging-erstellt"
            element={<HowToVirtualStagingBathroomDe />}
          />
          <Route
            path="/de/releases/stein-verbesserungen-pools-und-erhaltung"
            element={<ReleaseOct24De />}
          />
          <Route
            path="/de/renderings-fur-renovierungen"
            element={<RenovationDe />}
          />
          <Route
            path="/de/blog/wie-man-ein-renovierungsbedurftiges-haus-verkauft"
            element={<BlogDeArticle28 />}
          />
          <Route path="/de/kuchen-rendering" element={<RenderKitchenDe />} />
          <Route
            path="/de/schlafzimmer-rendering"
            element={<RenderBedroomDe />}
          />
          <Route
            path="/de/badezimmer-rendering"
            element={<RenderBathroomDe />}
          />
          <Route
            path="/de/wohnzimmer-rendering"
            element={<RenderLivingRoomDe />}
          />
          <Route path="/de/terrassen-rendering" element={<RenderTerraceDe />} />
          <Route path="/de/haus-rendering" element={<RenderHouseDe />} />
          <Route
            path="/de/hilfe/wie-man-kuchen-rendering-erstellt"
            element={<HowToRenderKitchenDe />}
          />
          <Route
            path="/de/hilfe/wie-man-badezimmer-rendering-erstellt"
            element={<HowToRenderBathroomDe />}
          />
          <Route
            path="/de/hilfe/wie-man-wohnzimmer-rendering-erstellt"
            element={<HowToRenderLivingRoomDe />}
          />
          <Route
            path="/de/hilfe/wie-man-schlafzimmer-rendering-erstellt"
            element={<HowToRenderBedroomDe />}
          />
          <Route
            path="/de/hilfe/wie-man-terrassen-rendering-erstellt"
            element={<HowToRenderTerraceDe />}
          />
          <Route
            path="/de/hilfe/wie-man-grundriss-zeichnet"
            element={<HowToDrawFloorPlanDe />}
          />
          <Route
            path="/de/hilfe/wie-man-haus-aussenansicht-rendering-erstellt"
            element={<HowToRenderHouseDe />}
          />
          <Route path="/de/hilfe" element={<HelpDe />} />
          <Route
            path="/de/releases/arbeitsplatze"
            element={<ReleaseNov24De />}
          />
          <Route
            path="/de/blog/traditionelles-vs-virtuelles-home-staging"
            element={<BlogDeArticle29 />}
          />
          <Route
            path="/de/rendering-vom-grundriss"
            element={<RenderFromFloorPlanDe />}
          />
          <Route
            path="/de/hilfe/grundriss-mit-ki-rendern"
            element={<HowToRenderFloorPlanWithAIDe />}
          />
          <Route
            path="/de/blog/roi-virtuelles-home-staging"
            element={<BlogDeArticle30 />}
          />
          <Route path="/de/gewerbe-rendering" element={<RenderLocalDe />} />
          <Route
            path="/de/home-staging-gewerbe"
            element={<HomeStagingLocalDe />}
          />
          <Route
            path="/de/hilfe/wie-man-gewerberaum-rendering-erstellt"
            element={<HowToRenderLocalDe />}
          />
          <Route
            path="/de/releases/grundrisse-und-gewerberaume"
            element={<ReleaseDec24De />}
          />
          <Route
            path="/de/hilfe/wie-man-vorhang-effekt-home-staging-virtuell-erstellt"
            element={<HowToCurtainEffectDe />}
          />
          <Route
            path="/de/blog/tipps-fur-immobilienfotografie"
            element={<BlogArticle31De />}
          />
          <Route
            path="/de/blog/optimierung-von-immobilienanzeigen"
            element={<BlogDeArticle32 />}
          />
        </Routes>

        <br />
        <br />

        <div
          style={{
            backgroundColor: "#f7f7f7",
            borderTop: "1px solid rgba(55, 53, 47, 0.09)",
            paddingBottom: "40px",
          }}
        >
          <div
            className="section-one-column-left"
            style={{ marginBottom: "0px" }}
          >
            <footer>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2px",
                }}
              >
                <div
                  style={{
                    marginTop: "0px",
                    alignItems: "top",
                  }}
                >
                  <img src={FooterLogo} height="30" alt="Pedra logo" />
                </div>

                <p
                  style={{
                    marginRight: "16px",
                    marginBottom: "16px",
                    display: "flex",
                    textAlign: "left",
                    color: "#333",
                    width: "140px",
                  }}
                >
                  Pedra Software SL <br />
                  &copy; 2025
                </p>
                <p style={{ display: "flex", gap: "16px" }}>
                  <a
                    href="https://www.instagram.com/pedrasoftware/"
                    target="_blank"
                    style={{ padding: "0px" }}
                    rel="nofollow noopener noreferrer"
                    title="Instagram"
                  >
                    <SocialMediaIcon1 />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCExqYM0ywIYkNu8ecW00kkw"
                    target="_blank"
                    style={{ padding: "0px" }}
                    rel="nofollow noopener noreferrer"
                    title="YouTube"
                  >
                    <SocialMediaIcon3 />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/pedrasoftware/"
                    target="_blank"
                    style={{ padding: "0px" }}
                    rel="nofollow noopener noreferrer"
                    title="LinkedIn"
                  >
                    <SocialMediaIcon2 />
                  </a>
                  <a
                    href="http://twitter.com/PedraSoftware"
                    target="_blank"
                    style={{ padding: "0px" }}
                    rel="nofollow noopener noreferrer"
                    title="Twitter"
                  >
                    <SocialMediaIcon4 />
                  </a>
                </p>
                <p style={{ display: "flex", gap: "16px", marginTop: "20px" }}>
                  <div style={{ color: "#E9CE45", display: "inline" }}>
                    {" "}
                    ★★★★★
                  </div>
                </p>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    color: "#000",
                  }}
                >
                  <GoogleIcon />
                  {locale === "en"
                    ? "5.0 on Google"
                    : locale === "es"
                    ? "5.0 en Google"
                    : locale === "pt"
                    ? "5.0 no Google"
                    : locale === "de"
                    ? "5.0 bei Google"
                    : "5.0 sur Google"}
                </p>
              </div>

              <div className="header-spacer"></div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2px",
                  width: "200px",
                }}
              >
                <div
                  style={{ color: "#000", fontWeight: "600", width: "200px" }}
                >
                  {locale === "en" ? (
                    <>Product</>
                  ) : locale === "es" ? (
                    <>Producto</>
                  ) : locale === "pt" ? (
                    "Produto"
                  ) : locale === "de" ? (
                    "Produkt"
                  ) : (
                    "Outils"
                  )}
                </div>

                <div>
                  <Link
                    to={`${localePath}${"render"}`}
                    hrefLang={
                      locale === "en"
                        ? "en"
                        : locale === "es"
                        ? "es"
                        : locale === "pt"
                        ? "pt"
                        : locale === "de"
                        ? "de"
                        : "fr"
                    }
                    className="article-link footer"
                  >
                    {locale === "en"
                      ? "AI Render"
                      : locale === "es"
                      ? "Render IA"
                      : locale === "pt"
                      ? "Renderização IA"
                      : locale === "de"
                      ? "KI-Rendering"
                      : "Rendu IA"}
                  </Link>
                </div>
                <div>
                  <Link
                    to={`${localePath}${
                      locale === "pt"
                        ? "home-staging-virtual"
                        : locale === "de"
                        ? "virtuelles-home-staging"
                        : "home-staging-virtual"
                    }`}
                    hrefLang={
                      locale === "en"
                        ? "en"
                        : locale === "es"
                        ? "es"
                        : locale === "pt"
                        ? "pt"
                        : locale === "de"
                        ? "de"
                        : "fr"
                    }
                    state={{ lineHeight: "124%" }}
                    className="article-link footer"
                  >
                    {locale === "en"
                      ? "Virtual home staging"
                      : locale === "es"
                      ? "Home staging virtual"
                      : locale === "pt"
                      ? "Home staging virtual"
                      : locale === "de"
                      ? "Virtuelles Home Staging"
                      : "Home staging virtuel"}
                  </Link>
                </div>
                <div>
                  <Link
                    to={`${localePath}${
                      locale === "pt"
                        ? "fotografia-imobiliaria"
                        : locale === "de"
                        ? "immobilienfotografie"
                        : "real-estate-photography"
                    }`}
                    hrefLang={
                      locale === "en"
                        ? "en"
                        : locale === "es"
                        ? "es"
                        : locale === "pt"
                        ? "pt"
                        : locale === "de"
                        ? "de"
                        : "fr"
                    }
                    state={{ lineHeight: "124%" }}
                    className="article-link footer"
                  >
                    {locale === "en"
                      ? "Real estate photography"
                      : locale === "es"
                      ? "Fotografía inmobiliaria"
                      : locale === "pt"
                      ? "Fotografia imobiliária"
                      : locale === "de"
                      ? "Immobilienfotografie"
                      : "Photos immobilières"}
                  </Link>
                </div>
                <div>
                  <Link
                    to={`${localePath}${
                      locale === "pt"
                        ? "planta-baixa"
                        : locale === "de"
                        ? "grundriss"
                        : "floorplan"
                    }`}
                    hrefLang={
                      locale === "en"
                        ? "en"
                        : locale === "es"
                        ? "es"
                        : locale === "pt"
                        ? "pt"
                        : locale === "de"
                        ? "de"
                        : "fr"
                    }
                    className="article-link footer"
                  >
                    {locale === "en"
                      ? "Floor plan"
                      : locale === "es"
                      ? "Planos de planta"
                      : locale === "pt"
                      ? "Planta baixa"
                      : locale === "de"
                      ? "Grundriss"
                      : "Plan d'étage"}
                  </Link>
                </div>
                <div>
                  <Link
                    to={
                      locale === "en"
                        ? `${localePath}render-from-floor-plan`
                        : locale === "es"
                        ? `${localePath}render-desde-plano`
                        : locale === "pt"
                        ? `${localePath}render-desde-planta`
                        : locale === "de"
                        ? `${localePath}rendering-vom-grundriss`
                        : `${localePath}render-depuis-plan`
                    }
                    hrefLang={
                      locale === "en"
                        ? "en"
                        : locale === "es"
                        ? "es"
                        : locale === "pt"
                        ? "pt"
                        : locale === "de"
                        ? "de"
                        : "fr"
                    }
                    className="article-link footer"
                  >
                    {locale === "en" ? (
                      <>Visuals from Floor Plans</>
                    ) : locale === "es" ? (
                      <>Visualizaciones de Planos</>
                    ) : locale === "pt" ? (
                      "Visualização de Plantas"
                    ) : locale === "de" ? (
                      "Visualisierungen von Grundrissen"
                    ) : (
                      "Visuels depuis Plans"
                    )}
                  </Link>
                </div>
                <div
                  style={{
                    color: "#000",
                    fontWeight: "600",
                    marginTop: "12px",
                  }}
                >
                  {locale === "en" ? (
                    <>Resources</>
                  ) : locale === "es" ? (
                    <>Recursos</>
                  ) : locale === "pt" ? (
                    "Recursos"
                  ) : locale === "de" ? (
                    "Ressourcen"
                  ) : (
                    "Ressources"
                  )}
                </div>
                <div>
                  <Link
                    to={`${localePath}blog`}
                    hrefLang={
                      locale === "en"
                        ? "en"
                        : locale === "es"
                        ? "es"
                        : locale === "pt"
                        ? "pt"
                        : locale === "de"
                        ? "de"
                        : "fr"
                    }
                    state={{ lineHeight: "124%" }}
                    className="article-link footer"
                  >
                    Blog
                  </Link>
                </div>
                <div>
                  <Link
                    to={`${localePath}releases`}
                    hrefLang={
                      locale === "en"
                        ? "en"
                        : locale === "es"
                        ? "es"
                        : locale === "pt"
                        ? "pt"
                        : locale === "de"
                        ? "de"
                        : "fr"
                    }
                    state={{ lineHeight: "124%" }}
                    className="article-link footer"
                  >
                    {locale === "en"
                      ? "Releases"
                      : locale === "es"
                      ? "Lanzamientos"
                      : locale === "pt"
                      ? "Lançamentos"
                      : locale === "de"
                      ? "Versionen"
                      : "Nouveautés"}
                  </Link>
                </div>
                <div>
                  <Link
                    to={`${localePath}reviews-and-testimonials`}
                    hrefLang={
                      locale === "en"
                        ? "en"
                        : locale === "es"
                        ? "es"
                        : locale === "pt"
                        ? "pt"
                        : locale === "de"
                        ? "de"
                        : "fr"
                    }
                    className="article-link footer"
                  >
                    {locale === "en"
                      ? "Reviews"
                      : locale === "es"
                      ? "Opiniones"
                      : locale === "pt"
                      ? "Avaliações"
                      : locale === "de"
                      ? "Bewertungen"
                      : "Témoignages"}
                  </Link>
                </div>

                <div
                  style={{
                    color: "#000",
                    fontWeight: "600",
                    width: "200px",
                    marginTop: "12px",
                  }}
                >
                  {locale === "en" ? (
                    <>Home staging</>
                  ) : locale === "es" ? (
                    <>Home staging</>
                  ) : locale === "pt" ? (
                    "Home staging"
                  ) : locale === "de" ? (
                    "Home Staging"
                  ) : (
                    "Mise en scene"
                  )}
                </div>

                <div>
                  <Link
                    to={
                      locale === "en"
                        ? `${localePath}home-staging-kitchen`
                        : locale === "es"
                        ? `${localePath}home-staging-cocina`
                        : locale === "pt"
                        ? `${localePath}home-staging-cozinha`
                        : locale === "de"
                        ? `${localePath}home-staging-kuche`
                        : `${localePath}home-staging-cuisine`
                    }
                    hrefLang={
                      locale === "en"
                        ? "en"
                        : locale === "es"
                        ? "es"
                        : locale === "pt"
                        ? "pt"
                        : locale === "de"
                        ? "de"
                        : "fr"
                    }
                    className="article-link footer"
                  >
                    {locale === "en"
                      ? "Home Staging Kitchen"
                      : locale === "es"
                      ? "Home Staging Cocina"
                      : locale === "pt"
                      ? "Home Staging Cozinha"
                      : locale === "de"
                      ? "Home Staging Küche"
                      : "Home Staging Cuisine"}
                  </Link>
                </div>
                <div>
                  <Link
                    to={
                      locale === "en"
                        ? `${localePath}home-staging-bedroom`
                        : locale === "es"
                        ? `${localePath}home-staging-dormitorio`
                        : locale === "pt"
                        ? `${localePath}home-staging-quarto`
                        : locale === "de"
                        ? `${localePath}home-staging-schlafzimmer`
                        : `${localePath}home-staging-chambre`
                    }
                    hrefLang={
                      locale === "en"
                        ? "en"
                        : locale === "es"
                        ? "es"
                        : locale === "pt"
                        ? "pt"
                        : locale === "de"
                        ? "de"
                        : "fr"
                    }
                    className="article-link footer"
                  >
                    {locale === "en"
                      ? "Home Staging Bedroom"
                      : locale === "es"
                      ? "Home Staging Dormitorio"
                      : locale === "pt"
                      ? "Home Staging Quarto"
                      : locale === "de"
                      ? "Home Staging Schlafzimmer"
                      : "Home Staging Chambre"}
                  </Link>
                </div>
                <div>
                  <Link
                    to={
                      locale === "en"
                        ? `${localePath}home-staging-living-room`
                        : locale === "es"
                        ? `${localePath}home-staging-salon`
                        : locale === "pt"
                        ? `${localePath}home-staging-sala`
                        : locale === "de"
                        ? `${localePath}home-staging-wohnzimmer`
                        : `${localePath}home-staging-salon`
                    }
                    hrefLang={
                      locale === "en"
                        ? "en"
                        : locale === "es"
                        ? "es"
                        : locale === "pt"
                        ? "pt"
                        : locale === "de"
                        ? "de"
                        : "fr"
                    }
                    className="article-link footer"
                  >
                    {locale === "en"
                      ? "Home Staging Living Room"
                      : locale === "es"
                      ? "Home Staging Salón"
                      : locale === "pt"
                      ? "Home Staging Sala"
                      : locale === "de"
                      ? "Home Staging Wohnzimmer"
                      : "Home Staging Salon"}
                  </Link>
                </div>
                <div>
                  <Link
                    to={
                      locale === "en"
                        ? `${localePath}home-staging-terrace`
                        : locale === "es"
                        ? `${localePath}home-staging-terraza`
                        : locale === "pt"
                        ? `${localePath}home-staging-terraco`
                        : locale === "de"
                        ? `${localePath}home-staging-terrasse`
                        : `${localePath}home-staging-terrasse`
                    }
                    hrefLang={
                      locale === "en"
                        ? "en"
                        : locale === "es"
                        ? "es"
                        : locale === "pt"
                        ? "pt"
                        : locale === "de"
                        ? "de"
                        : "fr"
                    }
                    className="article-link footer"
                  >
                    {locale === "en"
                      ? "Home Staging Terrace"
                      : locale === "es"
                      ? "Home Staging Terraza"
                      : locale === "pt"
                      ? "Home Staging Terraço"
                      : locale === "de"
                      ? "Home Staging Terrasse"
                      : "Home Staging Terrasse"}
                  </Link>
                </div>
                <div>
                  <Link
                    to={
                      locale === "en"
                        ? `${localePath}home-staging-commercial`
                        : locale === "es"
                        ? `${localePath}home-staging-local`
                        : locale === "pt"
                        ? `${localePath}home-staging-local`
                        : locale === "de"
                        ? `${localePath}home-staging-gewerbe`
                        : `${localePath}home-staging-local`
                    }
                    hrefLang={
                      locale === "en"
                        ? "en"
                        : locale === "es"
                        ? "es"
                        : locale === "pt"
                        ? "pt"
                        : locale === "de"
                        ? "de"
                        : "fr"
                    }
                    className="article-link footer"
                  >
                    {locale === "en"
                      ? "Home Staging Commercial "
                      : locale === "es"
                      ? "Home Staging Local"
                      : locale === "pt"
                      ? "Home Staging Local"
                      : locale === "de"
                      ? "Home Staging Gewerbe"
                      : "Home Staging Commercial"}
                  </Link>
                </div>

                <div
                  style={{
                    color: "#000",
                    fontWeight: "600",
                    width: "200px",
                    marginTop: "12px",
                  }}
                >
                  {locale === "en" ? (
                    <>Render</>
                  ) : locale === "es" ? (
                    <>Render</>
                  ) : locale === "pt" ? (
                    "Renderização"
                  ) : locale === "de" ? (
                    "Rendering"
                  ) : (
                    "Rendu"
                  )}
                </div>

                <div>
                  <Link
                    to={
                      locale === "en"
                        ? `${localePath}render-kitchen`
                        : locale === "es"
                        ? `${localePath}render-cocina`
                        : locale === "pt"
                        ? `${localePath}renderizar-cozinha`
                        : locale === "de"
                        ? `${localePath}kuchen-rendering`
                        : `${localePath}rendu-cuisine`
                    }
                    hrefLang={
                      locale === "en"
                        ? "en"
                        : locale === "es"
                        ? "es"
                        : locale === "pt"
                        ? "pt"
                        : locale === "de"
                        ? "de"
                        : "fr"
                    }
                    className="article-link footer"
                  >
                    {locale === "en"
                      ? "Render Kitchen"
                      : locale === "es"
                      ? "Render Cocina"
                      : locale === "pt"
                      ? "Renderização de Cozinha"
                      : locale === "de"
                      ? "Küchen-Rendering"
                      : "Rendu Cuisine"}
                  </Link>
                </div>

                <div>
                  <Link
                    to={
                      locale === "en"
                        ? `${localePath}render-bedroom`
                        : locale === "es"
                        ? `${localePath}render-dormitorio`
                        : locale === "pt"
                        ? `${localePath}renderizar-quarto`
                        : locale === "de"
                        ? `${localePath}schlafzimmer-rendering`
                        : `${localePath}rendu-chambre`
                    }
                    hrefLang={
                      locale === "en"
                        ? "en"
                        : locale === "es"
                        ? "es"
                        : locale === "pt"
                        ? "pt"
                        : locale === "de"
                        ? "de"
                        : "fr"
                    }
                    className="article-link footer"
                  >
                    {locale === "en"
                      ? "Render Bedroom"
                      : locale === "es"
                      ? "Render Dormitorio"
                      : locale === "pt"
                      ? "Renderização de Quarto"
                      : locale === "de"
                      ? "Schlafzimmer-Rendering"
                      : "Rendu Chambre"}
                  </Link>
                </div>

                <div>
                  <Link
                    to={
                      locale === "en"
                        ? `${localePath}render-bathroom`
                        : locale === "es"
                        ? `${localePath}render-bano`
                        : locale === "pt"
                        ? `${localePath}renderizar-banheiro`
                        : locale === "de"
                        ? `${localePath}badezimmer-rendering`
                        : `${localePath}rendu-salle-de-bain`
                    }
                    hrefLang={
                      locale === "en"
                        ? "en"
                        : locale === "es"
                        ? "es"
                        : locale === "pt"
                        ? "pt"
                        : locale === "de"
                        ? "de"
                        : "fr"
                    }
                    className="article-link footer"
                  >
                    {locale === "en"
                      ? "Render Bathroom"
                      : locale === "es"
                      ? "Render Baño"
                      : locale === "pt"
                      ? "Renderização de Banheiro"
                      : locale === "de"
                      ? "Badezimmer-Rendering"
                      : "Rendu Salle de Bain"}
                  </Link>
                </div>

                <div>
                  <Link
                    to={
                      locale === "en"
                        ? `${localePath}render-living-room`
                        : locale === "es"
                        ? `${localePath}render-salon`
                        : locale === "pt"
                        ? `${localePath}renderizar-sala`
                        : locale === "de"
                        ? `${localePath}wohnzimmer-rendering`
                        : `${localePath}rendu-salon`
                    }
                    hrefLang={
                      locale === "en"
                        ? "en"
                        : locale === "es"
                        ? "es"
                        : locale === "pt"
                        ? "pt"
                        : locale === "de"
                        ? "de"
                        : "fr"
                    }
                    className="article-link footer"
                  >
                    {locale === "en"
                      ? "Render Living Room"
                      : locale === "es"
                      ? "Render Salón"
                      : locale === "pt"
                      ? "Renderização de Sala"
                      : locale === "de"
                      ? "Wohnzimmer-Rendering"
                      : "Rendu Salon"}
                  </Link>
                </div>

                <div>
                  <Link
                    to={
                      locale === "en"
                        ? `${localePath}render-terrace`
                        : locale === "es"
                        ? `${localePath}render-terraza`
                        : locale === "pt"
                        ? `${localePath}renderizar-terraco`
                        : locale === "de"
                        ? `${localePath}terrassen-rendering`
                        : `${localePath}rendu-terrasse`
                    }
                    hrefLang={
                      locale === "en"
                        ? "en"
                        : locale === "es"
                        ? "es"
                        : locale === "pt"
                        ? "pt"
                        : locale === "de"
                        ? "de"
                        : "fr"
                    }
                    className="article-link footer"
                  >
                    {locale === "en"
                      ? "Render Terrace"
                      : locale === "es"
                      ? "Render Terraza"
                      : locale === "pt"
                      ? "Renderização de Terraço"
                      : locale === "de"
                      ? "Terrassen-Rendering"
                      : "Rendu Terrasse"}
                  </Link>
                </div>

                <div>
                  <Link
                    to={
                      locale === "en"
                        ? `${localePath}render-house`
                        : locale === "es"
                        ? `${localePath}render-casa`
                        : locale === "pt"
                        ? `${localePath}renderizar-casa`
                        : locale === "de"
                        ? `${localePath}haus-rendering`
                        : `${localePath}rendu-maison`
                    }
                    hrefLang={
                      locale === "en"
                        ? "en"
                        : locale === "es"
                        ? "es"
                        : locale === "pt"
                        ? "pt"
                        : locale === "de"
                        ? "de"
                        : "fr"
                    }
                    className="article-link footer"
                  >
                    {locale === "en"
                      ? "Render House"
                      : locale === "es"
                      ? "Render Casa"
                      : locale === "pt"
                      ? "Renderização de Casa"
                      : locale === "de"
                      ? "Haus-Rendering"
                      : "Rendu Maison"}
                  </Link>
                </div>

                <div>
                  <Link
                    to={
                      locale === "en"
                        ? `${localePath}render-commercial`
                        : locale === "es"
                        ? `${localePath}render-local`
                        : locale === "pt"
                        ? `${localePath}renderizacao-comercial`
                        : locale === "de"
                        ? `${localePath}gewerbe-rendering`
                        : `${localePath}rendu-local`
                    }
                    hrefLang={
                      locale === "en"
                        ? "en"
                        : locale === "es"
                        ? "es"
                        : locale === "pt"
                        ? "pt"
                        : locale === "de"
                        ? "de"
                        : "fr"
                    }
                    className="article-link footer"
                  >
                    {locale === "en"
                      ? "Render Commercial"
                      : locale === "es"
                      ? "Render Local"
                      : locale === "pt"
                      ? "Renderização Comercial"
                      : locale === "de"
                      ? "Gewerbe-Rendering"
                      : "Rendu Local"}
                  </Link>
                </div>

                <div
                  style={{
                    color: "#000",
                    fontWeight: "600",
                    marginTop: "12px",
                  }}
                >
                  {locale === "en" ? (
                    <>Articles</>
                  ) : locale === "es" ? (
                    <>Artículos</>
                  ) : locale === "pt" ? (
                    "Artigos"
                  ) : locale === "de" ? (
                    "Artikel"
                  ) : (
                    "Articles"
                  )}
                </div>
                <div
                  style={{
                    width: "120%",
                    lineHeight: "100%",
                    maxWidth: "180px",
                    padding: "4px 0px",
                  }}
                >
                  <Link
                    to={
                      locale === "en"
                        ? `${localePath}blog/sell-more-properties-with-ai`
                        : locale === "es"
                        ? `${localePath}blog/vende-mas-propiedades-inmobiliarias-con-ia`
                        : locale === "pt"
                        ? `${localePath}blog/vender-mais-imoveis-com-ia`
                        : locale === "de"
                        ? `${localePath}blog/mehr-immobilien-mit-ki-verkaufen`
                        : `${localePath}blog/vendre-plus-de-proprietes-immobilieres-avec-ia`
                    }
                    hrefLang={
                      locale === "en"
                        ? "en"
                        : locale === "es"
                        ? "es"
                        : locale === "pt"
                        ? "pt"
                        : locale === "de"
                        ? "de"
                        : "fr"
                    }
                    className="article-link footer"
                  >
                    {locale === "en"
                      ? "Sell More With AI"
                      : locale === "es"
                      ? "Vender Más con IA"
                      : locale === "pt"
                      ? "Vender Mais com IA"
                      : locale === "de"
                      ? "Mehr verkaufen mit KI"
                      : "Vendre Plus avec IA"}
                  </Link>
                </div>
                <div
                  style={{
                    width: "120%",
                    lineHeight: "100%",
                    maxWidth: "180px",
                    padding: "4px 0px",
                  }}
                >
                  <Link
                    to={
                      locale === "en"
                        ? `${localePath}blog/home-staging-examples`
                        : locale === "es"
                        ? `${localePath}blog/home-staging-ejemplos`
                        : locale === "pt"
                        ? `${localePath}blog/exemplos-home-staging`
                        : locale === "de"
                        ? `${localePath}blog/home-staging-beispiele`
                        : `${localePath}blog/exemples-de-home-staging`
                    }
                    hrefLang={
                      locale === "en"
                        ? "en"
                        : locale === "es"
                        ? "es"
                        : locale === "pt"
                        ? "pt"
                        : locale === "de"
                        ? "de"
                        : "fr"
                    }
                    className="article-link footer"
                  >
                    {locale === "en"
                      ? "Home Staging Examples"
                      : locale === "es"
                      ? "Ejemplos Home Staging"
                      : locale === "pt"
                      ? "Exemplos Home Staging"
                      : locale === "de"
                      ? "Home Staging Beispiele"
                      : "Exemples Home Staging"}
                  </Link>
                </div>
                <div
                  style={{
                    color: "#000",
                    fontWeight: "600",
                    marginTop: "12px",
                  }}
                >
                  API
                </div>
                <div
                  style={{
                    width: "120%",
                    lineHeight: "100%",
                    maxWidth: "180px",
                    padding: "4px 0px",
                  }}
                >
                  <Link
                    to={`${localePath}api-documentation`}
                    hrefLang={
                      locale === "en"
                        ? "en"
                        : locale === "es"
                        ? "es"
                        : locale === "pt"
                        ? "pt"
                        : locale === "de"
                        ? "de"
                        : "fr"
                    }
                    className="article-link footer"
                  >
                    {locale === "en"
                      ? "API documentation"
                      : locale === "es"
                      ? "Documentación API"
                      : locale === "pt"
                      ? "Documentação API"
                      : locale === "de"
                      ? "API-Dokumentation"
                      : "Documentation API"}
                  </Link>
                </div>
              </div>

              <div className="footer-block-group">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2px",
                    width: "100%",
                  }}
                >
                  <div style={{ color: "#000", fontWeight: "600" }}>
                    {locale === "en" ? (
                      <>Industries</>
                    ) : locale === "es" ? (
                      <>Industrias</>
                    ) : locale === "pt" ? (
                      "Industries"
                    ) : locale === "de" ? (
                      "Branchen"
                    ) : (
                      "Industries"
                    )}
                  </div>
                  <div>
                    <Link
                      to={`${localePath}realestate`}
                      hrefLang={
                        locale === "en"
                          ? "en"
                          : locale === "es"
                          ? "es"
                          : locale === "pt"
                          ? "pt"
                          : locale === "de"
                          ? "de"
                          : "fr"
                      }
                      className="article-link footer"
                    >
                      {locale === "en"
                        ? "Real Estate"
                        : locale === "es"
                        ? "Inmobiliaria"
                        : locale === "pt"
                        ? "Imobiliária"
                        : locale === "de"
                        ? "Immobilien"
                        : "Immobilier"}
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={`${localePath}${
                        locale === "de" ? "innenarchitektur" : "interiordesign"
                      }`}
                      hrefLang={
                        locale === "en"
                          ? "en"
                          : locale === "es"
                          ? "es"
                          : locale === "pt"
                          ? "pt"
                          : locale === "de"
                          ? "de"
                          : "fr"
                      }
                      className="article-link footer"
                    >
                      {locale === "en"
                        ? "Interior Design"
                        : locale === "es"
                        ? "Interiorismo"
                        : locale === "pt"
                        ? "Design de interiores"
                        : locale === "de"
                        ? "Innenarchitektur"
                        : "Intérieurisme"}
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={
                        locale === "en"
                          ? `${localePath}renders-for-renovations`
                          : locale === "es"
                          ? `${localePath}renders-para-reformas`
                          : locale === "pt"
                          ? `${localePath}renderizacoes-para-reformas`
                          : locale === "de"
                          ? `${localePath}renderings-fur-renovierungen`
                          : `${localePath}rendus-de-renovation`
                      }
                      hrefLang={
                        locale === "en"
                          ? "en"
                          : locale === "es"
                          ? "es"
                          : locale === "pt"
                          ? "pt"
                          : locale === "de"
                          ? "de"
                          : "fr"
                      }
                      className="article-link footer"
                    >
                      {locale === "en"
                        ? "Renovations"
                        : locale === "es"
                        ? "Reformas"
                        : locale === "pt"
                        ? "Renovações"
                        : locale === "de"
                        ? "Renovierungen"
                        : "Rénovations"}
                    </Link>
                  </div>
                  <div
                    style={{
                      color: "#000",
                      fontWeight: "600",
                      marginTop: "12px",
                    }}
                  >
                    {locale === "en" ? (
                      <>Company</>
                    ) : locale === "es" ? (
                      <>Empresa</>
                    ) : locale === "pt" ? (
                      "Empresa"
                    ) : locale === "de" ? (
                      "Unternehmen"
                    ) : (
                      "Entreprise"
                    )}
                  </div>
                  <div>
                    <Link
                      to={`${localePath}about`}
                      hrefLang={
                        locale === "en"
                          ? "en"
                          : locale === "es"
                          ? "es"
                          : locale === "pt"
                          ? "pt"
                          : locale === "de"
                          ? "de"
                          : "fr"
                      }
                      className="article-link footer"
                    >
                      {locale === "en"
                        ? "About Us"
                        : locale === "es"
                        ? "Sobre Nosotros"
                        : locale === "pt"
                        ? "Sobre nós"
                        : locale === "de"
                        ? "Über uns"
                        : "À propos"}
                    </Link>
                  </div>
                  <div
                    style={{
                      color: "#000",
                      fontWeight: "600",
                      marginTop: "12px",
                    }}
                  >
                    {locale === "en" ? (
                      <>Help</>
                    ) : locale === "es" ? (
                      <>Ayuda</>
                    ) : locale === "pt" ? (
                      "Ajuda"
                    ) : locale === "de" ? (
                      "Hilfe"
                    ) : (
                      "Aide"
                    )}
                  </div>
                  <div>
                    <Link
                      to={
                        locale === "en"
                          ? `${localePath}help`
                          : locale === "es"
                          ? `${localePath}help`
                          : locale === "pt"
                          ? `${localePath}ajuda`
                          : locale === "de"
                          ? `${localePath}hilfe`
                          : `${localePath}help`
                      }
                      hrefLang={
                        locale === "en"
                          ? "en"
                          : locale === "es"
                          ? "es"
                          : locale === "pt"
                          ? "pt"
                          : locale === "de"
                          ? "de"
                          : "fr"
                      }
                      className="article-link footer"
                    >
                      {locale === "en"
                        ? "Help And Tutorials"
                        : locale === "es"
                        ? "Ayuda y Tutoriales"
                        : locale === "pt"
                        ? "Ajuda e tutoriais"
                        : locale === "de"
                        ? "Hilfe und Tutorials"
                        : "Aide et Tutoriels"}
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={
                        locale === "en"
                          ? `${localePath}help/how-to-create-virtual-home-staging`
                          : locale === "es"
                          ? `${localePath}help/como-crear-home-staging-virtual`
                          : locale === "pt"
                          ? `${localePath}ajuda/como-criar-home-staging-virtual`
                          : locale === "de"
                          ? `${localePath}hilfe/wie-man-virtuelles-home-staging-erstellt`
                          : `${localePath}help/comment-creer-home-staging-virtuel`
                      }
                      hrefLang={
                        locale === "en"
                          ? "en"
                          : locale === "es"
                          ? "es"
                          : locale === "pt"
                          ? "pt"
                          : locale === "de"
                          ? "de"
                          : "fr"
                      }
                      className="article-link footer"
                    >
                      {locale === "en"
                        ? "How To Create Virtual Staging"
                        : locale === "es"
                        ? "Cómo Crear Home Staging Virtual"
                        : locale === "pt"
                        ? "Como Criar Home Staging Virtual"
                        : locale === "de"
                        ? "Wie man virtuelles Home Staging erstellt"
                        : "Comment Creer Home Staging Virtuel"}
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={
                        locale === "en"
                          ? `${localePath}help/how-to-draw-floor-plan`
                          : locale === "es"
                          ? `${localePath}help/como-dibujar-plano`
                          : locale === "pt"
                          ? `${localePath}ajuda/como-desenhar-planta-baixa`
                          : locale === "de"
                          ? `${localePath}hilfe/wie-man-grundriss-zeichnet`
                          : `${localePath}help/comment-dessiner-plan`
                      }
                      hrefLang={
                        locale === "en"
                          ? "en"
                          : locale === "es"
                          ? "es"
                          : locale === "pt"
                          ? "pt"
                          : locale === "de"
                          ? "de"
                          : "fr"
                      }
                      className="article-link footer"
                    >
                      {locale === "en"
                        ? "How To Draw Floor Plans"
                        : locale === "es"
                        ? "Cómo Dibujar Planos"
                        : locale === "pt"
                        ? "Como Desenhar Planta Baixa"
                        : locale === "de"
                        ? "Wie man Grundrisse zeichnet"
                        : "Comment Dessiner un Plan"}
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={
                        locale === "en"
                          ? `${localePath}help/how-to-furnish-virtually`
                          : locale === "es"
                          ? `${localePath}help/como-amueblar-virtualmente`
                          : locale === "pt"
                          ? `${localePath}ajuda/como-mobiliar-virtualmente`
                          : locale === "de"
                          ? `${localePath}hilfe/wie-man-virtuell-einrichtet`
                          : `${localePath}help/comment-meubler-en-virtuel`
                      }
                      hrefLang={
                        locale === "en"
                          ? "en"
                          : locale === "es"
                          ? "es"
                          : locale === "pt"
                          ? "pt"
                          : locale === "de"
                          ? "de"
                          : "fr"
                      }
                      className="article-link footer"
                    >
                      {locale === "en"
                        ? "How To Furnish"
                        : locale === "es"
                        ? "Cómo Amueblar"
                        : locale === "pt"
                        ? "Cómo Mobiliar"
                        : locale === "de"
                        ? "Wie man virtuell einrichtet"
                        : "Comment Meubler"}
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={
                        locale === "en"
                          ? `${localePath}help/how-to-renovate-houses-virtually`
                          : locale === "es"
                          ? `${localePath}help/como-renovar-casas-virtualmente`
                          : locale === "pt"
                          ? `${localePath}ajuda/como-renovar-casas-virtualmente`
                          : locale === "de"
                          ? `${localePath}hilfe/wie-man-hauser-virtuell-renoviert`
                          : `${localePath}help/comment-renover-des-maisons-en-virtuel`
                      }
                      hrefLang={
                        locale === "en"
                          ? "en"
                          : locale === "es"
                          ? "es"
                          : locale === "pt"
                          ? "pt"
                          : locale === "de"
                          ? "de"
                          : "fr"
                      }
                      className="article-link footer"
                    >
                      {locale === "en"
                        ? "How To Renovate"
                        : locale === "es"
                        ? "Cómo Renovar"
                        : locale === "pt"
                        ? "Cómo Renovar"
                        : locale === "de"
                        ? "Wie man Häuser virtuell renoviert"
                        : "Comment Renover"}
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={
                        locale === "en"
                          ? `${localePath}help/how-to-add-3d-objects`
                          : locale === "es"
                          ? `${localePath}help/como-anadir-objetos-3d`
                          : locale === "pt"
                          ? `${localePath}ajuda/como-adicionar-objetos-3d`
                          : locale === "de"
                          ? `${localePath}hilfe/wie-man-3d-objekte-hinzufugt`
                          : `${localePath}help/comment-ajouter-des-objets-3d`
                      }
                      hrefLang={
                        locale === "en"
                          ? "en"
                          : locale === "es"
                          ? "es"
                          : locale === "pt"
                          ? "pt"
                          : locale === "de"
                          ? "de"
                          : "fr"
                      }
                      className="article-link footer"
                    >
                      {locale === "en"
                        ? "How To Add Object"
                        : locale === "es"
                        ? "Cómo Añadir Objeto"
                        : locale === "pt"
                        ? "Como Adicionar Objeto"
                        : locale === "de"
                        ? "Wie man 3D-Objekte hinzufügt"
                        : "Comment Ajouter Objets"}
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={
                        locale === "en"
                          ? `${localePath}help/how-to-remove-object`
                          : locale === "es"
                          ? `${localePath}help/como-borrar-objeto`
                          : locale === "pt"
                          ? `${localePath}ajuda/como-remover-objetos`
                          : locale === "de"
                          ? `${localePath}hilfe/wie-man-objekte-entfernt`
                          : `${localePath}help/comment-supprimer-un-objet`
                      }
                      hrefLang={
                        locale === "en"
                          ? "en"
                          : locale === "es"
                          ? "es"
                          : locale === "pt"
                          ? "pt"
                          : locale === "de"
                          ? "de"
                          : "fr"
                      }
                      className="article-link footer"
                    >
                      {locale === "en"
                        ? "How To Remove Object"
                        : locale === "es"
                        ? "Cómo Borrar Objeto"
                        : locale === "pt"
                        ? "Como Remover Objetos"
                        : locale === "de"
                        ? "Wie man Objekte entfernt"
                        : "Comment Supprimer Objets"}
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={
                        locale === "en"
                          ? `${localePath}help/how-to-empty-rooms-virtually`
                          : locale === "es"
                          ? `${localePath}help/como-vaciar-habitacion-virtualmente`
                          : locale === "pt"
                          ? `${localePath}ajuda/como-esvaziar-ambientes-virtualmente`
                          : locale === "de"
                          ? `${localePath}hilfe/wie-man-raume-virtuell-leert`
                          : `${localePath}help/comment-vider-pieces-virtuellement`
                      }
                      hrefLang={
                        locale === "en"
                          ? "en"
                          : locale === "es"
                          ? "es"
                          : locale === "pt"
                          ? "pt"
                          : locale === "de"
                          ? "de"
                          : "fr"
                      }
                      className="article-link footer"
                    >
                      {locale === "en"
                        ? "How To Empty Rooms"
                        : locale === "es"
                        ? "Cómo Vaciar Habitación"
                        : locale === "pt"
                        ? "Como Esvaziar Ambientes"
                        : locale === "de"
                        ? "Wie man Räume virtuell leert"
                        : "Comment Vider les Pièces"}
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={
                        locale === "en"
                          ? `${localePath}help/how-to-create-terrace-virtual-home-staging`
                          : locale === "es"
                          ? `${localePath}help/como-crear-home-staging-virtual-terraza`
                          : locale === "pt"
                          ? `${localePath}ajuda/como-criar-home-staging-virtual-terraco`
                          : locale === "de"
                          ? `${localePath}hilfe/wie-man-virtuelles-terrassen-home-staging-erstellt`
                          : `${localePath}help/comment-creer-home-staging-virtuel-terrasse`
                      }
                      hrefLang={
                        locale === "en"
                          ? "en"
                          : locale === "es"
                          ? "es"
                          : locale === "pt"
                          ? "pt"
                          : locale === "de"
                          ? "de"
                          : "fr"
                      }
                      className="article-link footer"
                    >
                      {locale === "en"
                        ? "How To Create Virtual Staging Terrace"
                        : locale === "es"
                        ? "Cómo Crear Home Staging Terraza"
                        : locale === "pt"
                        ? "Como Criar Home Staging Terraço"
                        : locale === "de"
                        ? "Wie man virtuelles Terrassen-Home-Staging erstellt"
                        : "Comment Home Staging Virtuel Terrasse"}
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={
                        locale === "en"
                          ? `${localePath}help/how-to-create-bedroom-virtual-home-staging`
                          : locale === "es"
                          ? `${localePath}help/como-crear-home-staging-virtual-dormitorio`
                          : locale === "pt"
                          ? `${localePath}ajuda/como-criar-home-staging-virtual-quarto`
                          : locale === "de"
                          ? `${localePath}hilfe/wie-man-virtuelles-schlafzimmer-home-staging-erstellt`
                          : `${localePath}help/comment-creer-home-staging-virtuel-chambre`
                      }
                      hrefLang={
                        locale === "en"
                          ? "en"
                          : locale === "es"
                          ? "es"
                          : locale === "pt"
                          ? "pt"
                          : locale === "de"
                          ? "de"
                          : "fr"
                      }
                      className="article-link footer"
                    >
                      {locale === "en"
                        ? "How To Create Virtual Staging Bedroom"
                        : locale === "es"
                        ? "Cómo Crear Home Staging Dormitorio"
                        : locale === "pt"
                        ? "Como Criar Home Staging Quarto"
                        : locale === "de"
                        ? "Wie man virtuelles Schlafzimmer-Home-Staging erstellt"
                        : "Comment Home Staging Virtuel Chambre"}
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={
                        locale === "en"
                          ? `${localePath}help/how-to-create-living-room-virtual-home-staging`
                          : locale === "es"
                          ? `${localePath}help/como-crear-home-staging-virtual-salon`
                          : locale === "pt"
                          ? `${localePath}ajuda/como-criar-home-staging-virtual-sala`
                          : locale === "de"
                          ? `${localePath}hilfe/wie-man-virtuelles-wohnzimmer-home-staging-erstellt`
                          : `${localePath}help/comment-creer-home-staging-virtuel-salon`
                      }
                      hrefLang={
                        locale === "en"
                          ? "en"
                          : locale === "es"
                          ? "es"
                          : locale === "pt"
                          ? "pt"
                          : locale === "de"
                          ? "de"
                          : "fr"
                      }
                      className="article-link footer"
                    >
                      {locale === "en"
                        ? "How To Create Staging Living Room"
                        : locale === "es"
                        ? "Cómo Crear Home Staging Salón"
                        : locale === "pt"
                        ? "Como Criar Home Staging Sala"
                        : locale === "de"
                        ? "Wie man virtuelles Wohnzimmer-Home-Staging erstellt"
                        : "Comment Home Staging Virtuel Salon"}
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={
                        locale === "en"
                          ? `${localePath}help/how-to-create-kitchen-virtual-home-staging`
                          : locale === "es"
                          ? `${localePath}help/como-crear-home-staging-virtual-cocina`
                          : locale === "pt"
                          ? `${localePath}ajuda/como-criar-home-staging-virtual-cozinha`
                          : locale === "de"
                          ? `${localePath}hilfe/wie-man-virtuelles-kuchen-home-staging-erstellt`
                          : `${localePath}help/comment-creer-home-staging-virtuel-cuisine`
                      }
                      hrefLang={
                        locale === "en"
                          ? "en"
                          : locale === "es"
                          ? "es"
                          : locale === "pt"
                          ? "pt"
                          : locale === "de"
                          ? "de"
                          : "fr"
                      }
                      className="article-link footer"
                    >
                      {locale === "en"
                        ? "How To Create Virtual Staging Kitchen"
                        : locale === "es"
                        ? "Cómo Crear Home Staging Cocina"
                        : locale === "pt"
                        ? "Como Criar Home Staging Cozinha"
                        : locale === "de"
                        ? "Wie man virtuelles Küchen-Home-Staging erstellt"
                        : "Comment Home Staging Virtuel Cuisine"}
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={
                        locale === "en"
                          ? `${localePath}help/how-to-create-bathroom-virtual-home-staging`
                          : locale === "es"
                          ? `${localePath}help/como-crear-home-staging-virtual-bano`
                          : locale === "pt"
                          ? `${localePath}ajuda/como-criar-home-staging-virtual-banheiro`
                          : locale === "de"
                          ? `${localePath}hilfe/wie-man-virtuelles-badezimmer-home-staging-erstellt`
                          : `${localePath}help/comment-creer-home-staging-virtuel-salle-de-bain`
                      }
                      hrefLang={
                        locale === "en"
                          ? "en"
                          : locale === "es"
                          ? "es"
                          : locale === "pt"
                          ? "pt"
                          : locale === "de"
                          ? "de"
                          : "fr"
                      }
                      className="article-link footer"
                    >
                      {locale === "en"
                        ? "How To Create Virtual Staging Bathroom"
                        : locale === "es"
                        ? "Cómo Crear Home Staging Baño"
                        : locale === "pt"
                        ? "Como Criar Home Staging Banheiro"
                        : locale === "de"
                        ? "Wie man virtuelles Badezimmer-Home-Staging erstellt"
                        : "Comment Home Staging Virtuel Salle de Bain"}
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={
                        locale === "en"
                          ? `${localePath}help/how-to-create-kitchen-render`
                          : locale === "es"
                          ? `${localePath}help/como-crear-render-cocina`
                          : locale === "pt"
                          ? `${localePath}ajuda/como-criar-render-cozinha`
                          : locale === "de"
                          ? `${localePath}hilfe/wie-man-kuchen-rendering-erstellt`
                          : `${localePath}help/comment-creer-rendu-cuisine`
                      }
                      hrefLang={
                        locale === "en"
                          ? "en"
                          : locale === "es"
                          ? "es"
                          : locale === "pt"
                          ? "pt"
                          : locale === "de"
                          ? "de"
                          : "fr"
                      }
                      className="article-link footer"
                    >
                      {locale === "en"
                        ? "How To Create Kitchen Render"
                        : locale === "es"
                        ? "Cómo Crear Render de Cocina"
                        : locale === "pt"
                        ? "Como Criar Renderização de Cozinha"
                        : locale === "de"
                        ? "Wie man Küchen-Rendering erstellt"
                        : "Comment Créer un Rendu de Cuisine"}
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={
                        locale === "en"
                          ? `${localePath}help/how-to-create-bathroom-render`
                          : locale === "es"
                          ? `${localePath}help/como-crear-render-bano`
                          : locale === "pt"
                          ? `${localePath}ajuda/como-criar-render-banheiro`
                          : locale === "de"
                          ? `${localePath}hilfe/wie-man-badezimmer-rendering-erstellt`
                          : `${localePath}help/comment-creer-rendu-salle-de-bain`
                      }
                      hrefLang={
                        locale === "en"
                          ? "en"
                          : locale === "es"
                          ? "es"
                          : locale === "pt"
                          ? "pt"
                          : locale === "de"
                          ? "de"
                          : "fr"
                      }
                      className="article-link footer"
                    >
                      {locale === "en"
                        ? "How To Create Bathroom Render"
                        : locale === "es"
                        ? "Cómo Crear Render de Baño"
                        : locale === "pt"
                        ? "Como Criar Renderização de Banheiro"
                        : locale === "de"
                        ? "Wie man Badezimmer-Rendering erstellt"
                        : "Comment Créer un Rendu de Salle de Bain"}
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={
                        locale === "en"
                          ? `${localePath}help/how-to-create-living-room-render`
                          : locale === "es"
                          ? `${localePath}help/como-crear-render-sala-estar`
                          : locale === "pt"
                          ? `${localePath}ajuda/como-criar-render-sala`
                          : locale === "de"
                          ? `${localePath}hilfe/wie-man-wohnzimmer-rendering-erstellt`
                          : `${localePath}help/comment-creer-rendu-salon`
                      }
                      hrefLang={
                        locale === "en"
                          ? "en"
                          : locale === "es"
                          ? "es"
                          : locale === "pt"
                          ? "pt"
                          : locale === "de"
                          ? "de"
                          : "fr"
                      }
                      className="article-link footer"
                    >
                      {locale === "en"
                        ? "How To Create Living Room Render"
                        : locale === "es"
                        ? "Cómo Crear Render de Sala de Estar"
                        : locale === "pt"
                        ? "Como Criar Renderização de Sala"
                        : locale === "de"
                        ? "Wie man Wohnzimmer-Rendering erstellt"
                        : "Comment Créer un Rendu de Salon"}
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={
                        locale === "en"
                          ? `${localePath}help/how-to-create-house-exterior-render`
                          : locale === "es"
                          ? `${localePath}help/como-crear-render-exterior-casa`
                          : locale === "pt"
                          ? `${localePath}ajuda/como-criar-render-externa-casa`
                          : locale === "de"
                          ? `${localePath}hilfe/wie-man-haus-aussenansicht-rendering-erstellt`
                          : `${localePath}help/comment-creer-rendu-exterieur-maison`
                      }
                      hrefLang={
                        locale === "en"
                          ? "en"
                          : locale === "es"
                          ? "es"
                          : locale === "pt"
                          ? "pt"
                          : locale === "de"
                          ? "de"
                          : "fr"
                      }
                      className="article-link footer"
                    >
                      {locale === "en"
                        ? "How To Create House Exterior Render"
                        : locale === "es"
                        ? "Cómo Crear Render Exterior de Casa"
                        : locale === "pt"
                        ? "Como Criar Renderização Externa de Casa"
                        : locale === "de"
                        ? "Wie man Haus-Außenansicht-Rendering erstellt"
                        : "Comment Créer un Rendu Extérieur de Maison"}
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={
                        locale === "en"
                          ? `${localePath}help/how-to-create-bedroom-render`
                          : locale === "es"
                          ? `${localePath}help/como-crear-render-dormitorio`
                          : locale === "pt"
                          ? `${localePath}ajuda/como-criar-render-quarto`
                          : locale === "de"
                          ? `${localePath}hilfe/wie-man-schlafzimmer-rendering-erstellt`
                          : `${localePath}help/comment-creer-rendu-chambre`
                      }
                      hrefLang={
                        locale === "en"
                          ? "en"
                          : locale === "es"
                          ? "es"
                          : locale === "pt"
                          ? "pt"
                          : locale === "de"
                          ? "de"
                          : "fr"
                      }
                      className="article-link footer"
                    >
                      {locale === "en"
                        ? "How To Create Bedroom Render"
                        : locale === "es"
                        ? "Cómo Crear Render de Dormitorio"
                        : locale === "pt"
                        ? "Como Criar Renderização de Quarto"
                        : locale === "de"
                        ? "Wie man Schlafzimmer-Rendering erstellt"
                        : "Comment Créer un Rendu de Chambre"}
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={
                        locale === "en"
                          ? `${localePath}help/how-to-create-terrace-render`
                          : locale === "es"
                          ? `${localePath}help/como-crear-render-terraza`
                          : locale === "pt"
                          ? `${localePath}ajuda/como-criar-render-terraco`
                          : locale === "de"
                          ? `${localePath}hilfe/wie-man-terrassen-rendering-erstellt`
                          : `${localePath}help/comment-creer-rendu-terrasse`
                      }
                      hrefLang={
                        locale === "en"
                          ? "en"
                          : locale === "es"
                          ? "es"
                          : locale === "pt"
                          ? "pt"
                          : locale === "de"
                          ? "de"
                          : "fr"
                      }
                      className="article-link footer"
                    >
                      {locale === "en"
                        ? "How To Create Terrace Render"
                        : locale === "es"
                        ? "Cómo Crear Render de Terraza"
                        : locale === "pt"
                        ? "Como Criar Renderização de Terraço"
                        : locale === "de"
                        ? "Wie man Terrassen-Rendering erstellt"
                        : "Comment Créer un Rendu de Terrasse"}
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={
                        locale === "en"
                          ? `${localePath}help/how-to-create-commercial-space-render`
                          : locale === "es"
                          ? `${localePath}help/como-crear-render-local-comercial`
                          : locale === "pt"
                          ? `${localePath}ajuda/como-criar-render-local-comercial`
                          : locale === "de"
                          ? `${localePath}hilfe/wie-man-gewerberaum-rendering-erstellt`
                          : `${localePath}help/comment-creer-rendu-local-commercial`
                      }
                      hrefLang={
                        locale === "en"
                          ? "en"
                          : locale === "es"
                          ? "es"
                          : locale === "pt"
                          ? "pt"
                          : locale === "de"
                          ? "de"
                          : "fr"
                      }
                      className="article-link footer"
                    >
                      {locale === "en"
                        ? "How to Render Local"
                        : locale === "es"
                        ? "Como Crear Render Local Comercial"
                        : locale === "pt"
                        ? "Como Criar Renderização Comercial"
                        : locale === "de"
                        ? "Wie man Gewerberaum-Rendering erstellt"
                        : "Comment Créer un Rendu de Local Commercial"}
                    </Link>
                  </div>

                  <div>
                    <Link
                      to={
                        locale === "en"
                          ? `${localePath}help/render-floor-plan-with-ai`
                          : locale === "es"
                          ? `${localePath}help/renderizar-plano-de-planta-con-ia`
                          : locale === "pt"
                          ? `${localePath}ajuda/renderizar-planta-baixa-com-ia`
                          : locale === "de"
                          ? `${localePath}hilfe/grundriss-mit-ki-rendern`
                          : `${localePath}help/rendu-plan-etage-avec-ia`
                      }
                      hrefLang={
                        locale === "en"
                          ? "en"
                          : locale === "es"
                          ? "es"
                          : locale === "pt"
                          ? "pt"
                          : locale === "de"
                          ? "de"
                          : "fr"
                      }
                      className="article-link footer"
                    >
                      {locale === "en"
                        ? "How To Render Floor Plan"
                        : locale === "es"
                        ? "Cómo Renderizar un Plano de Planta"
                        : locale === "pt"
                        ? "Como Renderizar uma Planta Baixa"
                        : locale === "de"
                        ? "Wie man Grundriss rendert"
                        : "Comment Faire le Rendu d'un Plan d'Étage"}
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={
                        locale === "en"
                          ? `${localePath}help/how-to-create-virtual-home-staging-curtain-effect`
                          : locale === "es"
                          ? `${localePath}help/como-crear-efecto-cortinilla-home-staging-virtual`
                          : locale === "pt"
                          ? `${localePath}ajuda/como-criar-efeito-cortinas-home-staging-virtual`
                          : locale === "de"
                          ? `${localePath}hilfe/wie-man-vorhang-effekt-home-staging-virtuell-erstellt`
                          : `${localePath}help/comment-creer-effet-rideaux-home-staging-virtuel`
                      }
                      hrefLang={
                        locale === "en"
                          ? "en"
                          : locale === "es"
                          ? "es"
                          : locale === "pt"
                          ? "pt"
                          : locale === "de"
                          ? "de"
                          : "fr"
                      }
                      className="article-link footer"
                    >
                      {locale === "en"
                        ? "How To Create Curtain Effect"
                        : locale === "es"
                        ? "Cómo Crear Efecto Cortinilla"
                        : locale === "pt"
                        ? "Como Criar Efeito Cortinas"
                        : locale === "de"
                        ? "Wie man Vorhang-Effekt erstellt"
                        : "Comment Créer Effet Rideaux"}
                    </Link>
                  </div>
                  {locale === "es" && (
                    <div>
                      <Link
                        to={`${localePath}help/video-home-staging-virtual`}
                        hrefLang="es"
                        className="article-link footer"
                      >
                        Cómo Crear Home Staging Virtual - Video
                      </Link>
                    </div>
                  )}
                  {locale === "es" && (
                    <>
                      <div
                        style={{
                          color: "#000",
                          fontWeight: "600",
                          marginTop: "12px",
                        }}
                      >
                        Herramientas gratuitas
                      </div>
                      <div>
                        <Link
                          to="/es/herramientas-gratis/generar-descripcion-anuncio-inmobiliario-gratis"
                          hrefLang="es"
                          className="article-link footer"
                        >
                          Generar Descripción Anuncio
                        </Link>
                      </div>
                    </>
                  )}
                  {locale === "en" && (
                    <>
                      <div
                        style={{
                          color: "#000",
                          fontWeight: "600",
                          marginTop: "12px",
                        }}
                      >
                        Free tools
                      </div>
                      <div>
                        <Link
                          to="/free-tools/generate-real-estate-listing-description-free"
                          hrefLang="en"
                          className="article-link footer"
                        >
                          Generate Listing Description
                        </Link>
                      </div>
                    </>
                  )}
                  {locale === "fr" && (
                    <>
                      <div
                        style={{
                          color: "#000",
                          fontWeight: "600",
                          marginTop: "12px",
                        }}
                      >
                        Outils gratuits
                      </div>
                      <div>
                        <Link
                          to="/fr/outils-gratuits/generer-description-annonce-immobiliere-gratuit"
                          hrefLang="fr"
                          className="article-link footer"
                        >
                          Générer une description d'annonce
                        </Link>
                      </div>
                    </>
                  )}
                  {locale === "pt" && (
                    <>
                      <div
                        style={{
                          color: "#000",
                          fontWeight: "600",
                          marginTop: "12px",
                        }}
                      >
                        Ferramentas gratuitas
                      </div>
                      <div>
                        <Link
                          to="/pt/ferramentas-gratuitas/gerar-descricao-anuncio-gratis"
                          hrefLang="fr"
                          className="article-link footer"
                        >
                          Gerar Descrição Anúncio
                        </Link>
                      </div>
                    </>
                  )}
                  {locale === "de" && (
                    <>
                      <div
                        style={{
                          color: "#000",
                          fontWeight: "600",
                          marginTop: "12px",
                        }}
                      >
                        Kostenlose Tools
                      </div>
                      <div>
                        <Link
                          to="/de/kostenlose-tools/immobilienanzeige-beschreibung-kostenlos-erstellen"
                          hrefLang="de"
                          className="article-link footer"
                        >
                          Anzeigenbeschreibung generieren
                        </Link>
                      </div>
                    </>
                  )}
                  <div>
                    <Link
                      to={
                        locale === "en"
                          ? `${localePath}free-tools/free-image-format-converter`
                          : locale === "es"
                          ? `${localePath}herramientas-gratis/convertidor-formato-imagen-gratuito`
                          : locale === "pt"
                          ? `${localePath}ferramentas-gratuitas/conversor-formato-imagem-gratis`
                          : locale === "de"
                          ? `${localePath}kostenlose-tools/bildformat-konverter-kostenlos`
                          : `${localePath}outils-gratuits/convertisseur-format-image`
                      }
                      hrefLang={
                        locale === "en"
                          ? "en"
                          : locale === "es"
                          ? "es"
                          : locale === "pt"
                          ? "pt"
                          : locale === "de"
                          ? "de"
                          : "fr"
                      }
                      className="article-link footer"
                    >
                      {locale === "en"
                        ? "Convert Image Format"
                        : locale === "es"
                        ? "Convertir Formato Imagen"
                        : locale === "pt"
                        ? "Conversor Formato Imagem"
                        : locale === "de"
                        ? "Bildformat konvertieren"
                        : "Convertisseur Forat Image"}
                    </Link>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2px",
                  }}
                ></div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2px",
                  }}
                >
                  <div style={{ color: "#000", fontWeight: "600" }}>
                    {locale === "en" ? (
                      <>Other</>
                    ) : locale === "es" ? (
                      <>Otros</>
                    ) : locale === "pt" ? (
                      "Outros"
                    ) : locale === "de" ? (
                      "Sonstiges"
                    ) : (
                      "Autres"
                    )}
                  </div>
                  <div>
                    <Link
                      to={`${localePath}${
                        locale === "pt"
                          ? "precos"
                          : locale === "de"
                          ? "preise"
                          : "pricing"
                      }`}
                      hrefLang={
                        locale === "en"
                          ? "en"
                          : locale === "es"
                          ? "es"
                          : locale === "pt"
                          ? "pt"
                          : locale === "de"
                          ? "de"
                          : "fr"
                      }
                      rel="nofollow"
                      className="article-link footer"
                    >
                      {locale === "en"
                        ? "Pricing"
                        : locale === "es"
                        ? "Precio"
                        : locale === "pt"
                        ? "Preços"
                        : locale === "de"
                        ? "Preise"
                        : "Tarifs"}
                    </Link>
                  </div>

                  <div>
                    <Link
                      to={`${localePath}${
                        locale === "de" ? "rechtliches" : "legal"
                      }`}
                      hrefLang={
                        locale === "en"
                          ? "en"
                          : locale === "es"
                          ? "es"
                          : locale === "pt"
                          ? "pt"
                          : locale === "de"
                          ? "de"
                          : "fr"
                      }
                      rel="nofollow"
                      className="article-link footer"
                    >
                      {locale === "en"
                        ? "Legal"
                        : locale === "es"
                        ? "Legal"
                        : locale === "pt"
                        ? "Legal"
                        : locale === "de"
                        ? "Rechtliches"
                        : "Légale"}
                    </Link>
                  </div>

                  <div>
                    <Link
                      to={`${localePath}${
                        locale === "de" ? "datenschutz" : "privacy"
                      }`}
                      hrefLang={
                        locale === "en"
                          ? "en"
                          : locale === "es"
                          ? "es"
                          : locale === "pt"
                          ? "pt"
                          : locale === "de"
                          ? "de"
                          : "fr"
                      }
                      rel="nofollow"
                      className="article-link footer"
                    >
                      {locale === "en"
                        ? "Privacy"
                        : locale === "es"
                        ? "Privacidad"
                        : locale === "pt"
                        ? "Confidentialitée"
                        : locale === "de"
                        ? "Datenschutz"
                        : "Confidentialitée"}
                    </Link>
                  </div>

                  <div
                    style={{
                      color: "#000",
                      fontWeight: "600",
                      marginTop: "12px",
                    }}
                  >
                    {locale === "en" ? (
                      <>Contact</>
                    ) : locale === "es" ? (
                      <>Contacto</>
                    ) : locale === "pt" ? (
                      "Contact"
                    ) : locale === "de" ? (
                      "Kontakt"
                    ) : (
                      "Contact"
                    )}
                  </div>

                  <div style={{ color: "#333" }}>felix@pedra.so</div>
                  <div style={{ width: "150px", color: "#333" }}>
                    +34 646 811 068
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
