import React, { useState } from "react";
import CheckIcon from "../Icons/CheckIcon";
import CustomerLogos from "../CustomerLogos";
import DownloadIcon from "../Icons/DownloadIcon";

const FreeImageFormatConverterDe = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [targetFormat, setTargetFormat] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [convertedFiles, setConvertedFiles] = useState([]);

  const supportedFormats = ["JPG", "PNG", "JPEG"];

  const isValidFileType = (file) => {
    const validTypes = ["image/jpeg", "image/jpg", "image/png"];
    return validTypes.includes(file.type);
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const invalidFiles = files.filter((file) => !isValidFileType(file));

    if (invalidFiles.length > 0) {
      setError(
        `Nicht unterstützte Dateien: ${invalidFiles
          .map((f) => f.name)
          .join(", ")}. Nur JPG, JPEG und PNG werden akzeptiert.`
      );
      return;
    }

    setSelectedFiles(files);
    setError("");
    setConvertedFiles([]);
  };

  const handleFormatChange = (e) => {
    setTargetFormat(e.target.value);
    setError("");
  };

  const convertImage = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const img = new Image();

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const maxDimension = 4096; // Verhindert Speicherprobleme bei sehr großen Bildern

          let width = img.width;
          let height = img.height;

          // Verkleinern, wenn das Bild zu groß ist
          if (width > maxDimension || height > maxDimension) {
            const ratio = Math.min(maxDimension / width, maxDimension / height);
            width *= ratio;
            height *= ratio;
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext("2d", { willReadFrequently: true });

          // Weißer Hintergrund für PNG-Transparenz
          if (targetFormat.toLowerCase() !== "png") {
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, canvas.width, canvas.height);
          }

          ctx.drawImage(img, 0, 0, width, height);

          let quality = 0.92;
          if (
            targetFormat.toLowerCase() === "jpg" ||
            targetFormat.toLowerCase() === "jpeg"
          ) {
            quality = 0.92; // Hohe Qualität für JPG/JPEG
          }

          canvas.toBlob(
            (blob) => {
              if (!blob) {
                reject(new Error("Konvertierungsfehler"));
                return;
              }
              resolve(blob);
            },
            `image/${targetFormat.toLowerCase()}`,
            quality
          );
        };

        img.onerror = () => reject(new Error("Fehler beim Laden des Bildes"));
        img.src = reader.result;
      };

      reader.onerror = () => reject(new Error("Fehler beim Lesen der Datei"));
      reader.readAsDataURL(file);
    });
  };

  const handleDownload = async (file) => {
    try {
      const originalFile = selectedFiles.find(
        (f) => f.name === file.originalName
      );
      if (!originalFile) {
        throw new Error("Originaldatei nicht gefunden");
      }

      setIsLoading(true);
      const convertedBlob = await convertImage(originalFile);

      // Überprüfen der konvertierten Dateigröße
      if (convertedBlob.size === 0) {
        throw new Error("Die konvertierte Datei ist leer");
      }

      const url = URL.createObjectURL(convertedBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = file.name;

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (err) {
      setError(`Fehler beim Herunterladen von ${file.name}: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadAll = async () => {
    setIsLoading(true);
    try {
      for (const file of convertedFiles) {
        await handleDownload(file);
      }
    } catch (err) {
      setError("Fehler beim Herunterladen aller Dateien: " + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedFiles.length === 0) {
      setError("Bitte wählen Sie mindestens eine Datei aus");
      return;
    }
    if (!targetFormat) {
      setError("Bitte wählen Sie ein Zielformat aus");
      return;
    }

    setIsLoading(true);
    setError("");

    try {
      const newConvertedFiles = selectedFiles.map((file) => ({
        name: `${file.name.split(".")[0]}.${targetFormat.toLowerCase()}`,
        size: file.size,
        originalName: file.name,
      }));

      setConvertedFiles(newConvertedFiles);
    } catch (err) {
      setError("Fehler bei der Konvertierung: " + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="section-first-wrapper">
      <section className="section-first">
        <div className="section-first-text-container">
          <h1 className="section-first-title">
            Kostenloser Bildformat-Konverter{" "}
            <span className="text-highlight">Kostenlos</span>
          </h1>
          <div className="section-first-text free-tool-value-propcontainer">
            <p className="free-tool-value-prop">
              <CheckIcon /> <span>100% kostenlos</span>
            </p>
            <p className="free-tool-value-prop">
              <CheckIcon /> <span>Sofortige Konvertierung</span>
            </p>
            <p className="free-tool-value-prop">
              <CheckIcon /> <span>Mehrere Formate unterstützt</span>
            </p>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="converter-form">
          <div>
            <label className="free-tool-form">
              <div className="free-tool-form-number-container">
                <div className="free-tool-form-number">1</div>
              </div>{" "}
              Bilder auswählen
            </label>
            <input
              type="file"
              multiple
              accept="image/*"
              onChange={handleFileChange}
              className="form-input"
            />
          </div>

          <div className="form-group">
            <label className="free-tool-form">
              <div className="free-tool-form-number-container">
                <div className="free-tool-form-number">2</div>
              </div>{" "}
              Zielformat
            </label>
            <select
              value={targetFormat}
              onChange={handleFormatChange}
              className="form-select"
            >
              <option value="">Format auswählen</option>
              {supportedFormats.map((format) => (
                <option key={format} value={format}>
                  {format}
                </option>
              ))}
            </select>
          </div>

          <br />

          <button
            type="submit"
            disabled={isLoading}
            className="free-tool-button"
          >
            {isLoading ? "Konvertiere..." : "Bilder konvertieren"}
          </button>
          <br />

          {convertedFiles.length > 0 && (
            <div className="form-group">
              <div className="converted-files-header">
                <h2 className="converted-files-title">Konvertierte Dateien:</h2>
                <button
                  onClick={handleDownloadAll}
                  className="free-tool-button"
                >
                  <DownloadIcon />
                  Bilder herunterladen
                </button>
              </div>
            </div>
          )}

          {error && (
            <div className="error-message">
              <strong>Fehler:</strong> {error}
            </div>
          )}
        </form>

        <div className="step-container article" style={{ marginTop: "100px" }}>
          <article className="article-text">
            <h2 className="article-subtitleh2">
              Was ist ein Bildformat-Konverter?
            </h2>
            <p>
              Ein Bildformat-Konverter ist ein Werkzeug, mit dem Sie Bilder von
              einem Format in ein anderes umwandeln können, während die
              ursprüngliche Qualität erhalten bleibt. Dieses Tool ist besonders
              nützlich, wenn Sie Bilder in bestimmten Formaten für verschiedene
              Plattformen oder Verwendungszwecke benötigen.
            </p>
            <h2 className="article-subtitleh2">Unterstützte Formate</h2>
            <p>
              Unser Tool unterstützt die gängigsten und am häufigsten
              verwendeten Bildformate:
            </p>
            <ul>
              <li>JPG/JPEG - Ideal für Fotos und Bilder mit vielen Farben</li>
              <li>PNG - Perfekt für Bilder, die Transparenz erfordern</li>
            </ul>
            <h2 className="article-subtitleh2">Vorteile unseres Konverters</h2>
            <h3>Benutzerfreundlichkeit</h3>
            <p>
              Unser Tool ist intuitiv und einfach zu bedienen. Sie müssen nur
              Ihre Bilder hochladen, das gewünschte Format auswählen und auf
              Konvertieren klicken. Keine technischen Kenntnisse erforderlich.
            </p>
            <h3>Stapelkonvertierung</h3>
            <p>
              Konvertieren Sie mehrere Bilder gleichzeitig und sparen Sie Zeit
              und Aufwand bei der Verarbeitung mehrerer Dateien.
            </p>
            <h3>Qualitätserhaltung</h3>
            <p>
              Unser Konverter verwendet fortschrittliche Algorithmen, um die
              bestmögliche Qualität während der Konvertierung zu erhalten und
              sicherzustellen, dass Ihre Bilder in ihrem neuen Format
              hervorragend aussehen.
            </p>
            <h2 className="article-subtitleh2">
              Tipps zur Auswahl des richtigen Formats
            </h2>
            <ul>
              <li>
                JPG/JPEG: Ideal für Fotografien und Bilder mit vielen Farben. Es
                ist das häufigste Format für digitale Fotografien und bietet
                eine gute Komprimierung bei akzeptabler visueller Qualität.
              </li>
              <li>
                PNG: Beste Wahl, wenn Sie Transparenz benötigen oder für Bilder
                mit Text, Grafiken oder definierten Kanten. Auch hervorragend
                geeignet für Screenshots und Grafiken mit einfarbigen Flächen.
              </li>
            </ul>
          </article>
        </div>

        <div className="customer-logo-container">
          ÜBER 20.000 NUTZER VERTRAUEN BEREITS UNSEREM KONVERTER
          <CustomerLogos />
        </div>
      </section>
    </section>
  );
};

export default FreeImageFormatConverterDe;
