import React, { useState } from "react";
import CheckIcon from "../Icons/CheckIcon";
import CustomerLogos from "../CustomerLogos";
import DownloadIcon from "../Icons/DownloadIcon";

const FreeImageFormatConverterEs = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [targetFormat, setTargetFormat] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [convertedFiles, setConvertedFiles] = useState([]);

  const supportedFormats = ["JPG", "PNG", "JPEG"];

  const isValidFileType = (file) => {
    const validTypes = ["image/jpeg", "image/jpg", "image/png"];
    return validTypes.includes(file.type);
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const invalidFiles = files.filter((file) => !isValidFileType(file));

    if (invalidFiles.length > 0) {
      setError(
        `Archivos no soportados: ${invalidFiles
          .map((f) => f.name)
          .join(", ")}. Solo se aceptan JPG, JPEG y PNG.`
      );
      return;
    }

    setSelectedFiles(files);
    setError("");
    setConvertedFiles([]);
  };

  const handleFormatChange = (e) => {
    setTargetFormat(e.target.value);
    setError("");
  };

  const convertImage = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const img = new Image();

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const maxDimension = 4096; // Prevent memory issues with very large images

          let width = img.width;
          let height = img.height;

          // Scale down if image is too large
          if (width > maxDimension || height > maxDimension) {
            const ratio = Math.min(maxDimension / width, maxDimension / height);
            width *= ratio;
            height *= ratio;
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext("2d", { willReadFrequently: true });

          // Set white background for PNG transparency
          if (targetFormat.toLowerCase() !== "png") {
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, canvas.width, canvas.height);
          }

          ctx.drawImage(img, 0, 0, width, height);

          let quality = 0.92;
          if (
            targetFormat.toLowerCase() === "jpg" ||
            targetFormat.toLowerCase() === "jpeg"
          ) {
            quality = 0.92; // High quality for JPG/JPEG
          }

          canvas.toBlob(
            (blob) => {
              if (!blob) {
                reject(new Error("Error en la conversión"));
                return;
              }
              resolve(blob);
            },
            `image/${targetFormat.toLowerCase()}`,
            quality
          );
        };

        img.onerror = () => reject(new Error("Error al cargar la imagen"));
        img.src = reader.result;
      };

      reader.onerror = () => reject(new Error("Error al leer el archivo"));
      reader.readAsDataURL(file);
    });
  };

  const handleDownload = async (file) => {
    try {
      const originalFile = selectedFiles.find(
        (f) => f.name === file.originalName
      );
      if (!originalFile) {
        throw new Error("Archivo original no encontrado");
      }

      setIsLoading(true);
      const convertedBlob = await convertImage(originalFile);

      // Verify converted file size
      if (convertedBlob.size === 0) {
        throw new Error("El archivo convertido está vacío");
      }

      const url = URL.createObjectURL(convertedBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = file.name;

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (err) {
      setError(`Error al descargar ${file.name}: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadAll = async () => {
    setIsLoading(true);
    try {
      for (const file of convertedFiles) {
        await handleDownload(file);
      }
    } catch (err) {
      setError("Error al descargar todos los archivos: " + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedFiles.length === 0) {
      setError("Por favor, seleccione al menos un archivo");
      return;
    }
    if (!targetFormat) {
      setError("Por favor, seleccione un formato de destino");
      return;
    }

    setIsLoading(true);
    setError("");

    try {
      const newConvertedFiles = selectedFiles.map((file) => ({
        name: `${file.name.split(".")[0]}.${targetFormat.toLowerCase()}`,
        size: file.size,
        originalName: file.name,
      }));

      setConvertedFiles(newConvertedFiles);
    } catch (err) {
      setError("Error durante la conversión: " + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="section-first-wrapper">
      <section className="section-first">
        <div className="section-first-text-container">
          <h1 className="section-first-title">
            Convertidor de Formato de Imágenes{" "}
            <span className="text-highlight">Gratis</span>
          </h1>
          <div className="section-first-text free-tool-value-propcontainer">
            <p className="free-tool-value-prop">
              <CheckIcon /> <span>100% gratuito</span>
            </p>
            <p className="free-tool-value-prop">
              <CheckIcon /> <span>Conversión instantánea</span>
            </p>
            <p className="free-tool-value-prop">
              <CheckIcon /> <span>Múltiples formatos soportados</span>
            </p>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="converter-form">
          <div>
            <label className="free-tool-form">
              <div className="free-tool-form-number-container">
                <div className="free-tool-form-number">1</div>
              </div>{" "}
              Seleccionar Imágenes
            </label>
            <input
              type="file"
              multiple
              accept="image/*"
              onChange={handleFileChange}
              className="form-input"
            />
          </div>

          <div className="form-group">
            <label className="free-tool-form">
              <div className="free-tool-form-number-container">
                <div className="free-tool-form-number">2</div>
              </div>{" "}
              Formato de Destino
            </label>
            <select
              value={targetFormat}
              onChange={handleFormatChange}
              className="form-select"
            >
              <option value="">Seleccionar formato</option>
              {supportedFormats.map((format) => (
                <option key={format} value={format}>
                  {format}
                </option>
              ))}
            </select>
          </div>

          <br />

          <button
            type="submit"
            disabled={isLoading}
            className="free-tool-button"
          >
            {isLoading ? "Convirtiendo..." : "Convertir imágenes"}
          </button>
          <br />

          {convertedFiles.length > 0 && (
            <div className="form-group">
              <div className="converted-files-header">
                <h2 className="converted-files-title">Archivos Convertidos:</h2>
                <button
                  onClick={handleDownloadAll}
                  className="free-tool-button"
                >
                  <DownloadIcon />
                  Descargar imágenes
                </button>
              </div>
            </div>
          )}

          {error && (
            <div className="error-message">
              <strong>Error:</strong> {error}
            </div>
          )}
        </form>

        <div className="step-container article" style={{ marginTop: "100px" }}>
          <article className="article-text">
            <h2 className="article-subtitleh2">
              ¿Qué es un convertidor de formato de imágenes?
            </h2>
            <p>
              Un convertidor de formato de imágenes es una herramienta que
              permite transformar imágenes de un formato a otro, manteniendo la
              calidad original. Esta herramienta es especialmente útil cuando
              necesitas imágenes en formatos específicos para diferentes
              plataformas o usos.
            </p>
            <h2 className="article-subtitleh2">Formatos soportados</h2>
            <p>
              Nuestra herramienta soporta los formatos de imagen más comunes y
              utilizados:
            </p>
            <ul>
              <li>
                JPG/JPEG - Ideal para fotografías y imágenes con muchos colores
              </li>
              <li>PNG - Perfecto para imágenes que requieren transparencia</li>
            </ul>
            <h2 className="article-subtitleh2">
              Ventajas de nuestro convertidor
            </h2>
            <h3>Facilidad de uso</h3>
            <p>
              Nuestra herramienta está diseñada para ser intuitiva y fácil de
              usar. Solo necesitas subir tus imágenes, seleccionar el formato
              deseado y hacer clic en convertir. No se requieren conocimientos
              técnicos.
            </p>
            <h3>Conversión por lotes</h3>
            <p>
              Convierte múltiples imágenes simultáneamente, ahorrando tiempo y
              esfuerzo cuando necesitas procesar varios archivos.
            </p>
            <h3>Mantiene la calidad</h3>
            <p>
              Nuestro convertidor utiliza algoritmos avanzados para mantener la
              mejor calidad posible durante la conversión, asegurando que tus
              imágenes se vean excelentes en su nuevo formato.
            </p>
            <h2 className="article-subtitleh2">
              Consejos para elegir el formato correcto
            </h2>
            <ul>
              <li>
                JPG/JPEG: Ideal para fotografías y imágenes con muchos colores.
                Es el formato más común para fotografías digitales y ofrece una
                buena compresión manteniendo una calidad visual aceptable.
              </li>
              <li>
                PNG: Mejor opción cuando necesitas transparencia o para imágenes
                con texto, gráficos o bordes definidos. También es excelente
                para capturas de pantalla y gráficos con áreas de color sólido.
              </li>
            </ul>
          </article>
        </div>

        <div className="customer-logo-container">
          MÁS DE 20,000 USUARIOS YA CONFÍAN EN NUESTRO CONVERTIDOR
          <CustomerLogos />
        </div>
      </section>
    </section>
  );
};

export default FreeImageFormatConverterEs;
