// RenderEs.js
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

//Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import VideoPricingIcon from "./Icons/VideoPricingIcon.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import BathroomIcon from "./Icons/hero-icons/BathroomIcon.jsx";
import BedIcon from "./Icons/hero-icons/BedIcon.jsx";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";

//Images
import Bouchra from "./Images/Bouchra image.png";
import PaulaAharonian from "./Images/Paula Aharonian image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import RomainBury from "./Images/Romain Bury.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import NachoGarcia from "./Images/Nacho Garcia image.png";
import PurpleFurnishIcon from "./Icons/hero-icons/PurpleFurnishIcon.jsx";
import PurpleEnhanceIcon from "./Icons/hero-icons/PurpleEnhanceIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";
import BeforeFurnish from "./Images/curtain-effect-hero/BeforeFurnish2.png";
import AfterFurnish from "./Images/curtain-effect-hero/AfterFurnish2.png";
import BeforeBedroomStaging from "./Images/curtain-effect-hero/BeforeBedroomStaging.png";
import AfterBedroomStaging from "./Images/curtain-effect-hero/AfterBedroomStaging.png";
import BeforeTerraceStaging from "./Images/curtain-effect-hero/BeforeTerraceStaging.png";
import AfterTerraceStaging from "./Images/curtain-effect-hero/AfterTerraceStaging.png";
import BeforeBathroomStaging from "./Images/curtain-effect-hero/BeforeBathroomStaging.png";
import AfterBathroomStaging from "./Images/curtain-effect-hero/AfterBathroomStaging.png";
import BeforeStagingExample1 from "./Images/curtain-effect/BeforeStagingExample1.png";
import AfterStagingExample1 from "./Images/curtain-effect/AfterStagingExample1.png";
import BeforeStagingExample2 from "./Images/curtain-effect/BeforeStagingExample2.png";
import AfterStagingExample2 from "./Images/curtain-effect/AfterStagingExample2.png";
import BeforeStagingExample3 from "./Images/curtain-effect/BeforeStagingExample3.png";
import AfterStagingExample3 from "./Images/curtain-effect/AfterStagingExample3.png";
import BeforeStagingExample4 from "./Images/curtain-effect/BeforeStagingExample4.png";
import AfterStagingExample4 from "./Images/curtain-effect/AfterStagingExample4.png";
import BeforeStagingExample5 from "./Images/curtain-effect/BeforeStagingExample5.png";
import AfterStagingExample5 from "./Images/curtain-effect/AfterStagingExample5.png";
import BeforeStagingExample6 from "./Images/curtain-effect/BeforeStagingExample6.png";
import AfterStagingExample6 from "./Images/curtain-effect/AfterStagingExample6.png";
import BeforeStagingExample7 from "./Images/curtain-effect/BeforeStagingExample7.png";
import AfterStagingExample7 from "./Images/curtain-effect/AfterStagingExample7.png";
import VirtualStagingExample1 from "./Images/VirtualStagingExample1.png";
import VirtualStagingExample2 from "./Images/VirtualStagingExample2.png";
import VirtualStagingExample3 from "./Images/VirtualStagingExample3.png";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function HomeStagingEs() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);
  const [FAQ5, setFAQ5] = useState(false);
  const [FAQ6, setFAQ6] = useState(false);

  // Images in the staging examples hero

  const [example, setExample] = useState({
    name: "Furnish",
    beforeImage: BeforeFurnish,
    afterImage: AfterFurnish,
  });

  const handleExample = (example) => {
    if (example === "Furnish") {
      setExample({
        name: "Furnish",
        beforeImage: BeforeFurnish,
        afterImage: AfterFurnish,
      });
    } else if (example === "Bedroom") {
      setExample({
        name: "Bedroom",
        beforeImage: BeforeBedroomStaging,
        afterImage: AfterBedroomStaging,
      });
    } else if (example === "Terrace") {
      setExample({
        name: "Terrace",
        beforeImage: BeforeTerraceStaging,
        afterImage: AfterTerraceStaging,
      });
    } else {
      setExample({
        name: "Bathroom",
        beforeImage: BeforeBathroomStaging,
        afterImage: AfterBathroomStaging,
      });
    }
  };

  // Images in the staging examples body

  const stagingExamples = [
    {
      name: "stagingExample1",
      image: BeforeStagingExample1,
    },
    {
      name: "stagingExample2",
      image: BeforeStagingExample2,
    },
    {
      name: "stagingExample3",
      image: BeforeStagingExample3,
    },
    {
      name: "stagingExample4",
      image: BeforeStagingExample4,
    },
    {
      name: "stagingExample5",
      image: BeforeStagingExample5,
    },
    {
      name: "stagingExample6",
      image: BeforeStagingExample6,
    },
    {
      name: "stagingExample7",
      image: BeforeStagingExample7,
    },
  ];

  const [stagingExample, setStagingExample] = useState({
    name: "stagingExample1",
    beforeImage: BeforeStagingExample1,
    afterImage: AfterStagingExample1,
  });

  const handleStagingExample = (example) => {
    if (example === "stagingExample1") {
      setStagingExample({
        name: "stagingExample1",
        beforeImage: BeforeStagingExample1,
        afterImage: AfterStagingExample1,
      });
    } else if (example === "stagingExample2") {
      setStagingExample({
        name: "stagingExample2",
        beforeImage: BeforeStagingExample2,
        afterImage: AfterStagingExample2,
      });
    } else if (example === "stagingExample3") {
      setStagingExample({
        name: "stagingExample3",
        beforeImage: BeforeStagingExample3,
        afterImage: AfterStagingExample3,
      });
    } else if (example === "stagingExample4") {
      setStagingExample({
        name: "stagingExample4",
        beforeImage: BeforeStagingExample4,
        afterImage: AfterStagingExample4,
      });
    } else if (example === "stagingExample5") {
      setStagingExample({
        name: "stagingExample5",
        beforeImage: BeforeStagingExample5,
        afterImage: AfterStagingExample5,
      });
    } else if (example === "stagingExample6") {
      setStagingExample({
        name: "stagingExample6",
        beforeImage: BeforeStagingExample6,
        afterImage: AfterStagingExample6,
      });
    } else if (example === "stagingExample7") {
      setStagingExample({
        name: "stagingExample7",
        beforeImage: BeforeStagingExample7,
        afterImage: AfterStagingExample7,
      });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "¿Qué es home staging virtual?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "El home staging virtual es una técnica de marketing inmobiliario que utiliza herramientas digitales para preparar y presentar una propiedad de manera atractiva a posibles compradores o inquilinos. A diferencia del home staging tradicional, que implica cambios físicos en la propiedad, el home staging virtual se realiza mediante la edición de imágenes y renderizados digitales para mejorar la apariencia de los espacios.",
        },
      },
      {
        "@type": "Question",
        name: "¿En qué consiste el home staging virtual?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "El home staging virtual consiste en editar imágenes de la propiedad usando herramientas digitales. Esto incluye: \n\n- Edición de imágenes: Retoque y mejora de fotos mediante IA, eliminando objetos no deseados, ajustando la iluminación, y añadiendo muebles o decoración virtuales. \n\n- Renderizado de diseño: Creación de representaciones digitales que muestran cómo podría lucir la propiedad decorada o renovada. \n\n- Ahorro de tiempo y dinero: Es más rápido y económico que el home staging tradicional, ya que no requiere cambios físicos en la propiedad.",
        },
      },
      {
        "@type": "Question",
        name: "¿Qué pasos incluye el proceso de Home Staging Virtual?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "El proceso de home staging virtual incluye los siguientes pasos: \n\n1. Subir las fotos del proyecto. \n\n2. Seleccionar las opciones de edición de home staging virtual, como vaciar habitaciones amuebladas o añadir muebles virtuales. \n\n3. Descargar el proyecto una vez finalizados los cambios.",
        },
      },
      {
        "@type": "Question",
        name: "¿Cuánto tiempo lleva completar un proyecto de Home Staging Virtual?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "El tiempo de completar un proyecto de home staging virtual depende de la cantidad de imágenes y del tiempo que se tarde en editarlas. Sin embargo, el tiempo de subir archivos y descargar proyectos es rápido, y los resultados se obtienen al instante.",
        },
      },
      {
        "@type": "Question",
        name: "¿Se pueden realizar Home Staging Virtual en propiedades vacías?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "¡Sí! El home staging virtual es ideal para propiedades vacías. Puedes amueblarlas con IA o de manera manual, añadiendo los muebles que desees, lo que ayuda a visualizar el potencial de los espacios y mejora la presentación de la propiedad.",
        },
      },
    ],
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Home staging virtual</h1>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2
                  style={{
                    fontSize: "20px",
                    marginTop: "12px",
                  }}
                >
                  El mejor home staging virtual en tus manos
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Rápido:</strong> Resultados en segundos
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Fácil:</strong> Intuitivo desde el principio
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Calidad:</strong> Resultados hiperrealistas
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Ayuda:</strong> Asistencia humana si es necesario
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Prueba Pedra <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        29€ por 100 imágenes
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa, Director of Photography at aProperties"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera, Real Estate Agent at Huspy"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata, CEO at Actívox"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart, CEO at Gilart Consultors"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      ></img>
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        100+ testimonios
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore={"Antes del home staging virtual"}
                  altAfter={"Después del home staging virtual"}
                />

                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Furnish" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Furnish")}
                    >
                      <PurpleFurnishIcon />
                      Salón
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Bedroom" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Bedroom")}
                    >
                      <BedIcon />
                      Dormitorio
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Bathroom" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Bathroom")}
                    >
                      <BathroomIcon />
                      Baño
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Terrace" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Terrace")}
                    >
                      <PurpleEnhanceIcon />
                      Terraza
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          MÁS DE 20,000 PROFESIONALES INMOBILIARIOS YA CONFÍAN EN PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Home stagings virtuales para{" "}
          <span
            style={{
              color: "rgb(248, 121, 42)",
              fontWeight: "bold",
            }}
          >
            vender más rápido
          </span>{" "}
          cualquier tipo de propiedad.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h3 className="feature-title">
                  Amuebla virtualmente en 1 click
                </h3>
                <div className="feature-text">
                  Crea un home statging automático en 1 click
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">Pies de foto</h3>
                <div className="feature-text">
                  Indica que tu imagen es un home staging virtual con tu propio
                  texto
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">Mejora y corrige fotos</h3>
                <div className="feature-text">
                  Mejora la calidad y perspectiva de las fotos para un mejor
                  home staging virtual
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h3 className="feature-title">Añade tus muebles</h3>
                <div className="feature-text">
                  Dale personalidad añadiendo tus propios muebles al home
                  staging virtual
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h3 className="feature-title">Renueva virtualmente</h3>
                <div className="feature-text">
                  Crear propuestas de renovación para tus propiedades de segunda
                  mano
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h3 className="feature-title">
                  Borra objetos o vacía espacios
                </h3>
                <div className="feature-text">
                  Elimina objetos o marcas de agua para un home stagings virtual
                  más limpio
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Ejemplos de nuestro home staging virtual.
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="hero-example-container">
          <CurtainEffect
            beforeImage={stagingExample.beforeImage}
            afterImage={stagingExample.afterImage}
            altBefore={"Antes del home staging virtual"}
            altAfter={"Después del home staging virtual"}
          />

          <div style={{ display: "flex", flex: "1 0 0" }}></div>
          <div className="hero-example-buttons-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "6px",
                width: "100%",
              }}
            >
              {stagingExamples.map((item) => (
                <div
                  key={item.name}
                  className={`hero-button ${
                    stagingExample.name === item.name ? "selected" : ""
                  }`}
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    borderRadius: "8px",
                    overflow: "hidden",
                    height: "50px",
                    padding: "0px",
                  }}
                  onClick={() => handleStagingExample(item.name)}
                >
                  <img
                    src={item.image}
                    alt={item.name}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "6px",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container" style={{ display: "inline" }}>
          <span
            style={{
              color: "rgb(248, 121, 42)",
              fontWeight: "bold",
            }}
          >
            100+
          </span>{" "}
          testimonios de nuestro home staging virtual.
        </h3>
      </div>

      <div className="section-testimonials-container">
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={VictorLaviosa}
              alt="Victor Laviosa, Director de Fotografía en aProperties"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Victor Laviosa
              </div>
              <div className="testimonial-author-container-second-title">
                Director de Fotografía en aProperties
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            Pedra está yendo estupendamente. Creo que lo mejor que tiene la
            herramienta es{" "}
            <span className="text-highlight">
              la rapidez con que genera las imágenes.
            </span>
          </q>
          <div className="testimonial-author-container-date">3 July, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={Bouchra}
              alt="Bouchra, Asesor inmobiliario en iad"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Bouchra Benhammi
              </div>
              <div className="testimonial-author-container-second-title">
                Asesor inmobiliario en iad
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            <span className="text-highlight">
              Pedra es el descubrimiento del siglo
            </span>
          </q>
          <div className="testimonial-author-container-date">3 July, 2024</div>
        </div>

        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={RomainBury}
              alt="Romain Bury, Agente Inmobiliario en iad"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Romain Bury
              </div>
              <div className="testimonial-author-container-second-title">
                Agente Inmobiliario en iad
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            Impresionante.
            <div style={{ marginTop: "10px", display: "inline" }}>
              . Además que{" "}
              <span className="text-highlight">
                el espacio en concreto que quería vaciar me parecía más
                complicado por ser profundo, pero lo ha hecho genial.
              </span>
            </div>
          </q>
          <div className="testimonial-author-container-date">
            26 Junio, 2024
          </div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={NachoGarcia}
              alt="Nacho García, Manager en Lucas Fox"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Nacho García
              </div>
              <div className="testimonial-author-container-second-title">
                Manager en Lucas Fox
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            <span className="text-highlight">
              Un par de casas se han vendido gracias a Pedra.
            </span>
            <div style={{ marginTop: "10px", display: "inline" }}>
              Coloqué en foto de portada propuestas de como podría quedar la
              terraza amueblada y muchísima gente empezo a hacer click en el
              anuncio
            </div>
          </q>
          <div className="testimonial-author-container-date">7 Junio, 2024</div>
        </div>

        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={EmanuelaCosma}
              alt="Emanuela Cosma"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Emanuela Cosma
              </div>
              <div className="testimonial-author-container-second-title">
                Agente inmobiliaro
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            <span className="text-highlight">
              Con Pedra conseguí{" "}
              <a
                className="article-link"
                href="https://www.instagram.com/p/C4A6kCmIeMy/"
                rel="nofollow"
              >
                vender un inmueble de €500k
              </a>{" "}
              en menos de un día.{" "}
            </span>{" "}
            El piso que tenía a la venta era un piso A REFORMAR y con Pedra
            conseguí unos renders que permitieron visualizar el potencial de las
            espacios después de reforma pero sin reforma!
          </q>
          <div className="testimonial-author-container-date">2 Junio, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={PaulaAharonian}
              alt="Paula Aharonian, Asesora inmobiliaria en Monapart"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Paula Aharonian
              </div>
              <div className="testimonial-author-container-second-title">
                Asesora inmobiliaria en Monapart
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            <span className="text-highlight">
              Estoy muy satisfecha con los resultados
            </span>
            <div style={{ marginTop: "10px", display: "inline" }}>
              . Además, han ido mejorando el producto según el feedback que les
              he compartido!
            </div>
          </q>
          <div className="testimonial-author-container-date">
            4 de Junio, 2024
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container" style={{ display: "inline" }}>
          ¿Cómo funciona?
        </h3>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASO 1
            </h5>
            <h2>Crea un proyecto y carga una imagen</h2>
            <div className="value-prop-text">
              Carga una o más imágenes a Pedra.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample1}
              alt="Cómo subir archivos para crear un home staging virtual en Pedra – Infografía"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASO 2
            </h5>
            <h2>Elige una opción para crear tu home staging virtual</h2>
            <div className="value-prop-text">
              Sube una imagen y haz clic en la opción adecuada para crear tu
              home staging virtual.
              <p>
                Podrás amueblar espacios, añadir objetos concretos, renovarlos,
                quitar partes y mucho más!
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample2}
              alt="Cómo elegir opciones para crear un home staging virtual – Infografía"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASO 3
            </h5>
            <h2>Espera y descarga el home staging virtual</h2>
            <div className="value-prop-text">
              Los home stagings virtuales pueden tardar de 15 segundos a pocos
              minutos en generarse.
              <p>
                Una vez generados, podrás continuar editándolos o
                descargártelos.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample3}
              alt="Cómo descargar un home staging virtual – Infografía"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Los home staging virtuales qué necesitas por 29€ al mes. <br />{" "}
          <span
            style={{
              color: "rgb(248, 121, 42)",
              fontWeight: "bold",
            }}
          >
            Y sin permanencia.
          </span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Plan Pro
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">29€</div>
              <div className="modal-product-plan-description-text-3">
                por mes
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">Incluye</div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#B15DE1" }}>
                100 generaciones de imagen
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>por mes</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Renovar y redecorar
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Vaciar habitaciones
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Amueblar
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Quitar objetos
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Aumentar resolución
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Cambiar suelos
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Mejorar fotografía
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <VideoPricingIcon /> Generar videos virtuales
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Planos ilimitados
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Compartir proyectos ilimitado
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Añadir marcas de agua
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Soporte ilimitado
            </div>
            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Prueba Pedra
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Cancela cuando quieras.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Compra más imágenes a 0.20€ por imagen si las necesitas.
            </div>
          </div>
        </div>
      </div>

      <div className="long-break"> </div>

      <div className="section-one-column-left">
        <div className="title-container">Preguntas y respuestas</div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">¿Qué es home staging?</h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                El home staging es una técnica de marketing inmobiliario que
                consiste en preparar una propiedad (casa, apartamento, etc.)
                para la venta o alquiler con el objetivo de hacerla más
                atractiva para potenciales compradores o inquilinos. Esto se
                logra mediante la presentación y decoración estratégica del
                espacio para resaltar sus características más positivas y
                minimizar sus defectos.
                <br />
                <br />
                El proceso de home staging implica la limpieza,
                despersonalización y despeje de la propiedad para permitir que
                los posibles compradores o inquilinos visualicen fácilmente cómo
                podría ser vivir en ese espacio. También puede incluir la
                reorganización del mobiliario, la adición de elementos
                decorativos y, en algunos casos, pequeñas renovaciones o
                reparaciones para mejorar la apariencia general de la propiedad.
                <br />
                <br />
                El objetivo del home staging es generar una primera impresión
                positiva en los visitantes y aumentar las probabilidades de
                vender o alquilar la propiedad rápidamente y a un precio
                favorable. Es una estrategia cada vez más popular en el mercado
                inmobiliario debido a su capacidad para mejorar el atractivo
                visual y emocional de una propiedad.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                ¿En qué consiste el home staging virtual?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                <div>
                  El home staging virtual es una variante del home staging
                  tradicional que utiliza herramientas digitales y tecnología
                  para presentar una propiedad de manera atractiva a los
                  posibles compradores o inquilinos. En lugar de realizar
                  cambios físicos en la propiedad, como reorganizar el
                  mobiliario o añadir elementos decorativos, el home staging
                  virtual se lleva a cabo principalmente mediante las siguientes
                  formas:
                </div>
                <h4>Edición de imágenes:</h4>Con Pedra podrás editar las
                imágenes con IA para retocar y mejorar las fotografías de la
                propiedad. Esto puede implicar la eliminación de objetos no
                deseados, ajustes en la iluminación y el color, e incluso la
                adición de muebles virtuales o decoración.
                <br />
                <br />
                <h4>Renderizado de diseño:</h4> Crea representaciones digitales
                de cómo podría lucir la propiedad después de ser decorada o
                renovada. Esto puede ayudar a los compradores a visualizar el
                potencial del espacio y a inspirar ideas para su propia
                decoración.
                <br />
                <br />
                <h4>Ahorro de tiempo y dinero:</h4>El home staging virtual es
                más rápido y económico que el home staging tradicional, ya que
                no requiere la contratación de muebles o accesorios físicos ni
                la realización de cambios físicos en la propiedad. Esto puede
                ser especialmente beneficioso en propiedades vacías o en
                situaciones en las que el tiempo es un factor crítico.
                <br />
                <br />
                <h4>Flexibilidad y personalización:</h4>Con el home staging
                virtual, es posible experimentar con diferentes estilos de
                decoración y configuraciones de espacio de manera rápida y sin
                compromisos. Esto permite adaptar la presentación de la
                propiedad según el mercado objetivo y las preferencias de los
                posibles compradores o inquilinos.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                Consejos de home staging virtual
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                <h4>Conoce a tu público objetivo:</h4>Entender las necesidades y
                preferencias de tu público objetivo te ayudará a crear contenido
                de home staging virtual que resuene con ellos. Considera quiénes
                son los posibles compradores o inquilinos y adapta tu enfoque en
                consecuencia.
                <br />
                <br />
                <h4>Sé honesto y transparente:</h4>Asegúrate de que el contenido
                de home staging virtual refleje con precisión la apariencia y
                las características de la propiedad. Evita la sobreedición o la
                manipulación excesiva de imágenes, ya que esto puede generar
                expectativas poco realistas.
                <br />
                <br />
                <h4>Múltiples ángulos:</h4>Captura la propiedad desde varios
                ángulos para proporcionar una visión completa. Esto permite a
                los espectadores tener una mejor idea de la distribución del
                espacio y las características únicas de la propiedad.
                <br />
                <br />
                <h4>Mantén la consistencia estilística:</h4>Utiliza un estilo de
                decoración coherente en todas las imágenes y recorridos
                virtuales para crear una experiencia visual uniforme. Esto ayuda
                a mantener el interés de los espectadores y facilita la
                visualización de la propiedad como un todo.
                <br />
                <br />
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ4(!FAQ4)}>
              <h3 className="question-title">Precio home staging virtual</h3>
              {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ4 && (
              <div className="answer-container">
                El precio de Pedra es de 29€ más IVA al mes.
                <br />
                <br />
                Por ese precio podrás generar hasta 100 imágenes al mes.
                <br />
                <br />
                No tiene permanencia, así que puedes darte de baja cuando ya no
                lo necesites.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ5(!FAQ5)}>
              <h3 className="question-title">
                ¿Qué pasos incluye el proceso de Home Staging Virtual?
              </h3>
              {FAQ5 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ5 && (
              <div className="answer-container">
                El proceso de home staging es bastante rápido y sencillo, a
                continuación os dejamos los pasos a seguir para crear con Pedra
                un home staging virtual:
                <br />
                <br />
                1. <strong>Subir las fotos del proyecto:</strong> Al crear un
                proyecto podrás subir una o más imágenes para editarlas.
                <br />
                <br />
                2. <strong>Opciones del Home staging virtual:</strong> Una vez
                selecciones las fotos, dentro del desplegable de "Editar con IA"
                tedndrás todas las opciones que necesitas, desde vaciar
                habitaciones, hasta añadir muebles a tu habitación, renovar las
                estancias y más.
                <br />
                <br />
                3. <strong>Descargar tu proyecto:</strong> Una vez realizado
                todos los cambios y editadas las imágenes solo tendrás que
                descargar las imágenes de tu Home Staging
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ6(!FAQ6)}>
              <h3 className="question-title">
                ¿Se pueden realizar Home Staging Virtual en propiedades vacías?
              </h3>
              {FAQ6 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ6 && (
              <div className="answer-container">
                <p>
                  ¡Sí! Una de las funciones de nuestra herramienta de home
                  staging virtual es amueblar la estancia. Puedes utilizar la
                  opción de "Amueblar" para amueblar el espacio de manera
                  automática con IA, o si lo prefieres de manera manual podrás
                  añadir los muebles que quieras con la opción de "Añadir
                  objeto".
                </p>
                <p>
                  Con cualquiera de estas opciones, nuestra herramienta de Home
                  Staging Virtual te ayuda a visualizar el potencial de tus
                  espacios, facilitando la toma de decisiones y mejorando la
                  presentación de la propiedad.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeStagingEs;
