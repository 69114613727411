import React, { useState } from "react";
import CheckIcon from "../Icons/CheckIcon";
import CustomerLogos from "../CustomerLogos";
import DownloadIcon from "../Icons/DownloadIcon";

const FreeImageFormatConverterFr = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [targetFormat, setTargetFormat] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [convertedFiles, setConvertedFiles] = useState([]);

  const supportedFormats = ["JPG", "PNG", "JPEG"];

  const isValidFileType = (file) => {
    const validTypes = ["image/jpeg", "image/jpg", "image/png"];
    return validTypes.includes(file.type);
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const invalidFiles = files.filter((file) => !isValidFileType(file));

    if (invalidFiles.length > 0) {
      setError(
        `Fichiers non supportés: ${invalidFiles
          .map((f) => f.name)
          .join(", ")}. Seuls les formats JPG, JPEG et PNG sont acceptés.`
      );
      return;
    }

    setSelectedFiles(files);
    setError("");
    setConvertedFiles([]);
  };

  const handleFormatChange = (e) => {
    setTargetFormat(e.target.value);
    setError("");
  };

  const convertImage = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const img = new Image();

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const maxDimension = 4096; // Prevent memory issues with very large images

          let width = img.width;
          let height = img.height;

          // Scale down if image is too large
          if (width > maxDimension || height > maxDimension) {
            const ratio = Math.min(maxDimension / width, maxDimension / height);
            width *= ratio;
            height *= ratio;
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext("2d", { willReadFrequently: true });

          // Set white background for PNG transparency
          if (targetFormat.toLowerCase() !== "png") {
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, canvas.width, canvas.height);
          }

          ctx.drawImage(img, 0, 0, width, height);

          let quality = 0.92;
          if (
            targetFormat.toLowerCase() === "jpg" ||
            targetFormat.toLowerCase() === "jpeg"
          ) {
            quality = 0.92; // High quality for JPG/JPEG
          }

          canvas.toBlob(
            (blob) => {
              if (!blob) {
                reject(new Error("Erreur lors de la conversion"));
                return;
              }
              resolve(blob);
            },
            `image/${targetFormat.toLowerCase()}`,
            quality
          );
        };

        img.onerror = () =>
          reject(new Error("Erreur lors du chargement de l'image"));
        img.src = reader.result;
      };

      reader.onerror = () =>
        reject(new Error("Erreur lors de la lecture du fichier"));
      reader.readAsDataURL(file);
    });
  };

  const handleDownload = async (file) => {
    try {
      const originalFile = selectedFiles.find(
        (f) => f.name === file.originalName
      );
      if (!originalFile) {
        throw new Error("Fichier original introuvable");
      }

      setIsLoading(true);
      const convertedBlob = await convertImage(originalFile);

      // Verify converted file size
      if (convertedBlob.size === 0) {
        throw new Error("Le fichier converti est vide");
      }

      const url = URL.createObjectURL(convertedBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = file.name;

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (err) {
      setError(`Erreur lors du téléchargement de ${file.name}: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadAll = async () => {
    setIsLoading(true);
    try {
      for (const file of convertedFiles) {
        await handleDownload(file);
      }
    } catch (err) {
      setError(
        "Erreur lors du téléchargement de tous les fichiers: " + err.message
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedFiles.length === 0) {
      setError("Veuillez sélectionner au moins un fichier");
      return;
    }
    if (!targetFormat) {
      setError("Veuillez sélectionner un format cible");
      return;
    }

    setIsLoading(true);
    setError("");

    try {
      const newConvertedFiles = selectedFiles.map((file) => ({
        name: `${file.name.split(".")[0]}.${targetFormat.toLowerCase()}`,
        size: file.size,
        originalName: file.name,
      }));

      setConvertedFiles(newConvertedFiles);
    } catch (err) {
      setError("Erreur pendant la conversion: " + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="section-first-wrapper">
      <section className="section-first">
        <div className="section-first-text-container">
          <h1 className="section-first-title">
            Convertisseur de Format d'Image{" "}
            <span className="text-highlight">Gratuit</span>
          </h1>
          <div className="section-first-text free-tool-value-propcontainer">
            <p className="free-tool-value-prop">
              <CheckIcon /> <span>100% gratuit</span>
            </p>
            <p className="free-tool-value-prop">
              <CheckIcon /> <span>Conversion instantanée</span>
            </p>
            <p className="free-tool-value-prop">
              <CheckIcon /> <span>Formats multiples supportés</span>
            </p>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="converter-form">
          <div>
            <label className="free-tool-form">
              <div className="free-tool-form-number-container">
                <div className="free-tool-form-number">1</div>
              </div>{" "}
              Sélectionner les Images
            </label>
            <input
              type="file"
              multiple
              accept="image/*"
              onChange={handleFileChange}
              className="form-input"
            />
          </div>

          <div className="form-group">
            <label className="free-tool-form">
              <div className="free-tool-form-number-container">
                <div className="free-tool-form-number">2</div>
              </div>{" "}
              Format Cible
            </label>
            <select
              value={targetFormat}
              onChange={handleFormatChange}
              className="form-select"
            >
              <option value="">Sélectionner le format</option>
              {supportedFormats.map((format) => (
                <option key={format} value={format}>
                  {format}
                </option>
              ))}
            </select>
          </div>

          <br />

          <button
            type="submit"
            disabled={isLoading}
            className="free-tool-button"
          >
            {isLoading ? "Conversion en cours..." : "Convertir les images"}
          </button>
          <br />

          {convertedFiles.length > 0 && (
            <div className="form-group">
              <div className="converted-files-header">
                <h2 className="converted-files-title">Fichiers Convertis:</h2>
                <button
                  onClick={handleDownloadAll}
                  className="free-tool-button"
                >
                  <DownloadIcon />
                  Télécharger les images
                </button>
              </div>
            </div>
          )}

          {error && (
            <div className="error-message">
              <strong>Erreur:</strong> {error}
            </div>
          )}
        </form>

        <div className="step-container article" style={{ marginTop: "100px" }}>
          <article className="article-text">
            <h2 className="article-subtitleh2">
              Qu'est-ce qu'un convertisseur de format d'image ?
            </h2>
            <p>
              Un convertisseur de format d'image est un outil qui permet de
              transformer des images d'un format à un autre, tout en conservant
              la qualité d'origine. Cet outil est particulièrement utile lorsque
              vous avez besoin d'images dans des formats spécifiques pour
              différentes plateformes ou utilisations.
            </p>
            <h2 className="article-subtitleh2">Formats supportés</h2>
            <p>
              Notre outil prend en charge les formats d'image les plus courants
              et les plus utilisés :
            </p>
            <ul>
              <li>
                JPG/JPEG - Idéal pour les photographies et les images avec
                beaucoup de couleurs
              </li>
              <li>
                PNG - Parfait pour les images nécessitant de la transparence
              </li>
            </ul>
            <h2 className="article-subtitleh2">
              Avantages de notre convertisseur
            </h2>
            <h3>Facilité d'utilisation</h3>
            <p>
              Notre outil est conçu pour être intuitif et facile à utiliser. Il
              vous suffit de télécharger vos images, de sélectionner le format
              souhaité et de cliquer sur convertir. Aucune connaissance
              technique n'est requise.
            </p>
            <h3>Conversion par lots</h3>
            <p>
              Convertissez plusieurs images simultanément, gagnant ainsi du
              temps et des efforts lorsque vous devez traiter plusieurs
              fichiers.
            </p>
            <h3>Maintien de la qualité</h3>
            <p>
              Notre convertisseur utilise des algorithmes avancés pour maintenir
              la meilleure qualité possible pendant la conversion, garantissant
              que vos images paraissent excellentes dans leur nouveau format.
            </p>
            <h2 className="article-subtitleh2">
              Conseils pour choisir le bon format
            </h2>
            <ul>
              <li>
                JPG/JPEG : Idéal pour les photographies et les images avec
                beaucoup de couleurs. C'est le format le plus courant pour les
                photos numériques et offre une bonne compression tout en
                maintenant une qualité visuelle acceptable.
              </li>
              <li>
                PNG : Meilleur choix lorsque vous avez besoin de transparence ou
                pour les images avec du texte, des graphiques ou des bords
                définis. Également excellent pour les captures d'écran et les
                graphiques avec des zones de couleur unie.
              </li>
            </ul>
          </article>
        </div>

        <div className="customer-logo-container">
          PLUS DE 20 000 UTILISATEURS FONT DÉJÀ CONFIANCE À NOTRE CONVERTISSEUR
          <CustomerLogos />
        </div>
      </section>
    </section>
  );
};

export default FreeImageFormatConverterFr;
