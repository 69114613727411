import React from "react";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";

function HowToVirtualStageVideoEs() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            4 Técnicas de Home Staging Virtual en Video | Tutorial 2025
          </h1>

          <div className="title-secondary gray">
            Aprende métodos profesionales con demostración práctica en video
          </div>

          {/* Video Embed */}
          <div
            className="video-container"
            style={{
              margin: "25px 0",
              borderRadius: "12px",
              overflow: "hidden",
              boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
            }}
          >
            <iframe
              width="100%"
              height="450"
              src="https://www.youtube.com/embed/7K7DL3BkWbo"
              title="Video tutorial: 4 métodos prácticos de home staging virtual"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>

          <div className="author-block">
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Especialista en Home Staging Virtual
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Resumen detallado del contenido del video
            </h2>

            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Técnica 1 – </span>
              Renovación completa con preservación de elementos
            </h3>
            <p className="article-text">
              En el video verás cómo realizar una renovación completa
              manteniendo elementos arquitectónicos clave como ventanas y
              estructuras, utilizando el modo de preservación inteligente.
            </p>

            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Técnica 2 – </span>
              Vaciado profesional con retoques finales
            </h3>
            <p className="article-text">
              Demostración paso a paso del proceso de vaciado virtual,
              incluyendo la eliminación de sombras residuales y marcas mediante
              la herramienta de borrado preciso.
            </p>

            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Técnica 3 – </span>
              Reamueblado estratégico de espacios
            </h3>
            <p className="article-text">
              Técnica para reemplazar muebles existentes manteniendo la
              distribución espacial, usando la combinación de herramientas de
              dibujo y selección de estilos.
            </p>

            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Técnica 4 – </span>
              Aplicación de estilos personalizados
            </h3>
            <p className="article-text">
              Método para implementar diseños personalizados usando imágenes de
              referencia externas (como ejemplos de Pinterest) y ajustando los
              parámetros de preservación.
            </p>

            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">Key takeaways del video</h4>
                <ul>
                  <li>
                    Uso combinado de múltiples herramientas en un mismo proyecto
                  </li>
                  <li>
                    Configuración óptima de parámetros para diferentes
                    resultados
                  </li>
                  <li>Flujo de trabajo eficiente para proyectos recurrentes</li>
                  <li>
                    Integración con{" "}
                    <Link
                      to="/es/help/como-anadir-objetos-3d"
                      className="article-link"
                    >
                      objetos 3D personalizados
                    </Link>{" "}
                    para mayor control
                  </li>
                </ul>
              </div>
            </aside>

            <h2 className="article-subtitleh2" style={{ marginTop: "30px" }}>
              Beneficios clave demostrados en el video
            </h2>
            <p className="article-text">
              <strong>✓ Multi-estilos:</strong> Mismo espacio con diferentes
              diseños
              <br />
              <strong>✓ Flexibilidad:</strong> Transición entre vaciado y
              amueblado
              <br />
              <strong>✓ Personalización:</strong> Implementación de estilos
              específicos
              <br />
              <strong>✓ Eficiencia:</strong> Procesos completos en menos de 2
              minutos
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToVirtualStageVideoEs;
