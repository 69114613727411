// HomeDe.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CustomerLogos from "./CustomerLogos.js";
import CurtainEffect from "./CurtainEffect.js";
import GoogleIcon from "./Icons/GoogleIcon.jsx";

// Images

import REMAXLogo from "./Images/customer-logos/customer-logo-remax.png";
import EXPLogo from "./Images/customer-logos/customer-logo-exp.png";
import ENGELLogo from "./Images/customer-logos/customer-logo-agenthia.png";
import HomeDecoration1 from "./Images/HomeDecoration1.png";
import HomeDecoration2 from "./Images/HomeDecoration2.png";
import TitleDecoration1 from "./Images/TitleDecoration1.png";
import FelixIngla from "./Images/Felix Ingla picture.jpeg";
import PepeGomez from "./Images/Pepe Gomez image.png";
import CarmeHuges from "./Images/Carme Huges image.png";
import SoledadTerol from "./Images/Soledad Terol image.png";
import BeforeFurnish from "./Images/curtain-effect-hero/BeforeFurnish.png";
import AfterFurnish from "./Images/curtain-effect-hero/AfterFurnish.png";
import BeforeEmpty from "./Images/curtain-effect-hero/BeforeEmpty.png";
import AfterEmpty from "./Images/curtain-effect-hero/AfterEmpty.png";
import BeforeRenovate from "./Images/curtain-effect-hero/BeforeRenovate.png";
import AfterRenovate from "./Images/curtain-effect-hero/AfterRenovate.png";
import BeforeEnhance from "./Images/curtain-effect-hero/BeforeEnhance.png";
import AfterEnhance from "./Images/curtain-effect-hero/AfterEnhance.png";
import HomePageBlueSkyBefore from "./Images/HomePageBlueSkyBefore.jpg";
import HomePageBlueSkyAfter from "./Images/HomePageBlueSkyAfter.jpg";
import HomePageEmptyBefore from "./Images/HomePageEmptyBefore.jpg";
import HomePageEmptyAfter from "./Images/HomePageEmptyAfter.jpg";
import HomePageEnhanceBefore from "./Images/HomePageEnhanceBefore.jpg";
import HomePageEnhanceAfter from "./Images/HomePageEnhanceAfter.jpg";
import HomePageEnlightBefore from "./Images/HomePageEnlightBefore.jpg";
import HomePageEnlightAfter from "./Images/HomePageEnlightAfter.jpg";
import HomePageFurnishBefore from "./Images/HomePageFurnishBefore.jpg";
import HomePageFurnishAfter from "./Images/HomePageFurnishAfter.jpg";
import HomePagePoolBefore from "./Images/HomePagePoolBefore.jpg";
import HomePagePoolAfter from "./Images/HomePagePoolAfter.jpg";
import HomePageRemoveWatermarkBefore from "./Images/HomePageRemoveWatermarkBefore.jpg";
import HomePageRemoveWatermarkAfter from "./Images/HomePageRemoveWatermarkAfter.jpg";
import HomePageRenovateBefore from "./Images/HomePageRenovateBefore.jpg";
import HomePageRenovateAfter from "./Images/HomePageRenovateAfter.jpg";
import HomePageRenovateExteriorBefore from "./Images/HomePageRenovateExteriorBefore.jpg";
import HomePageRenovateExteriorAfter from "./Images/HomePageRenovateExteriorAfter.jpg";
import HomeDrawFloorPlan from "./Images/HomeDrawFloorPlan.png";
import HomeRendersFromFloorPlan from "./Images/HomeRendersFromFloorPlan.png";
import HomeStep1 from "./Images/HomeStep1.png";
import HomeStep2 from "./Images/HomeStep2.png";
import HomeStep3 from "./Images/HomeStep3.png";
import HeroArrow from "./Images/HeroArrow.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";

// Icons
import OrangePaletteIcon from "./Icons/OrangePaletteIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeVideoIcon from "./Icons/OrangeVideoIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import PurpleEmptyIcon from "./Icons/hero-icons/PurpleEmptyIcon.jsx";
import PurpleFurnishIcon from "./Icons/hero-icons/PurpleFurnishIcon.jsx";
import PurpleEnhanceIcon from "./Icons/hero-icons/PurpleEnhanceIcon.jsx";
import PurpleRenovateIcon from "./Icons/hero-icons/PurpleRenovateIcon.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import VideoPricingIcon from "./Icons/VideoPricingIcon.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

// Update the animation styles
const progressBarStyle = `
  @keyframes progress {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  .fade-transition {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

  .fade-transition.fade-out {
    opacity: 0;
  }
`;

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function HomeDe(locale) {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);
  const [FAQ5, setFAQ5] = useState(false);
  const [FAQ6, setFAQ6] = useState(false);
  const [FAQ7, setFAQ7] = useState(false);
  const [FAQ8, setFAQ8] = useState(false);
  const [FAQ9, setFAQ9] = useState(false);

  const examples = [
    {
      name: "Furnish",
      beforeImage: BeforeFurnish,
      afterImage: AfterFurnish,
      label: "Möblieren",
    },
    {
      name: "Empty",
      beforeImage: BeforeEmpty,
      afterImage: AfterEmpty,
      label: "Leerräumen",
    },
    {
      name: "Renovate",
      beforeImage: BeforeRenovate,
      afterImage: AfterRenovate,
      label: "Renovieren",
    },
    {
      name: "Enhance",
      beforeImage: BeforeEnhance,
      afterImage: AfterEnhance,
      label: "Verschönern",
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // Add styling for header
    const header = document.querySelector("header");
    if (header) {
      // Reset background color first
      header.style.backgroundColor = "#fff";

      // Update based on current path
      if (window.location.pathname.startsWith("/de")) {
        header.style.backgroundColor = "#f7f7f7";
      }
    }

    // Preload all example images
    examples.forEach((example) => {
      const beforeImg = new Image();
      const afterImg = new Image();
      beforeImg.src = example.beforeImage;
      afterImg.src = example.afterImage;
    });

    // Cleanup function to reset header style when component unmounts
    return () => {
      if (header) {
        header.style.backgroundColor = "#fff";
      }
    };
    // eslint-disable-next-line
  }, []); // Re-run when pathname changes

  // Add loading priority to first images
  const [example, setExample] = useState(examples[0]);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [progressKey, setProgressKey] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [intervalId, setIntervalId] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // Start the interval when component mounts
    const newIntervalId = setInterval(() => {
      handleNextImage();
    }, 4000);
    setIntervalId(newIntervalId);

    // Cleanup interval on component unmount
    return () => {
      if (newIntervalId) {
        clearInterval(newIntervalId);
      }
    };
    // eslint-disable-next-line
  }, []); // Empty dependency array means this only runs on mount/unmount

  const handleNextImage = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setExample((currentExample) => {
        const currentIndex = examples.findIndex(
          (ex) => ex.name === currentExample.name
        );
        const nextIndex = (currentIndex + 1) % examples.length;
        return examples[nextIndex];
      });
      setIsTransitioning(false);
      setProgressKey((prev) => prev + 1);
    }, 500);
  };

  return (
    <div>
      <style>{progressBarStyle}</style>

      {/* NEW HERO */}
      <div
        style={{
          textAlign: "center",
          padding: "40px 20px 0px 20px",
          backgroundColor: "#f7f7f7",
        }}
      >
        <h1 className="new-hero-header">
          Zeigen Sie das Potenzial Ihrer Immobilien mit einem Klick
        </h1>

        <h2 className="new-hero-paragraph">
          Erstellen Sie virtuelles Home Staging und verbessern Sie Ihre Fotos in
          Sekunden mit Pedra
        </h2>

        <button
          className="button-top-of-page"
          onClick={redirectToPage}
          style={{
            margin: "0 auto 16px",
            padding: "12px 32px",
            fontSize: "20px",
          }}
        >
          Jetzt starten <ArrowIcon />
        </button>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
            marginBottom: "12px",
          }}
        >
          <div className="new-hero-reviews-container">
            <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
              <GoogleIcon />
              <span
                style={{ color: "#333", fontWeight: "400", fontSize: "16px" }}
              >
                5.0
              </span>
              <div style={{ color: "#FFD700", letterSpacing: "2px" }}>
                ★★★★★
              </div>
            </div>
            <div style={{ marginLeft: "12px" }}>
              <img
                className="testimonials-on-header-image"
                src={VictorLaviosa}
                alt="Victor Laviosa, Director of Photography at aProperties"
              ></img>
              <img
                className="testimonials-on-header-image"
                src={Soma}
                alt="Francisco Somacarrera, Real Estate Agent at Huspy"
              ></img>
              <img
                className="testimonials-on-header-image"
                src={DanieldelaMata}
                alt="Daniel de la Mata, CEO at Actívox"
              ></img>
              <img
                className="testimonials-on-header-image"
                src={MariaJoseGilart}
                alt="Maria José Gilart, CEO at Gilart Consultors"
              ></img>
              <img
                className="testimonials-on-header-image"
                src={EmanuelaCosma}
                alt="Emanuela Cosma"
              ></img>
            </div>
            <span style={{ color: "#333" }}> Über 20.000 vertrauen uns</span>
          </div>
        </div>

        {/* Rest of the hero section with images */}
        <div className="hero-images-container">
          <img
            src={example.beforeImage}
            alt={`Before virtually ${example.name.toLowerCase()}ing the room`}
            className={`hero-before-image fade-transition ${
              isTransitioning ? "fade-out" : ""
            }`}
            loading="eager"
            fetchPriority="high"
            decoding="async"
          />
          <div className="image-arrow-container">
            <img
              src={HeroArrow}
              alt="Arrow icon"
              className="image-arrow"
              loading="eager"
              fetchPriority="high"
            />
          </div>
          <div className="hero-after-image">
            <img
              src={example.afterImage}
              alt={`After virtually ${example.name.toLowerCase()}ing the room`}
              className={`fade-transition ${isTransitioning ? "fade-out" : ""}`}
              loading="eager"
              fetchPriority="high"
              decoding="async"
              style={{
                width: "100%",
                borderRadius: "8px 8px 0 0",
                borderTop: "3px solid #f8792a",
                borderLeft: "3px solid #f8792a",
                borderRight: "3px solid #f8792a",
                display: "block",
              }}
            />
            <div
              className={`hero-loader fade-transition ${
                isTransitioning ? "fade-out" : ""
              }`}
              style={{
                position: "absolute",
                bottom: "16px",
                left: "16px",
                padding: "8px 12px 8px 10px",
                display: "flex",
                overflow: "hidden",
                cursor: "default",
              }}
            >
              <div
                key={progressKey}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "100%",
                  background:
                    "linear-gradient(to right, rgba(248, 121, 42, 0.1), rgba(248, 121, 42, 0.1))",
                  transform: "translateX(-100%)",
                  animation: "progress 4s linear infinite",
                }}
              />
              {example.name === "Furnish" && <PurpleFurnishIcon />}
              {example.name === "Empty" && <PurpleEmptyIcon />}
              {example.name === "Renovate" && <PurpleRenovateIcon />}
              {example.name === "Enhance" && <PurpleEnhanceIcon />}
              <span style={{ position: "relative", zIndex: 1 }}>
                {example.label}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        className="section-one-column-left"
        style={{ margin: "0px 24px", padding: "0px 24px" }}
      >
        <div className="customer-logo-container" style={{ color: "#878787" }}>
          Diese Immobilienunternehmen vertrauen bereits auf Pedra:
          <CustomerLogos />
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#f7f7f7",
          paddingTop: "40px",
          paddingBottom: "40px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src={HomeDecoration1}
          alt="Pedra Website Dekoration"
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            maxWidth: "1200px",
            opacity: 1,
            pointerEvents: "none",
            zIndex: 0,
          }}
        />
        <div
          style={{
            position: "relative",
            zIndex: 1,
          }}
        >
          <div className="section-one-column-left">
            <div className="title-container">
              <h2
                className="title-container"
                style={{ position: "relative", display: "inline" }}
              >
                Erstellen Sie Bilder, die Ihnen helfen{" "}
                <span
                  style={{
                    position: "relative",
                  }}
                >
                  <img
                    src={TitleDecoration1}
                    alt=""
                    style={{
                      position: "absolute",
                      bottom: "-10px",
                      left: 0,
                      width: "100%",
                      pointerEvents: "none",
                      zIndex: -1,
                    }}
                  />
                  mehr zu verkaufen
                </span>
              </h2>
              <div className="title-secondary">
                Möblieren, renovieren, entrümpeln, verbessern usw. Das virtuelle
                Home Staging Tool, das Sie brauchen, um mit nur einem Klick
                Bilder zu erstellen, die mehr verkaufen.
              </div>
            </div>
          </div>

          <div className="section-one-column-left">
            <div className="feature-container-two-features">
              <div className="feature-container-2-column">
                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageFurnishBefore}
                      afterImage={HomePageFurnishAfter}
                      altBefore={
                        "Vor der Möblierung des Raums mit Pedras virtuellem Home Staging Tool"
                      }
                      altAfter={
                        "Nach der Möblierung des Raums mit Pedras virtuellem Home Staging Tool"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Einen Raum möblieren</h2>
                    <div className="feature-text home">
                      Nutzen Sie Pedras KI für virtuelles Home Staging.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageEmptyBefore}
                      afterImage={HomePageEmptyAfter}
                      altBefore={"Vor der Leerung des Raums mit Pedras KI-Tool"}
                      altAfter={"Nach der Leerung des Raums mit Pedras KI-Tool"}
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Einen Raum leeren</h2>
                    <div className="feature-text home">
                      Nutzen Sie Pedras KI, um Räume mit einem Klick zu leeren.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageRenovateBefore}
                      afterImage={HomePageRenovateAfter}
                      altBefore={
                        "Vor der Renovierung des Raums mit Pedras KI-Tool"
                      }
                      altAfter={
                        "Nach der Renovierung des Raums mit Pedras KI-Tool"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Räume renovieren</h2>
                    <div className="feature-text home">
                      Nutzen Sie Pedras KI, um Räume automatisch zu renovieren.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-one-column-left">
            <div className="feature-container-two-features">
              <div className="feature-container-2-column">
                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageRenovateExteriorBefore}
                      afterImage={HomePageRenovateExteriorAfter}
                      altBefore={
                        "Vor der Renovierung der Außenansicht mit Pedras virtuellem Home Staging KI-Tool"
                      }
                      altAfter={
                        "Nach der Renovierung der Außenansicht mit Pedras virtuellem Home Staging KI-Tool"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">
                      Außenbereiche renovieren
                    </h2>
                    <div className="feature-text home">
                      Nutzen Sie Pedras virtuelles Home Staging, um Fassaden und
                      Gärten zu transformieren.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePagePoolBefore}
                      afterImage={HomePagePoolAfter}
                      altBefore={
                        "Vor dem virtuellen Home Staging des Pools mit Pedras KI-Tool"
                      }
                      altAfter={
                        "Nach dem virtuellen Home Staging des Pools mit Pedras KI-Tool"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Pools reparieren</h2>
                    <div className="feature-text home">
                      Nutzen Sie Pedras virtuelles Home Staging, um Pools zu
                      visualisieren und Ihren Garten zu transformieren.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageBlueSkyBefore}
                      afterImage={HomePageBlueSkyAfter}
                      altBefore={
                        "Vor dem virtuellen Home Staging des Himmels mit Pedras KI-Tool"
                      }
                      altAfter={
                        "Nach dem virtuellen Home Staging des Himmels mit Pedras KI-Tool"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">
                      Den Himmel blau machen
                    </h2>
                    <div className="feature-text home">
                      Nutzen Sie Pedras virtuelles Home Staging, um den Himmel
                      in Ihren Immobilienfotos zu verbessern.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-one-column-left">
            <div className="feature-container-two-features">
              <div className="feature-container-2-column">
                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageRemoveWatermarkBefore}
                      afterImage={HomePageRemoveWatermarkAfter}
                      altBefore={
                        "Vor dem Entfernen des Wasserzeichens mit Pedras virtuellem Home Staging KI-Tool"
                      }
                      altAfter={
                        "Nach dem Entfernen des Wasserzeichens mit Pedras virtuellem Home Staging KI-Tool"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">
                      Wasserzeichen entfernen
                    </h2>
                    <div className="feature-text home">
                      Nutzen Sie Pedras virtuelles Home Staging, um
                      Wasserzeichen aus Ihren Fotos zu entfernen.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageEnlightBefore}
                      afterImage={HomePageEnlightAfter}
                      altBefore={
                        "Vor der Aufhellung des Raums mit Pedras virtuellem Home Staging KI-Tool"
                      }
                      altAfter={
                        "Nach der Aufhellung des Raums mit Pedras virtuellem Home Staging KI-Tool"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Räume aufhellen</h2>
                    <div className="feature-text home">
                      Nutzen Sie Pedras KI, um die Beleuchtung in Ihren Fotos zu
                      verbessern.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageEnhanceBefore}
                      afterImage={HomePageEnhanceAfter}
                      altBefore={
                        "Vor der Verbesserung von Fotografie und Perspektive mit Pedras virtuellem Home Staging KI-Tool"
                      }
                      altAfter={
                        "Nach der Verbesserung von Fotografie und Perspektive mit Pedras virtuellem Home Staging KI-Tool"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">
                      Fotos verbessern und korrigieren
                    </h2>
                    <div className="feature-text home">
                      Nutzen Sie Pedra, um Perspektiven zu korrigieren und Farbe
                      und Licht in Fotos zu verbessern
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              "Ausgezeichnete virtuelle Home Staging Software, sehr einfach zu
              bedienen mit großartigen Ergebnissen"
            </h2>
            <div className="quote-author-block">
              <img
                className="quote-author-image"
                src={REMAXLogo}
                alt="REMAX Logo für Pedra"
              />
              <img
                className="testimonials-on-header-image"
                src={SoledadTerol}
                alt="Soledad Terol"
              />

              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Soledad Terol</div>
                <div className="quote-author-company">Immobilienmaklerin</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div
        style={{
          backgroundColor: "#f7f7f7",
          paddingTop: "40px",
          paddingBottom: "40px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src={HomeDecoration2}
          alt=""
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            maxWidth: "800px",
            opacity: 1,
            pointerEvents: "none",
            zIndex: 0,
          }}
        />
        <div
          style={{
            position: "relative",
            zIndex: 1,
          }}
        >
          <div className="section-one-column-left">
            <div className="title-container">
              <h2
                className="title-container"
                style={{ position: "relative", display: "inline" }}
              >
                <span style={{ position: "relative" }}>
                  Beschleunigen
                  <img
                    src={TitleDecoration1}
                    alt=""
                    style={{
                      position: "absolute",
                      bottom: "-8px",
                      left: 0,
                      width: "100%",
                      pointerEvents: "none",
                      zIndex: -1,
                    }}
                  />
                </span>{" "}
                Sie jeden Immobilienverkauf
              </h2>
              <div className="title-secondary">
                Ob Sie Immobilien vom Plan, Neubauten oder Bestandsimmobilien
                verkaufen, mit Pedra stellen Sie sicher, dass Sie in den Verkauf
                Ihrer Immobilie investieren können.
              </div>
            </div>
          </div>
          <div className="section-one-column-left">
            <div className="feature-container home">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h2 className="feature-title">
                  Erstellen Sie Renderings aus einem Grundriss-Foto
                </h2>
                <div className="feature-text">
                  Laden Sie ein Grundriss-Bild hoch, um fotorealistische
                  Renderings zu erstellen. Keine architektonische Erfahrung
                  nötig, und Sie haben Ihre Ergebnisse in Sekunden.
                </div>
              </div>
              <img
                src={HomeRendersFromFloorPlan}
                alt="Virtuelle Home Staging Tools mit KI, Grundriss-Renderings - Software Screenshot"
                width="1000"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>

          <div className="section-one-column-left">
            <div className="feature-container-two-features">
              <div className="feature-container-2-column">
                <div className="feature-container-small home">
                  <div className="feature-text-container ">
                    <OrangeVideoIcon />
                    <h2 className="feature-title ">
                      Virtuelle Videos aus Bildern
                    </h2>
                    <div className="feature-text ">
                      Erstellen Sie Berichte und Videos aus einem einzigen Bild,
                      die Ihnen helfen, Ihre Immobilie besser zu verkaufen. Und
                      das Beste: mit nur einem Klick.
                    </div>
                  </div>
                  <div
                    style={{
                      paddingLeft: "30px",
                      paddingTop: "30px",
                      marginBottom: "-8px",
                    }}
                  >
                    <video
                      width="100%"
                      height="auto"
                      playsInline
                      autoPlay
                      muted
                      loop
                      alt="Virtuelle Home Staging Software, virtuelle Videos - Video"
                      style={{
                        borderTopLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                        borderLeft: "1px solid #EEEEEE",
                        borderRight: "1px solid #EEEEEE",
                        borderTop: "1px solid #EEEEEE",
                      }}
                    >
                      <source
                        src="https://pedraimages.s3.eu-west-3.amazonaws.com/HomeVirtualVideo.mp4"
                        type="video/mp4"
                      />
                      Ihr Browser unterstützt das Video-Tag nicht.
                    </video>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-small home">
                  <div className="feature-text-container">
                    <OrangePaletteIcon />
                    <h2 className="feature-title ">
                      Grundrisse in Sekunden erstellen
                    </h2>
                    <div className="feature-text ">
                      Zeichnen Sie einfach Grundrisse, um Ihre
                      Immobilienangebote besser zu positionieren.
                    </div>
                  </div>
                  <img
                    src={HomeDrawFloorPlan}
                    alt="Virtuelles Home Staging Tool, Grundrisse - Software Screenshot"
                    width="600"
                    height="400"
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              "Ich musste eine Wohnung verkaufen, die renovierungsbedürftig war,
              und ich habe Pedra benutzt, um die Renderings in Sekunden zu
              erstellen. Die Ergebnisse waren unglaublich! Es hat mir geholfen,
              sie viel schneller zu verkaufen, jetzt empfehle ich es allen."
            </h2>
            <div className="quote-author-block">
              <img
                style={{
                  maxWidth: "100px",
                  height: "auto",
                }}
                className="quote-author-image"
                src={EXPLogo}
                alt="EXP Logo für Pedra"
              />
              <img
                className="testimonials-on-header-image"
                src={PepeGomez}
                alt="José Antonio Gómez"
              />
              <div className="quote-author-block-name-title">
                <div className="quote-author-name">José Antonio Gómez</div>
                <div className="quote-author-company">Immobilienmakler</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />

      <div
        style={{
          backgroundColor: "#f7f7f7",
          paddingTop: "40px",
          paddingBottom: "40px",
        }}
      >
        <div className="section-one-column-left">
          <div className="title-container">
            <h2 className="title-container" style={{ position: "relative" }}>
              Wie funktioniert es?
            </h2>
          </div>
        </div>
        <div className="section-one-column-left">
          <div className="feature-container-two-features">
            <div className="feature-container-2-column">
              <div className="feature-container-extra-small">
                <div
                  className="feature-text-container home"
                  style={{ backgroundColor: "#f7f7f7", boxShadow: "none" }}
                >
                  <img
                    src={HomeStep1}
                    alt="Schritt 1 von Pedra"
                    className="feature-text-container home"
                    style={{
                      backgroundColor: "#f7f7f7",
                      boxShadow: "none",
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  <h2 className="feature-title home" style={{ gap: "10px" }}>
                    <div
                      style={{
                        backgroundColor: "#FF8A33",
                        borderRadius: "3px",
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      1
                    </div>
                    Bilder hochladen
                  </h2>
                  <div
                    className="feature-text home"
                    style={{ marginTop: "10px" }}
                  >
                    Laden Sie ein oder mehrere Immobilienbilder hoch.
                  </div>
                </div>
              </div>

              <div className="feature-container-expander"></div>

              <div className="feature-container-extra-small">
                <div
                  className="feature-text-container home"
                  style={{ backgroundColor: "#f7f7f7", boxShadow: "none" }}
                >
                  <img
                    src={HomeStep2}
                    alt="Schritt 2 von Pedra"
                    className="feature-text-container home"
                    style={{
                      backgroundColor: "#f7f7f7",
                      boxShadow: "none",
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  <h2 className="feature-title home" style={{ gap: "10px" }}>
                    <div
                      style={{
                        backgroundColor: "#FF8A33",
                        borderRadius: "3px",
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      2
                    </div>
                    Transformieren
                  </h2>
                  <div
                    className="feature-text home"
                    style={{ marginTop: "10px" }}
                  >
                    Klicken Sie auf "Mit KI bearbeiten", um die Bilder zu
                    transformieren.
                  </div>
                </div>
              </div>

              <div className="feature-container-expander"></div>

              <div className="feature-container-extra-small">
                <div
                  className="feature-text-container home"
                  style={{ backgroundColor: "#f7f7f7", boxShadow: "none" }}
                >
                  <img
                    src={HomeStep3}
                    alt="Schritt 3 von Pedra"
                    className="feature-text-container home"
                    style={{
                      backgroundColor: "#f7f7f7",
                      boxShadow: "none",
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  <h2 className="feature-title home" style={{ gap: "10px" }}>
                    <div
                      style={{
                        backgroundColor: "#FF8A33",
                        borderRadius: "3px",
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      3
                    </div>
                    Warten und herunterladen
                  </h2>
                  <div
                    className="feature-text home"
                    style={{ marginTop: "10px" }}
                  >
                    Laden Sie Ihre KI-bearbeiteten Bilder herunter.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">"Pedra ist wunderbar"</h2>
            <div className="quote-author-block">
              <img
                style={{
                  maxWidth: "150px",
                  height: "auto",
                }}
                className="quote-author-image"
                src={ENGELLogo}
                alt="Engel & Völkers Logo für Pedra"
              />
              <img
                className="testimonials-on-header-image"
                src={CarmeHuges}
                alt="Carme Hugues"
              />

              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Carme Hugues</div>

                <div className="quote-author-company">Immobilienmaklerin</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#f7f7f7",
          paddingTop: "40px",
          paddingBottom: "40px",
        }}
      >
        <div className="section-one-column-left">
          <h2
            className="title-container"
            style={{ maxWidth: "400px", display: "inline" }}
          >
            Für 29€ pro Monat. <br />{" "}
            <span
              style={{
                color: "rgb(248, 121, 42)",
                fontWeight: "bold",
              }}
            >
              Keine Vertragsbindung.
            </span>{" "}
          </h2>
        </div>

        <div className="modal-product-tiers-container">
          <div className="modal-product-tier-current">
            <div className="modal-product-plan-description">
              <div className="modal-product-plan-description-text-1">
                Pro Plan
              </div>
              <div className="modal-product-plan-description-text-2-3-container">
                <div className="modal-product-plan-description-text-2">29€</div>
                <div className="modal-product-plan-description-text-3">
                  pro Monat
                </div>
              </div>
            </div>

            <div className="modal-product-plan-description">
              <div className="modal-product-plan-description-text-4">
                Enthält
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />{" "}
                <span style={{ fontWeight: "500", color: "#B15DE1" }}>
                  100 Bildgenerierungen
                </span>{" "}
                <MagicWandIconPurple />
                <div style={{ marginLeft: "2px" }}>pro Monat</div>
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <HammerIconPricing /> Renovieren und umgestalten
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <BrushIconPricing /> Räume leeren
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <FurnishPricingIcon /> Möblieren
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <RemoveObjectPricingIcon /> Objekte entfernen
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <HDIconPricing /> Auflösung erhöhen
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <WallChangePricingIcon /> Böden ändern
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <PhotographyPricingIcon /> Fotografie verbessern
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <VideoPricingIcon /> Virtuelle Videos generieren
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />
                Unbegrenzte Grundrisse
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />
                Unbegrenztes Projekt-Sharing
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />
                Wasserzeichen hinzufügen
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />
                Unbegrenzter Support
              </div>
              <div className="pricing-modal-button-container">
                <button className="button-top-of-page" onClick={redirectToPage}>
                  Pedra testen
                  <ArrowIcon />
                </button>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                textAlign: "center",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <div>Jederzeit kündbar.</div>
              <div style={{ fontSize: "12px", lineHeight: "100%" }}>
                Kaufen Sie bei Bedarf weitere Bilder für 0,20€ pro Bild.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#F8792A",
          paddingTop: "100px",
          paddingBottom: "100px",
        }}
      >
        <div className="section-first-button-container">
          <div
            style={{
              maxWidth: "600px",
              textAlign: "left",
              gap: "20px",
              paddingLeft: "20px",
            }}
          >
            <button
              className="button-top-of-page"
              onClick={redirectToPage}
              style={{ backgroundColor: "#2383E2", borderColor: "#0077D4" }}
            >
              Pedra testen <ArrowIcon />
            </button>

            <div
              style={{
                color: "#fff",
                fontSize: "20px",
                marginTop: "20px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <img
                className="testimonials-on-header-image"
                src={FelixIngla}
                style={{ marginLeft: "0px" }}
                alt="Gründer von Pedra"
              />
              <div>
                Fragen? Senden Sie uns eine E-Mail an{" "}
                <a
                  href="mailto:felix@pedra.so"
                  style={{
                    color: "#fff",
                    textDecoration: "underline",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                >
                  felix@pedra.so
                </a>{" "}
                oder vereinbaren Sie ein 15-minütiges Demo-Gespräch mit unserem
                Gründer Felix{" "}
                <a
                  href="https://calendar.app.google/W35goQDoST5hwDVL6"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#fff",
                    textDecoration: "underline",
                    paddingLeft: "0px",
                  }}
                >
                  hier
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <div className="section-one-column-left">
        <div className="title-container">Fragen und Antworten</div>
      </div>
      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">Was ist KI-Rendering?</h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                KI-Rendering im Kontext von Architekturdesign und Visualisierung
                bezieht sich auf die Anwendung künstlicher Intelligenz (KI) zur
                Erzeugung hochwertiger gerendeter Bilder von architektonischen
                Räumen. KI wird eingesetzt, um den Rendering-Prozess zu
                verbessern und zu optimieren und ermöglicht realistischere und
                effizientere Ergebnisse.
                <br />
                <br />
                KI im Rendering ermöglicht schnellere Ergebnisse als mit
                herkömmlichen Methoden. Alte Methoden erfordern stunden- oder
                wochenlange Arbeit, da sie die Nachbildung von 3D-Modellen von
                Räumen und die Verarbeitung von Rendering-Systemen erfordern.
                Mit KI hat sich das geändert. Renderings können aus Bildern
                generiert werden und spektakuläre Ergebnisse aus dem
                Originalbild mit neuen angewandten Stilen erstellen.
                <br />
                <br />
                Künstliche Intelligenz beim Rendering von Immobilienbildern
                spart nicht nur Zeit, sondern bietet auch Flexibilität bei der
                Vorstellung von Räumen für Immobilienmakler, Eigentümer und
                Käufer. Die Technologie hilft dabei, das Potenzial von Räumen
                auf eine noch nie dagewesene Weise zu visualisieren.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Was ist virtuelles Home Staging?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                Das{" "}
                <Link className="article-link" to="/es/home-staging-virtual">
                  virtuelle Home Staging
                </Link>{" "}
                ist eine Technik, die im Immobilienmarkt verwendet wird, um eine
                Immobilie potenziellen Käufern oder Mietern attraktiver und
                ansprechender zu präsentieren. Im Gegensatz zum traditionellen
                Home Staging, das die physische Vorbereitung und Dekoration des
                Raums beinhaltet, erfolgt virtuelles Home Staging digital.
                <br />
                <br />
                Beim virtuellen Home Staging werden Tools wie
                3D-Design-Software, virtuelle Realität (VR) und digitale
                Fotografie verwendet, um digitale Darstellungen einer Immobilie
                zu erstellen. Diese Darstellungen können hochwertige gerenderte
                Bilder, virtuelle Rundgänge durch die Immobilie und interaktive
                Visualisierungen umfassen, die zeigen, wie der Raum mit
                verschiedenen Möbel- und Dekorationskonfigurationen aussehen
                würde.
                <br />
                <br />
                Das Ziel des virtuellen Home Stagings ist es, potenziellen
                Käufern oder Mietern eine bessere Vorstellung davon zu
                vermitteln, wie die Immobilie einmal möbliert und dekoriert
                aussehen würde, was das Interesse und die Verkaufs- oder
                Vermietungschancen erhöhen kann. Darüber hinaus kann virtuelles
                Home Staging besonders nützlich sein in Situationen, in denen
                die Immobilie nicht vollständig möbliert oder leer ist, da es
                den Kunden hilft, das Potenzial des Raums zu visualisieren.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">Ist Pedra einfach zu bedienen?</h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Ja, Pedra ist darauf ausgelegt, einfach und schnell zu bedienen
                zu sein.
                <br />
                <br />
                Bei der Erstellung eines Projekts können Sie ein oder mehrere
                Bilder hochladen. Sie können dann Bilder auswählen, wodurch eine
                Optionsleiste angezeigt wird. Zu den Optionen gehören{" "}
                <span style={{ fontWeight: "600px" }}> Mit KI bearbeiten</span>,
                Hinzufügen eines Wasserzeichens, Herunterladen in verschiedenen
                Formaten und mehr. Die Erstellung eines Projekts gibt Ihnen auch
                die Möglichkeit, einen Grundriss zu zeichnen. Der Grundriss
                enthält mehrere Figuren, die Sie durch Auswählen, Verschieben
                oder über das Menü bearbeiten können.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ4(!FAQ4)}>
              <h3 className="question-title">Wie viel kostet Pedra?</h3>
              {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ4 && (
              <div className="answer-container">
                Pedra kostet 29€ plus Mehrwertsteuer pro Monat.
                <br />
                <br />
                Es gibt keine Vertragsbindung, Sie können also jederzeit
                kündigen.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ5(!FAQ5)}>
              <h3 className="question-title">Wie buche ich Pedra?</h3>
              {FAQ5 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ5 && (
              <div className="answer-container">
                Pedra zu buchen ist ganz einfach:
                <br />
                <br />
                1. Besuchen Sie unsere Website unter www.pedra.so/de
                <br />
                2. Klicken Sie auf <strong>"Pedra testen"</strong> zur
                Registrierung
                <br />
                3. Wählen Sie in der Plattform{" "}
                <strong>"Auf Pro upgraden"</strong> und dann{" "}
                <strong>"Abonnieren"</strong>
                <br />
                <br />
                Die Zahlung erfolgt per Kredit- oder Debitkarte. Das Abonnement
                kostet 29€ + MwSt. monatlich und Sie können es jederzeit
                kündigen.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ6(!FAQ6)}>
              <h3 className="question-title">
                Kann ich Pedra vor der Buchung testen?
              </h3>
              {FAQ6 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ6 && (
              <div className="answer-container">
                Natürlich! Senden Sie uns eine E-Mail an felix@pedra.so und wir
                helfen Ihnen gerne dabei, die Plattform kennenzulernen. Wir
                antworten normalerweise innerhalb von 30 Minuten und können eine
                persönliche Demo vereinbaren.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ7(!FAQ7)}>
              <h3 className="question-title">
                Hat das Abonnement eine Mindestlaufzeit?
              </h3>
              {FAQ7 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ7 && (
              <div className="answer-container">
                Nein, unser Abonnement ist völlig flexibel und ohne
                Mindestlaufzeit. Sie können es jederzeit einfach über Ihr Konto
                kündigen:
                <br />
                <br />
                1. Zugriff auf Ihr Profil
                <br />
                2. Wählen Sie <strong>"Abonnement bearbeiten"</strong>
                <br />
                3. Klicken Sie auf <strong>"Abonnement kündigen"</strong>
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ8(!FAQ8)}>
              <h3 className="question-title">
                Was ist das monatliche Bilderlimit?
              </h3>
              {FAQ8 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ8 && (
              <div className="answer-container">
                Ihr Abonnement umfasst 100 Bildgenerierungen pro Monat. Der
                Abrechnungszyklus beginnt am Tag der Buchung von Pedra und
                verlängert sich am gleichen Tag des Folgemonats. Wenn Sie sich
                zum Beispiel am 15. Januar anmelden, läuft Ihr Zyklus vom 15.
                Januar bis zum 14. Februar.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ9(!FAQ9)}>
              <h3 className="question-title">
                Was passiert, wenn ich mehr als 100 Bilder pro Monat benötige?
              </h3>
              {FAQ9 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ9 && (
              <div className="answer-container">
                Sie haben zwei Möglichkeiten:
                <br />
                <br />
                1. Warten Sie auf die Erneuerung Ihres monatlichen Zyklus für
                100 neue Generierungen
                <br />
                2. Kaufen Sie zusätzliche Generierungen für nur 0,20€ pro Stück
                <br />
                <br />
                Zusätzliche Generierungen sind einmalige Zahlungen und
                beeinflussen nicht Ihre monatliche Gebühr. Außerdem verfallen
                sie nicht, sodass Sie sie ansammeln und bei Bedarf nutzen
                können.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeDe;
