import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

// Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";

// Images (you'll need to update these with your bedroom images)
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import BeforeBedroom from "./Images/curtain-effect-hero/BeforeBedroom.png";
import AfterBedroom from "./Images/curtain-effect-hero/AfterBedroom.png";
import BeforeBedroomStaging1 from "./Images/curtain-effect/BeforeBedroomStaging1.png";
import AfterBedroomStaging1 from "./Images/curtain-effect/AfterBedroomStaging1.png";
import BeforeBedroomStaging2 from "./Images/curtain-effect/BeforeBedroomStaging2.png";
import AfterBedroomStaging2 from "./Images/curtain-effect/AfterBedroomStaging2.png";
import BeforeBedroomStaging3 from "./Images/curtain-effect/BeforeBedroomStaging3.png";
import AfterBedroomStaging3 from "./Images/curtain-effect/AfterBedroomStaging3.png";
import VirtualStagingExample1 from "./Images/VirtualStagingExample1.png";
import VirtualStagingExample2 from "./Images/VirtualStagingExample2.png";
import VirtualStagingExample3 from "./Images/VirtualStagingExample3.png";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function RenderBedroomDe() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);

  const [example, setExample] = useState({
    name: "Modern",
    beforeImage: BeforeBedroom,
    afterImage: AfterBedroom,
  });

  const handleExample = (example) => {
    if (example === "Modern") {
      setExample({
        name: "Modern",
        beforeImage: BeforeBedroom,
        afterImage: AfterBedroom,
      });
    } else if (example === "Traditional") {
      setExample({
        name: "Traditional",
        beforeImage: BeforeBedroomStaging1,
        afterImage: AfterBedroomStaging1,
      });
    } else if (example === "Cozy") {
      setExample({
        name: "Cozy",
        beforeImage: BeforeBedroomStaging2,
        afterImage: AfterBedroomStaging2,
      });
    } else {
      setExample({
        name: "Minimalist",
        beforeImage: BeforeBedroomStaging3,
        afterImage: AfterBedroomStaging3,
      });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "Was sind automatische Schlafzimmer-Renderings?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Automatische Schlafzimmer-Renderings sind KI-gestützte Visualisierungen, die Schlafzimmerfotos in professionell gerenderte Bilder umwandeln. Diese Technologie ermöglicht es Ihnen, fotorealistische Visualisierungen von Schlafzimmern mit verschiedenen Stilen, Einrichtungen und Layouts zu erstellen, ohne dass manuelles 3D-Modeling oder komplexe Software erforderlich ist.",
        },
      },
      {
        "@type": "Question",
        name: "Was sind die Vorteile von automatischen Schlafzimmer-Renderings?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Automatische Schlafzimmer-Renderings bieten mehrere Vorteile, darunter: sofortige Transformation von Schlafzimmerräumen, die Möglichkeit, schnell mehrere Designoptionen zu visualisieren, hochwertige fotorealistische Ergebnisse, erhebliche Zeit- und Kosteneinsparungen im Vergleich zum traditionellen Rendering und die Flexibilität, mit verschiedenen Stilen und Konfigurationen zu experimentieren.",
        },
      },
      {
        "@type": "Question",
        name: "Wie funktioniert der automatische Schlafzimmer-Rendering-Prozess?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Der Prozess beinhaltet das Hochladen von Fotos des aktuellen Schlafzimmers auf unsere Plattform, die Auswahl gewünschter Transformationen (wie das Ändern von Oberflächen, Hinzufügen von Möbeln oder Ändern von Layouts) und den Erhalt fotorealistischer Renderings, die das transformierte Schlafzimmer innerhalb von Minuten zeigen. Unsere KI-Technologie übernimmt automatisch alle komplexen Rendering-Arbeiten.",
        },
      },
      {
        "@type": "Question",
        name: "Wie viel kosten automatische Schlafzimmer-Renderings?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Unser automatischer Schlafzimmer-Rendering-Service ist für €29 plus MwSt. pro Monat verfügbar. Dieser Plan ermöglicht es Ihnen, bis zu 100 Renderings pro Monat zu generieren, ohne langfristige Verpflichtung.",
        },
      },
    ],
  };

  const [stagingExample, setStagingExample] = useState({
    name: "stagingExample1",
    beforeImage: BeforeBedroomStaging1,
    afterImage: AfterBedroomStaging1,
  });

  const stagingExamples = [
    {
      name: "stagingExample1",
      beforeImage: BeforeBedroomStaging1,
      afterImage: AfterBedroomStaging1,
    },
    {
      name: "stagingExample2",
      beforeImage: BeforeBedroomStaging2,
      afterImage: AfterBedroomStaging2,
    },
    {
      name: "stagingExample3",
      beforeImage: BeforeBedroomStaging3,
      afterImage: AfterBedroomStaging3,
    },
  ];

  const handleStagingExample = (exampleName) => {
    const selectedExample = stagingExamples.find(
      (example) => example.name === exampleName
    );
    if (selectedExample) {
      setStagingExample(selectedExample);
    }
  };
  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Schlafzimmer-Renderings</h1>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2 style={{ fontSize: "20px", marginTop: "12px" }}>
                  Erstellen Sie fotorealistische Schlafzimmer-Renderings
                  automatisch
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Sofort:</strong> Renderings in Sekunden generieren
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Einfach:</strong> Keine technischen Kenntnisse
                    erforderlich
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Realistisch:</strong> Fotorealistische Ergebnisse
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Support:</strong> Experten-Unterstützung verfügbar
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Pedra testen <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        €29 für 100 Renderings
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa, Direktor für Fotografie bei aProperties"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera, Immobilienmakler bei Huspy"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata, CEO bei Actívox"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart, CEO bei Gilart Consultors"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      ></img>
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        100+ Bewertungen
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore="Vor dem Schlafzimmer-Rendering"
                  altAfter="Nach dem Schlafzimmer-Rendering"
                />

                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Modern" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Modern")}
                    >
                      Modernes Schlafzimmer
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Traditional" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Traditional")}
                    >
                      Traditioneller Stil
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Cozy" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Cozy")}
                    >
                      Gemütliches Design
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Minimalist" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Minimalist")}
                    >
                      Minimalistischer Look
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          VON ÜBER 20.000 PROFIS VERTRAUT
          <CustomerLogos />
        </div>
      </div>
      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Erstellen Sie professionelle Schlafzimmer-Renderings{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            automatisch
          </span>{" "}
          in Sekunden.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h3 className="feature-title">
                  Schlafzimmer sofort umgestalten
                </h3>
                <div className="feature-text">
                  Fotorealistische Renderings mit einem Klick generieren
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">Mehrere Designoptionen</h3>
                <div className="feature-text">
                  Verschiedene Stile und Konfigurationen einfach visualisieren
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">Perfekte Perspektiven</h3>
                <div className="feature-text">
                  Kamerawinkel automatisch korrigieren und optimieren
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h3 className="feature-title">Automatische Beleuchtung</h3>
                <div className="feature-text">
                  Professionelle Lichtanpassungen in jedem Rendering
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h3 className="feature-title">Materialien ändern</h3>
                <div className="feature-text">
                  Oberflächen und Ausführungen automatisch aktualisieren
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h3 className="feature-title">Sauberes Szenen-Setup</h3>
                <div className="feature-text">
                  Unerwünschte Objekte automatisch entfernen
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Beispiele unserer automatischen Schlafzimmer-Renderings.
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="hero-example-container">
          <CurtainEffect
            beforeImage={stagingExample.beforeImage}
            afterImage={stagingExample.afterImage}
            altBefore="Vor dem Schlafzimmer-Rendering"
            altAfter="Nach dem Schlafzimmer-Rendering"
          />

          <div style={{ display: "flex", flex: "1 0 0" }}></div>
          <div className="hero-example-buttons-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "6px",
                width: "100%",
              }}
            >
              {stagingExamples.map((item) => (
                <div
                  key={item.name}
                  className={`hero-button ${
                    stagingExample.name === item.name ? "selected" : ""
                  }`}
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    borderRadius: "8px",
                    overflow: "hidden",
                    height: "50px",
                    padding: "0px",
                  }}
                  onClick={() => handleStagingExample(item.name)}
                >
                  <img
                    src={item.beforeImage}
                    alt={item.name}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "6px",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container" style={{ display: "inline" }}>
          Wie funktionieren automatische Schlafzimmer-Renderings?
        </h3>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              SCHRITT 1
            </h5>
            <h2>Laden Sie Ihr Schlafzimmerfoto hoch</h2>
            <div className="value-prop-text">
              Laden Sie ein beliebiges Foto Ihres Schlafzimmers auf die
              Pedra-Plattform hoch.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample1}
              alt="Wie man Schlafzimmerfotos für automatisches Rendering in Pedra hochlädt – Infografik"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              SCHRITT 2
            </h5>
            <h2>Wählen Sie Ihre Rendering-Optionen</h2>
            <div className="value-prop-text">
              Wählen Sie aus, wie Sie Ihr Schlafzimmer umgestalten möchten.
              <p>
                Ändern Sie Materialien, aktualisieren Sie die Beleuchtung,
                modifizieren Sie das Layout, fügen Sie Möbel hinzu und mehr.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample2}
              alt="Wie man Optionen für automatisches Schlafzimmer-Rendering auswählt – Infografik"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              SCHRITT 3
            </h5>
            <h2>Erhalten Sie Ihre fotorealistischen Renderings</h2>
            <div className="value-prop-text">
              Erhalten Sie Ihre professionell gerenderten Schlafzimmerbilder in
              Sekunden.
              <p>
                Laden Sie Ihre Renderings sofort herunter und verwenden Sie sie.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample3}
              alt="Wie man automatische Schlafzimmer-Renderings herunterlädt – Infografik"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Professionelle Schlafzimmer-Renderings für €29 pro Monat. <br />{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            Keine Verpflichtung erforderlich.
          </span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Pro Plan
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">€29</div>
              <div className="modal-product-plan-description-text-3">
                pro Monat
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">Enthält</div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#B15DE1" }}>
                100 Renderings
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>pro Monat</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Schlafzimmer sofort umgestalten
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Materialien und Oberflächen ändern
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Möbel und Dekoration hinzufügen
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Automatische Szenenbereinigung
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Hochauflösende Ausgabe
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Mehrere Layout-Optionen
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Professionelle Beleuchtung
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unbegrenzte Projekte
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unbegrenztes Teilen
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Benutzerdefinierte Wasserzeichen
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Prioritäts-Support
            </div>
            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Pedra testen
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Jederzeit kündbar.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Zusätzliche Renderings bei Bedarf für je €0,20 verfügbar.
            </div>
          </div>
        </div>
      </div>

      <div className="long-break"> </div>

      <div className="section-one-column-left">
        <div className="title-container">
          Fragen zu automatischen Schlafzimmer-Renderings
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">
                Was sind automatische Schlafzimmer-Renderings?
              </h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Automatische Schlafzimmer-Renderings nutzen fortschrittliche
                KI-Technologie, um gewöhnliche Schlafzimmerfotos in
                professionelle, fotorealistische Visualisierungen zu verwandeln.
                Diese Technologie ermöglicht es Ihnen, hochwertige Renderings
                ohne manuelle 3D-Modellierung oder komplexe Rendering-Software
                zu erstellen.
                <br />
                <br />
                Das System übernimmt automatisch Beleuchtung, Materialien,
                Perspektiven und andere technische Aspekte, um sofort
                professionelle Ergebnisse zu liefern.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Was sind die Vorteile von automatischen Schlafzimmer-Renderings?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                Automatische Schlafzimmer-Renderings bieten zahlreiche Vorteile:
                <br />
                <br />
                1. Geschwindigkeit: Renderings in Sekunden statt Stunden oder
                Tagen
                <br />
                2. Kostengünstig: Keine teure 3D-Modellierungssoftware
                erforderlich
                <br />
                3. Einfache Bedienung: Keine technischen Kenntnisse erforderlich
                <br />
                4. Mehrere Optionen: Testen Sie verschiedene Stile schnell
                <br />
                5. Fotorealistische Ergebnisse: Professionelle Qualität
                <br />
                6. Flexibilität: Sofortige Änderungen möglich
                <br />
                7. Konsistenz: Zuverlässige Ergebnisse jedes Mal
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                Was kann ich mit Pedras Schlafzimmer-Renderings machen?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Pedras automatisches Schlafzimmer-Rendering-System bietet
                umfangreiche Möglichkeiten:
                <br />
                <br />
                1. Renderings erstellen: Erstellen Sie sofort fotorealistische
                Visualisierungen aus jedem Schlafzimmerfoto.
                <br />
                2. Lichtoptimierung: Automatische Verbesserung von Licht und
                Schatten für professionelle Ergebnisse.
                <br />
                3. Materialänderungen: Ändern Sie Wände, Böden, Möbel und andere
                Oberflächen mit einem Klick.
                <br />
                4. Möbel hinzufügen: Fügen Sie automatisch neue Betten,
                Kommoden, Dekoration und Accessoires ein.
                <br />
                5. Szenenbereinigung: Entfernen Sie unerwünschte Objekte und
                Unordnung automatisch.
                <br />
                6. Layout-Visualisierung: Testen Sie schnell verschiedene
                Schlafzimmer-Konfigurationen.
                <br />
                <br />
                Alle Transformationen werden automatisch von unserer KI
                durchgeführt und liefern professionelle Ergebnisse in Sekunden.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RenderBedroomDe;
