// HomeEs.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";
import CurtainEffect from "./CurtainEffect.js";
import GoogleIcon from "./Icons/GoogleIcon.jsx";

// Images

import REMAXLogo from "./Images/customer-logos/customer-logo-remax.png";
import EXPLogo from "./Images/customer-logos/customer-logo-exp.png";
import ENGELLogo from "./Images/customer-logos/customer-logo-agenthia.png";
import HomeDecoration1 from "./Images/HomeDecoration1.png";
import HomeDecoration2 from "./Images/HomeDecoration2.png";
import TitleDecoration1 from "./Images/TitleDecoration1.png";
import FelixIngla from "./Images/Felix Ingla picture.jpeg";
import PepeGomez from "./Images/Pepe Gomez image.png";
import CarmeHuges from "./Images/Carme Huges image.png";
import SoledadTerol from "./Images/Soledad Terol image.png";
import BeforeFurnish from "./Images/curtain-effect-hero/BeforeFurnish.png";
import AfterFurnish from "./Images/curtain-effect-hero/AfterFurnish.png";
import BeforeEmpty from "./Images/curtain-effect-hero/BeforeEmpty.png";
import AfterEmpty from "./Images/curtain-effect-hero/AfterEmpty.png";
import BeforeRenovate from "./Images/curtain-effect-hero/BeforeRenovate.png";
import AfterRenovate from "./Images/curtain-effect-hero/AfterRenovate.png";
import BeforeEnhance from "./Images/curtain-effect-hero/BeforeEnhance.png";
import AfterEnhance from "./Images/curtain-effect-hero/AfterEnhance.png";
import HomePageBlueSkyBefore from "./Images/HomePageBlueSkyBefore.jpg";
import HomePageBlueSkyAfter from "./Images/HomePageBlueSkyAfter.jpg";
import HomePageEmptyBefore from "./Images/HomePageEmptyBefore.jpg";
import HomePageEmptyAfter from "./Images/HomePageEmptyAfter.jpg";
import HomePageEnhanceBefore from "./Images/HomePageEnhanceBefore.jpg";
import HomePageEnhanceAfter from "./Images/HomePageEnhanceAfter.jpg";
import HomePageEnlightBefore from "./Images/HomePageEnlightBefore.jpg";
import HomePageEnlightAfter from "./Images/HomePageEnlightAfter.jpg";
import HomePageFurnishBefore from "./Images/HomePageFurnishBefore.jpg";
import HomePageFurnishAfter from "./Images/HomePageFurnishAfter.jpg";
import HomePagePoolBefore from "./Images/HomePagePoolBefore.jpg";
import HomePagePoolAfter from "./Images/HomePagePoolAfter.jpg";
import HomePageRemoveWatermarkBefore from "./Images/HomePageRemoveWatermarkBefore.jpg";
import HomePageRemoveWatermarkAfter from "./Images/HomePageRemoveWatermarkAfter.jpg";
import HomePageRenovateBefore from "./Images/HomePageRenovateBefore.jpg";
import HomePageRenovateAfter from "./Images/HomePageRenovateAfter.jpg";
import HomePageRenovateExteriorBefore from "./Images/HomePageRenovateExteriorBefore.jpg";
import HomePageRenovateExteriorAfter from "./Images/HomePageRenovateExteriorAfter.jpg";
import HomeDrawFloorPlan from "./Images/HomeDrawFloorPlan.png";
import HomeRendersFromFloorPlan from "./Images/HomeRendersFromFloorPlan.png";
import HomeStep1 from "./Images/HomeStep1.png";
import HomeStep2 from "./Images/HomeStep2.png";
import HomeStep3 from "./Images/HomeStep3.png";
import HeroArrow from "./Images/HeroArrow.png";

// Icons
import OrangePaletteIcon from "./Icons/OrangePaletteIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeVideoIcon from "./Icons/OrangeVideoIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import PurpleEmptyIcon from "./Icons/hero-icons/PurpleEmptyIcon.jsx";
import PurpleFurnishIcon from "./Icons/hero-icons/PurpleFurnishIcon.jsx";
import PurpleEnhanceIcon from "./Icons/hero-icons/PurpleEnhanceIcon.jsx";
import PurpleRenovateIcon from "./Icons/hero-icons/PurpleRenovateIcon.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import VideoPricingIcon from "./Icons/VideoPricingIcon.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";

// Update the animation styles
const progressBarStyle = `
  @keyframes progress {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  .fade-transition {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

  .fade-transition.fade-out {
    opacity: 0;
  }
`;

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function HomeEs(locale) {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);
  const [FAQ5, setFAQ5] = useState(false);
  const [FAQ6, setFAQ6] = useState(false);
  const [FAQ7, setFAQ7] = useState(false);
  const [FAQ8, setFAQ8] = useState(false);
  const [FAQ9, setFAQ9] = useState(false);

  const examples = [
    {
      name: "Furnish",
      beforeImage: BeforeFurnish,
      afterImage: AfterFurnish,
      label: "Amueblar",
    },
    {
      name: "Empty",
      beforeImage: BeforeEmpty,
      afterImage: AfterEmpty,
      label: "Vaciar",
    },
    {
      name: "Renovate",
      beforeImage: BeforeRenovate,
      afterImage: AfterRenovate,
      label: "Renovar",
    },
    {
      name: "Enhance",
      beforeImage: BeforeEnhance,
      afterImage: AfterEnhance,
      label: "Embellecer",
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // Add styling for header
    const header = document.querySelector("header");
    if (header) {
      // Reset background color first
      header.style.backgroundColor = "#fff";

      // Update based on current path
      if (window.location.pathname.startsWith("/es")) {
        header.style.backgroundColor = "#f7f7f7";
      }
    }

    // Preload all example images
    examples.forEach((example) => {
      const beforeImg = new Image();
      const afterImg = new Image();
      beforeImg.src = example.beforeImage;
      afterImg.src = example.afterImage;
    });

    // Cleanup function to reset header style when component unmounts
    return () => {
      if (header) {
        header.style.backgroundColor = "#fff";
      }
    };
    // eslint-disable-next-line
  }, []); // Re-run when pathname changes

  // Add loading priority to first images
  const [example, setExample] = useState(examples[0]);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [progressKey, setProgressKey] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [intervalId, setIntervalId] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // Start the interval when component mounts
    const newIntervalId = setInterval(() => {
      handleNextImage();
    }, 4000);
    setIntervalId(newIntervalId);

    // Cleanup interval on component unmount
    return () => {
      if (newIntervalId) {
        clearInterval(newIntervalId);
      }
    };
    // eslint-disable-next-line
  }, []); // Empty dependency array means this only runs on mount/unmount

  const handleNextImage = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setExample((currentExample) => {
        const currentIndex = examples.findIndex(
          (ex) => ex.name === currentExample.name
        );
        const nextIndex = (currentIndex + 1) % examples.length;
        return examples[nextIndex];
      });
      setIsTransitioning(false);
      setProgressKey((prev) => prev + 1);
    }, 500);
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Pedra",
              "url": "https://pedra.so/es",
              "sameAs": "https://maps.app.goo.gl/uYEqAdxAUWjnucKk9",
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "5.0",
                "reviewCount": "50"
              }
            }
          `}
        </script>
      </Helmet>
      <style>{progressBarStyle}</style>

      {/* NEW HERO */}
      <div
        style={{
          textAlign: "center",
          padding: "40px 20px 0px 20px",
          backgroundColor: "#f7f7f7",
        }}
      >
        <h1 className="new-hero-header">
          Muestra el potencial de tus propiedades en 1 click
        </h1>

        <h2 className="new-hero-paragraph">
          Crea home stagings virtuales y mejora tus fotografías en segundos con
          Pedra
        </h2>

        <button
          className="button-top-of-page"
          onClick={redirectToPage}
          style={{
            margin: "0 auto 16px",
            padding: "12px 32px",
            fontSize: "20px",
          }}
        >
          Empezar <ArrowIcon />
        </button>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
            marginBottom: "12px",
          }}
        >
          <div className="new-hero-reviews-container">
            <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
              <GoogleIcon />
              <span
                style={{ color: "#333", fontWeight: "400", fontSize: "16px" }}
              >
                5.0
              </span>
              <div style={{ color: "#FFD700", letterSpacing: "2px" }}>
                ★★★★★
              </div>
            </div>
            <div style={{ marginLeft: "12px" }}>
              <img
                className="testimonials-on-header-image"
                src={VictorLaviosa}
                alt="Victor Laviosa, Director of Photography at aProperties"
              ></img>
              <img
                className="testimonials-on-header-image"
                src={Soma}
                alt="Francisco Somacarrera, Real Estate Agent at Huspy"
              ></img>
              <img
                className="testimonials-on-header-image"
                src={DanieldelaMata}
                alt="Daniel de la Mata, CEO at Actívox"
              ></img>
              <img
                className="testimonials-on-header-image"
                src={MariaJoseGilart}
                alt="Maria José Gilart, CEO at Gilart Consultors"
              ></img>
              <img
                className="testimonials-on-header-image"
                src={EmanuelaCosma}
                alt="Emanuela Cosma"
              ></img>
            </div>
            <span style={{ color: "#333" }}> 20,000+ confían en nosotros</span>
          </div>
        </div>

        <div className="hero-images-container">
          <img
            src={example.beforeImage}
            alt={`Before virtually ${example.name.toLowerCase()}ing the room`}
            className={`hero-before-image fade-transition ${
              isTransitioning ? "fade-out" : ""
            }`}
            loading="eager"
            fetchPriority="high"
            decoding="async"
          />
          <div className="image-arrow-container">
            <img
              src={HeroArrow}
              alt="Arrow icon"
              className="image-arrow"
              loading="eager"
              fetchPriority="high"
            />
          </div>
          <div className="hero-after-image">
            <img
              src={example.afterImage}
              alt={`After virtually ${example.name.toLowerCase()}ing the room`}
              className={`fade-transition ${isTransitioning ? "fade-out" : ""}`}
              loading="eager"
              fetchPriority="high"
              decoding="async"
              style={{
                width: "100%",
                borderRadius: "8px 8px 0 0",
                borderTop: "3px solid #f8792a",
                borderLeft: "3px solid #f8792a",
                borderRight: "3px solid #f8792a",
                display: "block",
              }}
            />
            <div
              className={`hero-loader fade-transition ${
                isTransitioning ? "fade-out" : ""
              }`}
              style={{
                position: "absolute",
                bottom: "16px",
                left: "16px",
                padding: "8px 12px 8px 10px",
                display: "flex",
                overflow: "hidden",
                cursor: "default",
              }}
            >
              <div
                key={progressKey}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "100%",
                  background:
                    "linear-gradient(to right, rgba(248, 121, 42, 0.1), rgba(248, 121, 42, 0.1))",
                  transform: "translateX(-100%)",
                  animation: "progress 4s linear infinite",
                }}
              />
              {example.name === "Furnish" && <PurpleFurnishIcon />}
              {example.name === "Empty" && <PurpleEmptyIcon />}
              {example.name === "Renovate" && <PurpleRenovateIcon />}
              {example.name === "Enhance" && <PurpleEnhanceIcon />}
              <span style={{ position: "relative", zIndex: 1 }}>
                {example.label}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className="section-one-column-left"
        style={{ margin: "0px 24px", padding: "0px 24px" }}
      >
        <div className="customer-logo-container" style={{ color: "#878787" }}>
          Equipos de estas inmobiliarias ya confían en Pedra:
          <CustomerLogos />
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#f7f7f7",
          paddingTop: "40px",
          paddingBottom: "40px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src={HomeDecoration1}
          alt="Decoración de la web de Pedra"
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            maxWidth: "1200px",
            opacity: 1,
            pointerEvents: "none",
            zIndex: 0,
          }}
        />
        <div
          style={{
            position: "relative",
            zIndex: 1,
          }}
        >
          <div className="section-one-column-left">
            <div className="title-container">
              <h2
                className="title-container"
                style={{ position: "relative", display: "inline" }}
              >
                Consigue imágenes que te ayuden a{" "}
                <span
                  style={{
                    position: "relative",
                  }}
                >
                  vender más
                  <img
                    src={TitleDecoration1}
                    alt=""
                    style={{
                      position: "absolute",
                      bottom: "-10px",
                      left: 0,
                      width: "100%",
                      pointerEvents: "none",
                      zIndex: -1,
                    }}
                  />
                </span>
              </h2>
              <div className="title-secondary">
                Amuebla, renueva, vacía, mejora, etc. la herramienta de home
                staging virtual necesitas para
                <strong> conseguir imágenes que vendan más</strong> en 1 click.
              </div>
            </div>
          </div>

          <div className="section-one-column-left">
            <div className="feature-container-two-features">
              <div className="feature-container-2-column">
                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageFurnishBefore}
                      afterImage={HomePageFurnishAfter}
                      altBefore={
                        "Antes de amueblar la habitación con la herramienta de home staging virtual de Pedra"
                      }
                      altAfter={
                        "Después de amueblar la habitación con la herramienta de home staging virtual de Pedra"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">
                      Amuebla una habitación
                    </h2>
                    <div className="feature-text home">
                      Usa la IA de Pedra para hacer un home staging virtual.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageEmptyBefore}
                      afterImage={HomePageEmptyAfter}
                      altBefore={
                        "Antes de vaciar la habitación con la herramienta de IA de Pedra"
                      }
                      altAfter={
                        "Después de vaciar la habitación con la herramienta de IA de Pedra"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Vacía una habitación</h2>
                    <div className="feature-text home">
                      Usa la IA de Pedra para vaciar habitaciones en 1 click.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageRenovateBefore}
                      afterImage={HomePageRenovateAfter}
                      altBefore={
                        "Antes de renovar la habitación con la herramienta de IA de Pedra"
                      }
                      altAfter={
                        "Después de renovar la habitación con la herramienta de IA de Pedra"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Renueva espacios</h2>
                    <div className="feature-text home">
                      Usa la IA de Pedra para renovar espacios automáticamente.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-one-column-left">
            <div className="feature-container-two-features">
              <div className="feature-container-2-column">
                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageRenovateExteriorBefore}
                      afterImage={HomePageRenovateExteriorAfter}
                      altBefore={
                        "Antes de renovar el exterior con la herramienta de home staging virtual con IA de Pedra"
                      }
                      altAfter={
                        "Después de renovar el exterior con la herramienta de home staging virtual con IA de Pedra"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Renueva exteriores</h2>
                    <div className="feature-text home">
                      Usa el home staging virtual de Pedra para transformar
                      fachadas y jardines.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePagePoolBefore}
                      afterImage={HomePagePoolAfter}
                      altBefore={
                        "Antes del home staging virtual de la piscina con la herramienta de IA de Pedra"
                      }
                      altAfter={
                        "Después del home staging virtual de la piscina con la herramienta de IA de Pedra"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Arregla piscinas</h2>
                    <div className="feature-text home">
                      Usa el home staging virtual de Pedra para visualizar
                      piscinas y transformar tu jardín.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageBlueSkyBefore}
                      afterImage={HomePageBlueSkyAfter}
                      altBefore={
                        "Antes del home staging virtual del cielo con la herramienta de IA de Pedra"
                      }
                      altAfter={
                        "Después del home staging virtual del cielo con la herramienta de IA de Pedra"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Pon el cielo azul</h2>
                    <div className="feature-text home">
                      Usa el home staging virtual de Pedra para mejorar el cielo
                      en tus fotos inmobiliarias.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-one-column-left">
            <div className="feature-container-two-features">
              <div className="feature-container-2-column">
                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageRemoveWatermarkBefore}
                      afterImage={HomePageRemoveWatermarkAfter}
                      altBefore={
                        "Antes de eliminar la marca de agua con la herramienta de home staging virtual con IA de Pedra"
                      }
                      altAfter={
                        "Después de eliminar la marca de agua con la herramienta de home staging virtual con IA de Pedra"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">
                      Elimina marcas de agua
                    </h2>
                    <div className="feature-text home">
                      Usa el home staging virtual de Pedra para eliminar marcas
                      de agua de tus fotos. fotos.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageEnlightBefore}
                      afterImage={HomePageEnlightAfter}
                      altBefore={
                        "Antes de iluminar la habitación con la herramienta de home staging virtual con IA de Pedra"
                      }
                      altAfter={
                        "Después de iluminar la habitación con la herramienta de home staging virtual con IA de Pedra"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Ilumina espacios</h2>
                    <div className="feature-text home">
                      Usa la IA de Pedra para mejorar la iluminación de tus
                      fotos.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageEnhanceBefore}
                      afterImage={HomePageEnhanceAfter}
                      altBefore={
                        "Antes de mejorar la fotografía y la perspectiva con la herramienta de home staging virtual con IA de Pedra"
                      }
                      altAfter={
                        "Antes de mejorar la fotografía y la perspectiva con la herramienta de home staging virtual con IA de Pedra"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">
                      Mejora y corrige fotos
                    </h2>
                    <div className="feature-text home">
                      Usa Pedra para corrige perspectivas y mejorar el color y
                      luz de las fotos
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              "Excelente software para home staging virtual, muy simple de usar
              y con muy buenos resultados"
            </h2>
            <div className="quote-author-block">
              <img
                className="quote-author-image"
                src={REMAXLogo}
                alt="Logotipo de REMAX para Pedra"
              />
              <img
                className="testimonials-on-header-image"
                src={SoledadTerol}
                alt="Foto de Soledad Terol"
              />

              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Soledad Terol</div>
                <div className="quote-author-company">Agente Inmobiliario</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div
        style={{
          backgroundColor: "#f7f7f7",
          paddingTop: "40px",
          paddingBottom: "40px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src={HomeDecoration2}
          alt=""
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            maxWidth: "800px",
            opacity: 1,
            pointerEvents: "none",
            zIndex: 0,
          }}
        />
        <div
          style={{
            position: "relative",
            zIndex: 1,
          }}
        >
          <div className="section-one-column-left">
            <div className="title-container">
              <h2
                className="title-container"
                style={{ position: "relative", display: "inline" }}
              >
                <span style={{ position: "relative" }}>
                  Acelera
                  <img
                    src={TitleDecoration1}
                    alt=""
                    style={{
                      position: "absolute",
                      bottom: "-4px",
                      left: 0,
                      width: "100%",
                      pointerEvents: "none",
                      zIndex: -1,
                    }}
                  />
                </span>{" "}
                cualquier tipo de venta
              </h2>
              <div className="title-secondary">
                Ya si vendes sobre plano, productos de obra nueva o segunda
                mano, con Pedra asegurarás poder invertir en la venta de tu
                inmueble.
              </div>
            </div>
          </div>
          <div className="section-one-column-left">
            <div className="feature-container home">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h2 className="feature-title">
                  Crear renders a partir de la foto de un plano
                </h2>
                <div className="feature-text">
                  Sube la imgaen de un plano para crear render foto realistas.
                  No hace falta ningún tipo de experiencia arquitectónica, y en
                  pocos segundos tendrás tus resultados.
                </div>
              </div>
              <img
                src={HomeRendersFromFloorPlan}
                alt="Herramientas de home staging virtual con IA, renders de planos – Captura de pantalla del software"
                width="1000"
                height="400"
                style={{
                  width: "100%",
                  height: "auto",
                  aspectRatio: "2.5/1",
                }}
              />
            </div>
          </div>

          <div className="section-one-column-left">
            <div className="feature-container-two-features">
              <div className="feature-container-2-column">
                <div className="feature-container-small home">
                  <div className="feature-text-container ">
                    <OrangeVideoIcon />
                    <h2 className="feature-title ">
                      Videos virtuales a partir de imágenes
                    </h2>
                    <div className="feature-text ">
                      Crea reportajes y videos a partir de una imagen que te
                      ayudarán a vender tu propiedad mejor. Y lo mejor de todo,
                      en 1 solo click.
                    </div>
                  </div>
                  <div
                    style={{
                      paddingLeft: "30px",
                      paddingTop: "30px",
                      marginBottom: "-8px",
                    }}
                  >
                    <video
                      width="100%"
                      height="auto"
                      playsInline
                      autoPlay
                      muted
                      loop
                      alt="Software para home staging virtual, videos virtuales – Video"
                      style={{
                        borderTopLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                        borderLeft: "1px solid #EEEEEE",
                        borderRight: "1px solid #EEEEEE",
                        borderTop: "1px solid #EEEEEE",
                      }}
                    >
                      <source
                        src="https://pedraimages.s3.eu-west-3.amazonaws.com/HomeVirtualVideo.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-small home">
                  <div className="feature-text-container">
                    <OrangePaletteIcon />
                    <h2 className="feature-title ">
                      Consigue planos en segundos
                    </h2>
                    <div className="feature-text ">
                      Dibuja planos de manera sencilla para posicionar mejor tus
                      anuncios inmobiliarios.
                    </div>
                  </div>
                  <img
                    src={HomeDrawFloorPlan}
                    alt="Herramienta de home staging virtual, planos de planta – Captura de pantalla del software"
                    width="600"
                    height="400"
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              "Tenía que vender un piso a reformar, y utilicé Pedra para crear
              los renders en cuestión de segundos. ¡Los resultados fueron
              increíbles! Me ayudó a venderlo mucho más rápido, ahora lo
              recomiendo a todo el mundo."
            </h2>
            <div className="quote-author-block">
              <img
                style={{
                  maxWidth: "100px",
                  height: "auto",
                }}
                className="quote-author-image"
                src={EXPLogo}
                alt="Logotipo de EXP para Pedra"
              />
              <img
                className="testimonials-on-header-image"
                src={PepeGomez}
                alt="Foto de José Antonio Gómez"
              />
              <div className="quote-author-block-name-title">
                <div className="quote-author-name">José Antonio Gómez</div>
                <div className="quote-author-company">Agente Inmobiliario</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />

      <div
        style={{
          backgroundColor: "#f7f7f7",
          paddingTop: "40px",
          paddingBottom: "40px",
        }}
      >
        <div className="section-one-column-left">
          <div className="title-container">
            <h2 className="title-container" style={{ position: "relative" }}>
              ¿Cómo funciona?
            </h2>
          </div>
        </div>
        <div className="section-one-column-left">
          <div className="feature-container-two-features">
            <div className="feature-container-2-column">
              <div className="feature-container-extra-small">
                <div
                  className="feature-text-container home"
                  style={{ backgroundColor: "#f7f7f7", boxShadow: "none" }}
                >
                  <img
                    src={HomeStep1}
                    alt="Paso 1 de Pedra"
                    className="feature-text-container home"
                    style={{
                      backgroundColor: "#f7f7f7",
                      boxShadow: "none",
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  <h2 className="feature-title home" style={{ gap: "10px" }}>
                    <div
                      style={{
                        backgroundColor: "#FF8A33",
                        borderRadius: "3px",
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      1
                    </div>
                    Sube las imágenes
                  </h2>
                  <div
                    className="feature-text home"
                    style={{ marginTop: "10px" }}
                  >
                    Sube una o varias imágenes de la propiedad.
                  </div>
                </div>
              </div>

              <div className="feature-container-expander"></div>

              <div className="feature-container-extra-small">
                <div
                  className="feature-text-container home"
                  style={{ backgroundColor: "#f7f7f7", boxShadow: "none" }}
                >
                  <img
                    src={HomeStep2}
                    alt="Paso 2 de Pedra"
                    className="feature-text-container home"
                    style={{
                      backgroundColor: "#f7f7f7",
                      boxShadow: "none",
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  <h2 className="feature-title home" style={{ gap: "10px" }}>
                    <div
                      style={{
                        backgroundColor: "#FF8A33",
                        borderRadius: "3px",
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      2
                    </div>
                    Transforma
                  </h2>
                  <div
                    className="feature-text home"
                    style={{ marginTop: "10px" }}
                  >
                    Haz click en "Editar con IA" para transformar las imágenes.
                  </div>
                </div>
              </div>

              <div className="feature-container-expander"></div>

              <div className="feature-container-extra-small">
                <div
                  className="feature-text-container home"
                  style={{ backgroundColor: "#f7f7f7", boxShadow: "none" }}
                >
                  <img
                    src={HomeStep3}
                    alt="Paso 3 de Pedra"
                    className="feature-text-container home"
                    style={{
                      backgroundColor: "#f7f7f7",
                      boxShadow: "none",
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  <h2 className="feature-title home" style={{ gap: "10px" }}>
                    <div
                      style={{
                        backgroundColor: "#FF8A33",
                        borderRadius: "3px",
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      3
                    </div>
                    Espera y descarga
                  </h2>
                  <div
                    className="feature-text home"
                    style={{ marginTop: "10px" }}
                  >
                    Descarga tus imágenes editadas con IA.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">“Pedra es una maravilla”</h2>
            <div className="quote-author-block">
              <img
                style={{
                  maxWidth: "150px",
                  height: "auto",
                }}
                className="quote-author-image"
                src={ENGELLogo}
                alt="Logotipo de Engel & Völkers para Pedra"
              />
              <img
                className="testimonials-on-header-image"
                src={CarmeHuges}
                alt="Foto de Carme Hugues"
              />

              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Carme Hugues</div>

                <div className="quote-author-company">Agente Inmobiliario</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#f7f7f7",
          paddingTop: "40px",
          paddingBottom: "40px",
        }}
      >
        <div className="section-one-column-left">
          <h2
            className="title-container"
            style={{ maxWidth: "400px", display: "inline" }}
          >
            Por 29€ al mes. <br />{" "}
            <span
              style={{
                color: "rgb(248, 121, 42)",
                fontWeight: "bold",
              }}
            >
              Y sin permanencia.
            </span>{" "}
          </h2>
        </div>

        <div className="modal-product-tiers-container">
          <div className="modal-product-tier-current">
            <div className="modal-product-plan-description">
              <div className="modal-product-plan-description-text-1">
                Plan Pro
              </div>
              <div className="modal-product-plan-description-text-2-3-container">
                <div className="modal-product-plan-description-text-2">29€</div>
                <div className="modal-product-plan-description-text-3">
                  por mes
                </div>
              </div>
            </div>

            <div className="modal-product-plan-description">
              <div className="modal-product-plan-description-text-4">
                Incluye
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />{" "}
                <span style={{ fontWeight: "500", color: "#B15DE1" }}>
                  100 generaciones de imagen
                </span>{" "}
                <MagicWandIconPurple />
                <div style={{ marginLeft: "2px" }}>por mes</div>
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <HammerIconPricing /> Renovar y redecorar
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <BrushIconPricing /> Vaciar habitaciones
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <FurnishPricingIcon /> Amueblar
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <RemoveObjectPricingIcon /> Quitar objetos
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <HDIconPricing /> Aumentar resolución
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <WallChangePricingIcon /> Cambiar suelos
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <PhotographyPricingIcon /> Mejorar fotografía
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <VideoPricingIcon /> Generar videos virtuales
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />
                Planos ilimitados
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />
                Compartir proyectos ilimitado
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />
                Añadir marcas de agua
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />
                Soporte ilimitado
              </div>
              <div className="pricing-modal-button-container">
                <button className="button-top-of-page" onClick={redirectToPage}>
                  Prueba Pedra
                  <ArrowIcon />
                </button>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                textAlign: "center",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <div>Cancela cuando quieras.</div>
              <div style={{ fontSize: "12px", lineHeight: "100%" }}>
                Compra más imágenes a 0.20€ por imagen si las necesitas.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#F8792A",
          paddingTop: "100px",
          paddingBottom: "100px",
        }}
      >
        <div className="section-first-button-container">
          <div
            style={{
              maxWidth: "600px",
              textAlign: "left",
              gap: "20px",
              paddingLeft: "20px",
            }}
          >
            <button
              className="button-top-of-page"
              onClick={redirectToPage}
              style={{ backgroundColor: "#2383E2", borderColor: "#0077D4" }}
            >
              Prueba Pedra <ArrowIcon />
            </button>

            <div
              style={{
                color: "#fff",
                fontSize: "20px",
                marginTop: "20px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <img
                className="testimonials-on-header-image"
                src={FelixIngla}
                style={{ marginLeft: "0px" }}
                alt="Fundador de Pedra"
              />
              <div>
                ¿Preguntas? Envíanos un email a{" "}
                <a
                  href="mailto:felix@pedra.so"
                  style={{
                    color: "#fff",
                    textDecoration: "underline",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                >
                  felix@pedra.so
                </a>{" "}
                o agenda una demo de 15min nuestro fundador Felix{" "}
                <a
                  href="https://calendar.app.google/W35goQDoST5hwDVL6"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#fff",
                    textDecoration: "underline",
                    paddingLeft: "0px",
                  }}
                >
                  aquí
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <div className="section-one-column-left">
        <div className="title-container">Preguntas y respuestas</div>
      </div>
      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">¿Qué es un render IA?</h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Un render IA, en el contexto del diseño y la visualización
                arquitectónica, se refiere a la aplicación de la inteligencia
                artificial (IA) para generar imágenes renderizadas de alta
                calidad de espacios arquitectónicos. La inteligencia artificial
                se utiliza para mejorar y optimizar el proceso de renderizado,
                permitiendo resultados más realistas y eficientes.
                <br />
                <br />
                La IA en el renderizado permite conseguir resultados de manera
                más rápida que con el método tradicional. Lo métodos antiguos
                involucran horas o semanas de trabajo ya que hay que re-crear
                modelos 3D de las habitaciones y procesar los sistemas de
                renderizado. Con la IA esto ha cambiado. Los renders pueden
                generarse a partir de imágenes y crear resultados espectaculares
                a partir de la imagen original con nuevos estilos aplicados.
                <br />
                <br />
                La inteligencia artificial en el renderizado de imágenes de
                propiedades inmobiliarias no solo ahorra tiempo si no que da
                flexibilidad a la hora de imaginar espacios para los
                inmobiliarios, los propietarios y los compradores. La tecnología
                ayuda a visualizar el potencial de los espaciso de una manera
                nunca vista.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                ¿Qué es un home staging virtual?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                El{" "}
                <Link className="article-link" to="/es/home-staging-virtual">
                  home staging virtual
                </Link>{" "}
                es una técnica utilizada en el mercado inmobiliario para
                presentar una propiedad de manera más atractiva y agradable a
                los posibles compradores o arrendatarios, pero a diferencia del
                home staging tradicional, que implica la preparación física y
                decoración del espacio, el home staging virtual se realiza de
                forma digital.
                <br />
                <br />
                En el home staging virtual, se utilizan herramientas como
                software de diseño 3D, realidad virtual (VR) y fotografía
                digital para crear representaciones digitales realistas de una
                propiedad. Estas representaciones pueden incluir imágenes
                renderizadas de alta calidad, recorridos virtuales por la
                propiedad y visualizaciones interactivas que muestran cómo se
                vería el espacio con diferentes configuraciones de muebles y
                decoración.
                <br />
                <br />
                El objetivo del home staging virtual es permitir que los
                posibles compradores o arrendatarios tengan una mejor idea de
                cómo se vería la propiedad una vez amueblada y decorada, lo que
                puede ayudar a aumentar el interés y las posibilidades de venta
                o alquiler. Además, el home staging virtual puede ser
                especialmente útil en situaciones donde la propiedad no está
                completamente amueblada o está vacía, ya que puede ayudar a los
                clientes a visualizar el potencial del espacio.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">¿Es Pedra sencillo de usar?</h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Sí, Pedra esta diseñada para tener un uso sencillo y ágil.
                <br />
                <br />
                Al crear un proyecto podrás subir una o más imágenes. Podrás
                hacer click para seleccionar imágenes, lo cual deplegará una
                barra de opciones. Las opciones incluyen{" "}
                <span style={{ fontWeight: "600px" }}> Editar con IA</span>,
                añadir marca de agua, descargar en varios formatos y más.
                Creando un proyecto también tendrás la opción de dibujar un
                plano. El plano contiene varias figuras, la cuales podrás editar
                seleccionandolas, arranstrandolas o a través del menú.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ4(!FAQ4)}>
              <h3 className="question-title">¿Cuanto cuesta Pedra?</h3>
              {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ4 && (
              <div className="answer-container">
                Pedra cuesta 29€ más IVA al mes.
                <br />
                <br />
                No tiene permanencia, así que puedes darte de baja cuando ya no
                lo necesites.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ5(!FAQ5)}>
              <h3 className="question-title">¿Cómo contrato Pedra?</h3>
              {FAQ5 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ5 && (
              <div className="answer-container">
                Contratar Pedra es muy sencillo:
                <br />
                <br />
                1. Visita nuestra web en www.pedra.so/es
                <br />
                2. Haz clic en <strong>"Prueba Pedra"</strong> para registrarte
                <br />
                3. Una vez dentro de la plataforma, selecciona{" "}
                <strong>"Actualizar a Pro"</strong> y luego{" "}
                <strong>"Suscribirse"</strong>
                <br />
                <br />
                El pago se realiza con tarjeta de crédito o débito. La
                suscripción tiene un coste de 29€ + IVA mensual y puedes
                cancelarla en cualquier momento.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ6(!FAQ6)}>
              <h3 className="question-title">
                ¿Puedo probar Pedra antes de contratarlo?
              </h3>
              {FAQ6 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ6 && (
              <div className="answer-container">
                ¡Por supuesto! Envíanos un email a felix@pedra.so y estaremos
                encantados de ayudarte a conocer la plataforma. Normalmente
                respondemos en menos de 30 minutos y podemos programar una
                demostración personalizada.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ7(!FAQ7)}>
              <h3 className="question-title">
                ¿La suscripción tiene permanencia?
              </h3>
              {FAQ7 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ7 && (
              <div className="answer-container">
                No, nuestra suscripción es totalmente flexible y sin compromisos
                de permanencia. Puedes cancelarla fácilmente cuando lo desees
                directamente desde tu cuenta:
                <br />
                <br />
                1. Accede a tu perfil
                <br />
                2. Selecciona <strong>"Editar suscripción"</strong>
                <br />
                3. Haz clic en <strong>"Cancelar suscripción"</strong>
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ8(!FAQ8)}>
              <h3 className="question-title">
                ¿Cuál es el límite de imágenes por mes?
              </h3>
              {FAQ8 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ8 && (
              <div className="answer-container">
                Tu suscripción incluye 100 generaciones de imágenes por periodo
                mensual. El ciclo de facturación comienza el día que contratas
                Pedra y se renueva el mismo día del mes siguiente. Por ejemplo,
                si te suscribes el 15 de enero, tu ciclo irá del 15 de enero al
                14 de febrero.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ9(!FAQ9)}>
              <h3 className="question-title">
                ¿Qué ocurre si necesito más de 100 imágenes al mes?
              </h3>
              {FAQ9 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ9 && (
              <div className="answer-container">
                Tienes dos opciones:
                <br />
                <br />
                1. Esperar a la renovación de tu ciclo mensual para recibir 100
                nuevas generaciones
                <br />
                2. Comprar generaciones adicionales por solo 0,20€ cada una
                <br />
                <br />
                Las generaciones adicionales son de pago único y no afectan a tu
                cuota mensual. Además, no caducan, por lo que puedes acumularlas
                y usarlas cuando las necesites.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeEs;
