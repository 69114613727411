// Render.js
import React, { useState } from "react";
import CustomerLogos from "./CustomerLogos.js";

//Icons
import RedEyeIcon from "./Icons/RedEyeIcon";
import RedHammerIcon from "./Icons/RedHammerIcon";
import RedMultipleIcon from "./Icons/RedMultipleIcon";
import RedBrushIcon from "./Icons/RedBrushIcon";
import RedDownloadIcon from "./Icons/RedDownloadIcon";
import RedPlantIcon from "./Icons/RedPlantIcon";
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import posterImageVideo from "./Images/poster-images/poster-image-video.png";

//Images
import RenderFeature1 from "./Images/RenderFeature1.png";
import RenderFeature2 from "./Images/RenderFeature2.png";
import RenderFeature3 from "./Images/RenderFeature3.png";
import RenderFeature4 from "./Images/RenderFeature4.png";
import RenderFeature5 from "./Images/RenderFeature5.png";
import RenderFeature6 from "./Images/RenderFeature6.png";
import RenderStep1 from "./Images/RenderStep1.png";
import RenderStep2 from "./Images/RenderStep2.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function RenderDe() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);

  return (
    <div>
      <section className="section-first-wrapper">
        <section className="section-first">
          <div className="section-first-text-container">
            <h1 className="section-first-title">Renderings mit KI</h1>
            <div className="section-first-text">
              Nutzen Sie Pedra, um professionelle Renovierungs-Renderings mit
              künstlicher Intelligenz zu erstellen. Laden Sie einfach Ihr Bild
              hoch und wählen Sie eine Renovierungsoption
            </div>
            <div className="section-first-button-container">
              <button
                className="button-small"
                onClick={redirectToPage}
                style={{
                  backgroundColor: "#EB5757",
                  border: "1px solid #EB5757",
                }}
              >
                Pedra kostenlos testen <ArrowIcon />
              </button>
            </div>
            <div className="testimonials-on-header-container">
              <div className="testimonials-on-header-image-container">
                <img
                  className="testimonials-on-header-image"
                  src={VictorLaviosa}
                  alt="Victor Laviosa, Direktor für Fotografie bei aProperties"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={Soma}
                  alt="Francisco Somacarrera, Immobilienmakler bei Huspy"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={DanieldelaMata}
                  alt="Daniel de la Mata, CEO bei Actívox"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={MariaJoseGilart}
                  alt="Maria José Gilart, CEO bei Gilart Consultors"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={EmanuelaCosma}
                  alt="Emanuela Cosma"
                ></img>
              </div>
              <div>
                <div className="testimonials-on-header-stars">★★★★★</div>
                <p className="testimonials-on-header-text">
                  Über 20.000 Fachleute nutzen bereits Pedra
                </p>
              </div>
            </div>
          </div>
          <div className="header-picture">
            <div className="header-video-frame">
              <div className="header-video-frame-circles-container">
                <div className="header-video-frame-circle"></div>
                <div className="header-video-frame-circle"></div>
                <div className="header-video-frame-circle"></div>
              </div>
              <div className="video-container">
                <video
                  className="header-video"
                  width="100%"
                  height="auto"
                  playsInline
                  autoPlay
                  muted
                  loop
                  preload="auto"
                  poster={posterImageVideo}
                  title="KI-Rendering"
                >
                  <source
                    src="https://pedraimages.s3.eu-west-3.amazonaws.com/Pedra+demo+video+09+Jul+24+English.mp4"
                    type="video/mp4"
                  />
                  Ihr Browser unterstützt das Video-Tag nicht.
                </video>
              </div>
            </div>
          </div>
        </section>
      </section>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          MEHR ALS 20.000 IMMOBILIENPROFIS VERTRAUEN BEREITS AUF PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <h2 className="title-container">Schöne Renderings mit einem Klick</h2>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <RedHammerIcon />
                <h2 className="feature-title">
                  Erstellen Sie Renderings aus einem Bild mit KI
                </h2>
                <div className="feature-text">
                  Generieren Sie ein professionelles Rendering aus einem
                  einzigen Bild
                </div>
              </div>
              <img
                src={RenderFeature1}
                alt="Erstellen Sie erstaunliche Renderings mit KI"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <RedMultipleIcon />
                <h2 className="feature-title">
                  Generieren Sie mehrere Renderings auf einmal
                </h2>
                <div className="feature-text">
                  Sie können auch mehrere Renderings gleichzeitig generieren,
                  indem Sie diese auswählen und eine Renovierungsoption wählen
                </div>
              </div>
              <img
                src={RenderFeature2}
                alt="Erstellen Sie viele Renderings gleichzeitig – Software Screenshot"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <RedEyeIcon />
                <h2 className="feature-title">Wählen Sie Rendering-Stile</h2>
                <div className="feature-text">
                  Wählen Sie einen unserer verschiedenen Rendering-Stile:
                  minimalistisch, skandinavisch, bohemian, etc.
                </div>
              </div>
              <img
                src={RenderFeature3}
                alt="Wählen Sie Rendering-Stile mit künstlicher Intelligenz"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <RedDownloadIcon />
                <h2 className="feature-title">
                  Laden Sie Renderings in jedem Format herunter
                </h2>
                <div className="feature-text">
                  Laden Sie Renderings in PNG-, JPG- oder JPEG-Formaten herunter
                </div>
              </div>
              <img
                src={RenderFeature4}
                alt="Rendering in jedem Format herunterladen – Software Screenshot"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <RedPlantIcon />
                <h2 className="feature-title">
                  Fügen Sie beliebige Objekte mit KI hinzu
                </h2>
                <div className="feature-text">
                  Fügen Sie spezifische Objekte in Räume ein: Pflanzen, Tische,
                  etc.
                </div>
              </div>
              <img
                src={RenderFeature5}
                alt="Beliebige Objekte hinzufügen – Software Screenshot"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <RedBrushIcon />
                <h2 className="feature-title">Entfernen Sie Objekte mit KI</h2>
                <div className="feature-text">
                  Entfernen Sie Teile eines Bildes vor oder nach dem Rendering
                </div>
              </div>
              <img
                src={RenderFeature6}
                alt="Objekte entfernen – Software Screenshot"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <h2 className="title-container">
          Wie erstellt man ein Renovierungs-Rendering mit KI?
        </h2>
      </div>

      <section className="project project-alt">
        <div className="picture">
          <img
            src={RenderStep1}
            alt="Wie man eine Datei hochlädt – Diagramm"
            width="600"
            height="400"
            style={{ width: "100%", height: "auto" }}
          />
        </div>

        <div className="description">
          <h5>SCHRITT 1</h5>
          <h2>
            Erstellen Sie ein neues Projekt und laden Sie ein oder mehrere
            Bilder hoch
          </h2>
        </div>
      </section>

      <section className="project project-alt">
        <div className="picture">
          <img
            src={RenderStep2}
            alt="Wie man ein Rendering erstellt – Diagramm"
            width="600"
            height="400"
            style={{ width: "100%", height: "auto" }}
          />
        </div>

        <div className="description">
          <h5>SCHRITT 2</h5>
          <h2>Wählen Sie eine der Renovierungsoptionen</h2>
          <div className="value-prop-text">
            Die Renovierungsoptionen dauern normalerweise 15 Sekunden. Sie
            können verschiedene Renovierungen gleichzeitig generieren.
          </div>
        </div>
      </section>

      <div className="long-break"> </div>
      <div className="centered-heading">
        <button className="button" onClick={redirectToPage}>
          Pedra testen <ArrowIcon />
        </button>
      </div>
      <br></br>

      <div className="section-one-column-left">
        <div className="title-container">Fragen & Antworten</div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">Was ist ein KI-Rendering?</h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Ein KI-Rendering im Kontext von Architekturdesign und
                Visualisierung bezieht sich auf die Anwendung künstlicher
                Intelligenz (KI) zur Generierung hochwertiger Renderbilder von
                architektonischen Räumen. Künstliche Intelligenz wird verwendet,
                um Räume basierend auf Eingangsbildern neu zu erstellen und
                ermöglicht realistische und präzise Ergebnisse.
                <br />
                <br />
                KI ermöglicht schnellere Ergebnisse als traditionelle
                Rendering-Methoden. Ältere Methoden erforderten die manuelle
                Erstellung von 3D-Modellen der Räume und die Bildverarbeitung
                zur Erstellung beeindruckender Renderings. Mit KI ist dies jetzt
                anders. Wir können von Bildern ausgehen, um neue
                Visualisierungen basierend auf dem Originalbild und einem neu
                angewandten Stil zu erstellen.
                <br />
                <br />
                Künstliche Intelligenz beim Rendering von Immobilienbildern
                spart nicht nur Zeit, sondern bietet dem Makler, den Eigentümern
                und den Käufern eine flexiblere Möglichkeit, sich Räume
                vorzustellen. Die Technologie hilft, das Potenzial von Räumen
                auf eine bisher nie dagewesene Weise schnell zu visualisieren.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Was sind die Vorteile von KI-Renderings?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                <h4>Verbesserte Qualität:</h4>KI-Renderings können visuell
                beeindruckende Ergebnisse mit einem hohen Grad an Realismus
                erzeugen. KI-Algorithmen können Lichteffekte, Schatten und
                Texturen genauer simulieren als traditionelle Methoden.
                <br />
                <br />
                <h4>Rendering-Zeiteffizienz:</h4>Künstliche Intelligenz kann den
                Rendering-Prozess beschleunigen, indem sie die zur Generierung
                eines Bildes benötigten Berechnungen optimiert. Dies kann die
                Rendering-Zeiten erheblich reduzieren, was besonders bei
                Projekten mit engen Terminen von Vorteil ist.
                <br />
                <br />
                <h4>Automatisierung sich wiederholender Aufgaben:</h4>KI kann
                bestimmte Aufgaben im Rendering-Prozess automatisieren. Dies
                gibt Künstlern und Designern mehr Zeit, sich auf kreative und
                konzeptionelle Aspekte zu konzentrieren.
                <br />
                <br />
                <h4>Personalisierung und Anpassungsfähigkeit:</h4>KI-Algorithmen
                können aus spezifischen Mustern und Präferenzen lernen, was die
                Anpassung von Renderings an Projektanforderungen oder
                Kundenwünsche ermöglicht. Dies kann zu maßgeschneideren und
                zufriedenstellenderen Ergebnissen führen.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                Wie funktioniert KI-gestütztes Rendering?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                <h4>Datenerfassung und Modelltraining:</h4>
                <li>
                  Der Prozess beginnt mit der Sammlung von Eingabedaten, die
                  Referenzbilder, 3D-Modelle, Texturen und andere szenenbezogene
                  Elemente umfassen können.
                </li>
                <li>
                  Diese Daten werden verwendet, um ein KI-Modell zu trainieren,
                  normalerweise ein neurales Netzwerk, das lernt, die Merkmale
                  der Eingabe auf die gewünschte Ausgabe, d.h. das gerenderte
                  Bild, abzubilden.
                </li>
                <br />
                <br />
                <h4>Szenenverarbeitung:</h4>
                <li>
                  Sobald das Modell trainiert ist, wird es zur Verarbeitung der
                  zu rendernden Szene verwendet. Dies beinhaltet die Generierung
                  von Pixeln für jeden Punkt des finalen Bildes.
                </li>
                <li>
                  Während dieses Prozesses kann das KI-Modell verschiedene
                  Faktoren wie Beleuchtung, Materialien, Schatten, Texturen und
                  die Geometrie der Szene berücksichtigen.
                </li>
                <br />
                <br />
                <h4>Generierung des gerenderten Bildes:</h4>
                <li>
                  Das KI-Modell generiert das gerenderte Bild Pixel für Pixel
                  und nutzt dabei die während des Trainings gelernten
                  Informationen, um Entscheidungen darüber zu treffen, wie jeder
                  Teil der Szene gerendert werden soll.
                </li>
                <li>
                  Dies kann die Anwendung von globalen Beleuchtungseffekten,
                  Schattierung, Reflexionen, Brechungen und anderen visuellen
                  Effekten umfassen, um die Qualität und den Realismus des
                  finalen Bildes zu verbessern.
                </li>
                <br />
                <br />
                <h4>Verfeinerung und Nachbearbeitung:</h4>
                <li>
                  Sobald das erste Bild generiert ist, kann es einen Prozess der
                  Verfeinerung und Nachbearbeitung durchlaufen, um seine
                  Qualität weiter zu verbessern.
                </li>
                <li>
                  Dies kann die Korrektur visueller Artefakte, die Anwendung von
                  Nachbearbeitungseffekten wie Unschärfe oder Leuchten und
                  andere Techniken zur Verbesserung der Gesamtästhetik des
                  Bildes umfassen.
                </li>
                <br />
                <br />
                <h4>Iteration und Anpassung:</h4>
                <li>
                  Je nach den erzielten Ergebnissen kann der
                  KI-Rendering-Prozess zusätzliche Iterationen beinhalten, bei
                  denen Modellparameter angepasst und Ergebnisse verfeinert
                  werden, bis die gewünschte Qualität und der gewünschte
                  Realismus erreicht sind.
                </li>
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ4(!FAQ4)}>
              <h3 className="question-title">Was kostet Pedra?</h3>
              {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ4 && (
              <div className="answer-container">
                Pedra kostet 29€ plus Mehrwertsteuer pro Monat.
                <br />
                <br />
                Es handelt sich um ein monatliches Abonnement, daher können Sie
                Ihr Abonnement kündigen, wenn Sie es nicht mehr benötigen.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RenderDe;
