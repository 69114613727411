// Home.js
import React, { useState } from "react";
import CustomerLogos from "./CustomerLogos.js";

//Icons
import BlueChairIcon from "./Icons/BlueChairIcon";
import BlueEyeIcon from "./Icons/BlueEyeIcon";
import BlueRulerIcon from "./Icons/BlueRulerIcon";
import BlueSmileIcon from "./Icons/BlueSmileIcon";
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";

//Images
import FloorPlanHeader from "./Images/FloorPlanHeader.png";
import FloorPlanFeature1 from "./Images/FloorPlanFeature1.png";
import FloorPlanFeature2 from "./Images/FloorPlanFeature2.png";
import FloorPlanFeature3 from "./Images/FloorPlanFeature3.png";
import FloorPlanFeature4 from "./Images/FloorPlanFeature4.png";
import FloorPlanStep1 from "./Images/FloorPlanStep1.png";
import FloorPlanStep2 from "./Images/FloorPlanStep2.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function FloorPlan() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);
  return (
    <div>
      <section className="section-first-wrapper">
        <section className="section-first">
          <div className="section-first-text-container">
            <h1 className="section-first-title">
              The simplest floor plan tool
            </h1>
            <div className="section-first-text">
              Simple. Powerful. Draw floor plans in seconds to position your
              properties better.
            </div>
            <div className="section-first-button-container">
              <button
                className="button-small"
                onClick={redirectToPage}
                style={{
                  backgroundColor: "#57A4EB",
                  border: "1px solid #57A4EB",
                }}
              >
                Try Pedra <ArrowIcon />
              </button>
            </div>
            <div className="testimonials-on-header-container">
              <div className="testimonials-on-header-image-container">
                <img
                  className="testimonials-on-header-image"
                  src={VictorLaviosa}
                  alt="Victor Laviosa, Director of Photography at aProperties"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={Soma}
                  alt="Francisco Somacarrera, Real Estate Agent at Huspy"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={DanieldelaMata}
                  alt="Daniel de la Mata, CEO at Actívox"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={MariaJoseGilart}
                  alt="Maria José Gilart, CEO at Gilart Consultors"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={EmanuelaCosma}
                  alt="Emanuela Cosma"
                ></img>
              </div>
              <div>
                <div className="testimonials-on-header-stars">★★★★★</div>
                <p className="testimonials-on-header-text">
                  20,000+ real estate professionals already use Pedra
                </p>
              </div>
            </div>
          </div>
          <div className="header-picture">
            <img
              src={FloorPlanHeader}
              alt="Tool To Make Floor Plan For Real Estae Properties – Software Screenshot"
            ></img>
          </div>
        </section>
      </section>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          MORE THAN 20,000 REAL ESTATE PROFESSIONALS ALREADY TRUST PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <h2 className="title-container">Draw a floor plan with Pedra</h2>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <BlueChairIcon />
                <h2 className="feature-title">Add furniture to floor plan</h2>
                <div className="feature-text">
                  Use furniture layers to show what fits in a space and make it
                  easier for your audience to understand floor plans
                </div>
              </div>
              <img
                src={FloorPlanFeature1}
                alt="Add Furniture To Floor Plan – Software Screenshot"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <BlueEyeIcon />
                <div className="feature-title">
                  Show floor plan measures in 1 click
                </div>
                <div className="feature-text">
                  Decide if to show or hide measures on your floor plans
                </div>
              </div>
              <img
                src={FloorPlanFeature2}
                alt="Show Floor Plan Measures – Software Screenshot"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <BlueSmileIcon />
                <h2 className="feature-title">Easy floor plan tool</h2>
                <div className="feature-text">
                  Pedra’s floor plan tool is the easiest — just drag and drop
                </div>
              </div>
              <img
                src={FloorPlanFeature3}
                alt="Easy Floor Plan Tool – Software Screenshot"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <BlueRulerIcon />
                <h2 className="feature-title">
                  Flexible but simple floor plan
                </h2>
                <div className="feature-text">
                  Pedra’s floor plan is simple yet flexible. Enjoy a lot of
                  different layer types: doors, windows, furniture and edit
                  their parameters
                </div>
              </div>
              <img
                src={FloorPlanFeature4}
                alt="Flexible But Simple Floor Plan Tool – Software Screenshot"
              />
            </div>
          </div>
        </div>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <div className="title-container">How to draw a floor plan?</div>
      </div>

      <section className="project project-alt">
        <div className="picture">
          <img src={FloorPlanStep1} alt="How To Draw A Floor Plan – Diagram" />
        </div>

        <div className="description">
          <h5>STEP 1</h5>
          <h2>Create a new project and select “Draw floor plan”</h2>
        </div>
      </section>

      <section className="project project-alt">
        <div className="picture">
          <img src={FloorPlanStep2} alt="How To Edit A Floor Plan – Diagram" />
        </div>

        <div className="description">
          <h5>STEP 2</h5>
          <h2>Click on layers to add them to the floor plan</h2>
          <div className="value-prop-text">
            To edit the layer’s size, color, or properties you can select them
          </div>
        </div>
      </section>

      <div className="long-break"> </div>
      <div className="centered-heading">
        <button
          className="button"
          onClick={redirectToPage}
          style={{ backgroundColor: "#57A4EB", border: "1px solid #57A4EB" }}
          onMouseOver={(e) => {
            e.target.style.backgroundColor = "#0077D4"; // Darker background color on hover
            e.target.style.borderColor = "#0077D4"; // Darker border color on hover
          }}
          onMouseOut={(e) => {
            e.target.style.backgroundColor = "#57A4EB"; // Restore original background color on mouse out
            e.target.style.borderColor = "#57A4EB"; // Restore original border color on mouse out
          }}
        >
          Try Pedra <ArrowIcon />
        </button>
      </div>
      <br></br>
      <br></br>
      <div className="section-one-column-left">
        <div className="title-container">Questions & answers</div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h2 className="question-title">What are floor plans?</h2>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Floor plans are graphic representations that show a top-down
                view of a building, house, or structure, as if you were looking
                from above downward. These plans are primarily used in
                architecture, interior design, civil engineering, and other
                related fields to visualize the layout and arrangement of spaces
                within a structure.
                <br />
                <br />
                Floor plans typically include details such as room dimensions,
                the location of walls, doors, windows, furniture, and other
                important elements for the planning and construction of a
                building. They are essential for communication among designers,
                architects, engineers, and contractors involved in a
                construction project.
                <br />
                <br />
                Imagine you're looking down from the sky over a building. Floor
                plans capture this perspective, showing the distribution of
                internal spaces, rooms, walls, doors, windows, and other
                structural elements. Essentially, these plans offer a top-down
                view of a building, allowing for a clear understanding of how
                spaces will be organized and utilized.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                What are the types of floor plans?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                <div>
                  There are several types of floor plans, each with its own
                  purpose and focus:
                </div>
                <h4>Ground Floor Plans:</h4>They show the layout of spaces on
                the ground level. This includes areas such as entryways, living
                rooms, kitchens, bedrooms, and garages.
                <br />
                <br />
                <h4>Upper Floor Plans:</h4> They represent the upper levels of a
                building, such as the second floor, attic, or any additional
                levels. These plans are crucial for understanding the vertical
                layout of a building.
                <br />
                <br />
                <h4>Furniture Floor Plans:</h4>They incorporate the placement of
                furniture within the spaces. These plans help interior designers
                and homeowners visualize how the space will look once furnished.
                <br />
                <br />
                <h4>Electrical and Plumbing Layout Plans:</h4>They detail the
                location of electrical and plumbing access points in the
                building. They are essential for ensuring proper installation of
                electrical and plumbing systems.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                What is the process of creating floor plans?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                <h4>Create a new project:</h4>Start creating a floor plan in the
                'Draw floor plan' section.
                <br />
                <br />
                <h4>Add layers:</h4>Add all the layers you need and edit the
                dimensions, color, or properties of the layer.
                <br />
                <br />
                <h4>Furnish it:</h4>Add furniture to the floor plan to visually
                show the available space and how it could be furnished.
                <br />
                <br />
                <h4>Download your floor plan online:</h4>Finish the final
                details such as doors, windows, etc., and download your floor
                plan.
                <br />
                <br />
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ4(!FAQ4)}>
              <h3 className="question-title">How much does Pedra cost?</h3>
              {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ4 && (
              <div className="answer-container">
                Pedra costs 29€ plus tax per month.
                <br />
                <br />
                It's a monthly subscription, therefore you can cancel your
                subscription if you don't need it anymore.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FloorPlan;
