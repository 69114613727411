import React, { useState } from "react";
import CheckIcon from "../Icons/CheckIcon";
import { Link } from "react-router-dom";
import CustomerLogos from "../CustomerLogos";
import ArrowIcon from "../Icons/ArrowIcon";

const FreeDescriptionGeneratorDe = () => {
  function redirectToPage() {
    if (window.location.href.includes("/es")) {
      window.open("https://app.pedra.so/es/register", "_blank");
    } else if (window.location.href.includes("/fr")) {
      window.open("https://app.pedra.so/fr/register", "_blank");
    } else if (window.location.href.includes("/pt")) {
      window.open("https://app.pedra.so/pt/register", "_blank");
    } else if (window.location.href.includes("/de")) {
      window.open("https://app.pedra.so/de/register", "_blank");
    } else {
      window.open("https://app.pedra.so/register", "_blank");
    }
  }

  const [formData, setFormData] = useState({
    propertyType: "",
    condition: "",
    numberOfBedrooms: "",
    location: "",
    locationVibe: "",
    area: "",
    otherWarnings: "",
    highlightedFeatures: "",
  });
  const [generatedOutput, setGeneratedOutput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [fieldErrors, setFieldErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setFieldErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    const errors = {};
    const requiredFields = [
      "propertyType",
      "condition",
      "numberOfBedrooms",
      "area",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field].trim()) {
        errors[field] = "Dieses Feld ist erforderlich";
      }
    });

    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const generateListing = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsLoading(true);
    setError(null);
    setGeneratedOutput("");

    try {
      const response = await fetch(
        "/.netlify/functions/generate-ad-description-de",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ formData }),
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || "Fehler beim Generieren der Anzeige");
      }

      setGeneratedOutput(data.output);
    } catch (err) {
      console.error("Fehler in generateListing:", err);
      setError(
        "Wir erleben derzeit ein hohes Verkehrsaufkommen – bitte versuchen Sie es in einigen Minuten erneut"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const renderField = (
    name,
    label,
    type = "text",
    rows = 1,
    placeholder = ""
  ) => (
    <div>
      <label htmlFor={name} className="free-tool-label">
        {label}:
      </label>
      {type === "textarea" ? (
        <textarea
          id={name}
          name={name}
          value={formData[name]}
          onChange={handleInputChange}
          rows={rows}
          className="free-tool-input"
          style={{ width: "100%", marginTop: "5px", height: "100px" }}
          placeholder={placeholder}
        />
      ) : (
        <input
          type={type}
          id={name}
          name={name}
          value={formData[name]}
          onChange={handleInputChange}
          className="free-tool-input"
          style={{ width: "100%", marginTop: "5px" }}
          placeholder={placeholder}
        />
      )}
      {fieldErrors[name] && (
        <div style={{ color: "red", fontSize: "0.8em" }}>
          {fieldErrors[name]}
        </div>
      )}
    </div>
  );

  return (
    <section className="section-first-wrapper">
      <section className="section-first">
        <div className="section-first-text-container">
          <h1 className="section-first-title">
            <span className="text-highlight">Kostenloser</span>{" "}
            Immobilienanzeigen- Generator
          </h1>
          <div className="section-first-text free-tool-value-propcontainer">
            <p className="free-tool-value-prop">
              <CheckIcon /> <span>100% kostenlos</span>
            </p>
            <p className="free-tool-value-prop">
              <CheckIcon /> <span>100% KI-gesteuert</span>
            </p>
            <p className="free-tool-value-prop">
              <CheckIcon /> <span>Anzeigen in 10 Sekunden</span>
            </p>
          </div>
        </div>
        <h2>Immobiliendetails eingeben</h2>
        <form
          onSubmit={generateListing}
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          {renderField(
            "propertyType",
            "Immobilientyp",
            "text",
            1,
            "Wohnung, Haus, Villa"
          )}
          {renderField(
            "condition",
            "Zustand",
            "text",
            1,
            "Neu, Renoviert, Renovierungsbedürftig"
          )}
          {renderField(
            "numberOfBedrooms",
            "Anzahl der Schlafzimmer",
            "text",
            1,
            "2, 3, 4+"
          )}
          {renderField(
            "location",
            "Lage (optional)",
            "text",
            1,
            "Innenstadt, Salamanca-Viertel"
          )}
          {renderField(
            "locationVibe",
            "Nachbarschaftsatmosphäre (optional)",
            "text",
            1,
            "Ruhig, Lebendig, Familienfreundlich"
          )}
          {renderField("area", "Fläche", "text", 1, "80m², 100m², 150m²")}
          {renderField(
            "highlightedFeatures",
            "Besondere Merkmale",
            "textarea",
            3,
            "Geräumige Terrasse, Ausgestattete Küche, Panoramablick"
          )}
          {renderField(
            "otherWarnings",
            "Weitere Hinweise (optional)",
            "textarea",
            3,
            "Kleine Reparaturen nötig, Keine Haustiere erlaubt"
          )}

          <button
            type="submit"
            disabled={isLoading}
            style={{ cursor: "pointer" }}
            className="free-tool-button"
          >
            {isLoading ? "Generiere..." : "Anzeige generieren"}
          </button>
        </form>
        {error && (
          <div style={{ color: "red", marginTop: "10px" }}>
            <strong>Fehler:</strong> {error}
          </div>
        )}

        {generatedOutput && (
          <div className="step-container article">
            <article className="article-text free-tool-result-container">
              <div style={{ marginTop: "20px" }}>
                <h2 className="article-subtitleh2">
                  <span className="text-highlight">Generierte Anzeige:</span>
                </h2>
                <p>{generatedOutput}</p>
              </div>
            </article>
          </div>
        )}

        <div className="step-container article" style={{ marginTop: "100px" }}>
          <article className="article-text">
            <h2 className="article-subtitleh2">
              Was ist ein Immobilienanzeigen-Generator?
            </h2>
            <p>
              Ein Immobilienanzeigen-Generator ist ein Tool, das
              Immobilienmaklern und Eigentümern hilft, attraktive und
              professionelle Beschreibungen für ihre zum Verkauf oder zur Miete
              stehenden Immobilien zu erstellen. Dieses Tool verwendet
              fortschrittliche Algorithmen und künstliche Intelligenz, um
              überzeugende und detaillierte Beschreibungen basierend auf den
              bereitgestellten Immobilieninformationen zu generieren.
            </p>

            <h2 className="article-subtitleh2">
              Wie erstellt man Immobilienanzeigen
            </h2>
            <p>
              Wenn Sie die effektivsten und attraktivsten
              Immobilienbeschreibungen erhalten möchten, nutzen Sie Pedra. Unser
              Beschreibungsgenerator verwendet eine einzigartige Methode, die
              fortschrittliche künstliche Intelligenz mit lokalem
              Immobilienmarktwissen kombiniert.
            </p>
            <p>
              Die Vorgehensweise zur kostenlosen Erstellung einer
              Immobilienanzeige ist wie folgt:
            </p>

            <h2 className="article-subtitleh2">
              Grundlegende Immobiliendetails ausfüllen
            </h2>
            <p>
              Immobilientyp: Wählen Sie die Art der Immobilie aus, die Sie
              bewerben, wie Wohnung, Haus oder Villa. Dies hilft, die Anzeige
              richtig zu kategorisieren und die Käufererwartungen von Anfang an
              festzulegen.
            </p>
            <p>
              Immobilienzustand: Geben Sie an, ob die Immobilie neu, renoviert
              oder renovierungsbedürftig ist. Diese Information ist
              entscheidend, da sie den aktuellen Zustand der Immobilie
              beschreibt und die Kaufentscheidungen beeinflussen kann.
            </p>

            <h2 className="article-subtitleh2">Hauptmerkmale angeben</h2>
            <p>
              Anzahl der Schlafzimmer: Geben Sie die Anzahl der Schlafzimmer an.
              Dies ist eines der meistgesuchten Details von Käufern und sollte
              klar und präzise sein.
            </p>
            <p>
              Fläche: Geben Sie die Gesamtfläche der Immobilie in Quadratmetern
              an. Achten Sie auf Genauigkeit, da dies Interessenten hilft,
              verschiedene Immobilien einfach zu vergleichen.
            </p>

            <h2 className="article-subtitleh2">
              Optionale Informationen zur Anreicherung der Anzeige
            </h2>
            <p>
              Lage: Obwohl optional, kann das Hinzufügen von Details zur Lage,
              wie "Innenstadt" oder "Salamanca-Viertel", Ihre Anzeige relevanter
              für diejenigen machen, die in bestimmten Gebieten suchen.
            </p>
            <p>
              Nachbarschaftsatmosphäre: Beschreiben Sie die Atmosphäre der
              Nachbarschaft, zum Beispiel "Ruhig", "Lebendig" oder
              "Familienfreundlich". Dies hilft Interessenten, sich den
              Lebensstil vorzustellen, den sie in dieser Immobilie haben
              könnten.
            </p>

            <h2 className="article-subtitleh2">
              Wichtige Merkmale der Immobilie hervorheben
            </h2>
            <p>
              Besondere Merkmale: Nutzen Sie dieses Feld, um besondere
              Eigenschaften wie "Geräumige Terrasse", "Ausgestattete Küche" oder
              "Panoramablick" hervorzuheben. Diese Details können entscheidend
              sein, um Aufmerksamkeit zu erregen und Ihre Anzeige von ähnlichen
              abzuheben.
            </p>

            <h2 className="article-subtitleh2">
              Warnhinweise oder Einschränkungen aufnehmen
            </h2>
            <p>
              Weitere Hinweise (optional): Fügen Sie wichtige Einschränkungen
              hinzu, wie "Kleine Reparaturen nötig" oder "Keine Haustiere
              erlaubt". Transparenz bei diesen Bedingungen vermeidet
              Missverständnisse und zieht Interessenten an, die bereit sind,
              diese Bedingungen zu akzeptieren.
            </p>

            <h2 className="article-subtitleh2">
              Vorteile des Immobilienanzeigen-Generators
            </h2>
            <h3>Zeiteffizienz</h3>
            <p>
              Das Erstellen einer Immobilienanzeige von Grund auf kann ein
              langer und detaillierter Prozess sein, besonders wenn Sie
              sicherstellen möchten, dass sie überzeugend und professionell ist.
              Mit dem Anzeigengenerator müssen Sie nur die wichtigsten
              Informationen über die Immobilie eingeben und erhalten in
              Sekundenschnelle eine vollständige und gut strukturierte
              Beschreibung. Dies ermöglicht es Ihnen, wertvolle Zeit zu sparen,
              die Sie anderen wichtigen Aufgaben wie Kundenservice oder der
              Verwaltung weiterer Immobilien widmen können.
            </p>

            <h3>Professionelle Qualität</h3>
            <p>
              Eine schlecht geschriebene oder unattraktive Beschreibung kann
              potenzielle Käufer oder Mieter von Anfang an abschrecken. Der
              Generator verwendet professionelle und überzeugende Sprache und
              stellt sicher, dass jede Anzeige die besten Eigenschaften der
              Immobilie hervorhebt und die Aufmerksamkeit des Lesers fesselt.
              Zusätzlich passt sich die Schreibweise den Best Practices des
              Immobilienmarktes an, was die Wahrscheinlichkeit erhöht,
              Interessenten anzuziehen.
            </p>

            <h3>Benutzerfreundlichkeit</h3>
            <p>
              Der Anzeigengenerator ist so konzipiert, dass er intuitiv und
              einfach zu bedienen ist, auch für diejenigen, die keine vorherige
              Erfahrung im Content-Writing oder Immobilienmarketing haben. Sie
              müssen nur einige Felder mit grundlegenden Informationen über die
              Immobilie ausfüllen, wie Typ, Lage und hervorgehobene Merkmale,
              und das Tool übernimmt den Rest. Diese Einfachheit ermöglicht es
              jedem, effektive Anzeigen ohne Komplikationen zu erstellen.
            </p>

            <h3>Kostenlos</h3>
            <p>
              Im Gegensatz zu anderen Schreibtools oder Diensten, die mit Kosten
              verbunden sein können, ist dieser Anzeigengenerator völlig
              kostenlos. Dies ermöglicht es Nutzern, ob unabhängige Makler,
              kleine Agenturen oder große Immobilienunternehmen, Zugang zu
              hochwertigen Beschreibungen zu erhalten, ohne zusätzliche Kosten
              zu verursachen.
            </p>

            <h2 className="article-subtitleh2">
              Beste Anwendungsfälle des Beschreibungsgenerators
            </h2>
            <h3>Für unabhängige Immobilienmakler</h3>
            <p>
              Unabhängige Immobilienmakler betreuen oft mehrere Immobilien und
              haben die Verantwortung, den gesamten Verkaufs- oder
              Vermietungsprozess zu managen, von der Immobilienakquisition bis
              zur Erstellung attraktiver Anzeigen. Der Beschreibungsgenerator
              ermöglicht es ihnen, Zeit und Mühe beim Schreiben von Anzeigen zu
              sparen und stellt sicher, dass jede Beschreibung professionell und
              überzeugend ist. Dies ermöglicht es ihnen, sich auf andere
              kritische Aufgaben zu konzentrieren, wie Verhandlungen mit Kunden
              oder die Organisation von Besichtigungen, und erhöht ihre
              Produktivität und Effektivität beim Abschluss von Geschäften.
            </p>

            <h3>Für Eigentümer, die selbst verkaufen</h3>
            <p>
              Eigentümer, die sich entscheiden, ihre Immobilien selbst zu
              verkaufen oder zu vermieten (ohne Vermittler), fehlt oft die
              Erfahrung im Immobilienmarketing, die notwendig ist, um attraktive
              und effektive Beschreibungen zu verfassen. Der
              Beschreibungsgenerator bietet ihnen eine einfache und kostenlose
              Lösung, um Anzeigen zu erstellen, die die besten Eigenschaften
              ihrer Immobilie hervorheben. Dies hilft ihnen, die Aufmerksamkeit
              potenzieller Käufer oder Mieter zu gewinnen und erhöht die
              Erfolgschancen beim Verkauf oder der Vermietung, ohne einen Makler
              oder professionellen Texter engagieren zu müssen.
            </p>

            <h3>Für Immobilienagenturen</h3>
            <p>
              Immobilienagenturen verwalten ein großes Volumen an Immobilien und
              müssen Konsistenz im Ton und in der Qualität der veröffentlichten
              Anzeigen bewahren. Der Beschreibungsgenerator ermöglicht es ihnen,
              die Content-Erstellung zu standardisieren und stellt sicher, dass
              alle Immobilien einheitlich und professionell präsentiert werden.
              Zusätzlich reduziert er die Zeit und Kosten, die mit manuellem
              Schreiben verbunden sind, sodass sich Marketing-Teams auf breitere
              Werbestrategien konzentrieren können. Durch das effiziente
              Anbieten hochwertiger Beschreibungen können Agenturen ihr
              Markenimage verbessern und mehr Kunden gewinnen.
            </p>

            <h2 className="article-subtitleh2">
              Für wen ist der Immobilienanzeigen-Generator gedacht?
            </h2>
            <p>
              Obwohl jeder Pedras Beschreibungsgenerator nutzen kann, sind dies
              die häufigsten Nutzer:
            </p>
            <ul>
              <li>Immobilienmakler</li>
              <li>Immobilienbesitzer</li>
              <li>Immobilieninvestoren</li>
            </ul>
            <p>
              Entdecken Sie heute, wie unser Tool Ihre Immobilienanzeigen
              transformieren kann.
            </p>

            <h2 className="article-subtitleh2">
              Verbessern Sie die visuelle Präsentation Ihrer Immobilienanzeige
            </h2>
            <p>
              Neben einer überzeugenden Beschreibung ist die visuelle
              Präsentation Ihrer Immobilie entscheidend, um potenzielle Käufer
              anzuziehen. Bei Pedra verstehen wir die Bedeutung, das volle
              Potenzial jeder Immobilie zu zeigen. Deshalb bieten wir
              zusätzliche Dienste zur Verbesserung der Präsentation Ihrer
              Anzeigen:
            </p>
            <ul>
              <li>
                Professionelle Immobilienfotografie: Unsere Software verbessert
                Ihre Bilder mit einem Klick, um sie mit besserer Auflösung,
                Licht und Farbe erscheinen zu lassen.
              </li>
              <li>
                Virtuelles Home Staging: Wir nutzen fortschrittliche
                Technologie, um zu zeigen, wie die Immobilie mit verschiedenen
                Einrichtungsstilen aussehen würde, was Käufern hilft, ihr
                Potenzial zu visualisieren.
              </li>
            </ul>
            <p>
              Diese ergänzenden Dienste, zusammen mit unseren KI-generierten
              Beschreibungen, werden Ihnen helfen, Immobilienanzeigen zu
              erstellen, die sich im heutigen wettbewerbsintensiven Markt
              abheben. Kontaktieren Sie uns, um herauszufinden, wie wir Ihnen
              helfen können, Ihre Immobilie bestmöglich zu präsentieren.
            </p>

            <h2 className="article-subtitleh2">
              Erstellen Sie Ihre Anzeige bereit für Immobilienportale
            </h2>
            <p>
              Mit unserem kostenlosen Beschreibungsgenerator und dem{" "}
              <Link to="/home-staging-virtual" className="article-link">
                virtuellen Home Staging
              </Link>{" "}
              Tool zur Optimierung Ihrer Immobilienfotos können Sie alle Ihre
              Immobilieninformationen schnell und einfach verwalten. Diese Tools
              ermöglichen es Ihnen, komplexe Aufgaben zu automatisieren, die
              Arbeitsstunden deutlich zu reduzieren und die Qualität der
              Ergebnisse zu verbessern, ohne zu viel Zeit und Mühe investieren
              zu müssen.
            </p>
            <p>
              Sobald Sie die Beschreibung erstellt und Ihre Fotos mit Pedras
              Hilfe optimiert haben, sind sie bereit, auf verschiedenen
              Immobilienportalen veröffentlicht zu werden, um zukünftige Mieter
              anzuziehen.
            </p>
          </article>
        </div>
        <br />
        <div className="customer-logo-container">
          ÜBER 20.000 IMMOBILIENPROFIS VERTRAUEN BEREITS PEDRA
          <CustomerLogos />
        </div>
        <div className="section-first-button-container">
          <button className="button-top-of-page" onClick={redirectToPage}>
            Pedra testen <ArrowIcon />
          </button>
        </div>
      </section>
    </section>
  );
};

export default FreeDescriptionGeneratorDe;
