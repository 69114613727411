import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

// Iconos
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";

// Imágenes
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import BeforeBedroom from "./Images/curtain-effect-hero/BeforeBedroom.png";
import AfterBedroom from "./Images/curtain-effect-hero/AfterBedroom.png";
import BeforeBedroomStaging1 from "./Images/curtain-effect/BeforeBedroomStaging1.png";
import AfterBedroomStaging1 from "./Images/curtain-effect/AfterBedroomStaging1.png";
import BeforeBedroomStaging2 from "./Images/curtain-effect/BeforeBedroomStaging2.png";
import AfterBedroomStaging2 from "./Images/curtain-effect/AfterBedroomStaging2.png";
import BeforeBedroomStaging3 from "./Images/curtain-effect/BeforeBedroomStaging3.png";
import AfterBedroomStaging3 from "./Images/curtain-effect/AfterBedroomStaging3.png";
import VirtualStagingExample1 from "./Images/VirtualStagingExample1.png";
import VirtualStagingExample2 from "./Images/VirtualStagingExample2.png";
import VirtualStagingExample3 from "./Images/VirtualStagingExample3.png";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

function redirigirAPagina() {
  if (window.location.href.includes("/en")) {
    window.open("https://app.pedra.so/en", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function HomeStagingBedroomEs() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);

  const [ejemplo, setEjemplo] = useState({
    nombre: "Moderno",
    imagenAntes: BeforeBedroom,
    imagenDespues: AfterBedroom,
  });

  const manejarEjemplo = (ejemplo) => {
    if (ejemplo === "Moderno") {
      setEjemplo({
        nombre: "Moderno",
        imagenAntes: BeforeBedroom,
        imagenDespues: AfterBedroom,
      });
    } else if (ejemplo === "Tradicional") {
      setEjemplo({
        nombre: "Tradicional",
        imagenAntes: BeforeBedroomStaging1,
        imagenDespues: AfterBedroomStaging1,
      });
    } else if (ejemplo === "Rústico") {
      setEjemplo({
        nombre: "Rústico",
        imagenAntes: BeforeBedroomStaging2,
        imagenDespues: AfterBedroomStaging2,
      });
    } else {
      setEjemplo({
        nombre: "Contemporáneo",
        imagenAntes: BeforeBedroomStaging3,
        imagenDespues: AfterBedroomStaging3,
      });
    }
  };

  const datosEstructurados = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "¿Qué es el home staging de dormitorios?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "El home staging de dormitorios es una técnica de marketing inmobiliario que se centra en preparar y presentar el dormitorio de una propiedad de manera atractiva para potenciales compradores o inquilinos. Esto puede incluir limpieza, organización, decoración estratégica y pequeñas mejoras para resaltar las fortalezas del dormitorio y minimizar sus defectos.",
        },
      },
      {
        "@type": "Question",
        name: "¿Cuáles son los beneficios del home staging virtual para dormitorios?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "El home staging virtual para dormitorios ofrece varios beneficios, incluyendo: la capacidad de mostrar el potencial del dormitorio sin hacer cambios físicos costosos, la posibilidad de experimentar rápidamente con diferentes estilos y configuraciones, mejorar la presentación en línea de la propiedad y la oportunidad de destacar en un mercado inmobiliario competitivo.",
        },
      },
      {
        "@type": "Question",
        name: "¿Cómo funciona el proceso de home staging virtual para dormitorios?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "El proceso de home staging virtual para dormitorios implica subir fotos del dormitorio actual a nuestra plataforma, seleccionar las opciones de edición deseadas (como cambiar acabados, agregar o quitar elementos, o cambiar la distribución), y luego recibir las imágenes editadas que muestran el dormitorio transformado.",
        },
      },
      {
        "@type": "Question",
        name: "¿Cuánto cuesta el home staging virtual de dormitorios?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "El precio de nuestro servicio de home staging virtual, que incluye dormitorios y otras áreas de la casa, es de 29€ más IVA al mes. Este plan te permite generar hasta 100 imágenes por mes, sin compromiso.",
        },
      },
    ],
  };

  const [ejemploStaging, setEjemploStaging] = useState({
    nombre: "ejemploStaging1",
    imagenAntes: BeforeBedroomStaging1,
    imagenDespues: AfterBedroomStaging1,
  });

  const ejemplosStaging = [
    {
      nombre: "ejemploStaging1",
      imagenAntes: BeforeBedroomStaging1,
      imagenDespues: AfterBedroomStaging1,
    },
    {
      nombre: "ejemploStaging2",
      imagenAntes: BeforeBedroomStaging2,
      imagenDespues: AfterBedroomStaging2,
    },
    {
      nombre: "ejemploStaging3",
      imagenAntes: BeforeBedroomStaging3,
      imagenDespues: AfterBedroomStaging3,
    },
  ];

  const manejarEjemploStaging = (nombreEjemplo) => {
    const ejemploSeleccionado = ejemplosStaging.find(
      (ejemplo) => ejemplo.nombre === nombreEjemplo
    );
    if (ejemploSeleccionado) {
      setEjemploStaging(ejemploSeleccionado);
    }
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(datosEstructurados)}
        </script>
      </Helmet>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Home Staging de Dormitorios</h1>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2 style={{ fontSize: "20px", marginTop: "12px" }}>
                  El mejor home staging virtual de dormitorios al alcance de tu
                  mano
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Rápido:</strong> Transforma tu dormitorio en
                    segundos
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Fácil:</strong> Diseña dormitorios atractivos sin
                    esfuerzo
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Calidad:</strong> Resultados hiperrealistas para
                    dormitorios
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Ayuda:</strong> Asistencia experta en diseño de
                    dormitorios
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirigirAPagina}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Prueba Pedra <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        29€ por 100 imágenes
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa, Director de Fotografía en aProperties"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera, Agente Inmobiliario en Huspy"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata, CEO en Actívox"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart, CEO en Gilart Consultors"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      ></img>
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        Más de 100 testimonios
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={ejemplo.imagenAntes}
                  afterImage={ejemplo.imagenDespues}
                  altBefore={"Antes del home staging virtual de dormitorio"}
                  altAfter={"Después del home staging virtual de dormitorio"}
                />

                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        ejemplo.nombre === "Moderno" ? "selected" : ""
                      }`}
                      onClick={() => manejarEjemplo("Moderno")}
                    >
                      Ejemplo de Dormitorio 1
                    </div>
                    <div
                      className={`hero-button ${
                        ejemplo.nombre === "Tradicional" ? "selected" : ""
                      }`}
                      onClick={() => manejarEjemplo("Tradicional")}
                    >
                      Ejemplo de Dormitorio 2
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        ejemplo.nombre === "Rústico" ? "selected" : ""
                      }`}
                      onClick={() => manejarEjemplo("Rústico")}
                    >
                      Ejemplo de Dormitorio 3
                    </div>
                    <div
                      className={`hero-button ${
                        ejemplo.nombre === "Contemporáneo" ? "selected" : ""
                      }`}
                      onClick={() => manejarEjemplo("Contemporáneo")}
                    >
                      Ejemplo de Dormitorio 4
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          MÁS DE 20.000 PROFESIONALES INMOBILIARIOS YA CONFÍAN EN PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Home staging virtual de dormitorios para{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            vender más rápido
          </span>{" "}
          cualquier tipo de propiedad.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h3 className="feature-title">
                  Renueva tu dormitorio virtualmente
                </h3>
                <div className="feature-text">
                  Transforma tu dormitorio con un clic, sin obras ni gastos
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">
                  Destaca características únicas
                </h3>
                <div className="feature-text">
                  Resalta los puntos fuertes de tu dormitorio con texto
                  descriptivo
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">
                  Mejora y corrige fotos de dormitorios
                </h3>
                <div className="feature-text">
                  Optimiza la calidad y perspectiva de tus fotos de dormitorios
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h3 className="feature-title">Añade muebles elegantes</h3>
                <div className="feature-text">
                  Actualiza tu dormitorio con muebles modernos virtualmente
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h3 className="feature-title">Renueva acabados</h3>
                <div className="feature-text">
                  Cambia suelos, paredes y acabados con un solo clic
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h3 className="feature-title">
                  Limpia y organiza virtualmente
                </h3>
                <div className="feature-text">
                  Elimina el desorden y objetos no deseados para un dormitorio
                  impecable
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Ejemplos de nuestro home staging virtual de dormitorios.
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="hero-example-container">
          <CurtainEffect
            beforeImage={ejemploStaging.imagenAntes}
            afterImage={ejemploStaging.imagenDespues}
            altBefore={"Antes del home staging virtual de dormitorio"}
            altAfter={"Después del home staging virtual de dormitorio"}
          />

          <div style={{ display: "flex", flex: "1 0 0" }}></div>
          <div className="hero-example-buttons-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "6px",
                width: "100%",
              }}
            >
              {ejemplosStaging.map((item) => (
                <div
                  key={item.nombre}
                  className={`hero-button ${
                    ejemploStaging.nombre === item.nombre ? "selected" : ""
                  }`}
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    borderRadius: "8px",
                    overflow: "hidden",
                    height: "50px",
                    padding: "0px",
                  }}
                  onClick={() => manejarEjemploStaging(item.nombre)}
                >
                  <img
                    src={item.imagenAntes}
                    alt={item.nombre}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "6px",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container" style={{ display: "inline" }}>
          ¿Cómo funciona el home staging virtual de dormitorios?
        </h3>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASO 1
            </h5>
            <h2>Sube fotos de tu dormitorio</h2>
            <div className="value-prop-text">
              Sube una o más imágenes de tu dormitorio a Pedra.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample1}
              alt="Cómo subir fotos de dormitorios para crear home staging virtual en Pedra - Infografía"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASO 2
            </h5>
            <h2>Elige opciones de transformación para tu dormitorio</h2>
            <div className="value-prop-text">
              Selecciona entre varias opciones para renovar virtualmente tu
              dormitorio.
              <p>
                Puedes cambiar acabados, añadir muebles, reorganizar el espacio
                y mucho más.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample2}
              alt="Cómo elegir opciones para crear home staging virtual de dormitorios - Infografía"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASO 3
            </h5>
            <h2>Recibe y descarga tu dormitorio transformado</h2>
            <div className="value-prop-text">
              En cuestión de minutos, obtendrás imágenes realistas de tu
              dormitorio renovado.
              <p>
                Descarga las imágenes y úsalas para promocionar tu propiedad.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample3}
              alt="Cómo descargar el home staging virtual de dormitorios - Infografía"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Home staging virtual de dormitorios por 29€ al mes. <br />{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            Y sin compromiso.
          </span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Plan Pro
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">29€</div>
              <div className="modal-product-plan-description-text-3">
                al mes
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">Incluye</div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#B15DE1" }}>
                100 generaciones de imágenes
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>al mes</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Renovar y redecorar dormitorios
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Cambiar acabados de dormitorios
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Añadir muebles elegantes
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Eliminar objetos no deseados
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Aumentar la resolución de fotos
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Cambiar diseño de dormitorios
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Mejorar fotografía de dormitorios
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Planes de dormitorios ilimitados
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Compartir proyectos ilimitados
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Añadir marcas de agua
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Soporte ilimitado
            </div>
            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirigirAPagina}>
                Prueba Pedra
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Cancela cuando quieras.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Compra más imágenes a 0,20€ por imagen si las necesitas.
            </div>
          </div>
        </div>
      </div>

      <div className="long-break"> </div>

      <div className="section-one-column-left">
        <div className="title-container">
          Preguntas y respuestas sobre el home staging de dormitorios
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">
                ¿Qué es el home staging de dormitorios?
              </h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                El home staging de dormitorios es una técnica de marketing
                inmobiliario que se centra en preparar y presentar el dormitorio
                de una propiedad de manera atractiva para potenciales
                compradores o inquilinos. Esto puede incluir limpieza,
                organización, decoración estratégica y pequeñas mejoras para
                resaltar las fortalezas del dormitorio y minimizar sus defectos.
                <br />
                <br />
                En el caso del home staging virtual de dormitorios, utilizamos
                tecnología avanzada para transformar digitalmente el espacio,
                mostrando su potencial sin necesidad de realizar cambios físicos
                costosos.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                ¿Cuáles son las ventajas del home staging virtual para
                dormitorios?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                El home staging virtual para dormitorios ofrece numerosas
                ventajas:
                <br />
                <br />
                1. Ahorro de costes: No es necesario realizar renovaciones
                físicas costosas.
                <br />
                2. Ahorro de tiempo: Las transformaciones se realizan en
                minutos, no en semanas.
                <br />
                3. Versatilidad: Puedes mostrar múltiples estilos y
                configuraciones del mismo dormitorio.
                <br />
                4. Atractivo visual: Ayuda a los compradores a visualizar el
                potencial del dormitorio.
                <br />
                5. Diferenciación en el mercado: Destaca con imágenes atractivas
                de dormitorios.
                <br />
                6. Sin interrupciones: No es necesario interrumpir la vida de
                los ocupantes actuales.
                <br />
                7. Mejor presentación en línea: Ideal para listados de
                propiedades en internet.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                ¿Qué puedo hacer con el home staging de dormitorios de Pedra?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Con el home staging virtual de dormitorios de Pedra, puedes
                realizar una amplia variedad de transformaciones:
                <br />
                <br />
                1. Renovar dormitorios: Puedes utilizar los estilos predefinidos
                de Pedra o crear tus propios estilos personalizados para
                transformar completamente la apariencia del dormitorio.
                <br />
                2. Mejorar la luz y el color: Optimiza la iluminación y los
                colores de las fotografías del dormitorio para que se vean más
                atractivas y profesionales.
                <br />
                3. Eliminar objetos: Borra elementos no deseados de las fotos,
                como muebles viejos, desorden o artículos personales.
                <br />
                4. Añadir elementos: Incorpora nuevos objetos al dormitorio,
                como muebles modernos, decoración o accesorios.
                <br />
                5. Cambiar acabados: Modifica la apariencia de suelos, paredes y
                otros acabados para mostrar diferentes opciones de diseño.
                <br />
                6. Reorganizar el espacio: Visualiza diferentes distribuciones
                del dormitorio sin necesidad de cambios físicos.
                <br />
                <br />
                Todas estas transformaciones se realizan virtualmente,
                permitiéndote mostrar el potencial del dormitorio de forma
                rápida y económica.
              </div>
            )}
          </div>

          {/* Añade más preguntas frecuentes aquí */}
        </div>
      </div>
    </div>
  );
}

export default HomeStagingBedroomEs;
