import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

// Icons (same imports as before)
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

// Images (same imports as before)
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";

import BeforeKitchen from "./Images/curtain-effect/BeforeInteriorDesignAI2.png";
import AfterKitchen from "./Images/curtain-effect/AfterInteriorDesignAI2.png";

import BeforeKitchenStaging1 from "./Images/curtain-effect/BeforeInteriorDesignAI1.png";
import AfterKitchenStaging1 from "./Images/curtain-effect/AfterInteriorDesignAI1.png";

import BeforeKitchenStaging2 from "./Images/curtain-effect-hero/BeforeFurnish.png";
import AfterKitchenStaging2 from "./Images/curtain-effect-hero/AfterFurnish.png";

import BeforeKitchenStaging3 from "./Images/curtain-effect-hero/BeforeTerraceStaging.png";
import AfterKitchenStaging3 from "./Images/curtain-effect-hero/AfterTerraceStaging.png";

import VirtualStagingExample1 from "./Images/VirtualStagingExample1.png";
import VirtualStagingExample2 from "./Images/VirtualStagingExample2.png";
import VirtualStagingExample3 from "./Images/VirtualStagingExample3.png";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function InteriorDesignFr() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);

  const [example, setExample] = useState({
    name: "Moderne",
    beforeImage: BeforeKitchenStaging1,
    afterImage: AfterKitchenStaging1,
  });

  const handleExample = (example) => {
    switch (example) {
      case "Moderne":
        setExample({
          name: "Moderne",
          beforeImage: BeforeKitchenStaging1,
          afterImage: AfterKitchenStaging1,
        });
        break;
      case "Minimaliste":
        setExample({
          name: "Minimaliste",
          beforeImage: BeforeKitchenStaging2,
          afterImage: AfterKitchenStaging2,
        });
        break;
      case "Industriel":
        setExample({
          name: "Industriel",
          beforeImage: BeforeKitchenStaging3,
          afterImage: AfterKitchenStaging3,
        });
        break;
      default:
        setExample({
          name: "Classique",
          beforeImage: BeforeKitchen,
          afterImage: AfterKitchen,
        });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "Qu'est-ce que la décoration d'intérieur avec l'IA ?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "La décoration d'intérieur avec l'IA combine la créativité du design traditionnel avec l'intelligence artificielle pour optimiser et visualiser les espaces efficacement. L'IA analyse votre espace et vos préférences pour générer des designs personnalisés et professionnels instantanément.",
        },
      },
      {
        "@type": "Question",
        name: "Quels types d'espaces puis-je concevoir ?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Vous pouvez concevoir des espaces résidentiels (salons, chambres, cuisines), commerciaux (bureaux, magasins, restaurants), publics (musées, bibliothèques) et des zones spécialisées (cliniques, salles de sport, hôtels).",
        },
      },
      {
        "@type": "Question",
        name: "Quels sont les avantages de la conception avec l'IA ?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Les avantages incluent la visualisation instantanée des changements, plusieurs options de design en quelques secondes, l'optimisation intelligente de l'espace, la personnalisation détaillée, les économies de temps et de coûts, et des résultats professionnels sans expérience préalable.",
        },
      },
    ],
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Décoration d'Intérieur avec IA</h1>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2 style={{ fontSize: "20px", marginTop: "12px" }}>
                  Transformez vos espaces avec la décoration d'intérieur
                  intelligente
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Instantané :</strong> Visualisez les changements en
                    temps réel
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Simple :</strong> Aucune connaissance technique
                    requise
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Professionnel :</strong> Résultats de haute qualité
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Personnalisé :</strong> Adapté à vos préférences
                  </div>
                </div>

                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Essayer le Design IA <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        29€ par mois sans engagement
                      </div>
                    </div>
                  </div>

                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      />
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        Plus de 100 témoignages
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore="Avant la décoration d'intérieur"
                  altAfter="Après la décoration d'intérieur"
                />

                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Moderne" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Moderne")}
                    >
                      Chambre
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Minimaliste" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Minimaliste")}
                    >
                      Salon
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Industriel" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Industriel")}
                    >
                      Terrasse
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Classique" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Classique")}
                    >
                      Cuisine
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          PLUS DE 20 000 PROFESSIONNELS FONT DÉJÀ CONFIANCE À PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Créez des designs professionnels{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            automatiquement
          </span>{" "}
          en quelques secondes.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h3 className="feature-title">Concevez plusieurs espaces</h3>
                <div className="feature-text">
                  Générez des designs pour plusieurs pièces simultanément
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h3 className="feature-title">Importez votre propre style</h3>
                <div className="feature-text">
                  Importez des images de vos propres styles et l'IA redessinera
                  vos photos selon ces styles
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h3 className="feature-title">Optimisation de l'espace</h3>
                <div className="feature-text">
                  Disposez les meubles de manière intelligente
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h3 className="feature-title">Nettoyage automatique</h3>
                <div className="feature-text">
                  Supprimez les objets indésirables de la scène
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">Personnalisation totale</h3>
                <div className="feature-text">
                  Ajustez chaque détail selon vos goûts
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">Design en quelques secondes</h3>
                <div className="feature-text">
                  Générez des propositions de design avec l'IA en quelques
                  secondes
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Comment fonctionne la décoration d'intérieur avec l'IA ?
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              ÉTAPE 1
            </h5>
            <h2>Téléchargez une image de votre espace</h2>
            <div className="value-prop-text">
              Commencez par télécharger une photo de la pièce que vous souhaitez
              réaménager. Notre logiciel analyse la structure, l'éclairage et
              les éléments clés.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample1}
              alt="Comment télécharger des photos pour la décoration d'intérieur avec IA"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              ÉTAPE 2
            </h5>
            <h2>Choisissez votre style préféré</h2>
            <div className="value-prop-text">
              Sélectionnez parmi différents styles de décoration et
              personnalisez les couleurs, les matériaux et les éléments
              décoratifs selon vos préférences.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample2}
              alt="Sélection des styles de décoration d'intérieur"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              ÉTAPE 3
            </h5>
            <h2>Obtenez votre design instantanément</h2>
            <div className="value-prop-text">
              Recevez des visualisations professionnelles de votre espace
              transformé. Téléchargez et utilisez vos designs immédiatement.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample3}
              alt="Résultat final de la décoration d'intérieur"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Décoration d'intérieur professionnelle pour 29€ par mois. <br />{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            Sans engagement.
          </span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Plan Pro
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">29€</div>
              <div className="modal-product-plan-description-text-3">
                par mois
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">Inclut</div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#B15DE1" }}>
                100 designs
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>par mois</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Design instantané d'espaces
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Choisissez vos propres styles
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Disposition intelligente des meubles
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Nettoyage automatique de la scène
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Visualisations haute résolution
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Modification des matériaux et textures
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Éclairage professionnel
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Projets illimités
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Partage illimité
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Support prioritaire
            </div>

            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Essayer le Design IA
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Résiliez quand vous voulez.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Designs supplémentaires disponibles pour 0,20€ chacun.
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="title-container">
          Questions fréquentes sur la décoration d'intérieur avec l'IA
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">
                Qu'est-ce que la décoration d'intérieur avec intelligence
                artificielle ?
              </h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                La décoration d'intérieur avec l'IA est une tendance émergente
                qui combine la créativité du design traditionnel avec les
                capacités analytiques et prédictives de l'IA. La technologie
                analyse les espaces et les préférences pour générer des designs
                personnalisés instantanément.
                <br />
                <br />
                Notre système peut :
                <br />
                • Générer des designs automatiquement
                <br />
                • Optimiser la distribution de l'espace
                <br />
                • Suggérer des combinaisons de couleurs et de matériaux
                <br />• Créer des visualisations photoréalistes
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Quels types d'espaces puis-je concevoir ?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                Notre outil peut concevoir une grande variété d'espaces :
                <br />
                <br />
                <strong>Espaces Résidentiels :</strong>
                <br />
                • Salons et salles à manger
                <br />
                • Chambres
                <br />
                • Cuisines et salles de bains
                <br />
                • Espaces extérieurs et jardins
                <br />
                <br />
                <strong>Espaces Commerciaux :</strong>
                <br />
                • Bureaux
                <br />
                • Magasins et espaces commerciaux
                <br />
                • Restaurants et cafés
                <br />
                <br />
                <strong>Espaces Publics :</strong>
                <br />
                • Musées et galeries
                <br />
                • Bibliothèques et centres éducatifs
                <br />• Installations sportives
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                Quels sont les avantages de l'IA en décoration d'intérieur ?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Les principaux avantages comprennent :
                <br />
                <br />
                <strong>Efficacité et rapidité</strong>
                <br />
                • Génération instantanée de designs
                <br />
                • Automatisation des tâches répétitives
                <br />
                • Visualisation immédiate des changements
                <br />
                <br />
                <strong>Précision et optimisation</strong>
                <br />
                • Analyse détaillée de l'espace
                <br />
                • Distribution optimale des éléments
                <br />
                • Réduction des erreurs de conception
                <br />
                <br />
                <strong>Personnalisation</strong>
                <br />
                • Adaptation aux préférences individuelles
                <br />
                • Multiples options de style
                <br />• Recommandations basées sur l'utilisation de l'espace
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InteriorDesignFr;
