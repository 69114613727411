import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

// Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeSinkIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";

// Images
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import BeforeBathroom from "./Images/curtain-effect-hero/BeforeBathroom.png";
import AfterBathroom from "./Images/curtain-effect-hero/AfterBathroom.png";
import BeforeBathroomStaging1 from "./Images/curtain-effect/BeforeBathroomStaging1.png";
import AfterBathroomStaging1 from "./Images/curtain-effect/AfterBathroomStaging1.png";
import BeforeBathroomStaging2 from "./Images/curtain-effect/BeforeBathroomStaging2.png";
import AfterBathroomStaging2 from "./Images/curtain-effect/AfterBathroomStaging2.png";
import BeforeBathroomStaging3 from "./Images/curtain-effect/BeforeBathroomStaging3.png";
import AfterBathroomStaging3 from "./Images/curtain-effect/AfterBathroomStaging3.png";
import VirtualStagingExample1 from "./Images/VirtualStagingExample1.png";
import VirtualStagingExample2 from "./Images/VirtualStagingExample2.png";
import VirtualStagingExample3 from "./Images/VirtualStagingExample3.png";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function HomeStagingBathroomFr() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);

  const [exemple, setExemple] = useState({
    nom: "Moderne",
    imageAvant: BeforeBathroom,
    imageApres: AfterBathroom,
  });

  const handleExample = (exemple) => {
    if (exemple === "Moderne") {
      setExemple({
        nom: "Moderne",
        imageAvant: BeforeBathroom,
        imageApres: AfterBathroom,
      });
    } else if (exemple === "Traditionnel") {
      setExemple({
        nom: "Traditionnel",
        imageAvant: BeforeBathroomStaging1,
        imageApres: AfterBathroomStaging1,
      });
    } else if (exemple === "Rustique") {
      setExemple({
        nom: "Rustique",
        imageAvant: BeforeBathroomStaging2,
        imageApres: AfterBathroomStaging2,
      });
    } else {
      setExemple({
        nom: "Contemporain",
        imageAvant: BeforeBathroomStaging3,
        imageApres: AfterBathroomStaging3,
      });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "Qu'est-ce que la mise en scène de salle de bain ?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "La mise en scène de salle de bain est une technique de marketing immobilier qui se concentre sur la préparation et la présentation de la salle de bain d'une propriété de manière attractive pour les acheteurs ou locataires potentiels. Cela peut inclure le nettoyage, l'organisation, la décoration stratégique et de petites améliorations pour mettre en valeur les points forts de la salle de bain et minimiser ses défauts.",
        },
      },
      {
        "@type": "Question",
        name: "Quels sont les avantages de la mise en scène virtuelle pour les salles de bain ?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "La mise en scène virtuelle pour les salles de bain offre plusieurs avantages, notamment : la possibilité de montrer le potentiel de la salle de bain sans faire de changements physiques coûteux, la capacité d'expérimenter rapidement différents styles et configurations, l'amélioration de la présentation en ligne de la propriété, et l'opportunité de se démarquer dans un marché immobilier concurrentiel.",
        },
      },
      {
        "@type": "Question",
        name: "Comment fonctionne le processus de mise en scène virtuelle pour les salles de bain ?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Le processus de mise en scène virtuelle pour les salles de bain implique le téléchargement de photos de la salle de bain actuelle sur notre plateforme, la sélection des options d'édition souhaitées (comme le changement de finitions, l'ajout ou la suppression d'éléments, ou la modification de l'agencement), puis la réception des images éditées montrant la salle de bain transformée.",
        },
      },
      {
        "@type": "Question",
        name: "Combien coûte la mise en scène virtuelle de salle de bain ?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Le prix de notre service de mise en scène virtuelle, qui inclut les salles de bain et d'autres zones de la maison, est de 29€ plus TVA par mois. Ce forfait vous permet de générer jusqu'à 100 images par mois, sans engagement.",
        },
      },
    ],
  };

  const [stagingExample, setStagingExample] = useState({
    nom: "stagingExample1",
    imageAvant: BeforeBathroomStaging1,
    imageApres: AfterBathroomStaging1,
  });

  const stagingExamples = [
    {
      nom: "stagingExample1",
      imageAvant: BeforeBathroomStaging1,
      imageApres: AfterBathroomStaging1,
    },
    {
      nom: "stagingExample2",
      imageAvant: BeforeBathroomStaging2,
      imageApres: AfterBathroomStaging2,
    },
    {
      nom: "stagingExample3",
      imageAvant: BeforeBathroomStaging3,
      imageApres: AfterBathroomStaging3,
    },
  ];

  const handleStagingExample = (exampleName) => {
    const selectedExample = stagingExamples.find(
      (example) => example.nom === exampleName
    );
    if (selectedExample) {
      setStagingExample(selectedExample);
    }
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Mise en scène de salle de bain</h1>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2 style={{ fontSize: "20px", marginTop: "12px" }}>
                  La meilleure mise en scène virtuelle de salle de bain à portée
                  de main
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Rapide :</strong> Transformez votre salle de bain en
                    quelques secondes
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Facile :</strong> Concevez des salles de bain
                    attrayantes sans effort
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Qualité :</strong> Résultats hyper-réalistes pour
                    les salles de bain
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Aide :</strong> Assistance d'experts en design de
                    salle de bain
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Essayer Pedra <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        29€ pour 100 images
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa, Directeur de la photographie chez aProperties"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera, Agent immobilier chez Huspy"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata, PDG chez Actívox"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart, PDG chez Gilart Consultors"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      ></img>
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        Plus de 100 témoignages
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={exemple.imageAvant}
                  afterImage={exemple.imageApres}
                  altBefore={
                    "Avant la mise en scène virtuelle de salle de bain"
                  }
                  altAfter={"Après la mise en scène virtuelle de salle de bain"}
                />

                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        exemple.nom === "Moderne" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Moderne")}
                    >
                      Salle de bain 1
                    </div>
                    <div
                      className={`hero-button ${
                        exemple.nom === "Traditionnel" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Traditionnel")}
                    >
                      Salle de bain 2
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        exemple.nom === "Rustique" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Rustique")}
                    >
                      Salle de bain 3
                    </div>
                    <div
                      className={`hero-button ${
                        exemple.nom === "Contemporain" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Contemporain")}
                    >
                      Salle de bain 4
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          PLUS DE 20 000 PROFESSIONNELS DE L'IMMOBILIER FONT DÉJÀ CONFIANCE À
          PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Mise en scène virtuelle de salle de bain pour{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            vendre plus rapidement
          </span>{" "}
          tout type de propriété.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeSinkIcon />
                <h3 className="feature-title">
                  Rénovez votre salle de bain virtuellement
                </h3>
                <div className="feature-text">
                  Transformez votre salle de bain en un clic, sans travaux ni
                  dépenses
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">
                  Mettez en valeur les caractéristiques uniques
                </h3>
                <div className="feature-text">
                  Soulignez les points forts de votre salle de bain avec du
                  texte descriptif
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">
                  Améliorez et corrigez les photos de salle de bain
                </h3>
                <div className="feature-text">
                  Optimisez la qualité et l'éclairage de vos photos de salle de
                  bain
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h3 className="feature-title">
                  Ajoutez des équipements élégants
                </h3>
                <div className="feature-text">
                  Modernisez votre salle de bain avec des équipements virtuels
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h3 className="feature-title">Renouvelez les finitions</h3>
                <div className="feature-text">
                  Changez les carrelages, les murs et les finitions en un seul
                  clic
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h3 className="feature-title">
                  Nettoyez et organisez virtuellement
                </h3>
                <div className="feature-text">
                  Supprimez le désordre et les objets indésirables pour une
                  salle de bain impeccable
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Exemples de notre mise en scène virtuelle de salle de bain.
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="hero-example-container">
          <CurtainEffect
            beforeImage={stagingExample.imageAvant}
            afterImage={stagingExample.imageApres}
            altBefore={"Avant la mise en scène virtuelle de salle de bain"}
            altAfter={"Après la mise en scène virtuelle de salle de bain"}
          />

          <div style={{ display: "flex", flex: "1 0 0" }}></div>
          <div className="hero-example-buttons-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "6px",
                width: "100%",
              }}
            >
              {stagingExamples.map((item) => (
                <div
                  key={item.nom}
                  className={`hero-button ${
                    stagingExample.nom === item.nom ? "selected" : ""
                  }`}
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    borderRadius: "8px",
                    overflow: "hidden",
                    height: "50px",
                    padding: "0px",
                  }}
                  onClick={() => handleStagingExample(item.nom)}
                >
                  <img
                    src={item.imageAvant}
                    alt={item.nom}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "6px",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container" style={{ display: "inline" }}>
          Comment fonctionne la mise en scène virtuelle de salle de bain ?
        </h3>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              ÉTAPE 1
            </h5>
            <h2>Téléchargez des photos de votre salle de bain</h2>
            <div className="value-prop-text">
              Téléchargez une ou plusieurs images de votre salle de bain sur
              Pedra.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample1}
              alt="Comment télécharger des photos de salle de bain pour créer une mise en scène virtuelle sur Pedra - Infographie"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              ÉTAPE 2
            </h5>
            <h2>
              Choisissez les options de transformation pour votre salle de bain
            </h2>
            <div className="value-prop-text">
              Sélectionnez parmi plusieurs options pour rénover virtuellement
              votre salle de bain.
              <p>
                Vous pouvez changer les carrelages, ajouter des équipements,
                réorganiser l'espace, et bien plus encore.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample2}
              alt="Comment choisir les options pour créer une mise en scène virtuelle de salle de bain - Infographie"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              ÉTAPE 3
            </h5>
            <h2>Recevez et téléchargez votre salle de bain transformée</h2>
            <div className="value-prop-text">
              En quelques minutes, vous obtiendrez des images réalistes de votre
              salle de bain rénovée.
              <p>
                Téléchargez les images et utilisez-les pour promouvoir votre
                propriété.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample3}
              alt="Comment télécharger la mise en scène virtuelle de salle de bain - Infographie"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Mise en scène virtuelle de salle de bain pour 29€ par mois. <br />{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            Et sans engagement.
          </span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Plan Pro
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">29€</div>
              <div className="modal-product-plan-description-text-3">
                par mois
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">Inclut</div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#B15DE1" }}>
                100 générations d'images
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>par mois</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Rénovez et redécorez les salles de bain
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Changez les finitions de salle de bain
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Ajoutez des équipements élégants
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Supprimez les objets indésirables
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Augmentez la résolution des photos
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Changez le design de la salle de bain
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Améliorez la photographie de salle de
              bain
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Plans de salle de bain illimités
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Partage de projets illimité
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Ajoutez des filigranes
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Support illimité
            </div>
            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Essayer Pedra
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Annulez à tout moment.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Achetez plus d'images à 0,20€ par image si vous en avez besoin.
            </div>
          </div>
        </div>
      </div>

      <div className="long-break"> </div>

      <div className="section-one-column-left">
        <div className="title-container"></div>
        <div className="title-container">
          Questions et réponses sur la mise en scène de salle de bain
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">
                Qu'est-ce que la mise en scène de salle de bain ?
              </h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                La mise en scène de salle de bain est une technique de marketing
                immobilier qui se concentre sur la préparation et la
                présentation de la salle de bain d'une propriété de manière
                attractive pour les acheteurs ou locataires potentiels. Cela
                peut inclure le nettoyage, l'organisation, la décoration
                stratégique et de petites améliorations pour mettre en valeur
                les points forts de la salle de bain et minimiser ses défauts.
                <br />
                <br />
                Dans le cas de la mise en scène virtuelle de salle de bain, nous
                utilisons une technologie avancée pour transformer numériquement
                l'espace, montrant son potentiel sans nécessiter de changements
                physiques coûteux.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Quels sont les avantages de la mise en scène virtuelle pour les
                salles de bain ?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                La mise en scène virtuelle pour les salles de bain offre de
                nombreux avantages :
                <br />
                <br />
                1. Économies de coûts : Pas besoin de rénovations physiques
                coûteuses.
                <br />
                2. Gain de temps : Les transformations sont réalisées en
                minutes, pas en semaines.
                <br />
                3. Polyvalence : Vous pouvez montrer plusieurs styles et
                configurations de la même salle de bain.
                <br />
                4. Attrait visuel : Aide les acheteurs à visualiser le potentiel
                de la salle de bain.
                <br />
                5. Différenciation sur le marché : Démarquez-vous avec des
                images de salle de bain attrayantes.
                <br />
                6. Pas de perturbation : Pas besoin d'interrompre la vie des
                occupants actuels.
                <br />
                7. Amélioration de la présentation en ligne : Idéal pour les
                annonces immobilières en ligne.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                Que puis-je faire avec la mise en scène de salle de bain de
                Pedra ?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Avec la mise en scène virtuelle de salle de bain de Pedra, vous
                pouvez effectuer une grande variété de transformations :
                <br />
                <br />
                1. Rénover les salles de bain : Vous pouvez utiliser les styles
                prédéfinis de Pedra ou créer vos propres styles personnalisés
                pour transformer complètement l'apparence de la salle de bain.
                <br />
                2. Améliorer la lumière et la couleur : Optimisez l'éclairage et
                les couleurs des photographies de salle de bain pour les rendre
                plus attrayantes et professionnelles.
                <br />
                3. Supprimer des objets : Effacez les éléments indésirables des
                photos, tels que les vieux équipements, le désordre ou les
                objets personnels.
                <br />
                4. Ajouter des éléments : Incorporez de nouveaux objets dans la
                salle de bain, comme des équipements modernes, des décorations
                ou des accessoires.
                <br />
                5. Changer les finitions : Modifiez l'apparence des carrelages,
                des murs et d'autres finitions pour montrer différentes options
                de design.
                <br />
                6. Réorganiser l'espace : Visualisez différentes dispositions de
                salle de bain sans avoir besoin de changements physiques.
                <br />
                <br />
                Toutes ces transformations sont réalisées virtuellement, vous
                permettant de montrer rapidement et économiquement le potentiel
                de la salle de bain.
              </div>
            )}
          </div>

          {/* Ajoutez plus de FAQ ici */}
        </div>
      </div>
    </div>
  );
}

export default HomeStagingBathroomFr;
